/* eslint-disable complexity */
import React, { useState } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import BarChart from '@Components/Finance/BarChart';
import DonutCharts from '@Components/Finance/DonutCharts';
import DonutChartsLTAT from '@Components/Finance/DonutChartLTAT';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import HorizontalBarCharts from '@Components/Finance/HorizontalBarCharts';
import BillingCollectionForm from '@Components/Finance/BillingCollectionForm';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import PieChart from '@Components/Finance/PieChart';
import NonReitFinanceForm from '@Components/Finance/NonReitFinanceForm';
import { yearComparisons, t } from '@Helpers';
import Hook from './hook';

function Finance(props) {
  const h = Hook(props);
  const [active, setActive] = useState('Revenue');

  const currentMonth = h.monthly_bar_data.Revenue.filter(d => !!d.collection).slice(-1)[0]?.month;
  const FinancialProgressDonutChart = (childProps) => (
    ['321ac1e2-935f-486a-ad3a-a3d89827d1b3'].includes(props.building.uuid)
      ? <DonutChartsLTAT {...childProps} />
      : <DonutCharts {...childProps} />);
  return (
    <>
      <MainContentNavbar
        to="/property"
        text="FINANCIAL"
        upload={props.building.DATA_SOURCE !== 'TIMBERLINE' && <NonReitFinanceForm uuid={props.building.uuid} />}
      />
      <MainContentContainer>
        <Grid container spacing={3}>
          {h.data.map((item, index) => (
            <Grid key={index} item xs={12} md={6} lg={3}>
              <FinancialProgressDonutChart data={item} active={active} setActive={setActive} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <BarChart
              title={`${active} ${yearComparisons(2)[0]} vs ${yearComparisons(2)[1]}`}
              style={{ border: '1px solid rgba(30, 52, 101, 1)' }}
              legend={yearComparisons(2)}
              data={h.monthly_bar_data[active]}
              loading={h.isLoading}
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <ArrowLink title="Revenue" to="/property/finance/income-and-expenditure" />
            <div className="paper" style={{ padding: 20, marginBottom: 20 }}>
              <HorizontalBarCharts
                width={1200}
                title={`Current Month (${h.latest_updated})`}
                labelPrefix="RM"
                data={[
                  { x: 'Actual', y: h.monthly_bar_data.Revenue.filter(d => !!d.collection).slice(-1)[0]?.collection },
                  { x: 'Budget', y: h.budgetByMonth[currentMonth]?.REV },
                ]}
              />
              <HorizontalBarCharts
                width={1200}
                title="Year to Date"
                labelPrefix="RM"
                data={[
                  { x: 'Actual', y: h.ytd_revenue_actual },
                  { x: 'Budget', y: h.ytd_revenue_budget },
                ]}
              />
            </div>
          </Grid>

          {!props.noServiceCharge && (
            <Grid item md={12} lg={6}>
              <div style={{ display: 'flex' }}>
                <ArrowLink title="Service Charge" to="/property/finance/service-charge" />
                {h.isServiceChargeLoading && <CircularProgress disableShrink size={35} style={{ marginLeft: 20 }} />}
              </div>
              <div className="paper" style={{ padding: 20 }}>
                {h.service_charge_piechart.length > 0 ? (
                  <Grid container>
                    <Grid item xs={8}>
                      <HorizontalBarCharts
                        width={800}
                        title={`Current Month (${h.latest_updated})`}
                        labelPrefix="RM"
                        data={[
                          { x: 'Actual', y: h.sc_actual_total },
                          { x: 'Budget', y: h.sc_budget_total },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <p
                        style={{
                          textAlign: 'center',
                          fontSize: 14,
                          marginBottom: 5,
                        }}
                      >
                        Total Saving
                      </p>
                      <p
                        style={{
                          textAlign: 'center',
                          fontSize: 28,
                          color: '#2ECCA9',
                          marginBottom: 5,
                        }}
                      >
                        {h.service_charge_piechart.length > 0
                          ? `RM ${(parseInt(h.sc_budget_total - h.sc_actual_total)).toLocaleString()}`
                          : 'N/A'}
                      </p>
                      <p style={{ textAlign: 'center', fontSize: 14 }} className="text-light">
                        NLA: {h.NLA?.toLocaleString()} ft²
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ marginTop: 30 }}>
                        {!!h.sc_actual_total && <PieChart data={h.service_charge_piechart} />}
                      </div>
                    </Grid>
                  </Grid>
                ) : (<p style={{ textAlign: 'center', fontSize: 28, color: '#2ECCA9' }}>N/A</p>)}
              </div>
            </Grid>
          )}

          <Grid item md={12} lg={6}>
            <ArrowLink title="Operating Expenses" to="/property/finance/operating-expenses" />
            <div className="paper" style={{ padding: 20 }}>
              <HorizontalBarCharts
                width={1200}
                title={`Current Month (${h.latest_updated})`}
                labelPrefix="RM"
                data={[
                  { x: 'Actual', y: h.monthly_bar_data.Expenses.filter(d => !!d.collection).slice(-1)[0]?.collection },
                  { x: 'Budget', y: h.budgetByMonth[currentMonth]?.EXP },
                ]}
              />
              <HorizontalBarCharts
                width={1200}
                title="Year to Date"
                labelPrefix="RM"
                data={[
                  { x: 'Actual', y: h.ytd_expenses_actual },
                  { x: 'Budget', y: h.ytd_expenses_budget },
                ]}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ArrowLink title="Billing &amp; Collection" to="/property/finance/billing-and-collection" />
              {h.isBillingLoading && <CircularProgress disableShrink size={35} style={{ marginLeft: 20 }} />}
              {props.building.DATA_SOURCE !== 'TIMBERLINE' && <BillingCollectionForm uuid={props.building.uuid} data={h.billing_data} />}
            </div>
            <BarChart legend={['Billing', 'Collection']} data={h.billing_collection} />
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default Finance;

function ArrowLink(props) {
  return (
    <h2
      style={{
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10,
      }}
    >
      {t(props.title)}
      <Link to={props.to} className="arrow-link">
        <KeyboardArrowRight />
      </Link>
    </h2>
  );
}
