export const URL = process.env.REACT_APP_ENDPOINT_URL;
export const CCTV = process.env.REACT_APP_CCTV_SERVER;
export const DAXSERVEDECK = process.env.REACT_APP_ENDPOINT_URL_DAX_SERVEDECK;
export const DAXLIGHTMAN = process.env.REACT_APP_ENDPOINT_URL_DAX_LIGHTMAN;

export const endpoints = {
  getStaticData: () => ['GET', `${URL}staticdata`],
  getToken: () => ['POST', `${URL}login`],
  register: () => ['POST', `${URL}signup`],
  subscribe: () => ['POST', `${URL}subscribe`],
  getProfile: () => ['GET', `${URL}api/user`],
  activityLog: () => ['GET', `${URL}api/activitylog`],
  updateProfile: (id) => ['POST', `${URL}api/user/${id}`],
  requestTokenToResetPassword: () => ['POST', `${URL}forgot-password`],
  verifyResetPasswordToken: () => ['POST', `${URL}verify-reset-password-token`],
  resetPassword: () => ['POST', `${URL}reset-password`],
  getBuildings: () => ['GET', `${URL}api/building`],
  getCameras: () => ['GET', `${URL}api/camera`],
  getAttendances: () => ['GET', `${URL}api/attendance`],
  getFacilities: () => ['GET', `${URL}api/facility`],
  showFacilities: (uuid) => ['GET', `${URL}api/facility/${uuid}`],
  updateFacility: (uuid, year, month) => ['POST', `${URL}api/facility/${uuid}?YEAR=${year}&MONTH=${month}`],

  getParking: () => ['GET', `${URL}api/parking`],
  createSecurityparking: () => ['POST', `${URL}api/parking`],

  getSecurityincidence: () => ['GET', `${URL}api/securityincidence`],
  createSecurityincidence: () => ['POST', `${URL}api/securityincidence`],

  getSecuritymanning: () => ['GET', `${URL}api/securitymanning`],
  createSecuritymanning: () => ['POST', `${URL}api/securitymanning`],

  getSecuritydebtoraging: () => ['GET', `${URL}api/securitydebtoraging`],
  createSecuritydebtoraging: () => ['POST', `${URL}api/securitydebtoraging`],

  getSecurityfinancial: () => ['GET', `${URL}api/securityfinancial`],
  createSecurityfinancial: () => ['POST', `${URL}api/securityfinancial`],

  getProjectMonitor: () => ['GET', `${URL}api/projectmonitor`],
  createProjectMonitor: () => ['POST', `${URL}api/projectmonitor`],
  updateProjectMonitor: (id) => ['POST', `${URL}api/projectmonitor/${id}`],
  deleteProjectMonitor: (id) => ['DELETE', `${URL}api/projectmonitor/${id}`],

  getManpowers: () => ['GET', `${URL}api/manpower`],
  getTurnovers: () => ['GET', `${URL}api/turnover`],
  updateTurnovers: (id) => ['POST', `${URL}api/turnover/${id}`],
  getEmolument: (company) => ['GET', `${URL}api/emolument?COMPANY=${company}`],
  getEmolumentRaw: () => ['GET', `${URL}api/emolument`],
  getEmolumentBuilding: () => ['GET', `${URL}api/emolumentGetBuilding`],
  updateEmolument: (year, month) => ['POST', `${URL}api/emolument-update?YEAR=${year}&MONTH=${month}`],
  getDevelopmentBudget: () => ['GET', `${URL}api/development-budget`],
  getTraining: () => ['GET', `${URL}api/training`],

  getBudget: () => ['GET', `${URL}api/budget`],
  getDebtoraging: () => ['GET', `${URL}api/debtoraging`],
  updateDebtoraging: (uuid) => ['POST', `${URL}api/debtoraging/${uuid}`],
  getOccupancy: () => ['GET', `${URL}api/occupancy`],
  getSalesturnover: () => ['GET', `${URL}api/salesturnover`],
  getTenancy: () => ['GET', `${URL}api/tenancy`],
  getTmb: () => ['GET', `${URL}api/tmb`],
  getTla: () => ['GET', `${URL}api/tla`],
  getBilling: () => ['GET', `${URL}api/billing`],
  getServiceCharge: () => ['GET', `${URL}api/servicecharge`],
  createBilling: (month, year, uuid) => ['POST', `${URL}api/billing/${uuid}?MONTH=${month}&YEAR=${year}`],

  getTenantDetails: (uuid) => ['GET', `${URL}api/tenantdetails/${uuid}`],
  updateTenantDetails: (uuid) => ['POST', `${URL}api/tenantdetails/${uuid}`],

  getTrainingData: () => ['GET', `${URL}api/training-getfile`],
  updateTrainingData: () => ['POST', `${URL}api/training-uploadfile`],
  getManpowerData: () => ['GET', `${URL}api/manpower-getfile`],
  updateManpowerData: () => ['POST', `${URL}api/manpower-uploadfile`],

  getServedeck: () => ['GET', `${DAXSERVEDECK}`],
  getLightman: () => ['GET', `${DAXLIGHTMAN}`],

  getInspectionSession: () => ['GET', `${URL}api/inspection`],
  deleteInspection: (id) => ['DELETE', `${URL}api/inspection/${id}`],
  createInspection: (uuid) => ['POST', `${URL}api/inspection/${uuid}`],
  getInspectionFile: () => ['GET', `${URL}api/inspectionfile`],
  updateInspectionFileAnnotate: () => ['POST', `${URL}api/inspectionfile-annotate`],
  uploadInspectionFile: uuid => ['POST', `${URL}api/inspectionfile/${uuid}`],
  getFootfall: () => ['GET', `${URL}api/vta/footfall`],

  getFinance: (uuid, month) => ['GET', `${URL}api/finance/${uuid}?MONTH=${month}`],
  updateFinance: (uuid, month) => ['POST', `${URL}api/finance/${uuid}?MONTH=${month}`],

  getDivisions: () => ['GET', `${URL}api/division`],
  getProjects: () => ['GET', `${URL}api/project`],
  updateProjects: (id) => ['POST', `${URL}api/project/${id}`],
  getAnnotations: () => ['GET', `${URL}api/annotation`],
  getCompliances: () => ['GET', `${URL}api/annotation-compliance`],
  getAnnotationStatistics: () => ['GET', `${URL}api/annotation-statistic`],

  getReports: () => ['GET', `${URL}api/report`],
  createReport: () => ['POST', `${URL}api/report`],
  showReport: (id) => ['GET', `${URL}api/report/${id}`],
  deleteReport: (id) => ['DELETE', `${URL}api/report/${id}`],

  getStoredReport: () => ['GET', `${URL}api/storedreport`],
  createStoredReport: (uuid) => ['POST', `${URL}api/storedreport/${uuid}`],
  deleteStoredReport: (id) => ['DELETE', `${URL}api/storedreport/${id}`],

  getQualityProgress: () => ['GET', `${URL}api/qualityprogress`],
  getQualityProgressSummary: () => ['GET', `${URL}api/qualityprogress-summary`],
  createQualityProgress: (uuid) => ['POST', `${URL}api/qualityprogress/${uuid}`],

  getPreqlassic: () => ['GET', `${URL}api/preqlassic`],
  getPreqlassicSummary: () => ['GET', `${URL}api/preqlassic-summary`],
  createPreqlassic: (uuid) => ['POST', `${URL}api/preqlassic/${uuid}`],

  getConquas: () => ['GET', `${URL}api/conquas`],
  createConquas: () => ['POST', `${URL}api/conquas`],

  getContractService: (id) => ['GET', `${URL}api/contractservice?BuildingId=${id}`],
  createContractService: (BuildingId) => ['POST', `${URL}api/contractservice/${BuildingId}`],

  getFireKpiAll: () => ['GET', 'https://dax-development-default-rtdb.firebaseio.com/kpi.json'],
  setFireKpi: () => ['PATCH', 'https://dax-development-default-rtdb.firebaseio.com/kpi.json'],
  getFireKpi: (YEAR) => ['GET', `https://dax-development-default-rtdb.firebaseio.com/kpi/${YEAR}.json`],

  getFireCidbAll: () => ['GET', 'https://dax-development-default-rtdb.firebaseio.com/cidb.json'],
  setFireCidb: () => ['PATCH', 'https://dax-development-default-rtdb.firebaseio.com/cidb.json'],
  getFireCidb: (YEAR) => ['GET', `https://dax-development-default-rtdb.firebaseio.com/cidb/${YEAR}.json`],

  getUsers: () => ['GET', `${URL}api/users`],
  getUser: () => ['GET', `${URL}api/user`],
  updateUser: (uuid) => ['POST', `${URL}api/user/${uuid}`],
};
