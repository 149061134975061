import React from 'react';
import { TextField } from '@material-ui/core';
import Button from '@Components/Button';
import AddIcon from '@material-ui/icons/Add';

export default (h) => {
  return (
    <div style={{ padding: '0 24px' }}>
      {h.incidenceTypes.map((d, idx) => (
        <CustomField
          placeholder={d.incidence_type}
          autoFocus={!idx}
          value={h.inputIncidence.find(x => x.SecurityIncidenceTypeId === d.id)?.incidence
            || h.selectedIncidenceData.find(x => x.SecurityIncidenceTypeId === d.id)?.incidence 
            || ''}
          onChange={e => {
            h.setInputIncidence(eachInput => {
              const input = [...eachInput];
              const objIdx = input.findIndex(x => x.SecurityIncidenceTypeId === d.id);
              if (objIdx >= 0) {
                input[objIdx].incidence = Number(e.target.value);
                input[objIdx].year = h.year;
                input[objIdx].month = h.month;
              } else {
                input.push({
                  SecurityIncidenceTypeId: d.id,
                  incidence: Number(e.target.value),
                  year: h.year,
                  month: h.month,
                });
              }
              return input;
            });
          }}
        />
      ))}
      <div className="flex">
        <Button onClick={h.handleSave} startIcon={<AddIcon />}>
          Save Data
        </Button>
        <Button variant="text" onClick={h.handleBackStep}>
          Return
        </Button>
      </div>
    </div>
  );
};

const CustomField = (props) => (
  <div className="flex-standard" style={{ marginBottom: 20 }}>
    <p style={{ width: 400, whiteSpace: 'nowrap', fontWeight: 600 }}>{props.placeholder}</p>
    <TextField variant="outlined" fullWidth size="small" {...props} />
  </div>
);
