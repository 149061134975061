import React, { useState } from 'react';
import {
  Dialog, Button, DialogActions, DialogContent, DialogContentText, IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default ({
  onDeleteStored, user, row,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  const canDelete = !!user.can_delete_stored_report;

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <DeleteIcon style={{ color: canDelete && 'red' }} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {canDelete
              ? 'Are you sure you want to delete this?'
              : 'You have no access to delete this stored report.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{canDelete ? 'Cancel' : 'Close'}</Button>
          {canDelete && (
            <Button Button onClick={() => onDeleteStored(row.id)} color="secondary" autoFocus>
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
