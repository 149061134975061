import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';
import { searchItems } from '@Helpers';

export default function Hook() {
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [search_key, setsearch_key] = useState('');
  const [user_role, setuser_role] = useState('all');
  const [page, setPage] = useState(1);
  const [pageAccessList, setPageAccessList] = useState([]);
  useEffect(() => {
    refresh();
  }, [page]);
  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => setPageAccessList(data.PageAccess ?? []),
    });
  }, []);
  const refresh = () => {
    Api({
      endpoint: endpoints.getUsers(),
      data: { perpage: 100 },
      onSuccess: ({ data }) => {
        const osh_filter = _.filter(data, { dax_role: 'osh_site' });
        setUsers(osh_filter);
        setAllUsers(osh_filter);
      },
      onFail: (e) => console.log('error', e),
    });
  };
  useEffect(() => {
    const keys = ['name', 'RoleId'];
    const values = [search_key, user_role];
    const result = searchItems(allUsers, keys, values);
    // console.log('mmm search', result, keys, values);
    setUsers(result);
  }, [search_key, user_role]);
  return {
    users,
    setUsers,
    allUsers,
    setAllUsers,
    search_key,
    setsearch_key,
    user_role,
    setuser_role,
    page,
    setPage,
    pageAccessList,
  };
}
