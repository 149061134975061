import moment from 'moment';
import _ from 'lodash';
import en from '@Assets/Lang/en.json';

export const t = (s) => en[s] ?? s;

export const formatTime = (unix, format) => moment.unix(unix / 1000).format(format);

export const getDates = (time) => moment(time, 'x').format('D');

export const changeDayToIndex = (day) => {
  const graphFirstDay = moment().subtract(30, 'days').startOf('day').valueOf();
  day = parseInt(day);
  const today = moment().format('D');
  const gFirstDay = getDates(graphFirstDay);
  const lastMonthTotalDays = parseInt(moment(graphFirstDay).clone().endOf('month').format('D'));

  const dayToIndex = day - gFirstDay + 1;
  return (day > today) ? dayToIndex : dayToIndex + lastMonthTotalDays;
};

export const initialsFromUser = (user) => {
  const str = user.name || user.email;
  if (!str) return '';
  return str
    .replace(/[^0-9a-z ]/gi, '')
    .split(' ')
    ?.map((i) => i.charAt(0))
    .join('')
    .toUpperCase()
    .slice(0, 2);
};

export const MoneyFormat = (labelValue) => {
  const num = Math.abs(Number(labelValue));
  return num >= 1.0e+9
    ? `${num / 1.0e+9 }B`
    : num >= 1.0e+6
      ? `${num / 1.0e+6 }M`
      : num >= 1.0e+3
        ? `${num / 1.0e+3 }K`
        : num;
};

export const groupByYear = (obj) => _.groupBy(obj, e => e.YEAR);

export const groupByYearAndMonth = (obj) => {
  const res = groupByYear(obj);
  _.forEach(res, (value, key) => {
    res[key] = _.groupBy(res[key], item => item.MONTH);
  });
  return res;
};

export const truncateString = (str, num) => {
  if (!str) return;
  if (num > str.length) {
    return str;
  }
  str = str.substring(0, num);
  return `${str } ...`;
};

export const replaceUnderscore = (data) => {
  return data.replace(/_/g, '-');
};

export const searchItems = (arr, keys, query) => _.filter(arr, (o) => {
  const ifAny = keys.map((attr, idx) => (query[idx] === 'all' ? true : String(o[attr]).indexOf(String(query[idx])) !== -1));
  return ifAny.filter(Boolean).length === keys.length;
});

export const replaceSpace = (text) => {
  if (!text) return;
  return text.replace(/_/g, ' ');
};

export const monthsSequence = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const lastYear = moment().year() - 1;
export const currentYear = moment().year();
export const currentMonth = moment().month() + 1;

export const yearComparisons = (m) => {
  const yc = [moment().year() - 1, moment().year()];
  return (m && currentMonth < m) ? yc.map(y => y - 1) : yc;
};

export const monthComparisons = (m, raw_deduction) => moment().subtract(raw_deduction || (currentMonth < m ? currentMonth : 1), 'months');

export const rentalRate = (rate, area) => {
  if(!!rate && !!area){
    return rate/area;
  } else {
    return 0;
  }
}