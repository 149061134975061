import React from 'react';
import { VictoryPie, VictoryLabel, VictoryLegend } from 'victory';
import moment from 'moment';

function OccupancyRateChart({ NLA, NLA_OC, updatedAt }) {
  const percentage_oc = Number.isNaN((NLA_OC / NLA) * 100) ? 0 : (NLA_OC / NLA) * 100;
  const graph_data = [
    {
      x: 1,
      y: parseInt(percentage_oc.toFixed(0)),
    },
    {
      x: 2,
      y: 100 - parseInt(percentage_oc.toFixed(0)),
    },
  ];
  const LegendData = [
    {
      name: 'NLA',
      symbol: { fill: '#82C9FF', type: 'square' },
    },
    {
      name: 'NLA Occupied',
      symbol: { fill: '#008FFB', type: 'square' },
    },
  ];
  return (
    <>
      <h2 style={{ fontWeight: 600, width: '100%', textAlign: 'center' }}>Occupancy Rate</h2>
      <p style={{ textAlign: 'center' }}>As of {moment(updatedAt).format('DD MMM YYYY')}</p>
      <svg viewBox="0 0 400 340">
        <VictoryPie
          colorScale={['#008FFB', '#82C9FF']}
          standalone={false}
          width={340}
          height={340}
          data={graph_data}
          innerRadius={64}
          labelComponent={<VictoryLabel style={{ fontFamily: 'Poppins', fontSize: 14 }} />}
          labels={[]}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 28 }}
          x={170}
          y={170}
          text={`${percentage_oc.toFixed(0).toLocaleString()}%`}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontFamily: 'Poppins', fontSize: 20 }}
          x={350}
          y={170}
          text={`NLA\nOccupied\n ${NLA_OC?.toLocaleString() ?? 0} ft²`}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontFamily: 'Poppins', fontSize: 20 }}
          x={170}
          y={320}
          text={`NLA ${NLA?.toLocaleString() ?? 0} ft²`}
        />
        <VictoryLegend
          x={270}
          y={30}
          orientation="horizontal"
          standalone={false}
          itemsPerRow={1}
          gutter={20}
          data={LegendData}
          style={{ labels: { fontFamily: 'Poppins', fontSize: 14 } }}
        />
      </svg>
    </>
  );
}

export default OccupancyRateChart;
