import Button from '@Components/Button';
import Navbar from '@Components/IntegratedFacilities/Navbar';
import MainContentContainer from '@Components/MainContentContainer';
import UploadIcon from '@Components/UploadIcon';
import { Link } from 'react-router-dom';
import DasbSecurityIncident from './components/DasbSecurityIncident';
import Security from './components/Security';
import SecurityManningTable from './components/SecurityManningTable';
import useHook from './hook';

export default function DasbSecurity(props) {
  const h = useHook();
  return (
    <>
      <Navbar dax_role={props.user?.dax_role} />
      <div className="flex space-between">
        <h1 style={{ fontWeight: 700, marginBottom: 10 }}>
          DASB Security
        </h1>
        <Link to="/integrated-facilities/security/upload-data">
          <Button startIcon={<UploadIcon />}>
            Upload Data
          </Button>
        </Link>
      </div>
      <MainContentContainer>
        <Security {...h} />
        <DasbSecurityIncident {...h} />
        <SecurityManningTable {...h} />
      </MainContentContainer>
    </>
  );
}
