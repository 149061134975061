/* eslint-disable max-lines */
import {
  Grid,
  TextField,
  FormControl,
  NativeSelect,
  withStyles,
  InputBase,
} from '@material-ui/core';
import BarChart from '@Components/IntegratedFacilities/BarChart';
import ContactServicingInfo from '@Components/IntegratedFacilities/ContactServicingInfo';

import TopRecurringIssue from '@Components/IntegratedFacilities/TopRecurringIssue';
// import YtdRevenue from '@Components/IntegratedFacilities/YtdRevenue';
import _ from 'lodash';
import moment from 'moment';
import ServiceContracts from '@Components/IntegratedFacilities/ServiceContracts';
import { IssuesCards, PreventivesCards } from '@Components/IntegratedFacilities/SummaryCards';
import { Cube3D, Search } from '@Assets/Icons';
import { MapIcon } from '@Assets/Icons/TopbarIcons';
import Wordcloud from '@Components/WordcloudIssues';
import Dropdown from '@Components/Dropdown';
import RoundedButton from '@Components/RoundedButton';
import Navbar from '@Components/IntegratedFacilities/Navbar';
import Hook from './hook';
import ManualDataForm from './ManualDataForm';
import MonitoringForm from './MonitoringForm';

import Title from './components/Title';
import Legend from './components/Legend';
import BuildingData from './components/BuildingData';
import ProjectMonitorTable from './components/ProjectMonitorTable';
import DonutCharts from './components/DonutCharts';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const get_latest_month = (arr) => arr?.slice(-1)[0];

export default function IntegratedFacilities(props) {
  const h = Hook(props);

  // LSC
  const contractServiceData = h.facilityDetails.lsc?.map(({ ITEM: serviceType, EXPIRY_DATE: expiringDate }) => {
    const expDate = expiringDate?.length ? moment(expiringDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'N/A';
    return {
      serviceType,
      expiringDate: expDate === 'Invalid date' ? 'N/A' : expDate,
    };
  });

  // RCE
  const sorted_rce = _.sortBy(h.facilityDetails.rce, ['YEAR', 'MONTH']);
  const latest_month = get_latest_month(sorted_rce)?.MONTH;
  const rce_till_latest_month = [...Array(latest_month)].map(
    // fill missing month with 0
    (e, i) => sorted_rce.find(r => r.MONTH === i + 1) ?? { MONTH: i + 1, BUDGET: 0, EXPENDITURE: 0 },
  );
  const rce_bar = rce_till_latest_month?.map(({ MONTH: x, EXPENDITURE: y2, BUDGET: y1 }) => ({ x: moment(x, 'M').format('MMM'), y1, y2 }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Navbar dax_role={props.user?.dax_role} />
        <Title />
      </Grid>
      <Grid container item spacing={3}>
        <Grid item md={6} xs={12}>
          <div className="main-content" style={{ marginBottom: 20 }}>
            {/* <YtdRevenue {...h.ytd} /> */}
            {h.monthlyGross && (
              <>
                <Grid container alignItems="center" justify="center" style={{ marginTop: 30 }}>
                  <h3 style={{ fontWeight: 600, marginRight: 20 }}>2021 Monthly Revenue</h3>
                  <Legend color="#1F3566" title="Actual" />
                  <Legend color="#B2DEFF" title="Target" />
                </Grid>
                <BarChart data={h.monthlyGross} />
              </>
            )}
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className="main-content">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 style={{ marginBottom: 20, marginTop: 15, fontWeight: 600 }}>Project Management Monitoring</h2>
              <MonitoringForm {...h} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField label="Search" variant="outlined" size="small" />
              <p style={{ marginLeft: 50 }}>Show:</p>
              <FormControl style={{ marginLeft: 20 }}>
                <NativeSelect
                  value={h.showDropdown}
                  onChange={(e) => h.setShowDropdown(e.target.value)}
                  input={<BootstrapInput />}
                >
                  {[5, 10, 20].map(i => <option key={i} value={i}>{i}</option>)}
                </NativeSelect>
              </FormControl>
              <FormControl style={{ marginLeft: 20 }}>
                <NativeSelect
                  value={h.sortDropdown}
                  onChange={(e) => h.setSortDropdown(e.target.value)}
                  input={<BootstrapInput />}
                >
                  <option value="">Sort by</option>
                  <option value="project">Project</option>
                  <option value="contract">Contract Amount</option>
                  <option value="status">Status</option>
                  <option value="current stage">Current Stage</option>
                </NativeSelect>
              </FormControl>
              <FormControl style={{ marginLeft: 20 }}>
                <NativeSelect
                  value={h.filterDropdown}
                  onChange={(e) => h.setFilterDropdown(e.target.value)}
                  input={<BootstrapInput />}
                >
                  <option value="">Filter by</option>
                  <option value="ontime">On Time</option>
                  <option value="delayed">Delayed</option>
                </NativeSelect>
              </FormControl>
            </div>
            <div className="paper" style={{ padding: '10px 20px', marginTop: 20 }}>
              <ProjectMonitorTable {...h} />
            </div>
          </div>
          <div className="main-content">
            <Grid container>
              <Wordcloud {...h} />
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container item>
        <div className="main-content" style={{ padding: '30px' }}>
          <Grid container justify="space-between">
            <div style={{ display: 'flex' }}>
              <Dropdown
                selectedItem={h.selectedFacilities}
                setSelectedItem={h.setSelectedFacilities}
                itemList={h.facilities.map(e => e.NAME)}
                itemKey="NAME"
                width={400}
              />
              <div style={{ display: 'flex', marginLeft: 20 }}>
                {props.building.threeD && (
                  <RoundedButton to="/3d" text="3D data">
                    <Cube3D />
                  </RoundedButton>
                )}
                <RoundedButton to="#" text="Inspection">
                  <Search />
                </RoundedButton>
                {props.building.orthophoto && (
                  <RoundedButton to="/orthophoto" text="Orthophoto">
                    <MapIcon />
                  </RoundedButton>
                )}
              </div>
            </div>
            <ManualDataForm {...h} />
          </Grid>
          <Grid container spacing={6}>
            <Grid container item md={5} xs={12}>
              <BuildingData {...h} {...props} />
            </Grid>
            <Grid container item md={7} xs={12}>
              <DonutCharts {...h} />
              <Grid item xs={12}>
                <IssuesCards {...h} />
              </Grid>
              {!!rce_bar.length && (
                <Grid item md={12}>
                  <Grid container alignItems="center">
                    <h3 style={{ fontWeight: 600, marginRight: 20, marginTop: 10 }}>Repair Cost Expenditure</h3>
                    <Legend color="#1F3566" title="Actual" />
                    <Legend color="#B2DEFF" title="Budget" />
                  </Grid>
                  {/* <BarChart topLabel={'Repair Cost Expenditure'} legend={['Actual', 'Budget']} data={rce_bar} /> */}
                  <BarChart data={rce_bar} />
                </Grid>
              )}
              <Grid item xs={12}>
                <PreventivesCards {...h} />
              </Grid>
              <Grid item md={12} lg={7}>
                <h3 style={{ fontWeight: 600, marginBottom: 10 }}>Licensing Statutory and Calibration</h3>
                <ContactServicingInfo data={contractServiceData} />
              </Grid>
              {/* <Grid item md={12} lg={4}>
              <h4 style={{ fontWeight: 400, marginBottom: 10 }}>Contract Servicing Info</h4>
              <ContactServicingInfo data={contractServiceData} />
            </Grid> */}
              <Grid item md={12} lg={5}>
                <h3 style={{ fontWeight: 600, marginBottom: 10 }}>Top Recurring Issues</h3>
                <TopRecurringIssue {...h} />
              </Grid>
              <Grid item md={12}>
                <h3 style={{ fontWeight: 600, marginBottom: 10 }}>Service Contracts</h3>
                <ServiceContracts data={h.serviceContracts} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
