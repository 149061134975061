import { useState } from 'react';
import {
  Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import DonutCharts from '@Components/IntegratedFacilities/DonutCharts';
import _ from 'lodash';
import moment from 'moment';
import PreviousDataDialog from './PreviousDataDialog';

const get_latest_month = (arr) => arr?.slice(-1)[0];

export default (h) => {
  const [previousDataIsOpen, setPreviousDataIsOpen] = useState(false);
  const { facilityDetails } = h;

  // EEI
  const EEI = get_latest_month(facilityDetails.eei);
  const latest_month_energy = EEI?.ENERGY;
  const latest_eei = latest_month_energy / facilityDetails.GFA;
  const latest_eei_percent = (((facilityDetails.SPEC - latest_eei) / facilityDetails.SPEC) * 100);
  const latest_update_eei = `${moment(EEI?.MONTH, 'M').format('MMMM')} ${EEI?.YEAR}`;
  const is_eei_percent_positive = latest_eei_percent > 0;

  // RCE
  const sorted_rce = _.sortBy(facilityDetails.rce, ['YEAR', 'MONTH']);
  const latest_month_rce_budget = get_latest_month(sorted_rce)?.BUDGET;
  const latest_month_rce_expenditure = get_latest_month(sorted_rce)?.EXPENDITURE;
  let latest_month_rce_percent = ((latest_month_rce_expenditure / latest_month_rce_budget) * 100);
  const latest_month = get_latest_month(sorted_rce)?.MONTH;
  const latest_update_rce = `${moment(latest_month, 'M').format('MMMM')} ${get_latest_month(sorted_rce)?.YEAR}`;
  const is_rce_overbudget = latest_month_rce_percent < 100;
  if (Number.isNaN(latest_month_rce_percent)) latest_month_rce_percent = 0;


  return (
    <>
      <Grid item md={12} lg={4} onClick={() => { setPreviousDataIsOpen(true); }}>
        <p className="text-light text-center">
          Electricity Energy Index
        </p>
        <h2 className="text-center" style={{ fontWeight: 600 }}>{latest_eei.toFixed(2)}</h2>
        <DonutCharts
          data={[
            { x: 1, y: 100 - latest_eei_percent },
            { x: 2, y: latest_eei_percent },
          ]}
          percent={`${-latest_eei_percent.toFixed(2)}%`}
          positive={is_eei_percent_positive}
          description={`target: < ${facilityDetails.SPEC}`}
          leftLabel={['Total Monthly', `${latest_month_energy?.toLocaleString()} kWh`]}
          rightLabel={['GFA', `${facilityDetails.GFA?.toLocaleString()} m²`]}
          latestUpdate={latest_update_eei}
        />
      </Grid>
      <Grid item md={12} lg={4}>
        <p className="text-light text-center">
          Repair Cost Expenditure
        </p>
        <h2 className="text-center" style={{ fontWeight: 600 }}>RM {latest_month_rce_expenditure?.toLocaleString()}</h2>
        <DonutCharts
          data={[
            { x: 1, y: latest_month_rce_percent },
            { x: 2, y: 100 - latest_month_rce_percent },
          ]}
          percent={`${latest_month_rce_percent.toFixed(2)}%`}
          positive={is_rce_overbudget}
          description={['budget:', `RM ${latest_month_rce_budget?.toLocaleString()}`]}
          latestUpdate={latest_update_rce}
        />
      </Grid>
      <Grid item md={12} lg={4}>
        <Link to="/integrated-facilities/facilities/incident-occurence">
          <p className="text-light text-center">
            Incident Occurrence
          </p>
          <h2 className="text-center" style={{ fontWeight: 600 }}>123</h2>
          <DonutCharts
            data={[
              { x: 1, y: 50 },
              { x: 2, y: 50 },
            ]}
            percent="-50%"
            positive={false}
            description="target: 0"
            latestUpdate={latest_update_rce}
          />
        </Link>
      </Grid>
      <PreviousDataDialog
        open={previousDataIsOpen}
        setOpen={setPreviousDataIsOpen}
        data={facilityDetails}
      />
    </>
  );
};
