import { Grid } from "@material-ui/core";
import Table from "@Components/Table";
import Rating from "@material-ui/lab/Rating";
import MainContentContainer from "@Components/MainContentContainer";
import MainContentNavbar from "@Components/MainContentNavbar";

let data = [];

for (let i = 0; i < 100; i++) {
  data.push({
    customer: (
      <div>
        <p>John Doe</p>
        <p className="text-light">0123456789</p>
      </div>
    ),
    gender: "Male",
    email: "johndoe@email.com",
    overallAccessibility: <Rating name="read-only" value={4} readOnly />,
    facilitiesProvided: <Rating name="read-only" value={3} readOnly />,
    cleanliness: <Rating name="read-only" value={4} readOnly />,
    shoppingExperiences: <Rating name="read-only" value={4} readOnly />,
    visitAgain: "Yes",
    recommend: "Yes",
  });
}

const columns = [
  {
    name: "Customer",
    selector: "customer",
    sortable: true,
    width: "150px",
  },
  {
    name: "Gender",
    selector: "gender",
    sortable: true,
    width: "80px",
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
    wrap: true,
    width: "200px",
  },
  {
    name: "Overall Accessibility",
    selector: "overallAccessibility",
    sortable: true,
    width: "150px",
  },
  {
    name: "Facilities Provided",
    selector: "facilitiesProvided",
    sortable: true,
    width: "150px",
  },
  {
    name: "Cleanliness",
    selector: "cleanliness",
    sortable: true,
    width: "150px",
  },
  {
    name: "Shopping Experiences",
    selector: "shoppingExperiences",
    sortable: true,
    width: "150px",
  },
  {
    name: "Visit Again",
    selector: "visitAgain",
    sortable: true,
  },
  {
    name: "Recommend",
    selector: "recommend",
    sortable: true,
  },
];

function UserFeedback(props) {
  return (
    <>
      <MainContentNavbar to="/property/maintenance" text="USER FEEDBACK" />
      <MainContentContainer>
        <Grid container>
          <Grid item xs={12}>
            <Table title="" columns={columns} data={data} />
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default UserFeedback;
