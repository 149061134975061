import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import BarChart from '@Components/HumanResources/BarChart';
import DonutChart from '@Components/HumanResources/DonutChart';
import HorizontalBarChart from '@Components/HumanResources/HorizontalBarChart';
import OverallEmployee from '@Components/HumanResources/OverallEmployee';
import PieChart from '@Components/HumanResources/PieChart';
import { Tabs } from '@Components/Tabs';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendarStyle.css';
import MaterialTable from '@Components/MaterialTable';
import moment from 'moment';
import Dropdown from '@Components/Dropdown';
import EmployeeDetailModal from '@Components/HumanResources/EmployeeDetailModal';
import { endpoints } from '@Helpers/api';
import Hook from './hook';
import UploadExcelForm from './components/UploadExcelForm';
import EmolumentForm from './components/EmolumentForm';
import TurnoverRateForm from './components/TurnoverRateForm';
import useManpower from './hook/manpower';
import useEmoluments from './hook/emoluments';
import useTrainingDevelopment from './hook/trainingDevelopment';

const columns = [
  {
    name: '',
    selector: 'name',
    align: 'left',
    minWidth: 100,
  },
  {
    name: '',
    selector: 'type',
    align: 'center',
    minWidth: 100,
  },
  {
    name: '',
    selector: 'leave',
    align: 'center',
    minWidth: 100,
  },
];

export default function HumanResources(props) {
  const h = Hook(props);
  const { buildings } = props;
  const mp = useManpower(props);
  const emo = useEmoluments(props);
  const td = useTrainingDevelopment(props);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState({});

  const handleOpen = (row) => {
    h.set_selected_employee_number(row.type);
    setRow({ ...row, ...h.selected_employee_details });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    h.set_profilePic(undefined);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>Human Resources</h1>
        </Grid>
        <Grid item xs={12}>
          <div className="main-content">
            <Grid container spacing={2}>
              <Grid item md={12} lg={5}>
                <OverallEmployee legend={mp.businessFunctions} topLabel="Overall Employee" data={mp.bargraph} />
              </Grid>
              <Grid item md={12} lg={3}>
                <h2 style={{ fontWeight: 600 }}>Total Manpower</h2>
                <PieChart data={mp.pie_manpower} innerLabelDescription={mp.manpowers.length} simple />
              </Grid>
              <Grid item md={12} lg={4}>
                <div style={{ float: 'right' }}>
                  <UploadExcelForm
                    sampleDataLink="https://dax-core.ofo.my/manpower-getfile"
                    endpointUpdate={endpoints.updateManpowerData()}
                  />
                </div>
                <HorizontalBarChart
                  title="Gender"
                  height={130}
                  colors={['#1E3465', '#2ECCA9']}
                  data={[
                    { x: 'Male', y: mp.male },
                    { x: 'Female', y: mp.female },
                  ]}
                />
                <div style={{ float: 'right' }}>
                  <TurnoverRateForm />
                </div>
                <HorizontalBarChart
                  title="Turnover Rate"
                  height={180}
                  labelPostfix="%"
                  labelToFixed={2}
                  data={mp.turnovers}
                  turnover
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="main-content">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div
                  className="flex-standard"
                  style={{
                    justifyContent: 'flex-start',
                    height: '100%',
                  }}
                >
                  <h2 style={{ fontWeight: 600 }}>Emolument</h2>
                </div>
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  selectedItem={emo.selected_company}
                  setSelectedItem={emo.set_selected_company}
                  itemList={emo.companies.map(e => e.name)}
                />
              </Grid>
              <Grid item xs={4}>
                <div className="flex-standard" style={{ justifyContent: 'flex-end' }}>
                  <EmolumentForm buildings={buildings} setJustUpdated={emo.setJustUpdated} justUpdated={emo.justUpdated} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Tabs
                  data={[
                    {
                      title: 'Total Emolument',
                      content: (
                        <TabContent1
                          legend={['Employee Earning', 'Employer Contribution']}
                          yAxis="RM"
                          pieTitle="Emolument by Department/Building"
                          data={emo.bar_total_emolument}
                          pieData={emo.pie_emolument}
                        />
                      ),
                    },
                    {
                      title: 'Overtime Hour',
                      content: (
                        <TabContent1
                          yAxis="Hour"
                          pieTitle="Overtime Hour by Department/Building"
                          data={emo.bar_ot_hour}
                          pieData={emo.pie_ot_hour}
                        />
                      ),
                    },
                    {
                      title: 'Overtime RM',
                      content: (
                        <TabContent1
                          yAxis="RM"
                          pieTitle="Overtime RM by Department/Building"
                          data={emo.bar_ot_cost}
                          pieData={emo.pie_ot_cost}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="main-content">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div
                  className="flex-standard"
                  style={{
                    justifyContent: 'flex-start',
                    height: '100%',
                  }}
                >
                  <h2 style={{ fontWeight: 600 }}>Training &amp; Development</h2>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="flex-standard" style={{ justifyContent: 'flex-end' }}>
                  <UploadExcelForm
                    sampleDataLink="https://dax-core.ofo.my/training-getfile"
                    endpointUpdate={endpoints.updateTrainingData()}
                  />
                </div>
              </Grid>
              <Grid item md={12} lg={3}>
                <p className="text-light" style={{ textAlign: 'center', marginTop: 90 }}>
                  Annual Budget Utilization
                </p>
                <h1 style={{ fontWeight: 600, textAlign: 'center' }}>{`RM ${td.budget.UTILIZATION}`}</h1>
                <DonutChart
                  data={[
                    { x: 1, y: td.budget.PERCENT },
                    { x: 2, y: 100 - td.budget.PERCENT },
                  ]}
                  positive
                  percent={`${td.budget.PERCENT}%`}
                  description={`RM ${td.budget.BUDGET}`}
                />
              </Grid>
              <Grid item md={12} lg={9}>
                <Tabs
                  data={[
                    {
                      title: 'Company',
                      content: (
                        <TabContent2
                          pieHour={td.pieCompTrainHour}
                          pieCost={td.pieCompTrainCost}
                          totalHour={td.totalHour}
                          totalCost={td.totalCost}
                        />
                      ),
                    },
                    {
                      title: 'Category',
                      content: (
                        <TabContent2
                          pieHour={td.pieCateTrainHour}
                          pieCost={td.pieCateTrainCost}
                          totalHour={td.totalHour}
                          totalCost={td.totalCost}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="main-content">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  className="flex-standard"
                  style={{
                    justifyContent: 'flex-start',
                    height: '100%',
                  }}
                >
                  <h2 style={{ fontWeight: 600 }}>Leave</h2>
                </div>
              </Grid>
              <Grid item md={12} lg={8}>
                <Calendar
                  onChange={(a) => h.set_leave_date(a)}
                // value={h.leave_date}
                />
              </Grid>
              <Grid item md={12} lg={4}>
                <div className="paper" style={{ padding: 20 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <div
                        className="flex-standard"
                        style={{
                          justifyContent: 'flex-start',
                          height: '100%',
                        }}
                      >
                        <h3 style={{ fontWeight: 600 }}>On Leave</h3>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div
                        className="flex-standard"
                        style={{
                          justifyContent: 'center',
                          height: '100%',
                        }}
                      >
                        <h4>{moment(h.leave_date).format('DD/MM/YYYY')}</h4>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <FilterList style={{ float: 'right' }} />
                    </Grid>
                    <Grid item xs={12} style={{ maxHeight: '380px', overflow: 'auto' }}>
                      <MaterialTable tableData={h.employee_onleave} columns={columns} rowClick={handleOpen} />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <EmployeeDetailModal {...h} row={row} open={open} handleClose={handleClose} />
    </>
  );
}

function TabContent1(props) {
  return (
    <Grid container>
      <Grid item md={12} lg={7}>
        {props.data?.length > 0 && <BarChart {...props} />}
      </Grid>
      <Grid item md={12} lg={5}>
        <h4 style={{ marginBottom: 20, fontWeight: 600 }}>{props.pieTitle}</h4>
        <PieChart legend data={props.pieData} />
      </Grid>
    </Grid>
  );
}

function TabContent2(props) {
  return (
    <Grid container>
      <Grid item md={12} lg={6}>
        <h4 style={{ fontWeight: 600 }}>Training Hour</h4>
        <PieChart
          data={props.pieHour}
          simple
          innerLabel="Total Hour"
          innerLabelSize={16}
          innerLabelDescription={`${props.totalHour} hour`}
          innerLabelDescriptionSize={20}
        />
      </Grid>
      <Grid item md={12} lg={6}>
        <h4 style={{ fontWeight: 600 }}>Training Cost</h4>
        <PieChart
          data={props.pieCost}
          simple
          innerLabel="Total Cost"
          innerLabelSize={16}
          innerLabelDescription={`RM ${props.totalCost}`}
          innerLabelDescriptionSize={20}
        />
      </Grid>
    </Grid>
  );
}
