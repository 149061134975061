import {
  Chip, Grid, Button, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Close, Edit } from '@material-ui/icons';

const animatedComponents = makeAnimated();

export default ({ selected, pageAccessList, updateUser }) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [pageAccessIds, setPageAccessIds] = useState([]);
  const openEdit = () => setIsEdit(true);
  const closeEdit = () => setIsEdit(false);
  const updatePageAccess = () => {
    closeEdit();
    if (!pageAccessIds) return;
    updateUser({ page_access: pageAccessIds.join(',') });
  };
  const CustomChip = (data) => {
    return (<Chip label={data.label} size="small" className={classes.selectedChip} />);
  };
  return (
    <Grid item>
      <div className={classes.root}>
        <Grid container justify="space-between">
          <div style={{ fontWeight: 600, color: '#1e3465' }}>Page Access</div>
          <IconButton style={{ cursor: 'pointer' }} onClick={isEdit ? closeEdit : openEdit}>
            {isEdit ? <Close /> : <Edit />}
          </IconButton>
        </Grid>
        {!isEdit ? (
          <Grid container item xs={12} style={{ overflowY: 'auto', marginBottom: 10 }}>
            {selected?.length ? (selected?.map(data => <CustomChip label={data.label} />)) : (<CustomChip label="None" />)}
          </Grid>
        ) : (
          <Grid container>
            <div style={{ width: '100%', marginBottom: 3 }}>
              <Select
                placeholder="Page access allowed ..."
                styles={{
                  control: styles => ({ ...styles }),
                  container: provided => ({
                    ...provided,
                    width: '-webkit-fill-available',
                  }),
                }}
                closeMenuOnSelect={false}
                components={animatedComponents}
                onChange={(data) => setPageAccessIds(!!data ? data.map(e => e.value) : [])}
                defaultValue={selected}
                options={pageAccessList.map(e => ({ label: e.name, value: e.id }))}
                isMulti
              />
            </div>
            <Button className={classes.saveButton} onClick={updatePageAccess}>Save changes</Button>
          </Grid>
        )}
      </div>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white', padding: 10, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', borderRadius: '10px',
  },
  selectedChip: {
    backgroundColor: '#1e3465', color: '#ffffff', marginRight: 5, marginBottom: 2, fontSize: 12, textTransform: 'capitalize',
  },
  saveButton: {
    marginLeft: 'auto', backgroundColor: '#1e3465', color: 'white', marginTop: 3,
  },
}));
