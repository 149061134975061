import { ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";

import "./dropdown.css";

export default function Dropdown({
  w,
  p,
  m,
  title,
  selected,
  options,
  onChange,
  variant = "",
}) {
  const [active, setActive] = useState(false);

  const handleFocus = (option) => {
    onChange(option);
    setActive(false);
  };

  return (
    <div className={`dropdown ${variant}`} style={{ minWidth: w, margin: m }}>
      <div
        className={`flex flex-standard row middle dropdown-button ${active && "active"}`}
        style={{
          padding: p, justifyContent: 'space-between',
        }}
        onClick={() => {
          setActive(!active);
          if (active && selected.name) {
            handleFocus({});
          }
        }}
      >
        <p className={selected.name && !active ? "active" : ""}>
          {selected.name && !active ? selected.name : title}
        </p>
        <ExpandMore />
      </div>
      <div className={`dropdown-container ${active && "active"}`}>
        {options.map((option) => (
          <p key={option.selector} onClick={() => handleFocus(option)}>
            {option.name}
          </p>
        ))}
      </div>
    </div>
  );
}
