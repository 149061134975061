import { Switch, Route } from 'react-router-dom';
import Osh from './Osh';
import Environment from './Environment';
import Quality from './Quality';
import Navbar from './Navbar';

export default function Dashboard(props) {
  const routes = [];
  if (props.user?.can_see_osh) routes.push({ path: '/dashboard/osh', children: <Osh />, exact: true });
  if (props.user?.can_see_environment) routes.push({ path: '/dashboard/environment', children: <Environment /> });
  if (props.user?.can_see_quality) routes.push({ path: '/dashboard/quality', children: <Quality /> });

  return (
    <>
      {routes.length > 1 && <Navbar {...props} />}
      <Switch>
        {routes.map(e => (
          <Route {...e} />
        ))}
      </Switch>
    </>
  );
}
