import { useState } from 'react';
import MainContentContainer from '@Components/MainContentContainer';
import CenteredTabs from '@Components/CenteredTabs';
import CloudProcessing from './CloudProcessing';
import LayerProcessing from './LayerProcessing';
import Navbar from '../components/Navbar';

export default function Mapping() {
  const [tabValue, setTabValue] = useState(0);
  const items = ['Cloud Processing', 'Layer Upload'];
  return (
    <MainContentContainer>
      <div style={{ marginBottom: 20 }}>
        <Navbar to="/project/mapping" text="Mapping" subtext={items[tabValue]} />
        <CenteredTabs value={tabValue} setValue={setTabValue} items={items} />
      </div>
      {tabValue ? <LayerProcessing /> : <CloudProcessing />}
    </MainContentContainer>
  );
}
