import { Button } from '@material-ui/core';

export default (props) => {
  let color = {
    color: 'white',
    backgroundColor: '#1E3465',
  };
  if (props.variant === 'text') color = { color: '#1E3465' };
  if (props.disabled) color = {};

  return (
    <Button
      {...props}
      style={{
        fontFamily: 'Poppins',
        textTransform: 'none',
        padding: '7px 25px',
        fontWeight: 600,
        fontSize: 14,
        borderRadius: 10,
        height: 36,
        margin: 'auto 0',
        ...color,
        ...props.style,
      }}
      variant={props.disabled && 'contained'}
    >
      {props.children}
    </Button>
  );
};
