import React from 'react';

import {
  VictoryBar, VictoryChart, VictoryAxis, VictoryLabel,
} from 'victory';

const formatterWithFraction = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
});
const formatter = new Intl.NumberFormat('en-US');

export default function HorizontalBarCharts(props) {
  return (
    <>
      <p style={{ fontSize: 16, fontWeight: 700 }} className="text-light">
        {props.title}
      </p>
      <div style={{ position: 'relative' }}>
        <VictoryChart
          width={props.width}
          height={150}
          padding={{
            left: 120, right: 60, top: 40, bottom: 40,
          }}
        >
          <VictoryBar
            style={{
              data: {
                fill: ({ datum }) => (datum.x === 'Actual' ? '#1F3566' : '#008FFB'),
              },
            }}
            cornerRadius={10}
            barWidth={props.barWidth}
            horizontal
            barRatio={3}
            data={props.data}
            labels={({ datum }) => `${props.labelPrefix || ''} ${props.withFraction
              ? formatterWithFraction.format(datum.y)
              : formatter.format(datum.y)
              }`}
            sortKey="x"
            sortOrder="descending"
            labelComponent={(
              <VictoryLabel
                x={130}
                dx={10}
                style={{
                  fontSize: 32,
                  fill: '#FFFFFF',
                  textShadow: '1px 1px 5px black',
                  stroke: 'none',
                }}
                textAnchor="start"
              />
            )}
          />

          <VictoryAxis
            // tickValues specifies both the number of ticks and where
            // they are placed on the axis
            style={{
              tickLabels: {
                fontSize: 28,
                fill: 'rgba(30, 52, 101, 0.65)',
                padding: 20,
              },
              ticks: { size: 0 },
              axis: { stroke: 'transparent' },
              grid: { stroke: 'transparent' },
            }}
            offsetX={120}
            orientation="left"
          />
        </VictoryChart>
      </div>
    </>
  );
}
