export const DaxLogo = (props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="100%" height="100%" viewBox="0 0 295 101.667" enable-background="new 0 0 295 101.667" xmlSpace="preserve" {...props}>
      <g>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="56.7065" y1="90.5" x2="56.7065" y2="11.3965">
          <stop offset="0" style={{ stopColor: "#76479C" }} />
          <stop offset="0.7634" style={{ stopColor: "#005AAA" }} />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M56.65,11.509L17.21,11.396V90.5h39.552c10.849,0,20.68-4.406,27.8-11.639
		c0.791-0.791,1.582-1.582,2.26-2.373c5.876-6.894,9.38-15.821,9.38-25.54C96.202,29.138,78.46,11.509,56.65,11.509z M56.65,76.488
		H31.223V25.635H56.65c14.013,0,25.426,11.414,25.426,25.426C82.076,65.075,70.663,76.488,56.65,76.488z"/>
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="145.2402" y1="90.5" x2="145.2402" y2="11.3965">
          <stop offset="0" style={{ stopColor: "#76479C" }} />
          <stop offset="0.7634" style={{ stopColor: "#005AAA" }} />
        </linearGradient>
        <polygon fill="url(#SVGID_2_)" points="145.24,11.396 112.129,76.375 104.897,90.5 120.718,90.5 127.95,76.375 145.24,42.473 
		162.53,76.375 169.763,90.5 185.583,90.5 178.351,76.375 	"/>
        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="240.8926" y1="90.8398" x2="240.8926" y2="11.5093">
          <stop offset="0" style={{ stopColor: "#76479C" }} />
          <stop offset="0.7634" style={{ stopColor: "#005AAA" }} />
        </linearGradient>
        <polygon fill="url(#SVGID_3_)" points="280.501,11.509 280.275,11.509 262.533,11.509 240.836,39.647 219.139,11.509 
		201.284,11.509 231.909,51.174 201.51,90.613 219.364,90.613 240.949,62.701 262.533,90.613 280.163,90.613 280.275,90.84 
		280.275,90.613 280.389,90.613 249.877,51.174 	"/>
        <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="201.2842" y1="90.8398" x2="201.2842" y2="90.6133">
          <stop offset="0" style={{ stopColor: "#76479C" }} />
          <stop offset="0.7634" style={{ stopColor: "#005AAA" }} />
        </linearGradient>
        <polygon fill="url(#SVGID_4_)" points="201.171,90.84 201.397,90.613 201.171,90.613" />
      </g>
    </svg>
  )
}