import { useState } from 'react';
import Api from '@Helpers/api';

export default function Hook({ endpointUpdate }) {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const handleClose = () => {
    setFiles([]);
    setOpenModal(false);
  };

  const handleClickOpen = () => setOpenModal(true);

  const handleSave = () => {
    setIsLoading(true);
    Api({
      endpoint: endpointUpdate,
      files,
      onSuccess: () => {
        toast('success', 'Successfully uploaded Training Data');
        setIsLoading(false);
        handleClose();
      },
      onFail: () => {
        toast('error', 'Failed to upload Training Data');
        setIsLoading(false);
      },
    });
  };

  return {
    openModal,
    isLoading,
    handleClose,
    handleClickOpen,
    handleSave,
    files,
    setFiles,
  };
}
