import React from 'react';
import { Grid } from '@material-ui/core';
import OccupancyRateChart from '@Components/Management/Reid/OccupancyRateChart';
import RenewalOfTenanciesChart from '@Components/Management/Reid/RenewalOfTenanciesChart';
import AverageRentalRateBar from '@Components/Management/Reid/AverageRentalRateBar';
import DebtorsAgingBar from '@Components/Management/Reid/DebtorsAgingBar';
import TenantLotChart from '@Components/Management/Reid/TenantLotChart';

const styles = {
  container: { padding: 10, height: '100%' },
};

export default function PerformanceCharts(props) {
  return (
    <>
      <Grid xs={12} lg={12} container justify="space-between">
        <h2 style={{ fontWeight: 600, marginBottom: 10 }}>Performance</h2>
        {/* <div>Dropdown Box</div> */}
      </Grid>
      <Grid xs={12} container>
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          <Grid item xs={12} md={4}>
            <Grid item className="paper" style={styles.container}>
              <OccupancyRateChart {...props.totalOR} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid item className="paper" style={styles.container}>
              <AverageRentalRateBar {...props} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          <Grid item xs={12} md={4}>
            <Grid item className="paper" style={styles.container}>
              <RenewalOfTenanciesChart {...props} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid item className="paper" style={styles.container}>
              <DebtorsAgingBar {...props} />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} lg={12}>
          <Grid item className="paper" style={styles.container}>
            <TenantLotChart {...props} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
