import moment from 'moment';
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';
import { monthComparisons, yearComparisons } from '@Helpers';

const latest_updated = monthComparisons(2).format('MMMM YYYY');
const totalOccupancyRate = (arr) => {
  let NLA = 0;
  let NLA_OC = 0;
  arr.forEach((e) => {
    NLA += e.NLA;
    NLA_OC += e.NLA_OC;
  });
  return { NLA, NLA_OC };
};

export default function Hook({ buildings }) {
  const [OccupancyRate, set_OccupancyRate] = useState([]);
  const [SalesTurnOver, set_SalesTurnOver] = useState([]);
  const [TotalSalesTurnOver, set_TotalSalesTurnOver] = useState([]);
  const [TotalSalesThisYear, set_TotalSalesThisYear] = useState(80042260);
  const [PercentageSalesThisYear, set_PercentageSalesThisYear] = useState(-66);
  const [RenewalOfTenancySummary, set_RenewalOfTenancySummary] = useState({});
  const [RenewalOfTenancyDetail, set_RenewalOfTenancyDetail] = useState({});
  const [debtorAging, set_debtorAging] = useState([]);
  const [tmb, set_Tmb] = useState({});
  const [tla, set_Tla] = useState({});
  const buildingName = buildings.reduce((a, b) => {
    a[b.uuid] = b.name;
    return a;
  }, {});

  useEffect(() => {
    const body = { data: { YEAR: yearComparisons(2)[1] } };
    Api({
      ...body,
      endpoint: endpoints.getOccupancy(),
      onSuccess: (response) => set_OccupancyRate(response.data),
      onFail: set_OccupancyRate([]),
    });
    Api({
      ...body,
      endpoint: endpoints.getDebtoraging(),
      onSuccess: (response) => set_debtorAging(response.data),
    });
    Api({
      ...body,
      endpoint: endpoints.getTla(),
      onSuccess: (response) => {
        const groupedV = _.groupBy(response.data, 'CATEGORY');
        set_Tmb(groupedV);
      },
    });
    Api({
      ...body,
      endpoint: endpoints.getTla(),
      onSuccess: (response) => {
        const groupedV = _.groupBy(response.data, 'CATEGORY');
        set_Tla(groupedV);
      },
    });
    Api({
      data: { YEAR: yearComparisons(2)[1] },
      endpoint: endpoints.getTenancy(),
      onSuccess: (response) => {
        const groupedV = _.groupBy(response.data, 'STATUS');
        delete groupedV.null;
        const RenewalOfTenancy = {};
        Object.keys(groupedV).map(
          (eachstatus) => (RenewalOfTenancy[eachstatus] = {
            AREA: _.sumBy(groupedV[eachstatus], (o) => o.AREA),
            RENTAL: _.sumBy(groupedV[eachstatus], (o) => o.RENTAL),
          }),
        );
        set_RenewalOfTenancySummary(RenewalOfTenancy);
        set_RenewalOfTenancyDetail(groupedV);
      },
    });
    Api({
      endpoint: endpoints.getSalesturnover(),
      onSuccess: (response) => {
        const sortedV = _.groupBy(_.sortBy(response.data, ['YEAR', 'MONTH']), 'YEAR');
        const latestYear = sortedV[Object.keys(sortedV).slice(-1)];
        const prevYear = sortedV[Object.keys(sortedV).slice(-2, -1)];
        const latestYearMonthly = _.groupBy(latestYear, 'MONTH');
        const prevYearMonthly = _.groupBy(prevYear, 'MONTH');

        const barChartData = Object.keys(latestYearMonthly).map(m => ({
          x: moment(m, 'MM').format('MMM'),
          y1: latestYearMonthly[m].reduce((a, b) => a + b.GROSS, 0),
          y2: prevYearMonthly[m].reduce((a, b) => a + b.GROSS, 0),
        }));

        let currentTotal = 0;
        if (latestYear) {
          currentTotal = _.sumBy(latestYear, (o) => o.GROSS);
          set_TotalSalesThisYear(currentTotal);
        }
        let prevTotal = 0;
        if (prevYear) {
          prevTotal = _.sumBy(prevYear, (o) => o.GROSS);
        }
        set_SalesTurnOver(barChartData);
        set_TotalSalesTurnOver([{ x: 1, y: currentTotal }, { x: 2, y: prevTotal - currentTotal }]);
        set_PercentageSalesThisYear(prevTotal === 0 ? 'N/A ' : ((currentTotal / prevTotal) * 100).toFixed(2));
      },
    });
  }, []);

  const totalOR = totalOccupancyRate(OccupancyRate);
  const ORByBuilding = OccupancyRate.map((m) => ({ x: buildingName[m.uuid], y: (m.NLA_OC / m.NLA) * 100 }));
  const DebtByBuilding = debtorAging.map((m) => ({ ...m, building: buildingName[m.uuid] }));

  return {
    latest_updated,
    SalesTurnOver,
    TotalSalesTurnOver,
    TotalSalesThisYear,
    PercentageSalesThisYear,
    OccupancyRate,
    RenewalOfTenancySummary,
    RenewalOfTenancyDetail,
    debtorAging,
    tmb,
    tla,
    totalOR,
    ORByBuilding,
    DebtByBuilding,
  };
}
