import React from 'react';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';

export default function SimpleCard({
  label, value, style, elevation, can_edit, ...props
}) {
  return (
    <Paper
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        padding: 20,
        margin: 10,
        ...style,
      }}
      className="flex-standard"
      elevation={elevation ?? 1}
    >
      {!!props.medal && <span style={{ marginRight: 5 }}>{props.medal}</span>}
      <p style={{ flex: 5 }}>{label}</p>
      <p style={{ flex: 2, textAlign: 'end' }}>{value}</p>
      {can_edit && (
        <IconButton
          color="primary"
          style={{ flex: 1 }}
          onClick={() => (props.update_form === 1 ? props.setOpenKpiDialog(true) : props.setOpenCidbScore(true))}
        >
          <EditIcon fontSize="small" style={{ marginLeft: 6, textAlign: 'end' }} />
        </IconButton>
      )}
    </Paper>
  );
}
