import { Grid, CircularProgress } from '@material-ui/core';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import Table from '@Components/Table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { yearComparisons } from '@Helpers';

const columns = [
  {
    name: 'Month', selector: 'month', sortable: false, right: false,
  },
  {
    name: 'Gross', selector: 'gross', sortable: true, right: true,
  },
];

function IncomeExpenditure({ building: { uuid } }) {
  const [isLoading, set_isLoading] = useState(false);
  const [income_expenditure, set_income_expenditure] = useState([]);
  const YEAR = yearComparisons(2)[1];
  const getMonth = (m) => moment(m, 'MM').format('MMMM').toUpperCase();
  const localCurrency = (num) => num.toLocaleString('en-MY', { style: 'currency', currency: 'MYR' });
  useEffect(() => {
    set_isLoading(true);
    Api({
      data: { uuid },
      endpoint: endpoints.getSalesturnover(),
      onSuccess: ({ data }) => {
        if (data.length > 0) {
          const sortedV = _.groupBy(_.sortBy(data, ['YEAR', 'MONTH']), 'YEAR');
          const latestYear = sortedV[YEAR];
          const graphData = latestYear.map(e => ({
            month: `${getMonth(e.MONTH)} ${e.YEAR}`,
            operatingExpenses: localCurrency(e.OE),
            gross: localCurrency(e.GROSS),
          }));
          set_income_expenditure(graphData);
        } else {
          set_income_expenditure([]);
        }
        set_isLoading(false);
      },
    });
  }, [uuid]);
  return (
    <>
      <MainContentNavbar to="/property/finance" text="INCOME &amp; EXPENDITURE" />
      <MainContentContainer>
        <Grid container>
          <Grid item xs={12}>
            {!isLoading ? <Table title="" columns={columns} data={income_expenditure} />
              : <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>}
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default IncomeExpenditure;
