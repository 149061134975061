/* eslint-disable max-nested-callbacks */
import React from 'react';
import Button from '@Components/Button';
import {
  Grid, OutlinedInput, FormControl, CircularProgress,
} from '@material-ui/core';

export default (h) => {
  const columns = [
    {
      children: <h4 style={{ opacity: 0.65 }}>Arrears</h4>, xs: 3, selector: 'arrears', inputProps: { step: '1' },
    },
    {
      children: <h4 style={{ opacity: 0.65 }}>Total Day Aging</h4>, xs: 3, selector: 'total_day_aging', inputProps: { step: '1' },
    },
    {
      children: <h4 style={{ opacity: 0.65 }}>Amount (RM)</h4>, xs: 3, selector: 'amount', inputProps: { step: '0.01' },
    },
  ];
  return (
    <div style={{ padding: '0 24px' }}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <h3>Debtor Aging</h3>
        </Grid>
        {columns.map(e => <Grid item {...e} />)}
      </Grid>
      {h.isLoading
        ? <div className="flex-standard" style={{ height: 300 }}><CircularProgress /></div>
        : h.debtorsAgingGroup.map((d) => (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <p style={{ fontWeight: 600 }}>{d.name}</p>
            </Grid>
            {columns.map(eachCol => (
              <Grid item xs={3}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <OutlinedInput
                    value={h.inputDebtorAging.find(x => x.SecurityDebtorAgingGroupId === d.id)?.[eachCol.selector]
                      || h.selectedDebtorAgingData.find(x => x.SecurityDebtorAgingGroupId === d.id)?.[eachCol.selector]
                      || ''}
                    inputProps={eachCol.inputProps}
                    type={eachCol.selector !== 'total_day_aging' && 'number'}
                    onChange={e => {
                      h.setInputDebtorAging(eachInput => {
                        const input = [...eachInput];
                        const objIdx = input.findIndex(x => x.SecurityDebtorAgingGroupId === d.id);
                        if (objIdx >= 0) {
                          input[objIdx][eachCol.selector] = e.target.value;
                          input[objIdx].year = h.year;
                          input[objIdx].month = h.month;
                        } else {
                          input.push({
                            SecurityDebtorAgingGroupId: d.id,
                            [eachCol.selector]: parseFloat(e.target.value),
                            year: h.year,
                            month: h.month,
                          });
                        }
                        return input;
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            ))}
          </Grid>
        ))}

      <div className="flex" style={{ justifyContent: 'flex-end', marginTop: 24 }}>
        <Button variant="text" onClick={h.handleBackStep}>
          Return
        </Button>
        <Button onClick={h.handleNextStep}>
          Next
        </Button>
      </div>
    </div>
  );
};
