import { Grid } from '@material-ui/core';
import { InfoOutlined, Sync } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import AreaChart from '@Components/Maintenance/AreaChart';
import Card from '@Components/Maintenance/Card';
import Chart from '@Components/Maintenance/Chart';
import MultiLineChart from '@Components/Maintenance/MultiLineChart';
import Rating from '@Components/Maintenance/Rating';
import OpenIcon from '@Assets/Icons/OpenIcon.png';
import InProgressIcon from '@Assets/Icons/InProgressIcon.png';
import OnHoldIcon from '@Assets/Icons/OnHoldIcon.png';
import OverdueIcon from '@Assets/Icons/OverdueIcon.png';
import ResolvedIcon from '@Assets/Icons/ResolvedIcon.png';
import Hook from './hook';
import Wordcloud from '@Components/WordcloudIssues';

function Maintenance(props) {
  const h = Hook(props);
  return (
    <>
      <MainContentNavbar to='/property/' text='MAINTENANCE' />
      <MainContentContainer>
        <Grid container style={{ marginBottom: 30 }}>
          <Wordcloud {...props} />
          <h2 style={{ fontWeight: 600, marginBottom: 10 }}>Issue</h2>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                flexWrap: 'wrap',
              }}
            >
              <Card
                to='/property/maintenance/issue?=Open'
                title='Open'
                description={h.issue_summary.Open.toLocaleString()}
                color='#008FFB'
                icon={<img src={OpenIcon} height='40px' />}
              />
              <Card
                to='/property/maintenance/issue?=In Progress'
                title='In Progress'
                description={h.issue_summary['In Progress'].toLocaleString()}
                color='#FB9C5D'
                icon={<img src={InProgressIcon} height='40px' />}
              />
              <Card
                to='/property/maintenance/issue?=On Hold'
                title='On Hold'
                description={h.issue_summary['On Hold'].toLocaleString()}
                color='#B85EEE'
                icon={<img src={OnHoldIcon} height='40px' />}
              />
              <Card
                to='/property/maintenance/issue?=Overdue'
                title='Overdue'
                description={h.issue_summary.Overdue.toLocaleString()}
                color='#FF4560'
                icon={<img src={OverdueIcon} height='40px' />}
              />
              <Card
                to='/property/maintenance/issue?=Resolved'
                title='Resolved'
                description={h.issue_summary.Resolved.toLocaleString()}
                color='#2ECCA9'
                icon={<img src={ResolvedIcon} height='40px' />}
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Link to='/property/maintenance/issue'>
              <div className='paper' style={{ padding: '10px 20px' }}>
                <p>Issues Completed Last 30 Days</p>
                <Chart
                  tickValues={h.days_arr}
                  graphOne={h.issues_created_graph}
                  graphTwo={h.issues_resolved_graph}
                  legends={['Issues Created', 'Issues Resolved']}
                  pointers={['Created', 'Resolved']}
                />
              </div>
            </Link>
          </Grid>
          <h2 style={{ fontWeight: 600, marginBottom: 10, marginTop: 30 }}>Preventive Maintenance</h2>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                flexWrap: 'wrap',
              }}
            >
              <Card
                to='/property/maintenance/preventive?=In Progress'
                title='In Progress Tasks'
                description={h.task_summary['In Progress']}
                color='#FB9C5D'
                icon={<img src={InProgressIcon} height='40px' />}
              />
              <Card
                to='/property/maintenance/preventive?=Pending Review'
                title='Pending Review Tasks'
                description={h.task_summary['Pending Review']}
                color='#B85EEE'
                icon={<img src={OnHoldIcon} height='40px' />}
              />
              <Card
                to='/property/maintenance/preventive?=Overdue'
                title='Overdue Tasks'
                description={h.task_summary['Overdue']}
                color='#FF4560'
                icon={<img src={OverdueIcon} height='40px' />}
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Link to='/property/maintenance/preventive'>
              <div className='paper' style={{ padding: '10px 20px' }}>
                <p>Tasks Reviewed Last 30 Days</p>
                <Chart
                  tickValues={h.days_arr}
                  graphOne={h.tasks_reviewed_graph}
                  graphTwo={h.tasks_scheduled_graph}
                  legends={['Tasks Reviewed', 'Tasks Scheduled']}
                  pointers={['Tasks reviewed', 'Tasks scheduled']}
                />
              </div>
            </Link>
          </Grid>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 10,
              marginTop: 30,
            }}
          >
            <h2 style={{ fontWeight: 600 }}>User Feedbacks</h2>
            <InfoOutlined style={{ marginLeft: 10, color: '#1E3465' }} />
          </div>
          <Grid container item xs={12} spacing={2}>
            <Grid item>
              <Rating text='Overall Accessibility' rating={3.5} ratingOver={4} ratings={['75%', '50%', '25%', '10%']} />
            </Grid>
            <Grid item>
              <Rating text='Overall Accessibility' rating={3.5} ratingOver={4} ratings={['75%', '50%', '25%', '10%']} />
            </Grid>
            <Grid item>
              <Rating text='Overall Accessibility' rating={3.5} ratingOver={4} ratings={['75%', '50%', '25%', '10%']} />
            </Grid>
            <Grid item>
              <Rating text='Overall Accessibility' rating={3.5} ratingOver={4} ratings={['75%', '50%', '25%', '10%']} />
            </Grid>
            <Grid item>
              <div
                className='paper'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '20px 20px 25px 20px',
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Sync style={{ fontSize: 16, color: '#1E3465' }} />
                  <p style={{ fontSize: 16, marginLeft: 6 }}>80%</p>
                </div>
                <p style={{ fontSize: 12 }}>
                  Will visit again <br /> in future
                </p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 18,
                  }}
                >
                  <Sync style={{ fontSize: 16, color: '#1E3465' }} />
                  <p style={{ fontSize: 16, marginLeft: 6 }}>80%</p>
                </div>
                <p style={{ fontSize: 12 }}>
                  Will recommend
                  <br />
                  to friend/family
                </p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className='paper' style={{ padding: '10px 20px' }}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div>
                      <p>No. Feedbacks Received Daily</p>
                      <AreaChart pointer='Feedback' />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <p>Average Rating To Date</p>
                      <MultiLineChart
                        pointers={[
                          'Overall Accessibility',
                          'Facilities Provided',
                          'Cleanliness',
                          'Shopping Experience',
                        ]}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default Maintenance;
