import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import moment from 'moment';

export default function Hook() {
  const [leave_date, set_leave_date] = useState(new Date());
  const [leave_types, set_leave_types] = useState([]);
  const [employees, set_employees] = useState([]);
  const [employee_leaves, set_employee_leaves] = useState([]);
  const [selected_employee_number, set_selected_employee_number] = useState('');
  const [selected_employee_details, set_selected_employee_details] = useState({});
  const [profilePic, set_profilePic] = useState({});

  useEffect(() => {
    // getLeaveTypes getEmployees getEmployeeLeaves
    Api({
      endpoint: endpoints.getLightman(),
      data: {
        query: `{
        msLeave{LeaveCdeID Description}
        msEmployeeProfile{ID EmployeeName EmployeeNumber BirthDte}
        prEmpLeave{LeaveCdeID EmployeeID StartDte EndDte}
      }`,
      },
      onSuccess: ({ data: { msLeave, msEmployeeProfile, prEmpLeave } }) => {
        if (msLeave) set_leave_types(msLeave);
        if (msEmployeeProfile) set_employees(msEmployeeProfile);
        if (prEmpLeave) set_employee_leaves(prEmpLeave);
      },
    });
  }, []);

  useEffect(() => {
    Api({
      endpoint: endpoints.getLightman(),
      data: { query: `{msEmployeeProfile(EmployeeNumber: "${selected_employee_number}"){ProfilePic}}` },
      onSuccess: ({ data: { msEmployeeProfile } }) => {
        if (msEmployeeProfile) set_profilePic(msEmployeeProfile[0].ProfilePic);
      },
    });
    Api({
      endpoint: endpoints.getManpowers(),
      data: { EMPLOYEE_NUMBER: selected_employee_number },
      onSuccess: (res) => set_selected_employee_details(res.data ?? {}),
    });
  }, [selected_employee_number]);

  const leave_types_obj = leave_types.reduce((a, b) => (a[b.LeaveCdeID] = b.Description, a), {});

  const employees_obj = employees.reduce((a, b) => (a[b.ID] = { name: b.EmployeeName, number: b.EmployeeNumber, birthdate: b.BirthDte }, a), {});

  const employee_onleave = employees.length > 0
    ? employee_leaves.filter(f => (f.LeaveCdeID !== 14 && parseInt(f.StartDte) < moment(leave_date).valueOf() && parseInt(f.EndDte) > moment(leave_date).valueOf()))
      .sort((a, b) => ((a.LeaveCdeID > b.LeaveCdeID) ? 1 : ((b.LeaveCdeID > a.LeaveCdeID) ? -1 : 0)))
      .map(m => ({
        name: employees_obj[m.EmployeeID].name, type: employees_obj[m.EmployeeID].number, birthdate: employees_obj[m.EmployeeID].birthdate, leave: leave_types_obj[m.LeaveCdeID],
      }))
    : [];

  return {
    employee_onleave,
    set_leave_date,
    leave_date,
    set_selected_employee_number,
    selected_employee_details,
    profilePic,
    set_profilePic,
  };
}
