import {
  Grid, Button, TextField, CircularProgress,
} from '@material-ui/core';
import { Redirect, useLocation } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AuthContext } from '@Context/Auth';
import { User, Lock } from '@Assets/Icons';
import { makeStyles } from '@material-ui/core/styles';
import Mail from '@material-ui/icons/MailOutline';
import Api, { endpoints } from '@Helpers/api';
import { remoteConfig } from '@Configs/firebase';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  btnLogin: {
    backgroundColor: '#7723CA',
    color: '#fff',
    width: '100%',
    '&:hover': {
      backgroundColor: '#1E3465',
    },
  },
  buttonProgress: {
    color: '#7723CA',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function LoginForm(h) {
  const { login } = h;
  const classes = useStyles();
  const location = useLocation();
  const { token } = useContext(AuthContext);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const { from } = location.state || { from: { pathname: '/' } };

  const toggleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
    h.setLoginError();
  };

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') {
      attemptLoginOrPasswordReset({ email, password });
    }
  };

  if (token && h.user !== 'logged out') return <Redirect to={from} />;

  const isUnauthorize = async (email) => {
    remoteConfig.defaultConfig = {
      web_unauthorize_login: [],
    };
    remoteConfig.fetchAndActivate();

    let unauthorize = false;
    const getData = remoteConfig.getValue('web_unauthorize_login');
    const obj = JSON.parse(getData.asString());
    const emailFilter = obj.find(o => o.type == 'email');
    const platformFilter = obj.find(o => o.type == 'platform');

    if (emailFilter && emailFilter.value.length) {
      unauthorize = emailFilter.value.some(em => email.toLowerCase().includes(em.toLowerCase()));
    }
    if (platformFilter && platformFilter.value.length) {
      unauthorize = platformFilter.value.some(em => ('web').includes(em.toLowerCase()));
    }
    return unauthorize;
  };

  const attemptLoginOrPasswordReset = async ({ email, password }) => {
    if (await isUnauthorize(email)) return h.setLoginError('Email unauthorize');
    if (!email) {
      h.setLoginError('Email required');
      return;
    }

    if (!isForgotPassword) {
      h.login({ email, password });
      return;
    }

    h.setIsLoading(true);
    Api({
      endpoint: endpoints.requestTokenToResetPassword(),
      data: { email, redirect_url: `${window.location.origin}/reset-password` },
      onSuccess: () => {
        h.setIsLoading(false);
        toast('success', 'Reset password successful. Please check your email to proceed'); // eslint-disable-line no-undef
        setIsForgotPassword(false);
      },
      onFail: () => {
        h.setIsLoading(false);
        toast('error', 'Failed to reset password'); // eslint-disable-line no-undef
      },
    });
  };

  return (
    <Grid item container xs={12} justify="center" spacing={1} style={{ paddingLeft: 15 }}>
      <Grid item container xs={12} justify="space-around" alignItems="center">
        {isForgotPassword ? <Mail width="20" />
          : <User width="20" />}
        <Grid item container xs={10}>
          <TextField
            onKeyDown={onKeyDown}
            id="standard-basic"
            label={isForgotPassword ? 'Email' : 'Username'}
            style={{ width: '100%' }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
      </Grid>
      {!isForgotPassword && (
        <Grid item container xs={12} justify="space-around" alignItems="center">
          <Lock width="20" />
          <Grid item container xs={10} style={{ flexFlow: 'row' }}>
            <TextField
              onKeyDown={onKeyDown}
              id="standard-password-input"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container xs={12} justify="flex-end" style={{ marginRight: 10 }}>
        <h6
          onClick={toggleForgotPassword}
          style={{
            color: 'gray', fontWeight: 400, margin: 0, cursor: 'pointer',
          }}
        >
          {isForgotPassword ? 'Back to Login' : 'Forgot Password'}
        </h6>
      </Grid>
      <Grid item xs={8}>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            className={classes.btnLogin}
            onClick={() => attemptLoginOrPasswordReset({ email, password })}
            disabled={h.isLoading}
          >
            {isForgotPassword ? 'RESET PASSWORD' : 'SIGN IN'}
          </Button>
          {h.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </Grid>
      {!!h.loginError && (
        <Grid item container xs={12} justify="space-around">
          <div style={{ color: 'red' }}>
            {h.loginError === 'fail to authenticate user' ? 'Invalid username or password' : h.loginError}
          </div>
        </Grid>
      )}
    </Grid>
  );
}
