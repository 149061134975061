import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserInfoCard from './UserInfoCard';

const useStyles = makeStyles(() => ({
  noDataBody: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  noDataText: { fontSize: '3em', color: '#e5e5e5', marginBottom: 20 },
}));

const NoDataUI = ({ classes }) => {
  return (
    <Grid item xs={12} style={{ marginTop: 10 }}>
      <div className={classes.noDataBody}><h1 className={classes.noDataText}> No data have been added </h1></div>
    </Grid>
  );
};

export default function UserTableList({ users, pageAccessList, userRole }) {
  const classes = useStyles();
  return (
    <>
      {users?.length ? (
        <Grid container xs={12} md={12} style={{ padding: 20, height: '100%' }}>
          <Grid container item xs={12} style={{ marginBottom: 10, color: '#A5ADBE', padding: '0 16px' }}>
            <Grid item xs={4} />
            <Grid container item xs={8}>
              <Grid item xs={4}>Email</Grid>
              <Grid item xs={3}>Module</Grid>
              <Grid item xs={2}>Role</Grid>
              <Grid item xs={2}>Action</Grid>
              <Grid item xs={1} />
            </Grid>
          </Grid>
          {users.map((userProfile) => (
            <Grid container item xs={12}><UserInfoCard userData={userProfile} userRole={userRole} pageAccessList={pageAccessList} /></Grid>
          ))}
        </Grid>
      ) : (<NoDataUI classes={classes} />)}
    </>
  );
}
