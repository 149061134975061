import { OnlineCloud, OfflineCloud } from '@Assets/Icons';
import { Grid } from '@material-ui/core';
import moment from 'moment';

export default function StatusCard({
  name = '',
  row_title = [],
  online = true,
  last_update = null,
  last_backup = null,
  nvr_type = null,
}) {
  return (
    <Grid
      item
      xs={12}
      container
      alignItems='center'
      className='paper'
      spacing={1}
      style={{ margin: '10px', padding: '10px 20px' }}
    >
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <p style={{ textTransform: 'uppercase', fontSize: 16, fontWeight: 600 }}>{name}</p>
        </Grid>
        <Grid item container xs={6} alignItems='center' justify='flex-end'>
          {online ? (
            <>
              <OnlineCloud width='30' height='24' style={{ marginRight: 5 }} />
              <p style={{ color: '#4FD8AF', fontSize: 16 }}>ONLINE</p>
            </>
          ) : (
            <>
              <OfflineCloud width='30' height='24' style={{ marginRight: 5 }} />
              <p style={{ color: '#EA1601', fontSize: 16 }}>OFFLINE</p>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <p style={{ fontSize: 12, color: '#1F3566' }}>Last update:</p>
        </Grid>
        <Grid item xs={6} alignItems='center' justify='flex-end' style={{ textAlign: 'right' }}>
          <p style={{ fontSize: 12, color: '#1F3566' }}>
            {last_update ? moment(last_update).format('DD MMMM YYYY') : '-'}
          </p>
          <p style={{ fontSize: 12, color: '#1F3566' }}>{last_update ? moment(last_update).format('hh:mm a') : '-'}</p>
        </Grid>
      </Grid>
      {last_backup && (
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <p style={{ fontSize: 12, color: '#1F3566' }}>Last backup:</p>
          </Grid>
          <Grid item xs={6} alignItems='center' justify='flex-end' style={{ textAlign: 'right' }}>
            <p style={{ fontSize: 12, color: '#1F3566' }}>
              {last_backup ? moment(last_backup).format('DD MMMM YYYY') : '-'}
            </p>
            <p style={{ fontSize: 12, color: '#1F3566' }}>
              {last_backup ? moment(last_backup).format('hh:mm a') : '-'}
            </p>
          </Grid>
        </Grid>
      )}
      {nvr_type && (
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <p style={{ fontSize: 12, color: '#1F3566' }}>Panel type:</p>
          </Grid>
          <Grid item container xs={6} alignItems='center' justify='flex-end' style={{ textAlign: 'right' }}>
            <p style={{ fontSize: 12, color: '#1F3566' }}>{nvr_type}</p>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
