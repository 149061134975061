import { useEffect, useState } from 'react';
import { endpoints, CCTV } from '@Configs/endpoints';
import Api from '@Helpers/api';

export default function Hook({ buildings, selectedBuilding }) {
  const building_uuid = buildings[selectedBuilding]?.uuid;
  const [cameras, setCameras] = useState([]);
  useEffect(() => {
    if (building_uuid) {
      Api({
        endpoint: endpoints.getCameras(),
        data: { building_uuid },
        onSuccess: (response) => setCameras(response.data),
        onFail: () => setCameras(),
      });
    }
  }, [building_uuid]);

  return {
    cameras,
    building_uuid,
    url: CCTV,
  };
}
