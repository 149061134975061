import React, { useEffect, useState } from 'react';
import {
  Grid, Dialog, DialogTitle, DialogContent, DialogActions, OutlinedInput, Box, FormControl, Select, MenuItem, TextField,
} from '@material-ui/core';
import Api, { endpoints } from '@Helpers/api';
import { Add, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import UploadIcon from '@Components/UploadIcon';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const inputTemplate = {
  id: 0,
  contractService: '',
  currentCharge: '',
  serviceCharge: '',
  remarks: '',
  expiryAt: '',
  service: '',
};

const titles = [
  { label: 'Service Type', col: 1 },
  { label: 'Vendor', col: 1 },
  { label: 'Expiring Date', col: 1 },
  { label: 'Current Charge (RM)', col: 1 },
  { label: 'Service Charges', col: 1 },
  { label: 'Remarks', col: 1 },
];

const useStyles = makeStyles(() => ({
  smallBtn: { borderRadius: '6px', margin: 0 },
  blueBtn: { borderRadius: '6px', width: '17em', marginRight: '2em' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
}));

export default (props) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(props.serviceContracts);
  const classes = useStyles();

  const handleClose = () => {
    setInput([]);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setInput(props.serviceContracts);
    setOpen(true);
  };

  const handleSave = () => {
    if (!props.building?.id) return toast('error', 'Building Id unavailable');
    Api({
      endpoint: endpoints.createContractService(props.building.id),
      data: { input },
      onSuccess: (response) => {
        toast('success', 'Successfully Upload Service Contract ');
        handleClose();
        props.set_isSaved(prev => prev + 1);
      },
      onFail: (error) => {
        toast('error', error);
        console.log('error getting service contracts', error);
      },
    });
  };

  const handleAddInput = (i) => {
    const temp = [...input];
    temp.splice(i, 0, inputTemplate);
    setInput(temp);
  };

  const handleChangeInput = (i, name, value) => {
    const temp = [...input];
    temp.splice(i, 1, { ...temp[i], [name]: value, BuildingId: props.building?.id });
    setInput(temp);
  };

  const handleRemoveInput = (index) => setInput(i => i.filter((_, idx) => idx !== index));

  return (
    <>
      <button
        onClick={handleClickOpen}
        className="blue-button flex-standard"
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          margin: 0,
        }}
      >
        <UploadIcon />
        <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>Upload Data</p>
      </button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <DialogTitle>
          <Grid container justify="space-between">
            <h1>Manual Upload Data - Service Contracts</h1>
            <Close className={classes.closeBtn} onClick={handleClose} />
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: 500 }}>
          <table className="custom-form-table" style={{ width: '100%' }}>
            <tr>
              <td>
                <Box
                  component="button"
                  className={`${classes.smallBtn} blue-button`}
                  onClick={() => handleAddInput(0)}
                >
                  <h1 className={`${classes.blueBtnText} flex-standard`}>
                    <Add />
                  </h1>
                </Box>
              </td>
              {titles.map(m => <td colSpan={m.col} children={<h4>{m.label}</h4>} />)}
              <td />
            </tr>
            {input?.map((ipt, index) => {
              return (
                <tr>
                  <td>
                    <Box
                      component="button"
                      className={`${classes.smallBtn} blue-button`}
                      onClick={() => handleAddInput(index)}
                    >
                      <h1 className={`${classes.blueBtnText} flex-standard`}>
                        <Add />
                      </h1>
                    </Box>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '20em' }}>
                      <OutlinedInput
                        value={ipt.service || ''}
                        placeholder="Service Type"
                        onChange={e => handleChangeInput(index, 'service', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '16em' }}>
                      <OutlinedInput
                        value={ipt.contractService || ''}
                        placeholder="Vendor"
                        onChange={e => handleChangeInput(index, 'contractService', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '10em' }}>
                      <OutlinedInput
                        type="date"
                        value={moment(ipt.expiryAt).format('YYYY-MM-DD')}
                        placeholder="Expiry Date"
                        onChange={e => handleChangeInput(index, 'expiryAt', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '12em' }}>
                      <OutlinedInput
                        value={ipt.currentCharge || ''}
                        placeholder="Current Charge"
                        onChange={e => handleChangeInput(index, 'currentCharge', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '10em' }}>
                      <OutlinedInput
                        value={ipt.serviceCharge || ''}
                        placeholder="Service Charge"
                        onChange={e => handleChangeInput(index, 'serviceCharge', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '10em' }}>
                      <OutlinedInput
                        value={ipt.remarks || ''}
                        placeholder="Remarks"
                        onChange={e => handleChangeInput(index, 'remarks', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Box
                      component="button"
                      className={`${classes.smallBtn} blue-button`}
                      onClick={() => handleRemoveInput(index)}
                    >
                      <h1 className={`${classes.blueBtnText} flex-standard`}>
                        <Close />
                      </h1>
                    </Box>
                  </td>
                </tr>
              );
            })}
          </table>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: '0 3em', width: '100%', display: 'flex' }}>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={handleSave}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                Save Data
              </h1>
            </Box>
            <Box
              component="button"
              className="cancel-button"
              onClick={handleClose}
              style={{
                borderRadius: '6px',
                width: '120px',
              }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>

          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}
