import Table from '@Components/Table';

const columns = [
  {
    name: 'Tenant',
    selector: 'SecurityDebtorAgingGroupName',
    sortable: true,
  },
  {
    name: 'Arreas',
    selector: 'arrears',
    sortable: true,
    right: true,
  },
  {
    name: 'Total Day Aging',
    selector: 'total_day_aging',
    sortable: true,
    center: true,
  },
  {
    name: 'Amount (RM)',
    selector: 'amount',
    sortable: true,
    center: true,
  },
];

export default function DebtorsAgingTable({ debtorAging }) {
  return (
    <div className="paper" style={{ height: '100%' }}>
      <h2 style={{ fontWeight: 600, margin: '8px 0 0 16px' }}>Debtors Aging</h2>
      <div style={{ overflow: 'auto', maxHeight: '70vh' }}>
        <Table
          columns={columns}
          data={debtorAging}
        />
      </div>
    </div>
  );
}
