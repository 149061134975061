import firebase from 'firebase/';
import 'firebase/remote-config';

const firebaseConfig = {
    apiKey: "AIzaSyCrsfKico6FT8Q5Fw8i1jPQim5Cba74Wf4",
    authDomain: "dasb-9d18c.firebaseapp.com",
    databaseURL: "https://dasb-9d18c-default-rtdb.firebaseio.com",
    projectId: "dasb-9d18c",
    storageBucket: "dasb-9d18c.appspot.com",
    messagingSenderId: "980179225913",
    appId: "1:980179225913:web:de2ea1f12fb7b9d914ece8",
    measurementId: "G-FCHJ4QDSKH"
};
export const db = firebase.initializeApp(firebaseConfig).database();

export const remoteConfig = firebase.remoteConfig();
