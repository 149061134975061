import { useState } from 'react';
import {
  Card, CardContent, Typography, IconButton, Grid, Collapse, Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { replaceSpace } from '@Helpers';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF', color: '#000000', marginBottom: 15, paddingTop: 5, paddingBottom: 5, width: '100%',
  },
  selectedPageChip: {
    backgroundColor: '#1E3465', color: '#FFFFFF', marginBottom: 5, marginRight: 5, textTransform: 'capitalize',
  },
  expandOpen: { transform: 'rotate(180deg)' },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

export default function UserInfoCard({ userData, userRole, pageAccessList }) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const handleExpandClick = () => { setExpanded(!expanded); };
  const selectedOption = (value) => ({ label: replaceSpace(pageAccessList.find(e => e.id === value)?.name), value });
  const selectedPage = userData?.page_access.split(',').map(e => selectedOption(Number(e)));

  return (
    <Card className={classes.root}>
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Grid container xs={12} style={{ color: '#1E3465' }}>
          <Grid item xs={4} style={{ marginTop: 10 }}>
            <Typography variant="h6" gutterBottom style={{ transform: 'scale(0.9)' }}>{userData?.name}</Typography>
          </Grid>
          <Grid container item xs={8}>
            <Grid xs={4} style={{ marginTop: 15 }}>{userData?.email}</Grid>
            <Grid xs={3} style={{ marginTop: 15, textTransform: 'capitalize' }}>{replaceSpace(userData?.dax_role)}</Grid>
            <Grid xs={2} style={{ marginTop: 15 }}>{userData?.RoleId === 1 ? 'Admin' : 'User'}</Grid>
            <Grid xs={2}>
              {userRole === 1 && (
                <Link to={`/user/${userData?.id}`}>
                  <IconButton><EditIcon style={{ color: 'black', fontSize: 18 }} /></IconButton>
                </Link>
              )}
            </Grid>
            <Grid xs={1}>
              <IconButton
                className={classes.expand + {
                  [classes.expandOpen]: expanded,
                }}
                onClick={handleExpandClick}
                aria-expanded={expanded}
              >
                {!expanded ? <ExpandMoreIcon style={{ color: 'black' }} /> : <ExpandLessIcon style={{ color: 'black' }} />}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ paddingTop: 0, paddingBottom: 3 }}>
          <Grid container>
            <Grid item xs={4} style={{ marginTop: 10 }} />
            <Grid container item xs={8}>
              <Grid item xs={12} style={{ marginTop: 10 }}><span style={{ color: '#A5ADBE' }}>Page Access</span></Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <div style={{ flex: 1, marginBottom: 10 }}>
                  {selectedPage.length
                    ? selectedPage?.map(data => <Chip label={data.label} size="small" className={classes.selectedPageChip} />)
                    : <Chip label="None" size="small" className={classes.selectedPageChip} />}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}
