import MainContentNavbar from '@Components/MainContentNavbar';

export default function IFrame({ buildings = [], selectedBuilding = 0, type = '' }) {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50px',
        bottom: '0px',
        right: '0px',
        left: '0px',
      }}
    >
      <div
        style={
          type === 'threeD'
            ? { position: 'absolute', top: '50px', zIndex: 999 }
            : { position: 'absolute', top: '20px', zIndex: 999 }
        }
      >
        <MainContentNavbar />
      </div>
      {buildings[selectedBuilding] ? (
        <iframe
          src={buildings[selectedBuilding][type]}
          key={buildings[selectedBuilding][type]}
          title="type"
          frameBorder="0"
          style={{ width: '100%', height: '100%', zIndex: 998 }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          NO DATA
        </div>
      )}
    </div>
  );
}
