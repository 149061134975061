import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import "./annotateImage.css";
import Button from "./Button";
import { Close, Delete, Save } from "@material-ui/icons";
import AnnotateIcon from "./AnnotateIcon";
import { Grid, TextField } from "@material-ui/core";
import DateDropdown from "./DateDropdown";

// Fabric config
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerColor = "#03A398";
fabric.Object.prototype.cornerStyle = "circle";
fabric.Object.prototype.cornerStrokeColor = "#FFFFFF";
fabric.Object.prototype.strokeWidth = 1;
fabric.Object.prototype.stroke = "#03A398";
fabric.Object.prototype._controlsVisibility = {
  mtr: false,
};

const reportData = [
  { label: "Filename", data: "DJI 9020.jpg" },
  { label: "Date", data: "14 Aug 2020" },
  { label: "Time", data: "7:53 AM" },
  { label: "Location", data: "2.04994 , 103.9201" },
  { label: "Altitude", data: "110.91 m" },
];

const initialFormData = {
  tag: "",
  description: "",
  date: "",
  status: 0,
};

function AnnotateImage({ data, setData }) {
  const [formData, setFormData] = useState(initialFormData);

  const handleFormData = (val, selector) => {
    setFormData({ ...formData, [selector]: val });
  };

  // Draw State
  const [drawState, setDrawState] = useState(false);
  const [annotateState, setAnnotateState] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [activeObject, setActiveObject] = useState(false);
  const { editor, onReady } = useFabricJSEditor();
  const onAddRectangle = (editor) => {
    let rect = new fabric.Rect({
      left: editor.canvas.width * 0.5 - 150,
      top: editor.canvas.height * 0.5 - 150,
      fill: "transparent",
      width: 150,
      height: 150,
      strokeWidth: 3,
      stroke: "#03A398",
      hasControls: true,
      hasRotatingPoint: false,
      hasBorders: false,
    });

    editor.canvas.add(rect);
    editor.canvas.setActiveObject(rect);
    setActiveObject(rect);
  };

  const onDelete = (editor) => {
    if (activeObject !== {}) {
      editor.canvas.remove(activeObject);
    }
    setActiveObject({});
    setDrawState(false);
    setAnnotateState(false);
    editId !== -1 && setEditId(-1);
    setFormData(initialFormData);
  };

  const onSave = () => {
    activeObject.hasControls = false;
    activeObject.lockMovementX = true;
    activeObject.lockMovementY = true;
    activeObject.hoverCursor = "default";
    setDrawState(false);
    setAnnotateState(true);
  };

  const onSubmit = () => {
    let metaData = data.metaData;
    if (editId !== -1) {
      metaData[editId].formData = formData;
    } else {
      let textObj = getTextObj(formData.tag, activeObject);
      let newObj = new fabric.Group([activeObject, textObj], {
        left: activeObject.left,
        right: activeObject.right,
        top: activeObject.top - 29,
        bottom: activeObject.bottom,
        hasControls: false,
        lockMovementX: true,
        lockMovementY: true,
        hoverCursor: "pointer",
        selectable: false,
        prevWidth: editor.canvas.width,
      });

      metaData.push({
        formData,
        object: newObj,
      });
      editor.canvas.remove(activeObject);
      setActiveObject({});
    }
    setData({ ...data, metaData });
    setEditId(false);
    setAnnotateState(false);
    setFormData(initialFormData);
  };

  const getTextObj = (text, activeObject) => {
    var t = new fabric.Text(text, {
      fontFamily: "Poppins",
      fontSize: 14,
      originX: "center",
      originY: "center",
      backgroundColor: "#03A398",
      fill: "#FFFFFF",
    });
    let rect = new fabric.Rect({
      fill: "#03A398",
      scaleY: 0.5,
      originX: "center",
      originY: "center",
      rx: 5,
      ry: 5,
      width: t.width + 25,
      height: t.height + 25,
    });
    let newObj = new fabric.Group([rect, t], {
      left: activeObject.left,
      right: activeObject.right,
      top: activeObject.top - 29,
      bottom: activeObject.bottom,
      width: t.width + 25,
      height: t.height + 25,
    });
    return newObj;
  };

  const hasSrcChanged = useHasChanged(data.src);

  useEffect(() => {
    const editReport = (id) => {
      setFormData({ ...data.metaData[id].formData });
      setEditId(id);
      setAnnotateState(true);
    };

    const resizeObjects = (i, width, prevWidth) => {
      let ratio = width / prevWidth;
      let scaleX = i.scaleX || 1;
      let left = i.left;

      let tempScaleX = scaleX * ratio;
      let tempLeft = left * ratio;

      i.scaleX = tempScaleX;
      i.left = tempLeft;

      i.setCoords();
      editor.canvas.calcOffset();
    };

    if (editor) {
      if (hasSrcChanged) {
        editor.canvas.remove(...editor.canvas.getObjects());
        setActiveObject({});
        setDrawState(false);
        setAnnotateState(false);
        editId !== -1 && setEditId(false);
        setFormData(initialFormData);
      }
      fabric.Image.fromURL(data.src, function (oImg) {
        editor.canvas.setBackgroundImage(
          oImg,
          editor.canvas.renderAll.bind(editor.canvas),
          {
            scaleX: editor.canvas.width / oImg.width,
            scaleY: editor.canvas.height / oImg.height,
          }
        );
      });
      if (data.metaData.length > 0) {
        let canvasObjects = editor.canvas.getObjects();
        for (let [index, metaData] of data.metaData.entries()) {
          if (!canvasObjects[index]) {
            editor.canvas.add(metaData.object);
            if (editor.canvas.width !== metaData.object.prevWidth) {
              resizeObjects(
                metaData.object,
                editor.canvas.width,
                metaData.object.prevWidth
              );
              metaData.object.prevWidth = editor.canvas.width;
            }
            metaData.object.on("mouseup", function () {
              editReport(index);
            });
          }
        }
      }
    }
  }, [data, editor, hasSrcChanged, editId]);

  return (
    <div className={`draw-image-container ${drawState && "active"}`}>
      <FabricJSCanvas className="fabric" onReady={onReady} />
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        {drawState && (
          <>
            <Button
              variant="contained"
              startIcon={<Delete />}
              style={{
                margin: 0,
                paddingLeft: 14,
                backgroundColor: "#ED3C58",
                borderColor: "#ED3C58",
              }}
              onClick={() => {
                onDelete(editor);
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              startIcon={<Save />}
              style={{ paddingLeft: 14 }}
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Button>
          </>
        )}
        {annotateState && (
          <div className="flex row center top">
            <Button
              variant="contained"
              style={{
                margin: 0,
              }}
              onClick={() => {
                onDelete(editor);
              }}
            >
              <Close />
            </Button>
            <div className="a-form-container">
              <h4 className="title">
                {editId !== -1 ? "Report 123" : "New Report"}
              </h4>
              <Grid container className="data-container" spacing={2}>
                {reportData.map((item, index) => {
                  if (index === 0) {
                    return (
                      <Grid item xs={12} key={index}>
                        <p className="label">{item.label}</p>
                        <p className="data">{item.data}</p>
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={index % 2 !== 0 ? 8 : 4} key={index}>
                      <p className="label">{item.label}</p>
                      <p className="data">{item.data}</p>
                    </Grid>
                  );
                })}
              </Grid>
              <h4 className="title">Asset Tag</h4>
              <div className="data-container single-line-input">
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter asset tag"
                  value={formData.tag}
                  onChange={(event) =>
                    handleFormData(event.target.value, "tag")
                  }
                />
                <div className="flex row left middle" style={{ marginTop: 10 }}>
                  {["Telco tower", "Disc", "Network"].map((item, index) => (
                    <p
                      className="label hover"
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #FFFFFF",
                        padding: 6,
                        marginRight: 6,
                        borderRadius: 4,
                      }}
                      key={index}
                      onClick={() => handleFormData(item, "tag")}
                    >
                      {item}
                    </p>
                  ))}
                </div>
              </div>
              <h4 className="title">Description</h4>
              <div className="data-container">
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Add a description"
                  multiline
                  rows={5}
                  value={formData.description}
                  onChange={(event) =>
                    handleFormData(event.target.value, "description")
                  }
                />
              </div>
              <h4 className="title">Due Date</h4>
              <div className="data-container">
                <DateDropdown
                  m={0}
                  variant="blue"
                  selected={formData.date}
                  onChange={(val) => handleFormData(val, "date")}
                />
              </div>
              <h4 className="title">Status</h4>
              <div className="data-container">
                <Grid container>
                  {[
                    { name: "Open", color: "#6E6E6E" },
                    { name: "In Progress", color: "#F7B84B" },
                    { name: "On Hold", color: "#11CDEF" },
                    { name: "Resolved", color: "#00B60C" },
                  ].map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={3}
                      className="flex column center middle"
                    >
                      <div
                        className={`flex row center middle a-checkbox ${
                          formData.status === index ? "active" : ""
                        }`}
                        onClick={() => handleFormData(index, "status")}
                      >
                        <div
                          className="a-checkbox-inner"
                          style={{ background: item.color }}
                        />
                      </div>
                      <p
                        className={`label`}
                        style={{
                          fontSize: "0.6em",
                          marginTop: 5,
                          color: formData.status === index && item.color,
                        }}
                      >
                        {item.name}
                      </p>
                    </Grid>
                  ))}
                </Grid>
                <Button
                  variant="contained"
                  style={{
                    margin: 0,
                    marginTop: 10,
                    marginBottom: 10,
                    fontSize: "0.7em",
                    float: "right",
                  }}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Save Report
                </Button>
              </div>
            </div>
          </div>
        )}
        {!annotateState && !drawState && (
          <Button
            variant="contained"
            startIcon={<AnnotateIcon />}
            style={{ margin: 0, paddingLeft: 14 }}
            onClick={() => {
              !drawState && onAddRectangle(editor);
              setDrawState(!drawState);
            }}
          >
            Annotate
          </Button>
        )}
      </div>
    </div>
  );
}

const useHasChanged = (val) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default AnnotateImage;
