/* eslint-disable complexity */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api, { endpoints } from '@Helpers/api';
import { replaceSpace } from '@Helpers';

export default function Hook() {
  const { user_id } = useParams();
  const [userData, setUserData] = useState([]);
  const [activityLog, setActivityLog] = useState([]);
  const [selected, setSelected] = useState([]);
  const [pageAccessList, setPageAccessList] = useState([]);

  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setPageAccessList(data.PageAccess ?? []);
      },
    });
  }, []);
  useEffect(() => {
    if (!user_id) return;
    getUser();
    Api({
      endpoint: endpoints.activityLog(),
      data: { UserId: user_id },
      onSuccess: (response) => {
        setActivityLog(response.data);
      },
      onFail: (e) => console.log('error', e),
    });
  }, [user_id, pageAccessList]);

  const selectedOption = (value) => ({ label: replaceSpace(pageAccessList.find(e => e.id === value).name), value });
  const updateUser = (data) => {
    Api({
      endpoint: endpoints.updateUser(user_id),
      data,
      onSuccess: () => toast('success', 'Update user successful'),
      onFail: () => toast('error', 'Failed update user'),
    });
  };
  const getUser = () => {
    Api({
      endpoint: endpoints.getUser(),
      data: { id: user_id },
      onSuccess: (res) => {
        setUserData(res.data);
        setSelected(res.data.page_access.split(',').map(e => selectedOption(Number(e))));
      },
      onFail: () => { toast('error', 'Failed update user'); },
    });
  };

  return {
    userData,
    activityLog,
    selected,
    updateUser,
    pageAccessList,
    getUser,
    user_id,
  };
}
