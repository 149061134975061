import { useEffect, useState } from 'react';
import Api from '@Helpers/api';
import { endpoints } from '@Configs/endpoints';
import useUrlQueries from '@Hooks/urlQueries';
import { ProgressBarCol, StatusCol, FormattedDate } from '@Components/Maintenance/TasksTableCol';

const buildings = {
  0: 261, // B5
  1: 157, // GALLERIA
  2: 161, // KOMTAR JBCC
  3: 156, // MENARA KOMTAR
  4: 162, // LARKIN SENTRAL
  5: 159, // MENARA ANSAR
  6: 155, // MENARA JLAND
  7: 163, // @MART
  8: 158, // Tg Leman Beach Resort
  9: 160, // PPTD
};

export default function Hook({ selectedBuilding, allBuilding }) {
  const { url_queries } = useUrlQueries();
  // const status = url_queries[""];
  const { '': status, building } = url_queries;
  const [tasks, set_tasks] = useState([]);
  const [is_update, set_is_update] = useState(0);
  const [is_ready, set_is_ready] = useState(0);
  const [institutions, set_institutions] = useState({});
  const [assignee, set_assignee] = useState({});

  // fetch all common data
  useEffect(() => {
    // request user_extra, institution
    Api({
      endpoint: endpoints.getServedeck(),
      data: { query: `query{user_extra{id name}institution{id name}}` },
      onSuccess: ({ data: { user_extra, institution } }) => {
        if (user_extra) {
          let data = {};
          user_extra.forEach((obj) => {
            data[obj.id] = obj.name;
          });
          set_assignee(data);
          set_is_ready(is_ready + 1);
        }
        if (institution) {
          let data = {};
          institution.forEach((obj) => {
            data[obj.id] = obj.name;
          });
          set_institutions(data);
          set_is_ready(is_ready + 1);
        }
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // request tasks
    Api({
      endpoint: endpoints.getServedeck(),
      data: {
        query: `query{task_execution${
          allBuilding ? '' : `(institution_id:${building ?? buildings[selectedBuilding]})`
        }{name schedule status created_date due_date assignee_id institution_id review_date assignee_remark reviewer_remark task_execution_running_number completed_task total_task schedule due_date}}`,
      },
      onSuccess: ({ data: { task_execution } }) => {
        if (task_execution) {
          set_tasks(task_execution);
          set_is_update(is_update + 1);
        }
      },
    });
    // eslint-disable-next-line
  }, [selectedBuilding]);

  // change Issue's foreign ID to its value from common data
  useEffect(() => {
    // change ID to value for table
    let data = [];
    let filteredTasks;

    if (status === undefined) {
      filteredTasks = tasks;
    } else if (status === 'Overdue') {
      filteredTasks = tasks.filter(
        (d) => ['Open', 'In Progress'].includes(d.status) && parseInt(d.due_date) < new Date().getTime()
      );
    } else {
      filteredTasks = tasks.filter((f) => f.status === status);
    }

    if (filteredTasks.length) {
      filteredTasks.forEach((s) => {
        data.push({
          ...s,
          assignee: assignee[s.assignee_id] ?? 'N/A',
          institution: institutions[s.institution_id],
          progress: <ProgressBarCol {...s} />,
          status: <StatusCol status={s.status} od={status === 'Overdue'} />,
          scheduleDate: <FormattedDate time={s.schedule} />,
          dueDate: <FormattedDate time={s.due_date} />,
          createdDate: <FormattedDate time={s.created_date} />,
        });
      });
    }

    set_tasks(data);
    // eslint-disable-next-line
  }, [is_ready, is_update]);

  return {
    tasks,
  };
}
