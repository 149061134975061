import { Grid } from "@material-ui/core";
import Table from "@Components/Table";
import MainContentContainer from "@Components/MainContentContainer";
import MainContentNavbar from "@Components/MainContentNavbar";

let data = [];

for (let i = 0; i < 100; i++) {
  data.push({
    no: 1,
    lotNo: 238,
    tradeName: "Abmann",
    area: 276,
    totalRental: 100000,
    reasons: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    lastTrading: "24/6/2020",
  });
}

const columns = [
  {
    name: "No.",
    selector: "no",
    sortable: true,
    width: "60px",
  },
  {
    name: "Lot No.",
    selector: "lotNo",
    sortable: true,
    right: false,
  },
  {
    name: "Trade Name",
    selector: "tradeName",
    sortable: true,
    right: false,
  },
  {
    name: "Area (ft²)",
    selector: "area",
    sortable: true,
    right: false,
  },
  {
    name: "Total Rental",
    selector: "totalRental",
    sortable: true,
    right: false,
  },
  {
    name: "Reasons",
    selector: "reasons",
    sortable: true,
    right: false,
    wrap: true,
    grow: 5,
  },
  {
    name: "Last Trading",
    selector: "lastTrading",
    sortable: true,
    right: false,
    width: "150px",
  },
];

function NonRenewalTenants(props) {
  return (
    <>
      <MainContentNavbar to="/property/leasing" text="NON RENEWAL TENANTS" />
      <MainContentContainer>
        <Grid container>
          <Grid item xs={12}>
            <Table title="" columns={columns} data={data} />
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <p>Total Area</p>
              </Grid>
              <Grid item xs={4}>
                <p>20,219 ft²</p>
              </Grid>
              <Grid item xs={4}>
                <p>5%</p>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <p>Total Rental Contribution</p>
              </Grid>
              <Grid item xs={4}>
                <p>RM 308,703</p>
              </Grid>
              <Grid item xs={4}>
                <p>17%</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default NonRenewalTenants;
