import React, { useState } from 'react';
import {
  Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, OutlinedInput, InputAdornment, IconButton, Grid, Chip,
} from '@material-ui/core';
import { GetApp, Close, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import DateRange from '@Components/DateRange';

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '210px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
  root: { '&$checked': { color: '#4fd8af' }, color: '#1e3465' },
  checked: {},
}));

export default function GenerateReport(p) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [search_input, set_search_input] = useState('');
  const [emp_list, set_emp_list] = useState([]);
  const [dateSelectionRange, setDateSelectionRange] = useState({ startDate: new Date(), endDate: new Date() });

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSearchInput = (value) => set_search_input(value);
  const onKeyDown = ({ key }) => { if (key === 'Enter') { onEnter(); } };

  const onEnter = () => {
    if (!search_input) return;
    if (emp_list.find(e => e === search_input)) return;
    set_emp_list(prev => [...prev, search_input]);
    set_search_input('');
  };

  const handleGenerate = () => {
    const startDate = moment(dateSelectionRange.startDate).format('YYYY-MM-DD');
    const endDate = moment(dateSelectionRange.endDate).format('YYYY-MM-DD');
    p.set_report_date((endDate === startDate) ? endDate : `${startDate} - ${endDate}`);
    p.getReport(dateSelectionRange, emp_list);
    p.set_show_report(true);
    setOpen(false);
  };

  const onDelete = (i) => { set_emp_list(prev => { prev.splice(i, 1); return [...prev]; }); };

  return (
    <div>
      <Box
        component="button"
        className={`${classes.blueBtn} blue-button`}
        onClick={handleClickOpen}
      >
        <h1 className={`${classes.blueBtnText} flex-standard`}>
          <GetApp />
          Generate Report
        </h1>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <DialogTitle>
          Generate Report
          <Close
            className={classes.closeBtn}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <div style={{
            padding: '0 20px', width: '460px', color: '#1e3465',
          }}
          >
            <Grid container spacing={3}>
              {['top_management', 'human_resource'].includes(p.user?.dax_role) && (
                <>
                  <Grid item xs={3}>
                    <b>Employee Number</b>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <OutlinedInput
                        onKeyDown={onKeyDown}
                        value={search_input}
                        onChange={(event) => handleSearchInput(event.target.value)}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={onEnter}>
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        )}
                        labelWidth={0}
                        autoFocus
                      />
                    </FormControl>
                    <div className="flex row middle" style={{ flexWrap: 'wrap' }}>
                      {!emp_list.length && <Chip color="primary" label="All employee" style={{ margin: '10px 10px 0 0' }} />}
                      {emp_list.map((e, i) => <Chip color="primary" onDelete={() => onDelete(i)} label={e} style={{ margin: '10px 10px 0 0' }} />)}
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={3}>
                <b>Date</b>
              </Grid>
              <Grid item xs={9}>
                <DateRange
                  selectionRange={dateSelectionRange}
                  setSelectionRange={setDateSelectionRange}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              padding: '0 40px', width: '100%', display: 'flex', justifyContent: 'space-between',
            }}
          >
            <Box
              component="button"
              className="blue-button-outlined"
              onClick={handleClose}
              style={{
                borderRadius: '6px',
                width: '120px',
              }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={handleGenerate}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                <GetApp />
                Generate Report
              </h1>
            </Box>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
