/* eslint-disable complexity */
import { useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { useRouteMatch, Link } from 'react-router-dom';
import {
  Grid, Menu, MenuItem,
} from '@material-ui/core';
import { DaxLogo } from '@Assets/Icons/daxLogo';
import {
  DashboardIcon, MapIcon, BuildingIcon, HRIcon,
} from '@Assets/Icons/TopbarIcons';
import Avatar from './Avatar';
import ActivityLog from './ActivityLog';
import ActivityLogDialog from './ActivityLogDialog';

export default function TopBar(props) {
  const { path } = useRouteMatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorDrawer, setAnchorDrawer] = useState(false);
  const handleClickMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleOpenDrawer = () => {
    setAnchorDrawer(true);
  };

  let oshSiteDashboardLink = '/dashboard/osh';
  if (!props.can_see_osh) oshSiteDashboardLink = props.can_see_environment ? '/dashboard/environment' : '/dashboard/quality';

  return (
    <Grid container item xs={12} direction="row" justify="space-between" alignItems="center" className="topbar">
      <div>
        {props.web_logo ? <img style={{ marginTop: -7 }} src={props?.web_logo} height="40" /> : <DaxLogo height="30" width="100" />}
      </div>
      <Grid container item xs={9} justify="space-around" alignItems="center">
        {[
          {
            roles: ['osh_site'],
            link: '/project',
            icon: <MapIcon />,
            title: 'Project Site',
          },
          {
            roles: ['osh_site'],
            link: oshSiteDashboardLink,
            selected: '/dashboard',
            icon: <DashboardIcon />,
            title: 'Dashboard',
            canNotSee: !(props.can_see_osh || props.can_see_environment || props.can_see_quality),
          },
          {
            roles: ['top_management', 'demo'],
            link: '/dashboard/leasing',
            selected: '/dashboard',
            icon: <DashboardIcon />,
            title: 'Dashboard',
          },
          {
            roles: ['top_management', 'demo', 'property_management', 'surian', 'chulan', 'only_surian'],
            link: '/property',
            icon: <MapIcon />,
            title: 'Property Management',
          },
          {
            roles: ['top_management', 'demo', 'facilities_management', 'parking', 'security'],
            link: '/integrated-facilities/facilities',
            icon: <BuildingIcon />,
            title: 'Integrated Facilities Management',
          },
          {
            roles: ['surian', 'chulan', 'only_surian'],
            link: '/integrated-facilities',
            icon: <BuildingIcon />,
            title: 'Integrated Facilities Management',
          },
          {
            roles: ['top_management', 'demo', 'human_resource'],
            link: '/human-resources',
            icon: <HRIcon />,
            title: 'Human Resources',
          },
          {
            roles: ['human_resource', 'facilities_management'],
            link: '/attendance',
            icon: <HRIcon />,
            title: 'Attendance',
          },
          // {
          //   roles: ['top_management', 'demo', 'marketing'],
          //   link: '/marketing',
          //   selected: '/dashboard/finance',
          //   icon: <MarketingIcon />,
          //   title: 'Marketing',
          // },
        ].filter(e => !e.canNotSee).map(nav => nav.roles.includes(props.dax_role) && (
          <Link to={nav.link}>
            <Grid
              item
              container
              alignItems="center"
              style={{ margin: '0 5px', opacity: path.includes(nav.selected ?? nav.link) ? 1 : 0.65 }}
            >
              {nav.icon}
              <h3 style={{ marginLeft: 3, fontWeight: 600 }}>{nav.title}</h3>
            </Grid>
          </Link>
        ))}
      </Grid>
      {/* <Grid item>
            <BellIcon />
          </Grid> */}
      <div>
        <Grid
          container
          item
          alignItems="center"
          style={{ cursor: 'pointer' }}
          onBlur={handleCloseMenu}
          onClick={handleClickMenu}
        >
          <Avatar {...props} />
          <p style={{ fontSize: 14, fontWeight: 600 }}>{props.name?.split(' ').slice(0, 2).join(' ')}</p>
          <ExpandMore />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <MenuItem disabled className="d-flex justify-content-between">
              <div className="mr-2">{props.name?.split(' ').slice(0, 2).join(' ')}</div>
            </MenuItem>
            {['top_management', 'demo'].includes(props.dax_role) && (
              <Link to="/admin-panel">
                <MenuItem>
                  <span style={{ color: '#1e3465' }}>Admin Panel</span>
                </MenuItem>
              </Link>
            )}
            {(props.dax_role !== 'osh_site' || !!props.can_see_activity_log) && (
              <MenuItem>
                <span style={{ color: '#1e3465' }} onClick={handleOpenDrawer}>Activity Log</span>
              </MenuItem>
            )}
            {props.dax_role === 'osh_site' && props.RoleId === 1 && (
              <Link to="/user">
                <MenuItem>
                  <span style={{ color: '#1e3465' }}>User Management</span>
                </MenuItem>
              </Link>
            )}
            <Link to="/logout">
              <MenuItem>
                <span style={{ color: '#1e3465' }}>Logout</span>
              </MenuItem>
            </Link>
          </Menu>
        </Grid>
      </div>
      {anchorDrawer && props.dax_role === 'osh_site' && (<ActivityLogDialog open={anchorDrawer} onClose={() => setAnchorDrawer(false)} />)}
      {anchorDrawer && props.dax_role !== 'osh_site' && (<ActivityLog open={anchorDrawer} onClose={() => setAnchorDrawer(false)} />)}
    </Grid>
  );
}
