/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import Api, { endpoints } from '@Helpers/api';
import {
  Grid, Paper, Typography, makeStyles, Button,
} from '@material-ui/core';
import { Save, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import HighlightTabs from '@Components/HighlightTabs';
import FormAnnotation from './FormAnnotation';

const useStyles = makeStyles(() => ({
  cardHeader: { fontSize: '14px' },
  cardDetail: { fontSize: '10px' },
}));

export default function ActionBar({ mainImage, mainImageAnnotations, setMainImageAnnotations, mainAnnotationId, saveImage, user }) {
  let userCanEditAnnotation = false;
  // osh
  if (mainImage['Inspection.InspectionCategoryId'] === 1) userCanEditAnnotation = !!user.can_edit_osh;
  if (mainImage['Inspection.InspectionCategoryId'] === 2) userCanEditAnnotation = !!user.can_edit_environment;
  if (mainImage['Inspection.InspectionCategoryId'] === 3) userCanEditAnnotation = !!user.can_edit_quality;
  const [displayModal, setDisplayModal] = useState(false);
  const [inspectionCategoryOptions, setInspectionCategoryOptions] = useState([]);
  const [oshCategoryOptions, setOshCategoryOptions] = useState([]);
  const [environmentCategoryOptions, setEnvironmentCategoryOptions] = useState([]);
  const [workCategoryOptions, setWorkCategoryOptions] = useState([]);
  const [workPartOptions, setWorkPartOptions] = useState([]);
  const [elementCategoryOptions, setElementCategoryOptions] = useState([]);
  const [annotationTypeOptions, setAnnotationTypeOptions] = useState([]);
  const [tab, setTab] = useState(0);
  const classes = useStyles();
  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setInspectionCategoryOptions(data.InspectionCategory ?? []);
        setOshCategoryOptions(data.OshCategory ?? []);
        setEnvironmentCategoryOptions(data.EnvironmentCategory ?? []);
        setWorkCategoryOptions(data.WorkCategory ?? []);
        setWorkPartOptions(data.WorkPart ?? []);
        setElementCategoryOptions(data.ElementCategory ?? []);
        setAnnotationTypeOptions(data.AnnotationType ?? []);
        // load other static here
      },
    });
  }, []);
  const toggleModal = () => setDisplayModal(prevV => !prevV);
  const MediaHeader = () => (
    <Paper gutterBottom className={classes.cardHeader} style={{ display: 'flex', margin: 0, padding: 10 }} elevation={2}>
      <div style={{ flex: 5 }}>
        Media Details ({mainImage.id}) &nbsp;
        {/* <IconButton color="primary" aria-label="upload picture" component="span" style={{ padding: 0 }} onClick={() => saveImage()}><Save /></IconButton> */}
      </div>
      <IconButton color="primary" component="span" style={{ padding: 0, flex: 1 }} onClick={() => toggleModal()}>
        {displayModal ? <ArrowDropDown /> : <ArrowDropUp />}
      </IconButton>
    </Paper>
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="sidebar paper" style={{ border: '1px solid #1E3465', backgroundColor: '#F5FAFF' }}>
          <MediaHeader />
          {displayModal && (
            <div>
              <Typography gutterBottom className={classes.cardDetail} style={{ padding: 10 }}>
                <strong>Filename:</strong> {mainImage.name} <br />
                <strong>Date:</strong> {moment(mainImage.recordedAt).format('D MMMM YYYY')} <br />
                <strong>Report ID:</strong> {mainImage['Inspection.id']} <br />
                <strong>Report Name:</strong> {mainImage['Inspection.name']} <br />
                <strong>Inspections Category:</strong> &nbsp;
                {inspectionCategoryOptions.find(({ id }) => id === mainImage['Inspection.InspectionCategoryId'])?.name ?? 'OSH'}
                <br />
              </Typography>
              {!!mainImageAnnotations.length && (
                <>
                  <HighlightTabs
                    items={[{ label: 'Active', value: 0 }, { label: 'All', value: 1 }]}
                    tab={tab}
                    setTab={setTab}
                    customStyle={{ fontSize: '10px', minWidth: '50%', minHeight: '20px' }}
                  />
                  <Grid container style={{ height: '300px', overflow: 'auto' }}>
                    {mainImageAnnotations.map((annotation) => {
                      const isselected = !!(mainAnnotationId === annotation.id);
                      if (!!!tab && !isselected) return;
                      return (
                        <FormAnnotation
                          mainImage={mainImage}
                          annotation={annotation}
                          isselected={isselected}
                          oshCategoryOptions={oshCategoryOptions}
                          environmentCategoryOptions={environmentCategoryOptions}
                          workCategoryOptions={workCategoryOptions}
                          workPartOptions={workPartOptions}
                          elementCategoryOptions={elementCategoryOptions}
                          annotationTypeOptions={annotationTypeOptions}
                          setMainImageAnnotations={setMainImageAnnotations}
                          mainImageAnnotations={mainImageAnnotations}
                        />
                      );
                    })}
                  </Grid>
                </>
              )}
              { userCanEditAnnotation ? (
                <div style={{ padding: 10 }}>
                  <Button size="small" variant="contained" color="primary" component="span" style={{ padding: 0 }} onClick={() => saveImage()}>Save</Button>
                </div>
              ) : (
                <div style={{ padding: 10, fontSize: '10px' }}>* User do not have permission to edit this inspection</div>
              )}
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
