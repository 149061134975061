import { db } from "@Configs/firebase";
import { useEffect, useState } from "react";
import Api, { endpoints } from '@Helpers/api';

export default function Hook(props) {
    const [serverData, setServerData] = useState({})
    const [cctvData, setCctvData] = useState({})
    const [vtaData, setVtaData] = useState({})
    const [miData, setMiData] = useState({})
    const [activityLogs, setActivityLogs] = useState([])

    useEffect(() => {
        Api({
            endpoint: endpoints.activityLog(),
            data: { forPanel: 1 },
            onSuccess: (response) => setActivityLogs(response.data),
            onFail: setActivityLogs([]),
          });
        db.ref("server").on("value", (snapshot) => setServerData(snapshot.val()));
        db.ref("cctv").on("value", (snapshot) => setCctvData(snapshot.val()));
        db.ref("vta").on("value", (snapshot) => setVtaData(snapshot.val()));
        db.ref("manual input").on("value", (snapshot) => setMiData(snapshot.val()));
        return () => {
            db.ref("server").off("value")
            db.ref("cctv").off("value")
            db.ref("vta").off("value")
            db.ref("manual input").off("value")
        };
    }, []);

    return {
        serverData,
        cctvData,
        vtaData,
        miData,
        activityLogs,
    }
}