import React, { useEffect, useState } from 'react';
import {
  Map, TileLayer, Marker, Tooltip, LayersControl,
} from 'react-leaflet';
import { GoogleLayer } from 'react-leaflet-google-v2';
import * as L from 'leaflet';
import KmlLoader from './KmlLoader';
import './map.css';

const { BaseLayer, Overlay } = LayersControl;
const google_map_key = 'AIzaSyCxC-PXJBZgXAsY1R8P_uvpQuveVheenbQ';

const LeafIcon = L.Icon.extend({ options: {} });
const greenIcon = new LeafIcon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export default function Maps({
  buildings = [],
  setSelectedBuilding = () => null,
  selectedBuilding = 0,
  divisions = [],
  set_selected_division = () => null,
  selected_division = 0,
  filtered_projects = [],
  set_selected_project = () => null,
  selected_project = 0,
  mapType = 'ROADMAP',
  layers = [],
}) {
  const [center, set_center] = useState([1.496798, 103.704124]);
  const [zoom, set_zoom] = useState(10);

  useEffect(() => {
    if (buildings?.length > 0 && Number.isInteger(selectedBuilding)) {
      set_center([buildings[selectedBuilding]?.latitude, buildings[selectedBuilding]?.longitude]);
      set_zoom(19);
    }
  }, [buildings, selectedBuilding]);

  useEffect(() => {
    if (!divisions.length || !divisions[selected_division].lng) return;
    set_center([divisions[selected_division].lat, divisions[selected_division].lng]);
    set_zoom(16);
  }, [divisions, selected_division]);

  useEffect(() => {
    if (!filtered_projects.length || !filtered_projects[selected_project].lng) return;
    set_center([filtered_projects[selected_project].lat, filtered_projects[selected_project].lng]);
    set_zoom(18);
  }, [filtered_projects, selected_project]);

  return (
    <Map
      center={center}
      zoom={zoom}
      maxZoom={23}
      scrollWheelZoom
      style={{
        position: 'fixed', top: 50, bottom: 0, right: 0, left: 0,
      }}
    >
      <LayersControl position="topright">
        <BaseLayer name="Google Maps Roads" checked={mapType === 'ROADMAP'}>
          <TileLayer
            url="http://{s}.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            maxZoom={23}
          />
        </BaseLayer>
        <BaseLayer name="Google Maps Terrain" checked={mapType === 'TERRAIN'}>
          <TileLayer
            url="http://{s}.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}"
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            maxZoom={23}
          />
        </BaseLayer>
        <BaseLayer name="Google Maps Satellite" checked={mapType === 'SATELLITE'}>
          <TileLayer
            url="http://{s}.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            maxZoom={23}
          />
        </BaseLayer>
        {buildings[selectedBuilding]?.orthophoto && (
          <Overlay name={buildings[selectedBuilding].name} checked>
            <TileLayer url={`${buildings[selectedBuilding].orthophoto}/{z}/{x}/{y}.png`} />
          </Overlay>
        )}
        {layers.map((l) => (
          <Overlay name={l.name}>
            <KmlLoader url={l.url} />
          </Overlay>
        ))}
      </LayersControl>
      {!!buildings.length && buildings.map((m, i) => (
        <Marker
          position={[m.latitude, m.longitude]}
          zIndexOffset={i === selectedBuilding ? 999 : 0}
          opacity={i === selectedBuilding ? 1 : 0.7}
          onclick={() => setSelectedBuilding(i)}
        >
          <Tooltip>{m.name}</Tooltip>
        </Marker>
      ))}

      {!!divisions.length && divisions.filter(d => d.lat).map((m, i) => (
        <Marker
          position={[m.lat, m.lng]}
          zIndexOffset={i === selected_division ? 999 : 0}
          opacity={i === selected_division ? 1 : 0.7}
          onclick={() => set_selected_division(i)}
        >
          <Tooltip>{m.name}</Tooltip>
        </Marker>
      ))}

      {!!filtered_projects.length && filtered_projects.filter((d) => d.lat).map((m, i) => {
        const icon = i !== 0 && { icon: greenIcon };
        return (
          <Marker
            position={[m.lat, m.lng]}
            zIndexOffset={i === selected_project ? 999 : 0}
            opacity={i === selected_project ? 1 : 0.7}
            onclick={() => set_selected_project(i)}
            {...icon}
          >
            <Tooltip>{m.name}</Tooltip>
          </Marker>
        );
      })}
    </Map>
  );
}
