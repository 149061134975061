/* eslint-disable max-lines */
/* eslint-disable complexity */
import {
  Switch, Route, Link, useLocation, Redirect,
} from 'react-router-dom';
import { yearComparisons } from '@Helpers';
import { Box, Grid } from '@material-ui/core';
import { MapOutlined } from '@material-ui/icons';
import SidebarChart from '@Components/Sidebar/SidebarChart';
import {
  CCTVIcon, Cube3D, Icon360, Search,
} from '@Assets/Icons';
import {
  IconFinancial, IconLeasing, IconFootfall,
} from '@Assets/Icons/CardIcons';

import ChulanTowerImg from '@Assets/Images/chulanTower.jpeg';
import Warehouse1Img from '@Assets/Images/CDC 1.jpg';
import Warehouse2Img from '@Assets/Images/Kompleks 1.jpg';

import Maintenance from '@Pages/Maintenance';
import CCTV from '@Pages/CCTV';
import Attendance from '@Pages/Attendance';
import Finance from '@Pages/Finance';
import Map from '@Components/Map';
import Dropdown from '@Components/Dropdown';

import NonRenewalTenants from '@Pages/Leasing/NonRenewalTenants';
import RenewalStatus from '@Pages/Leasing/RenewalStatus';
import IssueTable from '@Components/Maintenance/IssueTable';
import PreventiveTable from '@Components/Maintenance/PreventiveTable';
import UserFeedback from '@Pages/Maintenance/UserFeedback';
import RentalRate from '@Pages/Leasing/RentalRate';
import IncomeExpenditure from '@Pages/Finance/IncomeExpenditure';
import OperatingExpenses from '@Pages/Finance/OperatingExpenses';
import ServiceCharges from '@Pages/Finance/ServiceCharge';
import BillingCollection from '@Pages/Finance/BillingCollection';
import Rental from './Rental';
import Leasing from './Leasing';

import Hook from './hook';

export default function Property(props) {
  const isFinancialDisabled = props.buildings[props.selectedBuilding]?.DATA_SOURCE === 'DISABLED';
  const h = Hook(props);
  let leasing_percentage = (h.occupancyRate?.NLA_OC / h.occupancyRate?.NLA) * 100;
  leasing_percentage = Number.isNaN(leasing_percentage) ? '-%' : `${leasing_percentage.toFixed(0)}%`;
  const monthly_psf = `RM ${ props.buildings[props.selectedBuilding]?.monthly_psf.toFixed(2)}`;
  const NPI_data = {
    status: h.NPI_actual >= 0 ? 'Profit' : 'Profit',
    color: h.NPI_actual >= 0 ? '#4FD8AF' : '#4FD8AF',
  };
  // console.log('zzz', props.buildings[props.selectedBuilding]?.image);
  const isShowCCTV = !(props.building?.id === 21 || props.building?.id === 22);

  // const buildingImage = props.buildings[props.selectedBuilding]?.id === 20 ? ChulanTowerImg : props.buildings[props.selectedBuilding]?.image;
  const buildingImage = () => {
    switch (props.building.id) {
      case 20:
        return ChulanTowerImg;
      case 21:
        return Warehouse1Img;
      case 22:
        return Warehouse2Img;
      default:
        return props.buildings[props.selectedBuilding]?.image;
    }
  };
  return (
    <Grid container>
      <Grid item xs={12} md={3} className="sidebar" style={{ zIndex: 1 }}>
        {/* <img className="sidebar-img" src={props.buildings[props.selectedBuilding]?.image} alt="asset" /> */}
        <img
          className="sidebar-img"
          src={buildingImage()}
          alt="asset"
          style={{ objectFit: 'cover', width: '100%', aspectRatio: '3/4' }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            flex: 1,
          }}
        >
          <Box style={{ width: '90%' }}>
            <Dropdown
              selectedItem={props.selectedBuilding}
              setSelectedItem={props.setSelectedBuilding}
              itemList={props.buildings.map(e => e.name)}
              propertyValue={props.building.PROPERTY_VALUE}
              Hx="h2"
            />
          </Box>
          <Grid
            container
            style={{
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            <Grid xs={12} lg={6} item>
              <SidebarCard
                active
                title="Financial"
                description={NPI_data.status}
                color={NPI_data.color}
                to="/property/finance"
                disabled={isFinancialDisabled}
              >
                <IconFinancial />
              </SidebarCard>
            </Grid>
            <Grid xs={12} lg={6} item>
              <SidebarCard title="Tenancy" description={leasing_percentage} color="#FEB019" to="/property/leasing">
                <IconLeasing />
              </SidebarCard>
            </Grid>
            <Grid xs={12} lg={12} item>
              <SidebarCard title="Average Rental Rate" description={monthly_psf} color={NPI_data.color} to="/property/rental">
                <IconFootfall />
              </SidebarCard>
            </Grid>
          </Grid>
          <Box component="button" className="blue-button" style={{ width: '90%' }}>
            <Link to="/property">
              <h1
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#FFFFFF',
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                <MapOutlined style={{ marginRight: 6 }} />
                View on map
              </h1>
            </Link>
          </Box>
          {/* <Grid xs={12} justify="center" alignItems="center">
            {h.sideChartData.length && <SidebarChart {...h} selectedBuilding={16} />}
          </Grid> */}

          <div
            style={{
              display: 'flex',
              flex: 1,
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              alignItems: 'space-around',
              marginBottom: 30,
              marginRight: 20,
              marginLeft: 20,
            }}
          >
            {props.buildings[props.selectedBuilding]?.threeD && (
              <div style={{ flex: 1 }}>
                <SidebarButton to="/3d" text="3D Data">
                  <Cube3D />
                </SidebarButton>
              </div>
            )}
            {/* <div style={{ flex: 1 }}>
              <SidebarButton to="/inspection" text="Inspection" disabled>
                <Search />
              </SidebarButton>
            </div> */}
            {props.buildings[props.selectedBuilding]?.threeSixty && (
              <div style={{ flex: 1 }}>
                <SidebarButton to="/360" text="360">
                  <Icon360 />
                </SidebarButton>
              </div>
            )}
            {isShowCCTV && (
              <div style={{ flex: 1 }}>
                <SidebarButton to="/property/cctv" text="CCTV">
                  <CCTVIcon />
                </SidebarButton>
              </div>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={9} className="main">
        <Switch>
          <Route exact path="/property">
            <Map {...props} />
          </Route>
          <Route path="/property/finance/billing-and-collection">
            <BillingCollection {...props} />
          </Route>
          <Route path="/property/finance/service-charge">
            <ServiceCharges {...props} />
          </Route>
          <Route path="/property/finance/operating-expenses">
            <OperatingExpenses {...props} />
          </Route>
          <Route path="/property/finance/income-and-expenditure">
            <IncomeExpenditure {...props} />
          </Route>
          <Route path="/property/finance">
            {isFinancialDisabled ? <Redirect to="/property" /> : <Finance noServiceCharge {...props} />}
          </Route>
          <Route path="/property/rental">
            <Rental {...props} />
          </Route>
          <Route path="/property/cctv">
            <CCTV noTab {...props} />
          </Route>
          <Route path="/property/attendance">
            <Attendance {...props} />
          </Route>
          <Route path="/property/maintenance/user-feedback">
            <UserFeedback />
          </Route>
          <Route path="/property/maintenance/issue">
            <IssueTable prevUrl="/property/maintenance" {...props} />
          </Route>
          <Route path="/property/maintenance/preventive">
            <PreventiveTable prevUrl="/property/maintenance" {...props} />
          </Route>
          <Route path="/property/maintenance">
            <Maintenance {...props} />
          </Route>
          <Route path="/property/leasing/rental-rate">
            <RentalRate />
          </Route>
          <Route path="/property/leasing/non-renewal-tenants">
            <NonRenewalTenants />
          </Route>
          <Route path="/property/leasing/renewal-status">
            <RenewalStatus />
          </Route>
          <Route path="/property/leasing">
            <Leasing {...props} />
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
}

function SidebarCard(props) {
  const { pathname } = useLocation();

  const cardStyling = props.disabled ? {
    background: '#D9DADC',
    opacity: '0.4',
    cursor: 'default',
  } : {
    background: '#F5FAFF',
  };

  return (
    <Link to={!props.disabled && props.to}>
      <div
        style={{
          height: '3rem',
          ...cardStyling,
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          margin: 10,
          padding: '15px 0',
          borderRadius: 10,
          border: pathname === props.to ? '1px solid rgba(30, 52, 101, 1)' : '1px solid rgba(30, 52, 101, 0.1)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
            marginLeft: 10,
          }}
        >
          <div style={{ width: 40, height: 40 }}>{props.children}</div>
        </div>
        <div
          style={{
            flex: 2,
            flexDirection: 'column',
            justifyItems: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
            marginRight: '-13px',
          }}
        >
          <p style={{ fontSize: 14, lineHeight: '110%' }}>{props.title}</p>
          <p style={{ color: props.color, fontSize: 18, fontWeight: 600 }}>{props.description}</p>
        </div>
      </div>
    </Link>
  );
}

function SidebarButton(props) {
  return (
    <Link to={props.disabled || props.to}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 5,
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
            borderRadius: '100%',
            background: props.comingSoon || props.disabled ? 'rgba(128,128,128,0.1)' : '#1E3465',
            height: 24,
            width: 24,
          }}
        >
          {props.disabled
            ? <text style={{ textAlign: 'center', color: 'red', marginTop: 10 }}>Coming Soon</text>
            : props.children}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 6,
          }}
        >
          <p
            style={{
              fontSize: 12,
              textAlign: 'center',
              color: 'rgba(30,52,101,0.65)',
            }}
          >
            {props.text}
          </p>
        </div>
      </div>
    </Link>
  );
}