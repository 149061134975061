import React from 'react';

export default function NoDataInterface() {
  return (
    <div className="flex column center middle">
      <img src="" width={350} style={{ marginTop: 100 }} alt="" />
      <h1 style={{ fontSize: '3em', color: '#e5e5e5', marginBottom: 20 }}> No data have been added </h1>
    </div>
  );
}
