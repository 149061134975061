import { Link, useRouteMatch } from 'react-router-dom';

export default function Navbar(props) {
  const { path } = useRouteMatch();

  const links = [];
  if (props.user?.can_see_osh) links.push({ path: '/dashboard/osh', title: 'OSH' });
  if (props.user?.can_see_environment) links.push({ path: '/dashboard/environment', title: 'Environment' });
  if (props.user?.can_see_quality) links.push({ path: '/dashboard/quality', title: 'Quality' });

  return (
    <div
      className="flex-standard"
      style={{
        background: 'rgba(255,255,255,0.65)',
        marginBottom: 10,
      }}
    >
      {links.map(e => (
        <Link to={e.path}>
          <h3 style={{ margin: '0 30px', opacity: path === e.path ? 1 : 0.65 }}>{e.title}</h3>
        </Link>
      ))}
    </div>
  );
}
