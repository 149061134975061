import { useEffect, useState } from 'react';
import Api from '@Helpers/api';
import { endpoints } from '@Configs/endpoints';
import _ from 'lodash';

const buildings = {
  0: 261, // B5
  1: 157, // GALLERIA
  2: 161, // KOMTAR JBCC
  3: 156, // MENARA KOMTAR
  4: 162, // LARKIN SENTRAL
  5: 159, // MENARA ANSAR
  6: 155, // MENARA JLAND
  7: 163, // @MART
  8: 158, // Tg Leman Beach Resort
  9: 160, // PPTD
};

export default function Hook({
  allBuilding,
  facilities,
  selectedFacilities,
  buildingId: selectedBuilding,
  t: token,
}) {
  const [countIssuesInSelectedCategory, set_countIssuesInSelectedCategory] = useState([]);
  const [selectedCategory, set_selectedCategory] = useState('All Categories');
  const [issues, set_issues] = useState([]);
  const [is_update, set_is_update] = useState(0);
  const [is_ready, set_is_ready] = useState(0);
  const [is_loading, set_is_loading] = useState(true);
  const [refresh, set_refresh] = useState(0);
  const [categories, set_categories] = useState({});
  const [menuItem, set_menuItem] = useState([]);
  const [countCategory, set_countCategory] = useState([]);
  const [countIssues, set_countIssues] = useState([]);

  useEffect(() => {
    if (selectedCategory === 'All Categories') {
      set_countIssuesInSelectedCategory(countIssues);
    } else {
      const obj = issues
        .filter(e => e.category?.toUpperCase() === selectedCategory)
        .map(e => e.title.replace(/\s+/g, ' ').trim().toUpperCase())
        .reduce((a, b) => { (a[b] ? a[b] += 1 : a[b] = 1); return a; }, {});

      const arr = Object.keys(obj)
        .map(o => ({ text: o, value: obj[o] }))
        .sort((a, b) => a.value - b.value)
        .reverse()
        .slice(0, 60);

      set_countIssuesInSelectedCategory(arr);
    }
    // eslint-disable-next-line
  }, [selectedCategory, countIssues])

  useEffect(() => {
    set_selectedCategory('All Categories');
    // eslint-disable-next-line
  }, [menuItem])

  useEffect(() => {
    // request category
    Api({
      endpoint: endpoints.getServedeck(),
      data: { query: 'query { category { id name }}' },
      onSuccess: ({ data: { category } }) => {
        if (category) {
          const cat = {};
          category.forEach((obj) => {
            cat[obj.id] = obj.name;
          });
          set_categories(cat);
          set_is_ready(is_ready + 1);
        }
      },
      token,
    });
    // eslint-disable-next-line
  }, []);

  // request Issues by selectedBuilding
  useEffect(() => {
    set_is_loading(true);
    Api({
      endpoint: endpoints.getServedeck(),
      data: {
        query: `query{issue${
          !selectedBuilding
            ? ''
            : `(institution_id:${
                facilities
                  ? facilities[selectedFacilities]?.institution_id
                  : buildings[selectedBuilding]
              })`
        }{title category_id}}`,
      },
      onSuccess: ({ data: { issue } }) => {
        if (issue) {
          set_issues(issue);
          set_is_update(is_update + 1);
          set_is_loading(false);
        }
      },
      token,
    });
    // eslint-disable-next-line
  }, [selectedBuilding, selectedFacilities, refresh]);

  useEffect(() => {
    const data = [];

    if (issues.length) {
      issues.forEach((s) => {
        data.push({
          title: s.title,
          category: categories[s.category_id],
        });
      });
    }

    set_issues(data);

    const byCategory = _.groupBy(data, (e) => e.category?.toUpperCase());
    const categoryLength = Object.keys(byCategory).map((m) => ({
      text: m,
      value: byCategory[m].length,
    }));
    const menu = categoryLength.sort((a, b) => b.value - a.value);

    if (categoryLength.length === 1 && categoryLength[0].text === 'undefined') {
      set_refresh(refresh + 1);
    }

    set_countCategory(categoryLength);
    set_menuItem(menu.map((e) => e.text));

    const obj = data
      .map((e) => e.title.replace(/\s+/g, ' ').trim().toUpperCase())
      .reduce((a, b) => {
        a[b] ? (a[b] += 1) : (a[b] = 1);
        return a;
      }, {});

    const arr = Object.keys(obj)
      .map((o) => ({ text: o, value: obj[o] }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 60);

    set_countIssues(arr);

    // eslint-disable-next-line
  }, [is_update, is_ready]);

  return {
    countIssuesInSelectedCategory,
    selectedCategory,
    set_selectedCategory,
    issues,
    countCategory,
    countIssues,
    menuItem,
    is_loading,
  };
}
