import React from 'react';
import {
  IconButton, Dialog, DialogContent, DialogTitle, Table, TableRow, TableCell, TableBody,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import moment from 'moment';
import { sortBy } from 'lodash';

export default function PreviousDataDialog({ open, setOpen, data }) {
  const sorted_eei = sortBy(data.eei, ['YEAR', 'MONTH']).slice(-6);
  const handleClose = () => { setOpen(false); };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        Electricity Energy Index
        <IconButton onClick={handleClose} size="small" style={{ float: 'right' }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableBody>
            {sorted_eei?.map((e) => (
              <TableRow>
                <TableCell><p>{`${moment(e?.MONTH, 'M').format('MMMM')} ${e?.YEAR}`}</p></TableCell>
                <TableCell align="right"><p>{(e.ENERGY / data.GFA).toLocaleString()}</p></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}
