import moment from 'moment';
import React from 'react';
import {
  VictoryLine,
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryLegend,
  VictoryAxis,
  VictoryLabel,
  VictoryTooltip,
} from 'victory';

function getTickValues() {
  const temp = [];
  for (let i = 1; i < 32; i++) {
    temp.push(i);
  }
  return temp;
}

const generateData = () => {
  const data = [];
  for (let x = 1; x < 32; x++) {
    data.push({ x, y: Math.floor((Math.random() * 10) + 1) });
  }
  return data;
};

const colorStack = ['#A93226', '#2E86C1', '#239B56', '#CB4335', '#884EA0', '#7D3C98', '#2471A3', '#17A589', '#D4AC0D', '#229954', '#BA4A00'];

export default function MultilineChart({ lines: plines, spanDays }) {
  const tickCount = spanDays > 30 ? spanDays / 30 : spanDays;
  const lines = plines ?? [
    { name: 'Division 1', data: generateData() },
    { name: 'Division 2', data: generateData() },
    { name: 'Division 3', data: generateData() },
  ];
  const tickValues = plines ? plines[0]?.data.map(d => d.Date) : getTickValues();
  return (
    <>

      <VictoryChart
        height={600}
        width={2200}
        padding={{
          left: 60, right: 60, top: 50, bottom: 160,
        }}
        containerComponent={(
          <VictoryVoronoiContainer
            labels={({ datum }) => `${datum.x} : ${datum._y.toFixed(2)}`}
            labelComponent={
              <VictoryTooltip style={{ fontSize: 40 }} />
            }
          />
        )}
      >
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          style={{
            tickLabels: { fontSize: 32, opacity: 0.65 },
            ticks: { size: 0 },
            axis: { stroke: 'transparent' },
            grid: { stroke: 'transparent' },
          }}
          tickLabelComponent={<VictoryLabel angle={-45} />}
          tickValues={tickValues}
          tickCount={tickCount}
          label="Days"
          axisLabelComponent={(
            <VictoryLabel
              angle={0}
              verticalAnchor="start"
              dy={60}
              dx={0}
              style={{ fontSize: 32 }}
            />
          )}
        />
        <VictoryLegend
          x={800}
          y={-50}
          orientation="horizontal"
          gutter={100}
          style={{ labels: { fontFamily: 'Poppins', fontSize: 40 } }}
          data={lines?.map((line, idx) => ({
            name: line.name,
            symbol: { fill: line.color ?? colorStack[idx], type: 'minus' },
          }))}
        />
        {lines?.map((line, idx) => (
          <VictoryLine
            style={{
              data: { stroke: line.color ?? colorStack[idx] },
              parent: { border: `1px solid ${line.color ?? colorStack[idx]}` },
            }}
            data={line.data.map(({ x, y }) => (
              { y, x: moment(x).isValid() ? moment(x).format('DD MMM') : x }
            ))}
          />
        ))}
      </VictoryChart>
    </>
  );
}
