import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
} from '@material-ui/core';
import { GetApp, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import DateRange from '@Components/DateRange';

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '210px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
  fontLabel: { fontSize: '10px' },
  formControl: { transform: 'scale(0.9)' },
  root: { '&$checked': { color: 'rgb(30, 52, 101)' }, transform: 'scale(0.8)' },
  checked: {},
}));

export default function GenerateReport({ project, callback }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dateSelectionRange, setDateSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [osh, set_osh] = useState(false);
  const [environment, set_environment] = useState(false);
  const [qc1, set_qc1] = useState(false);
  const [qc2, set_qc2] = useState(false);
  const [qc3, set_qc3] = useState(false);
  const [preqc, set_preqc] = useState(false);

  const checkbox = {
    osh: (e) => set_osh(e),
    environment: (e) => set_environment(e),
    qc1: (e) => set_qc1(e),
    qc2: (e) => set_qc2(e),
    qc3: (e) => set_qc3(e),
    preqc: (e) => set_preqc(e),
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const checkboxChange = (event) => checkbox[event.target.name](event.target.checked);

  const handleGenerate = () => {
    const startDate = moment(dateSelectionRange.startDate).format('YYYY-MM-DD');
    const endDate = moment(dateSelectionRange.endDate).format('YYYY-MM-DD');
    const options = [];

    if (osh) options.push('OSH');
    if (environment) options.push('Environment');
    if (qc1) options.push('Quality Control 1');
    if (qc2) options.push('Quality Control 2');
    if (qc3) options.push('Quality Control 3');
    if (preqc) options.push('Preqlassic');

    callback({
      project_uuid: project.uuid,
      DivisionId: project.DivisionId,
      startDate,
      endDate,
      options: options.join(','),
    });
    setOpen(false);
    set_osh(false);
    set_environment(false);
    set_qc1(false);
    set_qc2(false);
    set_qc3(false);
    set_preqc(false);
  };

  return (
    <div>
      <Box
        component="button"
        className={`${classes.blueBtn} blue-button`}
        onClick={handleClickOpen}
      >
        <h1 className={`${classes.blueBtnText} flex-standard`}>
          <GetApp />
          Generate Report
        </h1>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 10, maxHeight: '40rem' } }}
      >
        <DialogTitle>
          Generate Report
          <Close className={classes.closeBtn} onClick={handleClose} />
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <div className="flex" style={{ justifyContent: 'center' }}>
            <DateRange
              selectionRange={dateSelectionRange}
              setSelectionRange={setDateSelectionRange}
            />
          </div>
          <div style={{ padding: '0 20px' }}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Please Select</FormLabel>
              <div style={{ display: 'flex' }}>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={osh}
                        name="osh"
                        onChange={checkboxChange}
                        classes={{ root: classes.root, checked: classes.checked }}
                      />
                    )}
                    label="OSH"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={environment}
                        name="environment"
                        onChange={checkboxChange}
                        classes={{ root: classes.root, checked: classes.checked }}
                      />
                    )}
                    label="Environment"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={qc1}
                        name="qc1"
                        onChange={checkboxChange}
                        classes={{ root: classes.root, checked: classes.checked }}
                      />
                    )}
                    label="Quality Control 1"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={qc2}
                        name="qc2"
                        onChange={checkboxChange}
                        classes={{ root: classes.root, checked: classes.checked }}
                      />
                    )}
                    label="Quality Control 2"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={qc3}
                        name="qc3"
                        onChange={checkboxChange}
                        classes={{ root: classes.root, checked: classes.checked }}
                      />
                    )}
                    label="Quality Control 3"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={preqc}
                        name="preqc"
                        onChange={checkboxChange}
                        classes={{ root: classes.root, checked: classes.checked }}
                      />
                    )}
                    label="Preqlassic"
                  />
                </FormGroup>
              </div>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <div
            className="flex space-between"
            style={{ padding: '0 20px', width: '100%' }}
          >
            <Box
              component="button"
              className="blue-button-outlined"
              onClick={handleClose}
              style={{ borderRadius: '6px', width: '120px' }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={handleGenerate}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                <GetApp />
                Generate Report
              </h1>
            </Box>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}