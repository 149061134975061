import React from 'react';
import { Grid } from '@material-ui/core';
import { VictoryPie, VictoryLabel, VictoryLegend } from 'victory';
import _ from 'lodash';

const colorSet = [
  '#008FFB',
  '#82C9FF',
  '#FB9C5D',
  '#2ECCA9',
  '#008FFB',
  '#B85EEE',
  '#FB9C5D',
  '#2ECCA9',
  '#008FFB',
  '#B85EEE',
  '#FB9C5D',
  '#2ECCA9',
];
function TenantLotChart({ tla, tmb }) {
  const legend = [];
  const chartComposition = ['Bumiputera', 'Non-Bumiputera'];
  const dataTenant = [];
  const dataLot = [];
  const dataArea = [];
  chartComposition.map((name, idx) => {
    if (tla[name]) {
      legend.push({ name, symbol: { fill: colorSet[idx], type: 'square' } });
      dataTenant.push({ x: idx + 1, y: tla[name]?.length ?? 0 });
      dataLot.push({ x: idx + 1, y: tla[name]?.length ?? 0 });
      dataArea.push({ x: idx + 1, y: _.sumBy(tla[name], (o) => o.VALUE) ?? 0 });
    }
  });
  const ocLot = _.sumBy(dataLot, (o) => o.y);
  const vLot = tla.Vacant ? tla.Vacant.length : 0;
  const ocArea = _.sumBy(dataArea, (o) => o.y);
  const vArea = tla.Vacant ? tla.Vacant[0].VALUE : 0;
  return (
    <>
      <h3 style={{ fontWeight: 600, textAlign: 'center' }}>Tenant and Lot Analysis</h3>
      <Grid container style={{ paddingInline: '4.5rem', marginBottom: 15 }}>
        <Grid item xs={12}>
          <VictoryLegend
            x={200}
            y={5}
            height={30}
            width={600}
            orientation="horizontal"
            gutter={20}
            style={{ labels: { fontFamily: 'Poppins', fontSize: 10 } }}
            data={legend}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <svg viewBox="0 0 400 400">
            <VictoryPie
              colorScale={['#008FFB', '#82C9FF']}
              standalone={false}
              width={400}
              height={400}
              data={dataTenant}
              innerRadius={80}
              labels={[]}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 24, fontWeight: 600 }}
              x={200}
              y={190}
              text={_.sumBy(dataTenant, (o) => o.y).toLocaleString()}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 16 }}
              x={200}
              y={210}
              text="total tenants"
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 600 }}
              x={200}
              y={28}
              text="Total Tenants"
            />
          </svg>
        </Grid>

        <Grid item xs={12} md={4}>
          <svg viewBox="0 0 400 400">
            <VictoryPie
              colorScale={['#008FFB', '#82C9FF']}
              standalone={false}
              width={400}
              height={400}
              data={dataLot}
              innerRadius={80}
              labels={[]}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 24, fontWeight: 600 }}
              x={200}
              y={190}
              text={(ocLot + vLot).toLocaleString()}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 16 }}
              x={200}
              y={210}
              text="total lots"
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 600 }}
              x={200}
              y={28}
              text="Total Lots"
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 20 }}
              x={200}
              y={380}
              text={`Vacant: ${vLot.toLocaleString()} lots (${((ocLot / (ocLot + vLot)) * 100).toFixed(0)}%)`}
            />
          </svg>
        </Grid>
        <Grid item xs={12} md={4}>
          <svg viewBox="0 0 400 400">
            <VictoryPie
              colorScale={['#008FFB', '#82C9FF']}
              standalone={false}
              width={400}
              height={400}
              data={dataArea}
              innerRadius={80}
              labels={[]}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 24, fontWeight: 600 }}
              x={200}
              y={190}
              text={`${(ocArea + vArea).toLocaleString()} ft²`}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 16 }}
              x={200}
              y={210}
              text="total tenants"
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 600 }}
              x={200}
              y={28}
              text="Total Area"
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontFamily: 'Poppins', fontSize: 20 }}
              x={200}
              y={380}
              text={`Vacant: ${vArea.toLocaleString()} ft² (${((ocArea / (ocArea + vArea)) * 100).toFixed(0)}%)`}
            />
          </svg>
        </Grid>
      </Grid>
    </>
  );
}

export default TenantLotChart;
