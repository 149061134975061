import React from "react";
import {
  VictoryScatter,
  VictoryTooltip,
  VictoryGroup,
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryArea,
  VictoryLabel,
} from "victory";

function getRandomData() {
  let data = [];

  for (let i = 1; i < 32; i++) {
    data.push({
      x: i,
      y: Math.floor(Math.random() * 9 + 1),
    });
  }
  return data;
}

export default function AreaChart(props) {
  return (
    <>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="myGradient" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgba(0, 143, 251, 0.45)" />
            <stop offset="100%" stopColor="#FFFFFF" />
          </linearGradient>
        </defs>
      </svg>
      <VictoryChart
        height={600}
        width={1600}
        domainPadding={10}
        padding={{ left: 60, right: 60, top: 60, bottom: 160 }}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          style={{
            tickLabels: { fontSize: 32, padding: 20, opacity: 0.65 },
            ticks: { size: 0 },
            axis: { stroke: "transparent" },
            grid: { stroke: "transparent" },
          }}
          label="Days"
          axisLabelComponent={
            <VictoryLabel
              angle={0}
              verticalAnchor="start"
              dy={60}
              dx={0}
              style={{ fontSize: 32 }}
            />
          }
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          dependentAxis
          label="Feedbacks"
          axisLabelComponent={
            <VictoryLabel
              angle={0}
              verticalAnchor="end"
              dy={-200}
              dx={60}
              style={{ fontSize: 32 }}
            />
          }
          style={{
            tickLabels: { fontSize: 32, padding: 20, opacity: 0.65 },
            ticks: { size: 0 },
            axis: { stroke: "transparent" },
            grid: { stroke: "transparent" },
          }}
        />

        <VictoryGroup
          color="#008FFB"
          labels={({ datum }) => [props.pointer, datum.y]}
          labelComponent={
            <VictoryTooltip
              style={{ fontSize: 24, fill: "#008FFB" }}
              flyoutPadding={20}
              flyoutStyle={{
                fill: "#FFFFFF",
                stroke: "rgba(0,0,0, 0.1)",
                strokeWidth: 3,
              }}
              pointerOrientation="left"
              centerOffset={{ x: 200, y: 20 }}
            />
          }
          data={getRandomData()}
        >
          <VictoryArea
            interpolation="linear"
            style={{
              data: {
                fill: "url(#myGradient)",
                stroke: "#008FFB",
                strokeWidth: 3,
              },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          />
          <VictoryScatter
            size={({ active }) => (active ? 5 : 0)}
            style={{
              data: {
                stroke: "#008FFB",
                fillOpacity: 0,
                strokeWidth: 3,
              },
            }}
          />
        </VictoryGroup>
      </VictoryChart>
    </>
  );
}
