import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default function Hook({ division, project }) {
  const [compliances, setCompliances] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [selectedInspections, setSelectedInspections] = useState();
  const [spanDays, setSpanDays] = useState(7);
  const [annotationStatistics, setAnnotationStatistics] = useState([]);
  const [scoreStatistics, setScoreStatistics] = useState([]);
  const [selectedCompliance, setSelectedCompliance] = useState({ compliance: null, category: null });
  const [openReport, setOpenReport] = useState(false);
  const [openInspectionCases, setOpenInspectionCases] = useState(false);
  const queryFilter = () => {
    const data = {};
    if (project?.uuid) {
      data.project_uuid = project.uuid;
    } else if (project?.name === 'View All Projects') {
      data.DivisionId = division.id;
    } else {
      data.filterOnly = 'osh';
    }
    data.InspectionCategoryId = 2;
    return data;
  };
  const UploadReport = (input) => {
    const { files, ...data } = input;
    Api({
      endpoint: endpoints.createStoredReport(project.uuid),
      data,
      files,
      onSuccess: () => {
        setOpenReport(false);
        refreshInspections();
        setOpenInspectionCases(true);
      },
      onFail: () => {},
    });
  };
  const refreshInspections = () => {
    console.log('refreshInspections');
    const data = queryFilter();
    Api({
      endpoint: endpoints.getInspectionSession(),
      data,
      onSuccess: (response) => {
        setInspections(response.data);
      },
      onFail: setInspections([]),
    });
  };
  useEffect(() => {
    const data = queryFilter();
    Api({
      endpoint: endpoints.getCompliances(),
      data,
      onSuccess: (response) => setCompliances(response.data),
      onFail: setCompliances([]),
    });
    refreshInspections();
  }, [project]);
  useEffect(() => {
    const data = queryFilter();
    if (selectedCompliance.compliance) {
      data.is_compliance = selectedCompliance.compliance === 'compliance' ? 1 : 0;
    }
    if (selectedCompliance.category) data.EnvironmentCategoryId = selectedCompliance.category;
    data.spanDays = spanDays ?? 7;
    Api({
      endpoint: endpoints.getAnnotationStatistics(),
      data,
      onSuccess: (response) => setAnnotationStatistics(response.data),
      onFail: setAnnotationStatistics([]),
    });
    // to change to score statistic api
    Api({
      endpoint: endpoints.getAnnotationStatistics(),
      data: { ...data, getScore: 1 },
      onSuccess: (response) => setScoreStatistics(response.data),
      onFail: setScoreStatistics([]),
    });
    // to change to score statistic api
  }, [spanDays, selectedCompliance, project]);
  return {
    compliances,
    inspections,
    selectedCompliance,
    setSelectedCompliance,
    selectedInspections,
    setSelectedInspections,
    lines: annotationStatistics.map(eachGroup => ({
      ...eachGroup,
      data: eachGroup.data.map(({ Date, value }) => ({ x: Date, y: value })),
    })),
    setAnnotationStatistics,
    score_lines: scoreStatistics.map(eachGroup => ({
      ...eachGroup,
      data: eachGroup.data.map(({ Date, value: total_annotation, total_is_compliance }) => {
        const comp_score = total_is_compliance ? total_is_compliance / total_annotation : 0;
        return { x: Date, y: comp_score };
      }),
    })),
    setScoreStatistics,
    spanDays,
    setSpanDays,
    openReport,
    setOpenReport,
    openInspectionCases,
    setOpenInspectionCases,
    UploadReport,
  };
}
