import MainContentContainer from '@Components/MainContentContainer';
import { Box, Button } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import useHook from './hook';
import SearchFilter from './SearchFilter';
import UserTableList from './Table';

export default function UserList(props) {
  const h = useHook();
  return (
    <MainContentContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box style={{ display: 'flex', margin: '2px 0' }}>
          <h1 style={{ paddingLeft: 20, marginRight: 15 }}>User Management</h1>
          <SearchFilter {...h} data={h.users} />
        </Box>
        {/* <Button variant="contained" style={{ color: '#FFFFFF', backgroundColor: '#1E3465' }}><AddOutlinedIcon />Add New</Button> */}
      </div>
      <UserTableList {...h} userRole={props.user.RoleId} />
    </MainContentContainer>
  );
}
