import { useEffect, useState } from 'react';
import { endpoints } from '@Configs/endpoints';
import Api from '@Helpers/api';
import {
  groupByYear, groupByYearAndMonth, yearComparisons, monthComparisons,
} from '@Helpers';
import moment from 'moment';
import _ from 'lodash';

const latest_updated = monthComparisons(2).format('MMMM YYYY');
const getMonth = (m) => moment(m, 'MM').format('MMM');

export default function Hook({ buildings }) {
  const [service_charge_piechart, set_service_charge_piechart] = useState([]);
  const [sc_actual_total, set_sc_actual_total] = useState(0);
  const [sc_budget_total, set_sc_budget_total] = useState(0);
  const [NLA, set_NLA] = useState(0);
  const [billing_collection, set_billing_collection] = useState([]);
  const [revenue_budget, set_revenue_budget] = useState(0);
  const [expenses_budget, set_expenses_budget] = useState(0);
  const [NPI_budget, set_NPI_budget] = useState(0);
  const [ytd_revenue_budget, set_ytd_revenue_budget] = useState(0);
  const [ytd_expenses_budget, set_ytd_expenses_budget] = useState(0);
  const [revenue_actual, set_revenue_actual] = useState(0);
  const [expenses_actual, set_expenses_actual] = useState(0);
  const [NPI_actual, set_NPI_actual] = useState(0);
  const [ytd_revenue_actual, set_ytd_revenue_actual] = useState(0);
  const [ytd_expenses_actual, set_ytd_expenses_actual] = useState(0);
  const [prev_revenue_actual, set_prev_revenue_actual] = useState(0);
  const [prev_expenses_actual, set_prev_expenses_actual] = useState(0);
  const [prev_NPI_actual, set_prev_NPI_actual] = useState(0);
  const [monthly_rev, set_monthly_rev] = useState([]);
  const [monthly_exp, set_monthly_exp] = useState([]);
  const [monthly_npi, set_monthly_npi] = useState([]);
  const [prev_yield_actual, set_prev_yield_actual] = useState(0);
  const [yield_actual, set_yield_actual] = useState(0);
  const [yield_budget, set_yield_budget] = useState(0);
  const [monthly_yield, set_monthly_yield] = useState(0);
  const [isServiceChargeLoading, set_isServiceChargeLoading] = useState(false);
  const [isBillingLoading, set_isBillingLoading] = useState(false);
  const [isLoadingSto, set_isLoadingSto] = useState(false);
  const [collection_actual, set_collection_actual] = useState(0);
  const [ytd_collection_actual, set_ytd_collection_actual] = useState(0);

  useEffect(() => {
    const totalPropertyValue = buildings.reduce((a, b) => a + b.PROPERTY_VALUE, 0);
    set_prev_yield_actual(prev_NPI_actual * 12 * 100 / totalPropertyValue);
    set_yield_actual(NPI_actual * 12 * 100 / totalPropertyValue);
    set_yield_budget(NPI_budget * 12 * 100 / totalPropertyValue);
    set_monthly_yield(monthly_npi.map(m => ({
      month: m.month,
      prevYear: (m.prevYear * 12 * 100 / totalPropertyValue),
      currentYear: (m.currentYear * 12 * 100 / totalPropertyValue),
    })));
  }, [buildings, prev_NPI_actual, NPI_actual, NPI_budget, monthly_npi]);

  useEffect(() => {
    set_isServiceChargeLoading(true);
    set_isBillingLoading(true);
    set_isLoadingSto(true);
    Api({
      endpoint: endpoints.getServiceCharge(),
      onSuccess: ({ data }) => {
        if (data?.length > 0) {
          const total = (data.reduce((a, b) => ({ ACTUAL: a.ACTUAL + b.ACTUAL, BUDGET: a.BUDGET + b.BUDGET })));
          set_sc_actual_total(total.ACTUAL);
          set_sc_budget_total(total.BUDGET);

          const bySer = _.groupBy(data.filter(e => e.ACTUAL !== 0), (o) => o.Service);
          const graph = Object.keys(bySer)
            .map(m => ({ service: m, expenses: bySer[m].reduce((a, b) => a + b.ACTUAL, 0) }))
            .map(m => ({
              service: `${m.service } (${((m.expenses / Math.abs(total.ACTUAL) * 100).toPrecision(3))}%)`,
              expenses: Math.abs(m.expenses),
            }));
          set_service_charge_piechart(graph);
        } else {
          set_service_charge_piechart([]);
          set_sc_actual_total(0);
          set_sc_budget_total(0);
        }
        set_isServiceChargeLoading(false);
      },
    });
    Api({
      endpoint: endpoints.getOccupancy(),
      onSuccess: ({ data }) => set_NLA(data.reduce((a, b) => a + b.NLA, 0)),
      onFail: set_NLA(0),
    });
    Api({
      endpoint: endpoints.getBilling(),
      onSuccess: ({ data }) => {
        if (data.length > 0) {
          const sorted = groupByYearAndMonth(data);
          const [latestYear, prevYear] = Object.entries(sorted).map(e => e[0]).reverse();
          const lastYear = Object.entries(sorted[prevYear])
            .map(e => ({
              month: `${getMonth(e[0]) } ${ prevYear}`,
              ...e[1].reduce((a, b) => ({ billing: a.billing + b.BILLING, collection: a.collection + b.COLLECTION }), { billing: 0, collection: 0 }),
            }));
          const thisYear = Object.entries(sorted[latestYear])
            .map(e => ({
              month: `${getMonth(e[0]) } ${ latestYear}`,
              ...e[1].reduce((a, b) => ({ billing: a.billing + b.BILLING, collection: a.collection + b.COLLECTION }), { billing: 0, collection: 0 }),
            }));
          const graphData = lastYear.slice(thisYear.length).concat(thisYear);
          const this_month_col = _.find(graphData, { month: monthComparisons(2).format('MMM YYYY') }).collection;
          const ytd_col_sum = _.sumBy(thisYear, e => e.collection);
          set_billing_collection(graphData);
          set_collection_actual(this_month_col);
          set_ytd_collection_actual(ytd_col_sum);
        } else {
          set_billing_collection([]);
        }
        set_isBillingLoading(false);
      },
    });
    Api({
      endpoint: endpoints.getBudget(),
      onSuccess: ({ data }) => {
        const YEAR = yearComparisons(2)[1];
        const sortedByYear = groupByYear(data);
        const sorted = groupByYearAndMonth(data);
        const [latestMonth, latestMonthData] = Object.entries(sorted[YEAR]).slice(-1)[0];
        const RENTAL_REV = latestMonthData.reduce((a, b) => a + b.RENTAL_REV, 0);
        const OE = latestMonthData.reduce((a, b) => a + b.OE, 0);
        const ytd_RENTAL_REV = sortedByYear[YEAR].reduce((a, b) => a + b.RENTAL_REV, 0);
        const ytd_OE = sortedByYear[YEAR].reduce((a, b) => a + b.OE, 0);

        set_revenue_budget(RENTAL_REV);
        set_expenses_budget(OE);
        set_NPI_budget(RENTAL_REV - OE);
        set_ytd_revenue_budget(ytd_RENTAL_REV);
        set_ytd_expenses_budget(ytd_OE);
      },
    });
    Api({
      endpoint: endpoints.getSalesturnover(),
      onSuccess: ({ data }) => {
        const YEAR = yearComparisons(2)[1];
        const sortedByYear = groupByYear(data);
        const sorted = groupByYearAndMonth(data);
        const [latestMonth, latestMonthData] = Object.entries(sorted[YEAR]).slice(-1)[0];

        const rev = latestMonthData.reduce((a, b) => a + b.GROSS, 0);
        const exp = latestMonthData.reduce((a, b) => a + b.OE, 0);
        const ytd_rev = sortedByYear[YEAR].reduce((a, b) => a + b.GROSS, 0);
        const ytd_exp = sortedByYear[YEAR].reduce((a, b) => a + b.OE, 0);
        set_revenue_actual(rev);
        set_expenses_actual(exp);
        set_NPI_actual(rev - exp);
        set_ytd_revenue_actual(ytd_rev);
        set_ytd_expenses_actual(ytd_exp);

        const [prevYearLatestMonth, prevYearLatestMonthData] = Object.entries(sorted[YEAR - 1]).slice(latestMonth - 1, latestMonth)[0];
        const prev_rev = prevYearLatestMonthData.reduce((a, b) => a + b.GROSS, 0);
        const prev_exp = prevYearLatestMonthData.reduce((a, b) => a + b.OE, 0);
        set_prev_revenue_actual(prev_rev);
        set_prev_expenses_actual(prev_exp);
        set_prev_NPI_actual(prev_rev - prev_exp);

        const mRev = Object.keys(sorted[YEAR]).map(month => ({
          month: `${moment(month, 'M').format('MMM')}`,
          prevYear: sorted[YEAR - 1][month].reduce((a, b) => a + b.GROSS, 0),
          currentYear: sorted[YEAR][month].reduce((a, b) => a + b.GROSS, 0),
        }));
        const mExp = Object.keys(sorted[YEAR]).map(month => ({
          month: `${moment(month, 'M').format('MMM')}`,
          prevYear: sorted[YEAR - 1][month].reduce((a, b) => a + b.OE, 0),
          currentYear: sorted[YEAR][month].reduce((a, b) => a + b.OE, 0),
        }));
        const mNpi = mRev.map((eachM, idx) => ({
          month: eachM.month,
          prevYear: eachM.prevYear - mExp[idx].prevYear,
          currentYear: eachM.currentYear - mExp[idx].currentYear,
        }));
        set_monthly_rev(mRev);
        set_monthly_exp(mExp);
        set_monthly_npi(mNpi);

        set_isLoadingSto(false);
      },
    });
  }, []);

  return {
    latest_updated,
    isServiceChargeLoading,
    isBillingLoading,
    isLoadingSto,
    service_charge_piechart,
    sc_actual_total,
    sc_budget_total,
    NLA,
    billing_collection,
    revenue_budget,
    expenses_budget,
    NPI_budget,
    ytd_revenue_budget,
    ytd_expenses_budget,
    revenue_actual,
    expenses_actual,
    NPI_actual,
    ytd_revenue_actual,
    ytd_expenses_actual,
    collection_actual,
    ytd_collection_actual,
    monthly_bar_data: {
      Revenue: monthly_rev,
      Expenses: monthly_exp,
      NPI: monthly_npi,
      /* Yield: monthly_yield, */
    },
    data: [
      {
        title: 'Total Revenue of all building',
        selector: 'Revenue',
        percent: `${(revenue_actual / revenue_budget * 100).toFixed(0)}%`,
        positive: false,
        donutData: [{ x: 1, y: revenue_actual }, { x: 2, y: revenue_budget }],
        amount: `RM ${revenue_actual.toLocaleString()}`,
        asOf: latest_updated,
        barData: [{ x: String(yearComparisons(2)[0]), y: prev_revenue_actual }, { x: 'Actual', y: revenue_actual }, { x: 'Budget', y: revenue_budget }],
      },
      {
        title: 'Total Expenses of all building',
        selector: 'Expenses',
        percent: `${(expenses_actual / expenses_budget * 100).toFixed(0)}%`,
        positive: true,
        donutData: [{ x: 1, y: expenses_actual }, { x: 2, y: expenses_budget }],
        amount: `RM ${expenses_actual.toLocaleString()}`,
        asOf: latest_updated,
        barData: [{ x: String(yearComparisons(2)[0]), y: prev_expenses_actual }, { x: 'Actual', y: expenses_actual }, { x: 'Budget', y: expenses_budget }],
      },
      {
        title: 'Total NPI of all building',
        selector: 'NPI',
        percent: `${(NPI_actual / NPI_budget * 100).toFixed(0)}%`,
        positive: false,
        donutData: [{ x: 1, y: NPI_actual }, { x: 2, y: NPI_budget }],
        amount: `RM ${NPI_actual.toLocaleString()}`,
        asOf: latest_updated,
        barData: [{ x: String(yearComparisons(2)[0]), y: prev_NPI_actual }, { x: 'Actual', y: NPI_actual }, { x: 'Budget', y: NPI_budget }],
      },
      /* {
        title: 'Yield',
        selector: 'Yield',
        percent: `${(yield_actual / yield_budget * 100).toFixed(0)}%`,
        positive: false,
        donutData: [{ x: 1, y: yield_actual }, { x: 2, y: yield_budget }],
        amount: `${yield_actual.toFixed(2)}%`,
        asOf: latest_updated,
        barData: [{ x: String(yearComparisons(2)[0]), y: prev_yield_actual.toFixed(2) }, { x: 'Actual', y: yield_actual.toFixed(2) }, { x: 'Budget', y: yield_budget.toFixed(2) },
        ],
      }, */
    ],
  };
}
