/* eslint-disable max-lines */
/* eslint-disable object-curly-newline */
import { useState, useEffect } from 'react';
import { Grid, Select, MenuItem } from '@material-ui/core';
import Api, { endpoints } from '@Helpers/api';
import MainContentContainer from '@Components/MainContentContainer';
import SimpleCard from '@Components/SimpleCard';
import BarChart from '@Components/IntegratedFacilities/BarChart';
import _ from 'lodash';
import medal from '@Assets/Icons/medal.svg';
import cidb_medal from '@Assets/Icons/cidb_medal.svg';
import Table from '@Components/Table';
import KPIDialog from '@Components/FormDialog';
import ContractorHistoricalDialog from '@Components/ContractorHistoricalDialog';
import CIDBScoreDialog from '@Components/CIDBScoreDialog';
import { yearComparisons } from '@Helpers';
import ContractorDialog from './ContractorDialog';
import Navbar from '../components/Navbar';

// later change to this when have data
const columns = [
  { name: 'PROJECT', selector: 'project', width: '39%', wrap: true, center: false },
  { name: 'CONTRACTORS', selector: 'contractors', width: '20%', wrap: true, center: false },
  { name: 'GFA (m²)', selector: 'GFA', width: '9%', wrap: true, center: true },
  { name: 'NO. OF SAMPLE', selector: 'no_samples', width: '6%', wrap: true, center: true },
  { name: 'YEAR', selector: 'year', width: '8%', wrap: true, center: true },
  { name: 'PREQLASSIC', selector: 'preqlassic_score', width: '11%', wrap: true, center: true },
  { name: 'CIDB QLASSIC', selector: 'cidb_score', width: '7%', wrap: true, center: true, sortable: true },
];

export default function QualityDashboard(props) {
  const [mainCards, setMainCards] = useState([]);
  const [qlassicSummaryCharts, setQlassicSummaryCharts] = useState([]);
  const [qlassicSummaryCards, setQlassicSummaryCards] = useState([]);
  const [qlassicSummaryTableData, setQlassicSummaryTableData] = useState([]);
  const [qlassicSummaryRawData, setQlassicSummaryRawData] = useState([]);
  const [currentYear, setYear] = useState(yearComparisons(2)[1]);
  const [openContractorDialog, setOpenContractorDialog] = useState(false);
  const [openAddContractorHistoryDialog, setOpenAddContractorHistoryDialog] = useState(false);
  const [openKpiDialog, setOpenKpiDialog] = useState(false);
  const [AllKPI, setAllKPI] = useState({});
  const [kpi, setKpi] = useState(75);
  const [openCidbScore, setOpenCidbScore] = useState(false);
  const [AllCIDB, setAllCIDB] = useState({});
  const [cidb, setCidb] = useState(75);
  const [justUpdated, setJustUpdated] = useState(false);
  const CardsMap = ({ items }) => items.map(({ label, value, can_edit, update_form, medal }) => (
    <SimpleCard
      label={label}
      value={value}
      style={{ backgroundColor: 'rgb(245, 250, 255)' }}
      elevation={0} // increase elevation to increase shadow
      can_edit={!!can_edit}
      setOpenKpiDialog={setOpenKpiDialog}
      setOpenCidbScore={setOpenCidbScore}
      update_form={update_form}
      medal={medal}
    />
  ));
  const refreshKPI = () => {
    Api({
      isAnnonymous: true,
      endpoint: endpoints.getFireKpiAll(),
      onSuccess: (val) => {
        setAllKPI(val);
        setKpi(val[currentYear] ?? 0);
      },
    });
  };
  const refreshCIDB = () => {
    Api({
      isAnnonymous: true,
      endpoint: endpoints.getFireCidbAll(),
      onSuccess: (val) => {
        setAllCIDB(val);
        setCidb(val[currentYear] ?? 0);
      },
    });
  };
  const refreshQlassicSummaryTableData = () => {
    Api({
      endpoint: endpoints.getConquas(),
      onSuccess: ({ data }) => {
        const rawData = data.map(d => ({ ...d, contractors: d.ContractorId ? d.Contractor.name : null }));
        setQlassicSummaryRawData(rawData);
        setQlassicSummaryTableData(rawData
          .filter(x => x.year === currentYear)
          .map(e => ({
            ...e,
            contractors: (
              <span className="flex-standard">
                {e.contractors}
                {e.cidb_score >= kpi && <img src={medal} height="25" />}
                {e.cidb_score >= cidb && <img src={cidb_medal} height="25" />}
              </span>
            ),
          })));
        const eachBar = _.groupBy(data, 'year');
        const arrayBar = Object.keys(eachBar).map(year => ({
          x: year,
          y2: _.meanBy(eachBar[year], 'cidb_score'),
          y1: AllKPI[year] ?? 0,
        }));
        setQlassicSummaryCharts(arrayBar);
        const mainItemsAllTime = [
          { label: 'AVERAGE QLASSIC SCORE', value: _.meanBy(data, 'cidb_score').toFixed(2) },
          { label: 'NO. OF PROJECTS', value: data.length },
        ];
        setMainCards(mainItemsAllTime);
        const qlassicSummaryItemsThisyear = [
          { label: `${currentYear} AVERAGE QLASSIC SCORE`, value: _.meanBy(eachBar[currentYear], 'cidb_score').toFixed(2) },
          { label: `${currentYear} NO. OF PROJECTS`, value: eachBar[currentYear].length },
          { label: `${currentYear} KPI`, value: kpi, can_edit: true, update_form: 1, medal: <img src={medal} height="25" /> },
          { label: `${currentYear} CIDB SCORE`, value: cidb, can_edit: true, update_form: 2, medal: <img src={cidb_medal} height="25" /> },
        ];
        setQlassicSummaryCards(qlassicSummaryItemsThisyear);
      },
      onFail: () => console.log('xx'),
    });
  };
  const onSaveContractorData = (data) => {
    Api({
      endpoint: endpoints.createConquas(),
      data,
      onSuccess: () => {
        toast('success', 'Successfully Create ');
        refreshQlassicSummaryTableData();
        setOpenAddContractorHistoryDialog(false);
        setOpenContractorDialog(qlassicSummaryRawData.filter(x => x.ContractorId === openContractorDialog[0]?.ContractorId));
      },
      onFail: console.log('lol'),
    });
  };

  const onSaveKPI = (data) => {
    Api({
      isAnnonymous: true,
      endpoint: endpoints.setFireKpi(),
      data: { [currentYear]: data.KPI },
      onSuccess: () => {
        toast('success', 'Successfully Update KPI ');
        setOpenKpiDialog(false);
        setJustUpdated(v => v + 1);
      },
      onFail: console.log('lol'),
    });
  };
  const onSaveCIDB = (data) => {
    Api({
      isAnnonymous: true,
      endpoint: endpoints.setFireCidb(),
      data: { [currentYear]: data.CIDB },
      onSuccess: () => {
        toast('success', 'Successfully Update CIDB ');
        setOpenCidbScore(false);
        setJustUpdated(v => v + 1);
      },
      onFail: console.log('lol'),
    });
  };
  useEffect(() => {
    refreshKPI();
    refreshCIDB();
    refreshQlassicSummaryTableData();
  }, []);
  useEffect(() => {
    refreshQlassicSummaryTableData();
  }, [kpi, cidb]);
  useEffect(() => {
    refreshKPI();
    refreshCIDB();
    setKpi(AllKPI[currentYear] ?? 0);
    setCidb(AllCIDB[currentYear] ?? 0);
    refreshQlassicSummaryTableData();
  }, [currentYear, justUpdated]);
  return (
    <MainContentContainer>
      <KPIDialog open={openKpiDialog} setOpen={setOpenKpiDialog} onSave={onSaveKPI} />
      <CIDBScoreDialog open={openCidbScore} setOpen={setOpenCidbScore} onSave={onSaveCIDB} />
      <ContractorDialog
        open={openContractorDialog}
        setOpen={setOpenContractorDialog}
        setOpenAddContractorHistoryDialog={setOpenAddContractorHistoryDialog}
      />
      {!!openContractorDialog && (
        <ContractorHistoricalDialog
          ContractorId={openContractorDialog[0]?.ContractorId}
          open={openAddContractorHistoryDialog}
          setOpen={setOpenAddContractorHistoryDialog}
          onSave={onSaveContractorData}
        />
      )}
      {props.navbar !== false
        ? <Navbar to="/project/" text="Quality" subtext={props.filtered_projects[props.selected_project]?.name} />
        : <h1 style={{ paddingLeft: 20 }}>Quality Dashboard</h1>}
      <Grid container xs={12} md={12} style={{ padding: 20, display: 'flex' }}>
        <CardsMap items={mainCards} />
      </Grid>
      <h3 style={{ paddingLeft: 20 }}>Summary of Qlassic score by year</h3>
      <Grid container xs={12} md={12} style={{ padding: 20 }}>
        <BarChart data={qlassicSummaryCharts} legend={['Average Score', 'KPI']} legendProps={{ x: 900, y: 10 }} />
      </Grid>
      <Grid container xs={12} md={12} style={{ padding: 20 }}>
        <Grid item xs={10}>
          <h3 style={{ paddingLeft: 20 }}>Summary of {currentYear} Qlassic score</h3>
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'right', paddingRight: 15 }}>
          <Select variant="outlined" value={currentYear} style={{ padding: '0px' }} onChange={(e) => setYear(e.target.value)}>
            {[...Array(100)].map((e, i) => (<MenuItem value={i + 1980} children={i + 1980} />))}
          </Select>
        </Grid>
      </Grid>
      <Grid container xs={12} md={12} style={{ padding: 20 }}>
        {!!qlassicSummaryTableData.length && <CardsMap items={qlassicSummaryCards} />}
      </Grid>
      <Grid container xs={12} md={12} style={{ padding: 20 }}>
        <Table
          title=""
          columns={columns}
          data={qlassicSummaryTableData}
          fixedHeader
          rowClick={({ ContractorId }) => {
            setOpenContractorDialog(qlassicSummaryRawData.filter(x => x.ContractorId === ContractorId));
          }}
        />
      </Grid>
    </MainContentContainer>
  );
}
