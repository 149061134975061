/* eslint-disable max-nested-callbacks */
import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid, OutlinedInput, FormControl, CircularProgress,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@Components/Button';
import UploadIcon from '@Components/UploadIcon';
import SelectYear from '@Components/SelectYear';
import SelectMonth from '@Components/SelectMonth';

const columns = [
  {
    selector: 'daily_revenue', type: 'number', name: 'Total daily revenue (RM)', autoFocus: true,
  },
  { selector: 'daily_paid_tickets', type: 'number', name: 'No. of daily paid tickets' },
  { selector: 'parking_rate', type: 'number', name: 'Parking rate per hour (RM)' },
  { selector: 'season_parking', type: 'number', name: 'No. of season parking' },
  { selector: 'parking_bays', type: 'number', name: 'No. of parking bays' },
];

export default (h) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  return (
    <>
      <Button onClick={handleClickOpen} startIcon={<UploadIcon />}>
        Upload data
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <div className="flex space-between">
            <h2>Manual Upload Data</h2>
            <div className="flex" style={{ width: '30%' }}>
              <SelectYear onChange={h.setYear} year={h.year} />
              &nbsp;&nbsp;&nbsp;
              <SelectMonth onChange={h.setMonth} month={h.month} />
            </div>
            <IconButton onClick={handleClose} style={{ float: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <h3>Security Manning</h3>
            </Grid>
            <Grid container item xs={10}>
              {columns.map(c => (
                <h4 style={{ flex: 1, opacity: 0.65, marginLeft: 10 }}>{c.name.toUpperCase()}</h4>
              ))}
            </Grid>
          </Grid>
          {h.isLoading
            ? <div className="flex-standard" style={{ height: 300 }}><CircularProgress /></div>
            : h.projectParking.map((d, idx) => {
              const isEven = idx % 2 === 0;
              const color = isEven ? '#008FFB' : '#1F3566';
              return (
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <p style={{ fontWeight: 600, color }}>{d.name}</p>
                  </Grid>
                  <Grid container item xs={10}>
                    {columns.map(eachCol => (
                      <FormControl style={{ flex: 1, margin: '0 10px' }} variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          style={{ color }}
                          value={h.inputParking.find(x => x.ProjectParkingId === d.id)?.[eachCol.selector]
                        || h.selectedParking.find(x => x.ProjectParkingId === d.id)?.[eachCol.selector]
                        || ''}
                          onChange={e => {
                            h.setInputParking(eachInput => {
                              const input = [...eachInput];
                              const objIdx = input.findIndex(x => x.ProjectParkingId === d.id);
                              if (objIdx >= 0) {
                                input[objIdx][eachCol.selector] = Number(e.target.value);
                                input[objIdx].year = h.year;
                                input[objIdx].month = h.month;
                              } else {
                                input.push({
                                  ProjectParkingId: d.id,
                                  [eachCol.selector]: Number(e.target.value),
                                  year: h.year,
                                  month: h.month,
                                });
                              }
                              return input;
                            });
                          }}
                        />
                      </FormControl>
                    ))}
                  </Grid>
                </Grid>
              );
            })}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', padding: 24 }}>
          <Button onClick={h.handleSave} startIcon={<AddIcon />}>
            Save Data
          </Button>
          <Button onClick={handleClose} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
