import { Grid } from '@material-ui/core';
import Table from '@Components/Table';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import useHook from './hook';

const columns = [
  {
    name: 'Issue Number',
    selector: 'issue_number',
    sortable: true,
    width: '100px',
  },
  {
    name: 'Site',
    selector: 'institution',
    sortable: true,
    right: false,
    wrap: true,
    width: '110px',
  },
  {
    name: 'Issue',
    selector: 'title',
    sortable: true,
    wrap: true,
    // center: true,
    width: '300px',
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    right: false,
  },
  {
    name: 'Area',
    selector: 'area',
    sortable: true,
    right: false,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    right: false,
  },
  {
    name: 'Assignee',
    selector: 'assignee',
    sortable: true,
    right: false,
    wrap: true,
    width: '180px',
  },
  {
    name: 'Created Date',
    selector: 'createdDate',
    sortable: true,
    right: false,
    wrap: true,
    width: '130px',
  },
];

function MaintenanceTable(props) {
  const h = useHook(props);
  return (
    <>
      <MainContentNavbar to={props.prevUrl} text={props.text || 'MAINTENANCE'} />
      <MainContentContainer>
        <Grid container>
          <Grid item xs={12}>
            <h2>ISSUES</h2>
            <Table columns={columns} data={h.issues} />
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default MaintenanceTable;
