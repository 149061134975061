import React from "react";
import { VictoryPie, VictoryLabel } from "victory";

function DonutCharts(props) {
  const {
    data, positive, percent, description, leftLabel, rightLabel, latestUpdate,
  } = props;
  return (
    <>
      <div style={{ position: "relative" }}>
        <svg viewBox="0 0 500 450">
          <VictoryPie
            colorScale={["#1F3566", "rgba(30, 52, 101, 0.15)"]}
            cornerRadius={10}
            standalone={false}
            width={500}
            height={400}
            data={data}
            innerRadius={130}
            labels={[]}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{
              fontSize: 64,
              fill: positive ? "#2ECCA9" : "#FF4560",
            }}
            x={250}
            y={174}
            text={percent}
          />
          <VictoryLabel
            textAnchor="middle"
            lineHeight={1.2}
            style={{
              fontSize: 24,
              fill: "rgba(30, 52, 101, 0.55)",
              width: 100,
            }}
            x={255}
            y={222}
            text={`As of ${latestUpdate}`}
          />
          <VictoryLabel
            textAnchor="middle"
            lineHeight={1.2}
            style={{
              fontSize: 24,
              fill: 'rgba(30, 52, 101, 0.55)',
              width: 100,
            }}
            x={255}
            y={265}
            text={description}
          />
          {leftLabel && (
            <VictoryLabel
              textAnchor="middle"
              lineHeight={1.2}
              style={{
                fontSize: 24,
                fill: "rgba(30, 52, 101, 0.55)",
                width: 100,
              }}
              x={120}
              y={400}
              text={leftLabel}
            />
          )}
          {rightLabel && (
            <VictoryLabel
              textAnchor="middle"
              lineHeight={1.2}
              style={{
                fontSize: 24,
                fill: "rgba(30, 52, 101, 0.55)",
                width: 100,
              }}
              x={380}
              y={400}
              text={rightLabel}
            />
          )}
        </svg>
      </div>
    </>
  );
}

export default DonutCharts;
