import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';

export default function FormDialog({ open, setOpen, ...props }) {
  const [KPI, setKPI] = useState(50);
  const update = () => props.onSave({ KPI });
  useEffect(() => {
    if (!!open) return;
    setKPI(open);
  }, [open]);
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Update KPI</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update KPI Score
        </DialogContentText>
        <TextField
          autoFocus
          defaultValue={KPI}
          margin="dense"
          id="name"
          label="KPI"
          type="number"
          fullWidth
          onChange={(e) => setKPI(Number(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => update()} color="primary" autoFocus>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
