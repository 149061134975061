import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List, ListItem, ListItemText, ListItemAvatar, Avatar,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '35vh',
    overflow: 'auto',
  },
}));

export default function HighestScore({ data }) {
  return (
    <div style={{ background: '#fff', borderRadius: '10px', marginBottom: '20px' }}>
      <h3 style={{ padding: '16px 16px 0' }}>Highest Score</h3>
      {!!data.length && (<CustomList data={data[0]} />)}
    </div>
  );
}

function CustomList({ data }) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {data.items?.sort((a, b) => b.value - a.value).map(e => (
        <ListItem>
          <ListItemAvatar>
            <Avatar src={e.image} />
          </ListItemAvatar>
          <ListItemText primary={e.name} secondary={e.division_name} />
          <p style={{ color: '#2ECCA9', fontWeight: 600 }}>{`${((e.value / _.sumBy(data.items, 'value')) * 100).toFixed(2) }%`}</p>
        </ListItem>
      ))}
    </List>
  );
}
