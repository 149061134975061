import { Grid, Button } from '@material-ui/core';
import MainContentContainer from '@Components/MainContentContainer';
import SelectableCollapse from '@Components/SelectableCollapse';
import MultilineChart from '@Components/MultilineChart';
import UploadReportDialog from '@Components/ReportSite/UploadReportDialog';
import { truncateString } from '@Helpers';
import Navbar from '../components/Navbar';
import Hook from './hook';
import InspectionList from './InspectionList';

export default function Environment(props) {
  const h = Hook(props);
  return (
    <MainContentContainer>
      {props.navbar !== false
        ? <Navbar to="/project/" text="Environment" subtext={truncateString(props.filtered_projects[props.selected_project]?.name, 35 )} />
        : <h1 style={{ paddingLeft: 20 }}>Environment</h1>}
      {!!props.project?.uuid && (
        <>
          <InspectionList
            {...h}
            open={h.openInspectionCases}
            setOpen={h.setOpenInspectionCases}
          />
          <UploadReportDialog
            {...h}
            onSave={h.UploadReport}
            open={h.openReport}
            setOpen={h.setOpenReport}
          />
        </>
      )}
      <Grid container>
        <Grid item xs={12} md={4} style={{ padding: 20 }}>
          <SelectableCollapse
            data={h.compliances}
            selected={h.selectedCompliance}
            callback={h.setSelectedCompliance}
          />
        </Grid>
        <Grid item xs={12} md={8} style={{ padding: 20 }}>
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12} md={12}>
              <div className="flex" style={{ justifyContent: 'space-between' }}>
                <h2 className="text-light" style={{ fontWeight: 600 }}>STATISTIC</h2>
                <div>
                  {[
                    { value: 7, name: '7 DAYS' },
                    { value: 30, name: '30 DAYS' },
                    { value: 180, name: '6 MONTHS' },
                  ].map(e => (
                    <Button size="small" onClick={() => { h.setSpanDays(e.value); }}>
                      <p style={{ color: h.spanDays !== e.value && '#AEB8CB', fontWeight: 600 }}>{e.name}</p>
                    </Button>
                  ))}
                </div>
              </div>
              <MultilineChart {...h} />
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="flex" style={{ justifyContent: 'space-between' }}>
                <h2 className="text-light" style={{ fontWeight: 600 }}>SCORE</h2>
                <div>
                  {[
                    { value: 7, name: '7 DAYS' },
                    { value: 30, name: '30 DAYS' },
                    { value: 180, name: '6 MONTHS' },
                  ].map(e => (
                    <Button size="small" onClick={() => { h.setSpanDays(e.value); }}>
                      <p style={{ color: h.spanDays !== e.value && '#AEB8CB', fontWeight: 600 }}>{e.name}</p>
                    </Button>
                  ))}
                </div>
              </div>
              <MultilineChart {...h} lines={h.score_lines} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainContentContainer>
  );
}
