import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Search } from '@Assets/Icons';
import ReportIcon from '@Assets/Icons/reporting.png';
import { MapIcon } from '@Assets/Icons/TopbarIcons';
import moment from 'moment';

import Map from '@Components/Map';
import Dropdown from '@Components/Dropdown';
import RoundedButton from '@Components/RoundedButton';

import InspectionSession from './InspectionSession';
import Mapping from './Mapping';
import OSH from './OSH';
import Environment from './Environment';
import Quality from './Quality';
import Report from './Report';
import SidebarCards from './components/SidebarCards';
import EditProject from './components/EditProject';

import Hook from './hook';

const style = {
  editIcon: {
    position: 'absolute',
    paddingTop: 5,
    paddingLeft: 10,
    fontSize: 20,
    color: 'rgb(30, 52, 101)',
    cursor: 'pointer',
  },
};

export default function Property(props) {
  const [isDisabled, setIsDisabled] = useState(true);
  const handleClick = () => { setIsDisabled(!isDisabled); };

  const h = Hook();
  const EditClick = () => (<> {isDisabled && <EditIcon style={style.editIcon} onClick={handleClick} />} </>);

  return (
    <Grid container>
      <Grid item xs={12} md={3} className="sidebar" style={{ zIndex: 1 }}>
        <img
          className="sidebar-img"
          style={{ objectFit: 'cover', width: '100%', aspectRatio: '3/2' }}
          src={h.division?.image}
          alt="asset"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            flex: 1,
          }}
        >
          <Box style={{ width: '90%', marginTop: '10px' }}>
            <Dropdown
              selectedItem={h.selected_division}
              setSelectedItem={h.set_selected_division}
              itemList={h.divisions
                .filter((e) => !e.is_special)
                .map((e) => e.name)}
              xtraText={h.noSpecial ? '' : 'SPECIAL PROJECTS'}
            />
          </Box>
          {h.isSpecialSelected && (
            <Box style={{ width: '90%', marginTop: '10px' }}>
              <Dropdown
                selectedItem={h.selected_division - h.special_project_idx}
                setSelectedItem={(e) => h.set_selected_division(e + h.special_project_idx)}
                itemList={h.divisions
                  .filter((e) => e.is_special)
                  .map((e) => e.name)}
                size="small"
              />
            </Box>
          )}
          <Grid container style={{ margin: '10px 5px' }}>
            <SidebarCards {...props} />
          </Grid>

          <div
            className="flex"
            style={{ width: '100%', margin: '16px 20px 0' }}
          >
            <h3 className="text-light text-center" style={{ flex: 11 }}>
              PROJECT INFO {h.project?.uuid && <EditClick />}
            </h3>
          </div>

          {isDisabled && (
            <Box style={{ width: '90%', marginTop: '10px' }}>
              <Dropdown
                selectedItem={h.selected_project}
                setSelectedItem={h.set_selected_project}
                itemList={h.filtered_projects.map((e) => e.name)}
                size="small"
                Hx="h4"
              />
            </Box>
          )}

          <Grid container spacing={2} style={{ padding: '30px', justifyContent: 'space-evenly' }}>
            { isDisabled ? (
              <>
                {[
                  { title: 'Commencement Date', data: h.project?.commencementAt ? moment(h.project?.commencementAt).format('DD MMMM YYYY') : '-' },
                  { title: 'Expected Completion', data: h.project?.expectedCompletionAt ? moment(h.project?.expectedCompletionAt).format('DD MMMM YYYY') : '-' },
                  { title: 'Project Manager', data: h.project?.project_manager },
                  { title: 'Main Contract', data: h.project?.contractor_name },
                  { title: 'Contractor No', data: h.project?.contract_no },
                ].map((e) => (
                  <>
                    <Grid item xs={6}>
                      <p style={{ fontWeight: 600, paddingTop: 2 }}>{e.title}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <p>{e.data ?? ' -'}</p>
                    </Grid>
                  </>
                ))}
              </>
            )
              : <EditProject {...h} handleClick={handleClick} />}
          </Grid>

          <Grid container spacing={1.5}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                alignItems: 'space-around',
                marginBottom: 30,
                marginRight: 20,
                marginLeft: 20,
              }}
            >
              {[
                {
                  text: 'Inspection',
                  to: '/project/inspection',
                  children: <Search />,
                  canNotSee: !(
                    props.user?.can_see_osh
                    || props.user?.can_see_environment
                    || props.user?.can_see_quality
                  ),
                },
                {
                  text: 'Report',
                  to: '/project/report',
                  children: <img src={ReportIcon} />,
                  canNotSee: !props.user?.can_view_report,
                },
                {
                  text: 'Mapping',
                  to: '/project/mapping',
                  children: <MapIcon />,
                  disabled: true,
                },
              ]
                .filter((e) => !e.canNotSee)
                .map((e) => (
                  <div style={{ flex: 1 }}>
                    <RoundedButton {...e} />
                  </div>
                ))}
            </div>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} md={9} className="main">
        <Switch>
          {[
            { path: '/project', children: <Map {...h} />, exact: true },
            { path: '/project/osh', children: <OSH {...h} /> },
            { path: '/project/environment', children: <Environment {...h} /> },
            { path: '/project/quality', children: <Quality {...h} /> },
            { path: '/project/inspection', children: <InspectionSession {...h} user={props.user} /> },
            { path: '/project/report', children: <Report {...h} user={props.user} /> },
            { path: '/project/mapping', children: <Mapping {...h} /> },
          ].map((e) => (<Route {...e} />))}
        </Switch>
      </Grid>
    </Grid>
  );
}
