import { useEffect, useState } from 'react';
import { endpoints, URL } from '@Configs/endpoints';
import Api from '@Helpers/api';
import moment from 'moment';
import _ from 'lodash';
import { yearComparisons } from '@Helpers';

export default function Hook({ buildings, setSelectedBuilding, selectedBuilding }) {
  const [projectMonitor, setProjectMonitor] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState(undefined);
  const [facilityDetails, setFacilityDetails] = useState({});
  const [issues, set_issues] = useState([]);
  const [tasks, set_tasks] = useState([]);
  const [monthlyGross, set_monthlyGross] = useState([]);
  const [monthlyBudget, set_monthlyBudget] = useState([]);
  const [TotalRev, set_TotalRev] = useState(0);
  const [TargetRev, set_TargetRev] = useState(0);
  const [TotalExp, set_TotalExp] = useState(0);
  const [showDropdown, setShowDropdown] = useState(10);
  const [sortDropdown, setSortDropdown] = useState('');
  const [filterDropdown, setFilterDropdown] = useState('');
  const [serviceType, set_serviceType] = useState({});
  const [serviceProvider, set_serviceProvider] = useState({});
  const [serviceContracts, set_serviceContracts] = useState([]);
  const [files, set_files] = useState([]);
  const [open, set_open] = useState(false);
  const [isSaved, set_isSaved] = useState(0);
  useEffect(() => {
    refreshProjectMonitor();
    Api({
      endpoint: endpoints.getFacilities(),
      onSuccess: (response) => setFacilities(response.data),
      onFail: () => setFacilities([]),
    });
    Api({
      endpoint: endpoints.getSalesturnover(),
      data: { YEAR: yearComparisons(2)[1] },
      onSuccess: (response) => {
        set_TotalRev(_.sumBy(response.data, (o) => o.GROSS));
        set_TotalExp(_.sumBy(response.data, (o) => o.OE));

        const sortedV = _.chain(response.data)
          .sortBy(['YEAR', 'MONTH'])
          .groupBy((datum) => moment(datum.MONTH, 'M').format('MMM'))
          .map((value, key) => ({ x: key, y2: _.sumBy(value, (o) => o.GROSS) }))
          .value();
        set_monthlyGross(sortedV);
      },
    });
    Api({
      endpoint: endpoints.getBudget(),
      data: { YEAR: yearComparisons(2)[1] },
      onSuccess: (response) => {
        set_TargetRev(_.sumBy(response.data, (o) => o.RENTAL_REV));

        const sortedV = _.chain(response.data)
          .sortBy(['YEAR', 'MONTH'])
          .groupBy((datum) => moment(datum.MONTH, 'M').format('MMM'))
          .map((value, key) => ({ MONTH: key, target: _.sumBy(value, (o) => o.RENTAL_REV) }))
          .value();
        set_monthlyBudget(sortedV.reduce((obj, item) => Object.assign(obj, { [item.MONTH]: item.target }), {}));
      },
    });
    Api({
      endpoint: endpoints.getServedeck(),
      data: { query: '{service_type{id name}service_provider{id name}}' },
      onSuccess: ({ data: { service_type, service_provider } }) => {
        if (service_type) set_serviceType(service_type.reduce((a, b) => { a[b.id] = b.name; return a; }, {}));
        if (service_provider) set_serviceProvider(service_provider.reduce((a, b) => { a[b.id] = b.name; return a; }, {}));
      },
    });
  }, []);

  useEffect(() => {
    getServiceContracts(buildings[selectedBuilding]?.id);
  }, [buildings, selectedBuilding, isSaved]);

  const refreshProjectMonitor = () => {
    Api({
      endpoint: endpoints.getProjectMonitor(),
      onSuccess: (response) => setProjectMonitor(response.data),
      onFail: () => setProjectMonitor([]),
    });
  };

  const onDeleteProjectMonitor = (id) => {
    Api({
      endpoint: endpoints.deleteProjectMonitor(id),
      onSuccess: (response) => {
        toast('success', 'Successfully Delete ');
        refreshProjectMonitor();
      },
      onFail: console.log('error'),
    });
  };

  const getServiceContracts = (id) => {
    if (!id) return;
    Api({
      endpoint: endpoints.getContractService(id),
      onSuccess: (response) => {
        set_serviceContracts(response.data);
      },
      onFail: console.log('error getting service contracts'),
    });
  };

  const uploadServiceContracts = () => {
    Api({
      endpoint: endpoints.createContractService(),
      data: { BuildingId: buildings[selectedBuilding]?.id },
      files,
      onSuccess: (response) => {
        toast('success', 'Successfully Upload Service Contract ');
        set_open(false)
      },
      onFail: console.log('error getting service contracts'),
    });
  };

  useEffect(() => {
    if (facilities.length > 0 && selectedFacilities !== undefined) {
      setSelectedBuilding(buildings.findIndex((f) => f.id === facilities[selectedFacilities].BuildingId));
    }
    if (facilities.length && selectedFacilities !== undefined) {
      const facId = facilities[selectedFacilities]?.institution_id;
      Api({
        endpoint: endpoints.showFacilities(facilities[selectedFacilities]?.uuid),
        data: { YEAR: yearComparisons(2)[1] },
        onSuccess: (response) => setFacilityDetails(response.data),
        onFail: setFacilityDetails({}),
      });
      Api({
        endpoint: endpoints.getServedeck(),
        data: {
          query: `{
            issue(institution_id:${facId}){status due_date}
            task_execution(institution_id:${facId}){status due_date}
            service_contract(institution_id:${facId}){service_type_id jhi_start jhi_end service_provider_id status amount}
          }`,
        },
        onSuccess: ({ data: { issue, task_execution, service_contract } }) => {
          if (issue) set_issues(issue);
          if (task_execution) set_tasks(task_execution);
          if (service_contract) refreshSC(service_contract);
        },
      });
    } else {
      setSelectedFacilities(facilities.length ? 0 : undefined);
    }
  }, [facilities, selectedFacilities, serviceType, serviceProvider]);

  // const refreshSC = (sc) => {
  //   if (_.isEmpty(serviceType) || _.isEmpty(serviceProvider)) return;
  //   set_serviceContracts(
  //     sc.filter(e => ['Active', 'Expired'].find(st => st === e.status)).map((obj) => ({
  //       contractValue: obj.amount ? `RM ${obj.amount.toLocaleString()}` : 'N/A',
  //       costSqft: obj.amount ? `RM ${(obj.amount / facilities[selectedFacilities]?.NLA).toLocaleString()}` : 'N/A',
  //       serviceType: serviceType[obj.service_type_id] ?? 'N/A',
  //       renewalDate: moment(parseInt(obj.jhi_start)).format('D MMM YYYY'),
  //       expiringDate: (
  //         <span style={{ color: obj.status !== 'Active' && '#FF4560' }}>
  //           {moment(parseInt(obj.jhi_end)).format('D MMM YYYY')}
  //         </span>
  //       ),
  //       serviceProvider: (
  //         <span style={{ color: 'SlateGray', marginLeft: '10px' }}>{serviceProvider[obj.service_provider_id]}</span>
  //       ),
  //       status: <span style={{ color: obj.status === 'Active' ? '#2ECCA9' : '#FF4560' }}>{obj.status}</span>,
  //     })),
  //   );
  // };

  const issue_count = issues.reduce(
    (a, b) => {
      if (a[b.status]) a[b.status] += 1;
      else a[b.status] = 1;
      return a;
    },
    {
      'Call Off': 0, 'In Progress': 0, 'On Hold': 0, Open: 0, Resolved: 0,
    },
  );

  const task_count = tasks.reduce(
    (a, b) => {
      if (a[b.status]) a[b.status] += 1;
      else a[b.status] = 1;
      return a;
    },
    { 'In Progress': 0, 'Pending Review': 0, Overdue: 0 },
  );
  const overdue_issues = issues.filter(
    (d) => ['Open', 'In Progress'].includes(d.status) && parseInt(d.due_date) < new Date().getTime(),
  );
  const overdue_tasks = tasks.filter(
    (d) => ['Open', 'In Progress'].includes(d.status) && parseInt(d.due_date) < new Date().getTime(),
  );
  for (let eachmonth = 0; eachmonth < monthlyGross.length; eachmonth++) {
    monthlyGross[eachmonth].y1 = monthlyBudget[monthlyGross[eachmonth].x];
  }

  return {
    projectMonitor,
    facilities,
    selectedFacilities,
    setSelectedFacilities,
    facilityDetails,
    issue_summary: { ...issue_count, Overdue: overdue_issues.length },
    task_summary: { ...task_count, Overdue: overdue_tasks.length },
    url: URL,
    ytd: {
      TotalRev: TotalRev.toLocaleString(),
      TargetRev: TargetRev.toLocaleString(),
      PrecentTR: (TotalRev / TargetRev - 1) * 100,
      PrecentGPM: ((TotalRev - TotalExp) / TotalRev) * 100,
    },
    monthlyGross: monthlyGross[0]?.y1 !== undefined ? monthlyGross : undefined,
    monthlyBudget,
    showDropdown,
    setShowDropdown,
    sortDropdown,
    setSortDropdown,
    filterDropdown,
    setFilterDropdown,
    serviceContracts,
    refreshProjectMonitor,
    onDeleteProjectMonitor,
    uploadServiceContracts,
    files,
    set_files,
    open,
    set_open,
    isSaved,
    set_isSaved,
  };
}
