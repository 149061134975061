/* eslint-disable max-lines */
import React, { useState } from 'react';
import {
  Grid, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, FormControl,
} from '@material-ui/core';
import Api, { endpoints } from '@Helpers/api';
import { Add, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import UploadIcon from '@Components/UploadIcon';
import NumberFormat from 'react-number-format';

const inputTemplate = {
  TENANT: null,
  AMOUNT: null,
  TDA: 0,
};

const titles = [
  { label: 'Tenant', col: 1 },
  { label: 'Amount (RM)', col: 1 },
  { label: 'Total Day Aging', col: 1 },
];

const useStyles = makeStyles(() => ({
  smallBtn: { borderRadius: '6px', margin: 0 },
  blueBtn: { borderRadius: '6px', width: '17em', marginRight: '2em' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
}));

export default ({ uuid, refresh = () => null }) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState([]);
  const classes = useStyles();

  const handleClose = () => {
    setInput([]);
    setOpen(false);
  };

  const getTenantDetails = () => Api({
    endpoint: endpoints.getDebtoraging(uuid),
    data: { building_uuid: uuid },
    onSuccess: ({ data }) => setInput(data),
  });

  const handleClickOpen = () => {
    getTenantDetails();
    setOpen(true);
  };

  const handleSave = () => {
    Api({
      endpoint: endpoints.updateDebtoraging(uuid),
      data: { input },
      onSuccess: () => {
        toast('success', 'Successfully update Debtor Aging Details'); // eslint-disable-line no-undef
        handleClose();
        refresh();
      },
      onFail: () => {
        toast('error', 'Successfully update Debtor Aging Details'); // eslint-disable-line no-undef
      },
    });
  };

  const handleAddInput = (i) => {
    const temp = [...input];
    temp.splice(i, 0, inputTemplate);
    setInput(temp);
  };

  const handleChangeInput = (i, name, value) => {
    const temp = [...input];
    temp.splice(i, 1, { ...temp[i], [name]: value });
    setInput(temp);
  };

  const handleRemoveInput = (index) => setInput(i => i.filter((_, idx) => idx !== index));

  return (
    <>
      <button
        onClick={handleClickOpen}
        className="blue-button flex-standard"
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          margin: 0,
        }}
      >
        <UploadIcon />
        <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>Upload Data</p>
      </button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <DialogTitle>
          <Grid container justify="space-between">
            <h1>Manual Upload Data - Debtor Aging Details</h1>
            {uuid}
            <Close className={classes.closeBtn} onClick={handleClose} />
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: 500 }}>
          <table className="custom-form-table" style={{ width: '100%' }}>
            <tr>
              <td>
                <Box
                  component="button"
                  className={`${classes.smallBtn} blue-button`}
                  onClick={() => handleAddInput(0)}
                >
                  <h1 className={`${classes.blueBtnText} flex-standard`}>
                    <Add />
                  </h1>
                </Box>
              </td>
              {titles.map(m => <td colSpan={m.col} children={<h4>{m.label}</h4>} />)}
              <td />
            </tr>
            {input.map((ipt, index) => {
              return (
                <tr>
                  <td>
                    <Box
                      component="button"
                      className={`${classes.smallBtn} blue-button`}
                      onClick={() => handleAddInput(index)}
                    >
                      <h1 className={`${classes.blueBtnText} flex-standard`}>
                        <Add />
                      </h1>
                    </Box>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '-webkit-fill-available' }}>
                      <TextField
                        value={ipt.TENANT || ''}
                        placeholder="Tenant Name"
                        onChange={e => handleChangeInput(index, 'TENANT', e.target.value)}
                        variant="outlined"
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '-webkit-fill-available' }}>
                      <TextField
                        value={ipt.AMOUNT || ''}
                        placeholder="Amount"
                        onChange={e => handleChangeInput(index, 'AMOUNT', e.target.value)}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '-webkit-fill-available' }}>
                      <TextField
                        value={ipt.TDA || ''}
                        placeholder="Total Day Aging"
                        onChange={e => handleChangeInput(index, 'TDA', e.target.value)}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Box
                      component="button"
                      className={`${classes.smallBtn} blue-button`}
                      onClick={() => handleRemoveInput(index)}
                    >
                      <h1 className={`${classes.blueBtnText} flex-standard`}>
                        <Close />
                      </h1>
                    </Box>
                  </td>
                </tr>
              );
            })}
          </table>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: '0 3em', width: '100%', display: 'flex' }}>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={handleSave}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                Save Data
              </h1>
            </Box>
            <Box
              component="button"
              className="cancel-button"
              onClick={handleClose}
              style={{
                borderRadius: '6px',
                width: '120px',
              }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>

          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}
