/* eslint-disable max-lines */
import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import ProjectMonitorDialog from './ProjectMonitorDialog';

const columns = [
  { name: 'Project', selector: 'project_name' },
  { name: 'Contract Amount', selector: 'contract_amount' },
  { name: 'Status', selector: 'status', right: true },
  { name: 'Current Stage', selector: 'currentStage', right: true },
];

const stage = [
  { name: 'Appointment of Consultant', selector: 'appointmentAt' },
  { name: 'Open Tender', selector: 'open_tenderAt' },
  { name: 'Close Tender', selector: 'close_tenderAt' },
  { name: 'MTC Approval', selector: 'mtc_approveAt' },
  { name: 'Letter of Appointment', selector: 'letter_appointmentAt' },
  { name: 'Kick Off Meeting/Site Possesion', selector: 'kick_off_meetingAt' },
  { name: 'EOT', selector: 'eotAt' },
  { name: 'CPC', selector: 'cpcAt' },
  { name: 'DLP', selector: 'dlpAt' },
  { name: 'CMGD', selector: 'cmgdAt' },
];

export default function ProjectMonitorTable(props) {
  const [openModal, setOpenModal] = useState(false);
  const [rowData, setRowData] = useState();

  const isMoney = (selector, item) => ((selector === 'contract_amount') ? `RM ${item.toLocaleString()}` : item);
  const formatDate = (date) => moment(date).format('YYYYMMDD');

  const currentStage = (item, selector) => {
    const {
      id, project_uuid, project_name, contract_amount, createdAt, updatedAt, ...stage_date
    } = item;
    const last_date = _.sortBy(stage_date).filter(f => f != null).pop();
    const current_stage = stage.filter(f => formatDate(item[f.selector]) === formatDate(formatDate(moment(item[selector]))));
    const isAfter = moment(current_stage).isAfter(last_date);
    if (isAfter) return 'End';
    return current_stage[0]?.name ?? 'Not start yet';
  };

  return (
    <>
      <TableContainer>
        {props.projectMonitor?.length > 0 ? (
          <Table size="small" style={{ minWidth: 400 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell align={column.right && 'right'}>{column.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.projectMonitor?.map((row, rowIndex) => (
                <TableRow
                  onClick={() => { setOpenModal(true); setRowData(row); }}
                  style={{ backgroundColor: rowIndex % 2 === 0 && 'rgba(30, 52, 101, 0.05)' }}
                >
                  {columns.map((column) => (
                    <TableCell
                      align={column.right && 'right'}
                      style={{ paddingTop: 5, paddingBottom: 20 }}
                      className="pointer"
                    >
                      {(column.selector === 'project_name' || 'contract_amount') && (
                        <p style={{ color: 'var(--text-primary)', fontSize: 13 }}>
                          {isMoney(column.selector, row[column.selector])}
                        </p>
                      )}
                      {column.selector === 'status' && (<p style={{ color: '#2ECCA9', fontSize: 13 }}>On Time</p>)}
                      {column.selector === 'currentStage' && (
                        <p style={{ color: 'var(--text-primary)', fontSize: 13 }}>
                          {currentStage(row, column.selector)}
                        </p>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p className="text-center">N/A</p>
        )}
      </TableContainer>
      <ProjectMonitorDialog
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        stage={stage}
        onDelete={props.onDeleteProjectMonitor}
      />
    </>
  );
}
