import React, { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import moment from 'moment';

const initInput = {
  GFA: 0,
  ENERGY: 0,
  BUDGET: 0,
  EXPENDITURE: 0,
};

export default function Hook(props) {
  const [open, setOpen] = React.useState(false);
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [Facility, setFacility] = useState();
  const [LSC, setLSC] = useState([]);
  const [EEI, setEEI] = useState([]);
  const [RCE, setRCE] = useState([]);
  const [input, setInput] = useState(initInput);

  const handleClose = () => {
    setInput(initInput);
    setLSC([]);
    setFacility();
    setOpen(false);
  };
  const handleClickOpen = () => setOpen(true);

  const handleChange = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
  };

  const AddLSC = (event) => {
    setLSC([...LSC, { ITEM: null, EXPIRY_DATE: null }]);
  };

  const EditLSC = (event, index) => {
    if (index > -1) {
      if (event.target.type === 'date') {
        const date = moment(event.target.value).format('YYYY-MM-DD');
        const arr = [...LSC];
        arr.splice(index, 1, { ITEM: event.target.name, EXPIRY_DATE: date });
        setLSC(arr);
        return;
      }
      const arr = [...LSC];
      arr[index].ITEM = event.target.value;
      setLSC(arr);
    }
  };

  const removeLSC = (index) => {
    if (index > -1) {
      const arr = [...LSC];
      arr.splice(index, 1);
      setLSC(arr);
    }
  };

  const handleSave = () => {
    Api({
      endpoint: endpoints.updateFacility(Facility, year, month),
      data: { input: { ...input, LSC } },
      onSuccess: (response) => {
        toast('success', 'Successfully update Facility'); // eslint-disable-line no-undef
        handleClose();
        setInput(initInput);
      },
      onFail: () => {
        toast('error', 'Successfully update Facility'); // eslint-disable-line no-undef
      },
    });
  };

  useEffect(() => {
    if (open) {
      setFacility(props.facilities[props.selectedFacilities]?.uuid);
    }
  }, [open]); // eslint-disable-line

  useEffect(() => {
    if (!Facility) return;
    Api({
      endpoint: endpoints.showFacilities(Facility),
      data: { YEAR: year, MONTH: month },
      onSuccess: ({ data }) => {
        setInput({ ...input, GFA: data.GFA });
        setEEI(data.eei);
        setRCE(data.rce);
        setLSC(data.lsc);
      },
      onFail: () => {},
    });
  }, [Facility, year, month]); // eslint-disable-line

  useEffect(() => {
    const energy = EEI.find((f) => f.YEAR === year && f.MONTH === month);

    if (energy) {
      setInput({ ...input, ENERGY: energy.ENERGY });
    } else {
      setInput({ ...input, ENERGY: 0 });
    }
  }, [EEI, year, month]); // eslint-disable-line

  useEffect(() => {
    const repair = RCE.find((f) => f.YEAR === year && f.MONTH === month);

    if (repair) {
      setInput({ ...input, BUDGET: repair.BUDGET, EXPENDITURE: repair.EXPENDITURE });
    } else {
      setInput({ ...input, BUDGET: 0, EXPENDITURE: 0 });
    }
  }, [RCE, year, month]); // eslint-disable-line

  return {
    year,
    setYear,
    month,
    setMonth,
    input,
    setInput,
    Facility,
    setFacility,
    handleSave,
    handleClose,
    handleClickOpen,
    handleChange,
    open,
    LSC,
    AddLSC,
    removeLSC,
    EditLSC,
  };
}
