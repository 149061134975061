/* eslint-disable complexity */
import MaterialTable from '@Components/MaterialTable';
import _ from 'lodash';
import { yearComparisons } from '@Helpers';

const columns = [
  {
    name: 'Sector',
    selector: 'sector',
    align: 'left',
    minWidth: 200,
  },
  {
    name: 'PB',
    selector: 'pb',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'BGSB',
    selector: 'bgsb',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'TFSB',
    selector: 'tfsb',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'SSSB',
    selector: 'sssb',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'TEGAS',
    selector: 'tegas',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'DYNAMIC',
    selector: 'dynamic',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'EXPERT',
    selector: 'expert',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'SHELTER',
    selector: 'shelter',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'Total',
    selector: 'total',
    align: 'center',
    minWidth: 100,
  },
];

const currYear = yearComparisons(2)[1];

export default (h) => {
  const groupedManning = _(h.securityManningData)
    .groupBy(item => `${item.year}-${item.ProjectSecurityId}`)
    .map((objs, manning_session) => {
      const pb = _.sumBy(objs, 'pb') || 0;
      const bgsb = _.sumBy(objs, 'bgsb') || 0;
      const tfsb = _.sumBy(objs, 'tfsb') || 0;
      const sssb = _.sumBy(objs, 'sssb') || 0;
      const tegas = _.sumBy(objs, 'tegas') || 0;
      const dynamic = _.sumBy(objs, 'dynamic') || 0;
      const expert = _.sumBy(objs, 'expert') || 0;
      const shelter = _.sumBy(objs, 'shelter') || 0;
      const total = pb + bgsb + tfsb + sssb + tegas + dynamic + expert + shelter;
      return ({
        manning_session, pb, bgsb, tfsb, sssb, tegas, dynamic, expert, shelter, total,
      });
    })
    .value();
  const computedRows = h.securityManningGroup.map((eachSector, i) => ({
    sector: eachSector.name,
    ...groupedManning.find(d => d.manning_session === `${currYear}-${eachSector.id}`),
  }));
  // total rows
  const sector = <p className="text-light">Total</p>;
  const pb = _.sumBy(computedRows, 'pb') || 0;
  const bgsb = _.sumBy(computedRows, 'bgsb') || 0;
  const tfsb = _.sumBy(computedRows, 'tfsb') || 0;
  const sssb = _.sumBy(computedRows, 'sssb') || 0;
  const tegas = _.sumBy(computedRows, 'tegas') || 0;
  const dynamic = _.sumBy(computedRows, 'dynamic') || 0;
  const expert = _.sumBy(computedRows, 'expert') || 0;
  const shelter = _.sumBy(computedRows, 'shelter') || 0;
  const total = pb + bgsb + tfsb + sssb + tegas + dynamic + expert + shelter;
  computedRows.push({
    sector, pb, bgsb, tfsb, sssb, tegas, dynamic, expert, shelter, total,
  });
  // end total rows
  return (
    <div className="paper" style={{ padding: 16, marginTop: 24 }}>
      <h3>{`Security Manning ${currYear}`}</h3>
      <MaterialTable
        color="alternate"
        tableHead
        columns={columns}
        tableData={computedRows}
      />
    </div>
  );
};
