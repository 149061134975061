import React, { useState } from 'react';
import {
  InputAdornment,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import SortIcon from '@material-ui/icons/Sort';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
  radioLabel: { fontSize: 16 },
  iconButton: { marginLeft: 5, padding: '0 5px', backgroundColor: '#FFFFFF' },
}));

const SearchBox = styled(TextField)(() => ({
  transform: 'scale(0.8)',
  paddingTop: 3,
  marginLeft: -25,
  '& fieldset': {
    borderRadius: '30px',
  },
}));

export default function SearchFilter(props) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const role_radios = [{ value: 'all', label: 'All' }, { value: '1', label: 'Admin' }, { value: '2', label: 'User' }];
  return (
    <div style={{ display: 'flex' }}>
      <SearchBox
        label="Search User"
        size="small"
        variant="outlined"
        onChange={(e) => props.setsearch_key(e.target.value)}
        InputProps={{
          endAdornment: (<InputAdornment><IconButton style={{ pointerEvents: 'none' }}><SearchIcon /></IconButton></InputAdornment>),
        }}
      />
      <IconButton className={classes.iconButton}><SortIcon /><ExpandMoreIcon onClick={() => setShowDialog(!showDialog)} /></IconButton>
      <Dialog onClose={() => setShowDialog(!showDialog)} open={showDialog} fullWidth maxWidth="xs">
        <DialogTitle>Filter</DialogTitle>
        <DialogContent dividers>
          <FormLabel component="legend" className={classes.radioLabel}>Role</FormLabel>
          <RadioGroup value={props.user_role} onChange={(e) => props.setuser_role(e.target.value)}>
            {role_radios.map(({ value, label }) => <FormControlLabel key={value} value={value} control={<Radio />} label={label} />)}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setShowDialog(!showDialog)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
