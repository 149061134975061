/* eslint-disable max-nested-callbacks */
import React from 'react';
import Button from '@Components/Button';
import {
  Grid, OutlinedInput, FormControl, CircularProgress,
} from '@material-ui/core';

export default (h) => {
  const columns = [
    'pb',
    'bgsb',
    'tfsb',
    'sssb',
    'tegas',
    'dynamic',
    'shelter',
  ];

  return (
    <div style={{ padding: '0 24px' }}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <h3>Security Manning</h3>
        </Grid>
        <Grid container item xs={10}>
          {columns.map(c => (
            <h4 style={{ flex: 1, opacity: 0.65, marginLeft: 10 }}>{c.toUpperCase()}</h4>
          ))}
        </Grid>
      </Grid>

      {h.isLoading
        ? <div className="flex-standard" style={{ height: 300 }}><CircularProgress /></div>
        : h.securityManningGroup.map((d, idx) => {
          const isEven = idx % 2 === 0;
          const color = isEven ? '#008FFB' : '#1F3566';
          return (
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <p style={{ fontWeight: 600, color }}>{d.name}</p>
              </Grid>
              <Grid container item xs={10}>
                {columns.map(eachCol => (
                  <FormControl style={{ flex: 1, margin: '0 10px' }} variant="outlined" size="small" fullWidth>
                    <OutlinedInput
                      style={{ color }}
                      value={h.inputSecurityManning.find(x => x.ProjectSecurityId === d.id)?.[eachCol]
                        || h.selectedSecurityManningData.find(x => x.ProjectSecurityId === d.id)?.[eachCol] 
                        || ''}
                      onChange={e => {
                        h.setInputSecurityManning(eachInput => {
                          const input = [...eachInput];
                          const objIdx = input.findIndex(x => x.ProjectSecurityId === d.id);
                          if (objIdx >= 0) {
                            input[objIdx][eachCol] = Number(e.target.value);
                            input[objIdx].year = h.year;
                            input[objIdx].month = h.month;
                          } else {
                            input.push({
                              ProjectSecurityId: d.id,
                              [eachCol]: Number(e.target.value),
                              year: h.year,
                              month: h.month,
                            });
                          }
                          return input;
                        });
                      }}
                    />
                  </FormControl>
                ))}
              </Grid>
            </Grid>
          );
        })}

      <div className="flex" style={{ justifyContent: 'flex-end', marginTop: 24 }}>
        <Button variant="text" onClick={h.handleBackStep}>
          Return
        </Button>
        <Button onClick={h.handleNextStep}>
          Next
        </Button>
      </div>
    </div>
  );
};
