import React from 'react';
import { TextField } from '@material-ui/core';
import Button from '@Components/Button';

export default (h) => {
  return (
    <div style={{ padding: '0 24px' }}>
      {[
        { title: true, label: 'Revenue' },
        { key_name: 'revenue_budget', placeholder: 'Budget', autoFocus: true },
        { key_name: 'revenue_actual', placeholder: 'Actual' },
        { title: true, label: 'Operating Expenses' },
        { key_name: 'operating_budget', placeholder: 'Budget' },
        { key_name: 'operating_actual', placeholder: 'Actual' },
        { title: true, label: 'Billing & Collection' },
        { key_name: 'billing', placeholder: 'Billing' },
        { key_name: 'collection', placeholder: 'Collection' },
      ].map(field => (field.title ? <h3 children={field.label} /> : (
        <CustomField
          {...field}
          // value '' should be set as default as per final discussion in fb react team
          // instead of transition to uncontrolled component with undefined value
          // it is required to set it to '' to let it remain in controlled state
          // ref https://github.com/facebook/react/issues/2533#issuecomment-188134273
          value={h.inputFinancial[field.key_name] || h.selectedFinancialData[field.key_name] || ''}
          onChange={e => {
            h.setInputFinancial(eachInput => ({
              ...eachInput,
              [field.key_name]: Number(e.target.value),
              year: h.year,
              month: h.month,
            }));
          }}
        />
      )))}

      <div className="flex" style={{ justifyContent: 'flex-end' }}>
        <Button onClick={h.handleNextStep}>
          Next
        </Button>
      </div>
    </div>
  );
};

const CustomField = (props) => (
  <div className="flex-standard" style={{ marginBottom: 20 }}>
    <p style={{ width: 400, whiteSpace: 'nowrap', fontWeight: 600 }}>{props.placeholder}</p>
    <TextField variant="outlined" fullWidth size="small" {...props} />
  </div>
);
