import React from "react";
import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@material-ui/core";
import { VictoryPie } from "victory";

import data from "./RentalRateData.json";
import { KeyboardArrowRight } from "@material-ui/icons";
import { Link } from "react-router-dom";

const colors = ["#B85EEE", "#FB9C5D", "#008FFB", "#2ECCA9", "#FF4560"];

let tableData = [];

let colorCount = 0;
for (let item of data) {
  if (colorCount > colors.length) {
    colorCount = 0;
  }
  tableData.push({
    color: (
      <div
        style={{
          width: 24,
          height: 24,
          backgroundColor: colors[colorCount],
        }}
      ></div>
    ),
    level: (
      <p style={{ fontSize: 14 }}>
        {item.level} <span className="text-light">({item.percent}%)</span>
      </p>
    ),
    avgRentalRate: `RM ${item.avgRentalRate.toFixed(2)}/ft²`,
    rentPerMonth: `RM ${item.rentPerMonth.toLocaleString()}`,
  });
  colorCount++;
}

const columns = [
  {
    name: "",
    selector: "color",
    sortable: false,
    width: "60px",
  },
  {
    name: "Level",
    selector: "level",
    sortable: false,
    wrap: true,
    width: "170px",
  },
  {
    name: "Avg. Rental Rate",
    selector: "avgRentalRate",
    sortable: false,
    right: true,
    width: "140px",
  },
  {
    name: "Rent per month",
    selector: "rentPerMonth",
    sortable: false,
    right: true,
    width: "140px",
  },
];

function RentalRateChart(props) {
  return (
    <>
      <Grid container style={{ marginBottom: 20 }}>
        <Grid item xs={12}>
          <h2
            style={{
              fontWeight: 600,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            Rental Rate
            <span className="text-light" style={{ marginLeft: 12 }}>
              for January 2021
            </span>
            <Link to="/property/leasing/rental-rate" className="arrow-link">
              <KeyboardArrowRight />
            </Link>
          </h2>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ padding: 20, marginTop: 20 }}
        >
          <Grid container>
            <Grid item xs={12} md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <p style={{ textAlign: "center" }}>Total Sales Turnover</p>
                  <h2 style={{ fontWeight: 400, textAlign: "center" }}>
                    RM 80,042,260
                  </h2>
                </Grid>
                <Grid item xs={12}>
                  <svg viewBox="0 0 400 400">
                    <VictoryPie
                      colorScale={colors}
                      standalone={false}
                      width={400}
                      height={400}
                      x={"level"}
                      y={"percent"}
                      data={data}
                      labels={({ datum }) => `${datum.percent}%`}
                      labelRadius={({ datum }) => 100 / datum.percent + 80}
                      style={{
                        labels: {
                          fill: "white",
                          fontSize: 16,
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </svg>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={4}>
                  <p style={{ textAlign: "center", fontSize: "0.7em" }}>
                    Total Area
                  </p>
                  <h2
                    style={{
                      fontWeight: 400,
                      textAlign: "center",
                      fontSize: "1em",
                    }}
                  >
                    150,000 ft²
                  </h2>
                </Grid>
                <Grid item xs={4}>
                  <p style={{ textAlign: "center", fontSize: "0.7em" }}>
                    Total Rent per Month
                  </p>
                  <h2
                    style={{
                      fontWeight: 400,
                      textAlign: "center",
                      fontSize: "1em",
                    }}
                  >
                    RM 1,000,000
                  </h2>
                </Grid>
                <Grid item xs={4}>
                  <p style={{ textAlign: "center", fontSize: "0.7em" }}>
                    Rental per Annum
                  </p>
                  <h2
                    style={{
                      fontWeight: 400,
                      textAlign: "center",
                      fontSize: "1em",
                    }}
                  >
                    RM 12,000,000
                  </h2>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <TableContainer>
                    <Table size="small" style={{ minWidth: 500 }}>
                      <TableHead>
                        <TableRow>
                          {columns.map((column, index) => (
                            <TableCell
                              key={index}
                              align={column.right && "right"}
                            >
                              {column.name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {columns.map((column, colIndex) => (
                              <TableCell
                                key={rowIndex + colIndex}
                                align={column.right && "right"}
                              >
                                {row[column.selector]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                        <TableRow className="MuiTable-fixedFooter">
                          <TableCell />
                          <TableCell>Total Service Charge</TableCell>
                          <TableCell align="right">RM 2.50/ft²</TableCell>
                          <TableCell align="right">RM 250,000</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default RentalRateChart;
