import {
  Container, Grid, Button, TextField, CircularProgress,
} from '@material-ui/core';
import { Redirect, useLocation } from 'react-router-dom';
import login_video from '@Assets/Videos/login_video.webm';
import { useContext, useState } from 'react';
import { AuthContext } from '@Context/Auth';
import { User, Lock } from '@Assets/Icons';
import { DaxLogo } from '@Assets/Icons/daxLogo';
import { makeStyles } from '@material-ui/core/styles';
import Mail from '@material-ui/icons/MailOutline';
import Api, { endpoints } from '@Helpers/api';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  btnLogin: {
    backgroundColor: '#7723CA',
    color: '#fff',
    width: '100%',
    '&:hover': {
      backgroundColor: '#1E3465',
    },
  },
  buttonProgress: {
    color: '#7723CA',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function LoginPage(h) {
  const location = useLocation();
  const is_login = location.pathname.includes('login');
  const is_reset = location.pathname.includes('reset-password');

  return (
    <>
      <Container>
        <Grid container alignItems="center" justify="space-around" style={{ height: '100vh' }}>
          <Grid item container lg={4} justify="center">
            <Grid item xs={12} direction="column" justify="space-between" alignItems="center">
              <Grid>
                <DaxLogo style={{ width: '100%' }} />
              </Grid>
              <Grid>
                {is_login && <LoginForm {...h} />}
                {is_reset && <ResetPasswordForm />}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={8} alignItems="center">
            <video width="100%" autoPlay muted loop>
              <source src={login_video} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        </Grid>
      </Container>
      <Grid container justify="center" alignItems="center" className="footer" style={{ fontSize: 12 }}>
        <p>
          Damansara Assets Xchange (DAX) is an integrated asset lifecycle management platform by&nbsp;
          <a
            href="https://www.damansaraassets.com.my"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#1e3465' }}
          >
            DASB
          </a>
          , powered by&nbsp;
          <a href="https://ofo.my" target="_blank" rel="noopener noreferrer" style={{ color: '#1e3465' }}>
            OFO Tech
          </a>
        </p>
      </Grid>
    </>
  );
}
