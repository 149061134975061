import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, FormControl, MenuItem, createMuiTheme, Select, Button
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { rentalRate } from '@Helpers/index'
import moment from 'moment';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formControlContainer: {
    height: '0',
  },
  formControl: {
    width: '100%',
    visibility: 'hidden',
    padding: 0,
  },
}));

const StyledMenuItem = withStyles(() => ({
  root: {
    justifyContent: 'center',
    backgroundColor: '#1e3465',
    '& h1, h2, h3, h4, h5, h6': {
      textAlign: 'center',
      color: 'white',
    },
    '&:focus, &:hover': {
      backgroundColor: 'white',
      '& h1, h2, h3, h4, h5, h6': {
        color: '#1e3465',
      },
    },
  },
}))(MenuItem);

const ExpireTable = (props) => {
  const classes = useStyles();

  const data = props.tenancyExpiry;
  data.forEach(obj => {
    obj.rentalRate = rentalRate(obj.rental, obj.area).toFixed(2);
  });
  const [officeData, setOfficeData] = useState([]);
  const [telcoData, setTelcoData] = useState([]);
  const [spaceData, setSpaceData] = useState([]);
  const [filter, setFilter] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterList = [
    {
      name: "SQFT ASC",
      value: 0,
    },
    {
      name: "SQFT DESC",
      value: 1,
    },
    {
      name: "RENTAL ASC",
      value: 2,
    },
    {
      name: "RENTAL DESC",
      value: 3,
    },
    {
      name: "LEVEL",
      value: 4,
    },
  ];
  const columns = [
    {
      name: 'Tenant Name',
      selector: 'name',
    },
    {
      name: 'Expiry Date',
      selector: 'expireAt',
      right: true,
    },
    {
      name: 'Rental Rate(RM)',
      selector: 'rentalRate',
      right: true,
    },
  ];
  const sortByLevel = (a, b) => {
      const levelA = a.lot_no;
      const levelB = b.lot_no;
      const partsA = levelA.match(/[a-zA-Z]+|[0-9]+/g) ?? [''];
      const partsB = levelB.match(/[a-zA-Z]+|[0-9]+/g) ?? [''];
      const alphaComparison = partsA[0].localeCompare(partsB[0]);
      if (alphaComparison !== 0) {
          return alphaComparison;
      }
      return parseInt(partsA[1]) - parseInt(partsB[1]);
  };

  const additionalColumn = (type) => {
    let newColumns = [];
    const category = {
      O1: [{ name: 'Total Sqft', selector: 'area', right: true }, { name: 'Lot No', selector: 'lot_no', right: true }],
      O13: [],
      SR: [{ name: 'Location', selector: 'lot_no', right: true }],
    };
    newColumns = [...columns, ...category[type]];
    return newColumns;
  };

  const handleSortOffice = (value) => {
    if (value === 0) {
      setOfficeData(props.tenancyExpiry
        .filter(e => (!['O13', 'SR'].includes(e.TenantTradeCategoryDetailId)) && moment(e.expireAt).isSameOrAfter(moment()) && !!e.expireAt)
        .sort((a, b) => a.expireAt.localeCompare(b.expireAt)));
      setOfficeData(officeData.sort((a, b) => a.rentalRate - b.rentalRate));
      setOfficeData(officeData.sort((a, b) => a.area - b.area));
      setFilter(value);
    } else if (value === 1) {
      setOfficeData(props.tenancyExpiry
        .filter(e => (!['O13', 'SR'].includes(e.TenantTradeCategoryDetailId)) && moment(e.expireAt).isSameOrAfter(moment()) && !!e.expireAt)
        .sort((a, b) => a.expireAt.localeCompare(b.expireAt)));
      setOfficeData(officeData.sort((a, b) => b.area - a.area));
      setFilter(value);
    } else if (value === 2) {
      setOfficeData(props.tenancyExpiry
        .filter(e => (!['O13', 'SR'].includes(e.TenantTradeCategoryDetailId)) && moment(e.expireAt).isSameOrAfter(moment()) && !!e.expireAt)
        .sort((a, b) => a.expireAt.localeCompare(b.expireAt)));
      setOfficeData(officeData.sort((a, b) => a.rentalRate - b.rentalRate));
      setFilter(value);
    } else if (value === 3) {
      setOfficeData(props.tenancyExpiry
        .filter(e => (!['O13', 'SR'].includes(e.TenantTradeCategoryDetailId)) && moment(e.expireAt).isSameOrAfter(moment()) && !!e.expireAt)
        .sort((a, b) => a.expireAt.localeCompare(b.expireAt)));
      setOfficeData(officeData.sort((a, b) => b.rentalRate - a.rentalRate));
      setFilter(value);
    } else if (value === 4) {
      setOfficeData(props.tenancyExpiry
        .filter(e => (!['O13', 'SR'].includes(e.TenantTradeCategoryDetailId)) && moment(e.expireAt).isSameOrAfter(moment()) && !!e.expireAt)
        .sort(sortByLevel));
      setFilter(value);
    }
  };

  const handleResetSort = (tableName) => {
    if (tableName === 'Office') {
      setOfficeData(props.tenancyExpiry
        .filter(e => (!['O13', 'SR'].includes(e.TenantTradeCategoryDetailId)) && moment(e.expireAt).isSameOrAfter(moment()) && !!e.expireAt)
        .sort((a, b) => a.expireAt.localeCompare(b.expireAt)));
      props.setSort('');
    }
    if (tableName === 'Telco') {
      setTelcoData(props.tenancyExpiry
        .filter(e => e.TenantTradeCategoryDetailId === 'O13' && !!e.expireAt)
        .sort((a, b) => a.expireAt.localeCompare(b.expireAt)));
      props.setSort('');
    }
    if (tableName === 'Space Rental') {
      setSpaceData(props.tenancyExpiry
        .filter(e => e.TenantTradeCategoryDetailId === 'SR' && !!e.expireAt)
        .sort((a, b) => a.expireAt.localeCompare(b.expireAt)));
      props.setSort('');
    }
  };

  const handleTableData = () => {
    setOfficeData(props.tenancyExpiry
      .filter(e => (!['O13', 'SR'].includes(e.TenantTradeCategoryDetailId)) && moment(e.expireAt).isSameOrAfter(moment()) && !!e.expireAt)
      .sort((a, b) => a.expireAt.localeCompare(b.expireAt)));
    setTelcoData(props.tenancyExpiry
      .filter(e => e.TenantTradeCategoryDetailId === 'O13' && !!e.expireAt)
      .sort((a, b) => a.expireAt.localeCompare(b.expireAt)));
    setSpaceData(props.tenancyExpiry
      .filter(e => e.TenantTradeCategoryDetailId === 'SR' && !!e.expireAt)
      .sort((a, b) => a.expireAt.localeCompare(b.expireAt)));
  };

  useEffect(() => {
    handleTableData();
  }, [props.tenancyExpiry]);
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2 style={{ fontWeight: 600 }}>Expiry Date</h2>
      </div>
      {[
        {
          title: 'Office',
          category: 'O1',
          data: officeData,
        },
        {
          title: 'Telco',
          category: 'O13',
          data: telcoData,
        },
        {
          title: 'Space Rental',
          category: 'SR',
          data: spaceData,
        },
      ].map(type => (
        <div className="paper" style={{ padding: '10px 20px', marginTop: 20 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h4>{type.title}</h4>
            {type.title === 'Office' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4 style={{ whiteSpace: 'nowrap' }}>Sort by:&nbsp;&nbsp;</h4>
                  <div>
                    <Button
                      onClick={handleClick}
                      endIcon={<ExpandMore />}
                      style={{
                        width: '100%',
                        height: '30px',
                        borderRadius: 10,
                        backgroundColor: '#1E3465',
                        color: '#ffffff',
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                    <div>
                      <h4 style={{ color: '#ffffff' }} numberOfLines={1} ellipsizeMode="tail">
                        {filterList[filter].name}
                      </h4>
                    </div>
                  </Button>
                  <div className={classes.formControlContainer}>
                    <FormControl className={classes.formControl}>
                      <Select
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        value={filter}
                        onChange={e => handleSortOffice(e.target.value)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                          getContentAnchorEl: null,
                          MenuListProps: { disablePadding: true },
                        }}
                      >
                        {filterList?.map((f, idx) => (
                          <StyledMenuItem
                            selected={filter === f.value}
                            value={f.value}
                          >
                            <h3>{f.name}</h3>
                          </StyledMenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <Tooltip title="Reset Sort" style={{ cursor: 'pointer' }}>
                  <Refresh style={{ cursor: 'pointer' }} onClick={() => handleResetSort(type.title)} />
                </Tooltip>
              </div>
            )}
          </div>
          <TableContainer style={{ maxHeight: 640 }}>
            <Table size="small" style={{ minWidth: 400 }}>
              <TableHead>
                <TableRow>
                  {additionalColumn(type.category).map((column) => (
                    <TableCell align={column.right && 'right'}>
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {type.data?.map((row) => (
                  <TableRow>
                    {additionalColumn(type.category).map((column) => (
                      <TableCell
                        align={column.right && 'right'}
                        style={{ paddingTop: 5, paddingBottom: 20, cursor: 'pointer' }}
                        onClick={() => { props.setSelectedTenant(row); props.setOpenExpiryDialog(true); }}
                      >
                        <span style={{ color: isExpiring(row.expireAt) && 'rgb(234, 22, 1)', fontWeight: isExpiring(row.expireAt) && 600 }}>
                          {column.selector === 'remainingDays' && moment(row.expireAt).diff(moment(), 'days')}
                          {column.selector !== 'expireAt'
                            ? row[column.selector]
                            : <span>{moment(row[column.selector]).format('DD/MM/YYYY')}</span>}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}
    </>
  );
};

const isExpiring = (expireAt) => moment().diff(expireAt, 'days') > -183;

export default ExpireTable;
