import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Add, Close, GetApp } from '@material-ui/icons';
import UploadIcon from '@Components/UploadIcon';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import '@Components/Inspection/dropzone.css';
import DropzoneBox from '@Components/DropzoneBox';
import useHook from './hook';

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '145px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  root: { '&$checked': { color: '#4fd8af' }, color: '#1e3465' },
  checked: {},
}));

export default function ManualDataForm(props) {
  const classes = useStyles();
  const {
    openModal,
    isLoading,
    handleClose,
    handleClickOpen,
    handleSave,
    files,
    setFiles,
  } = useHook(props);

  return (
    <div>
      <button
        onClick={handleClickOpen}
        className="blue-button flex-standard"
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          margin: 0,
        }}
      >
        <UploadIcon />
        <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>Upload Data</p>
      </button>
      <Dialog
        open={openModal}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <DialogTitle>
          <Grid container justify="space-between">
            <h1>Manual Upload Data</h1>
            {props.sampleDataLink && (
              <Grid container xs={6} alignItems="center">
                <Link to={{ pathname: props.sampleDataLink }} target="_blank">
                  <button
                    className="blue-button flex-standard"
                    style={{
                      width: 160,
                      height: 36,
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      padding: 15,
                      margin: 0,
                      color: '#FFFFFF',
                    }}
                  >
                    <GetApp />
                    <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>Sample Data</p>
                  </button>
                </Link>
              </Grid>
            )}
            <Close className={classes.closeBtn} onClick={handleClose} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DropzoneBox
            files={files}
            setFiles={setFiles}
            type="excel"
          />
        </DialogContent>
        <DialogActions>
          <div style={{ padding: '0 16px', width: '100%', display: 'flex' }}>
            {isLoading ? <CircularProgress />
              : files.length > 0
              && (
                <Box
                  component="button"
                  className={`${classes.blueBtn} blue-button`}
                  onClick={handleSave}
                >
                  <h1 className={`${classes.blueBtnText} flex-standard`}>
                    <Add />
                    {' '}
                    Save Data
                  </h1>
                </Box>
              )}
            <Box
              component="button"
              className="cancel-button"
              onClick={handleClose}
              style={{
                borderRadius: '6px',
                width: '120px',
              }}
            >
              <h4 style={{ fontSize: 14 }} className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h4>
            </Box>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
