import React, { useState } from 'react';
import { Grid, Box } from '@material-ui/core';
import MaterialTable from '@Components/MaterialTable';
import BoxTabs from './BoxTabs';

const tabslist = [
  { label: 'FINANCIAL', value: 'financial', borderRadius: '5px 0 0 5px' },
  { label: 'NON-FINANCIAL', value: 'non_financial', borderRadius: '0 5px 5px 0' },
];

const columnsFinancial = [
  {
    name: 'Elements',
    selector: 'elements',
    align: 'left',
    minWidth: 140,
  },
  {
    name: 'Status',
    selector: 'status',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'Actual',
    selector: 'actual',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'Budget',
    selector: 'budget',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'Cum Actual',
    selector: 'cum_actual',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'Cum Budget',
    selector: 'cum_budget',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'Remarks',
    selector: 'remarks',
    align: 'left',
    minWidth: 100,
  },
];

const columnsNonFinancial = [
  {
    name: 'Elements',
    selector: 'elements',
    align: 'left',
    minWidth: 140,
  },
  {
    name: 'Status',
    selector: 'status',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'Remarks',
    selector: 'remarks',
    align: 'left',
    minWidth: 100,
  },
];

const financial = [
  {
    elements: 'Revenue',
    status: 'On Time',
    actual: '12',
    budget: '34',
    cum_actual: '21',
    cum_budget: '23',
    remarks: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    elements: 'Gross Profit',
    status: 'Delayed',
    actual: '42',
    budget: '21',
    cum_actual: '12',
    cum_budget: '32',
    remarks: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    elements: 'Gross Profit Margin',
    status: 'Pending',
    actual: '23',
    budget: '23',
    cum_actual: '12',
    cum_budget: '32',
    remarks: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];

const non_financial = [
  {
    elements: 'Corporate Restructuring',
    status: 'On Time',
    remarks: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    elements: 'Organization Redesign',
    status: 'On Time',
    remarks: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    elements: 'ESG',
    status: 'On Time',
    remarks: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    elements: 'Rebranding',
    status: 'On Time',
    remarks: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];

export default function PerformanceReview(props) {
  const [tab, setTab] = useState('financial');

  const statusIndicator = (status) => {
    const color = {
      'On Time': '#34D2B2',
      Delayed: '#FD4E6B',
      Pending: '#FCD55C',
    };
    return (
      <div className="flex row center">
        <Box style={{
          backgroundColor: color[status], width: 20, height: 20, borderRadius: 50,
        }}
        />
      </div>
    );
  };

  return (
    <>
      <Grid xs={12} lg={12} container justifyContent="space-between">
        <h2 style={{ fontWeight: 600, marginBottom: 10 }}>Value Creation Plan</h2>
        {/* <div>Dropdown Box</div> */}
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: 10, minHeight: 300 }}>
        <Grid item xs={12} md={12}>
          <BoxTabs items={tabslist} tab={tab} setTab={setTab} />
          {tab === 'financial' ? (
            <MaterialTable
              tableHead
              color="alternate"
              tableMaxHeight={450}
              columns={columnsFinancial}
              tableData={financial.map((x) => ({
                ...x,
                status: statusIndicator(x.status),
              }))}
            />
          ) : (
            <MaterialTable
              tableHead
              color="alternate"
              tableMaxHeight={450}
              columns={columnsNonFinancial}
              tableData={non_financial.map((x) => ({
                ...x,
                status: statusIndicator(x.status),
              }))}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
