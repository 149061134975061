import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, IconButton } from '@material-ui/core';
import MainContentContainer from '@Components/MainContentContainer';
import MaterialTable from '@Components/MaterialTable';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightTabs from '@Components/HighlightTabs';
import moment from 'moment';
import { truncateString } from '@Helpers';
import Navbar from '../components/Navbar';
import Hook from './hook';
import DeleteDialog from '../components/DeleteDialog';
import CreateInspection from './CreateInspection';

const columns = [
  {
    name: 'Id',
    selector: 'id',
    align: 'left',
    minWidth: 60,
  },
  {
    name: 'Name',
    selector: 'name',
    align: 'left',
    minWidth: 240,
  },
  {
    name: 'Description',
    selector: 'description',
    align: 'left',
    minWidth: 370,
  },
  {
    name: 'Date',
    selector: 'inspectionAtDate',
    align: 'center',
    minWidth: 120,
  },
  {
    name: 'Time',
    selector: 'inspectionAtTime',
    align: 'center',
    minWidth: 120,
  },
  {
    name: 'Pilot',
    selector: 'pilot_name',
    align: 'center',
    minWidth: 120,
  },
  {
    name: 'View Details',
    selector: 'view_details',
    align: 'center',
    minWidth: 100,
  },
];
const can_delete_columns = [
  {
    name: 'Delete',
    selector: 'delete',
    align: 'center',
    width: 100,
  },
];

const styles = {
  pilot: {
    display: 'flex',
    flexDirection: 'inline',
    paddingTop: 2,
  },
  img: {
    borderRadius: 50,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'white',
    shadowColor: '#000',
    shadowOpacity: 0.8,
    shadowRadius: 4,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    elevation: 4,
    marginRight: 4,
    width: 25,
  },
};

export default function InspectionSession(props) {
  const h = Hook(props);
  const history = useHistory();
  const [tab, setTab] = useState('osh');
  const [selectedInspection, setSelectedInspection] = useState();
  const [data, setData] = useState([]);
  const [accessible_colums, setaccessible_colums] = useState([]);

  const tabslist = [];
  if (props.user?.can_see_osh) tabslist.push({ label: 'OSH', value: 'osh', access: 'osh', filter: { InspectionCategoryId: 1 } });
  if (props.user?.can_see_environment) {
    tabslist.push({
      label: 'ENVIRONMENT', value: 'env', access: 'environment', filter: { InspectionCategoryId: 2 },
    });
  }
  if (props.user?.can_see_quality) {
    tabslist.push({ label: 'Q. CONTROL 1', value: 'qc1', access: 'quality', filter: { InspectionCategoryId: 3, QualityCategoryId: 1 } });
    tabslist.push({ label: 'Q. CONTROL 2', value: 'qc2', access: 'quality', filter: { InspectionCategoryId: 3, QualityCategoryId: 2 } });
    tabslist.push({ label: 'Q. CONTROL 3', value: 'qc3', access: 'quality', filter: { InspectionCategoryId: 3, QualityCategoryId: 3 } });
    tabslist.push({ label: 'PREQLASSIC', value: 'preqc', access: 'quality', filter: { InspectionCategoryId: 3, QualityCategoryId: 4 } });
  }

  useEffect(() => {
    if (!tabslist.length) return;
    setTab(tabslist[0].value);
  }, [props.user]);

  const ViewBtn = (id) => (
    <IconButton
      style={{
        color: 'white', background: '#1e3465', width: 36, height: 36,
      }}
      onClick={() => history.push(`/inspection/${id}`)}
    >
      <SearchIcon />
    </IconButton>
  );

  const DeleteBtn = (e) => (
    <IconButton onClick={() => { setSelectedInspection(e); }}>
      <DeleteIcon style={{ color: 'red' }} />
    </IconButton>
  );

  useEffect(() => {
    const selectedTab = tabslist.find(({ value }) => value === tab);
    const filteredInspections = h.inspectionSessions.filter(x => {
      const toFilter = Object.keys(selectedTab?.filter ?? []);
      for (let idx = 0; idx < toFilter.length; idx++) {
        const keys = toFilter[idx];
        if (!(x[keys] === selectedTab.filter[keys])) return false;
      }
      return true;
    });
    setData(filteredInspections.map(eachSession => ({
      ...eachSession,
      view_details: ViewBtn(eachSession.id),
      delete: DeleteBtn(eachSession),
      inspectionAtDate: moment(eachSession.inspectionAt).format('DD/MM/YYYY'),
      inspectionAtTime: moment(eachSession.inspectionAt).utc().format('hh:mmA'),
      pilot_name: (
        <div className="flex row middle">
          <img
            src={eachSession['User.image'] ?? `https://ui-avatars.com/api/?name=${eachSession['pilot_name']}`}
            style={styles.img}
            loading="lazy"
          />
          &nbsp;{eachSession.pilot_name}
        </div>
      ),
    })));
    const user_can_delete = selectedTab?.access && !!props.user[`can_remove_${selectedTab.access}`];
    setaccessible_colums(user_can_delete ? [...can_delete_columns] : []);
  }, [tab, h.inspectionSessions]);
  return (
    <MainContentContainer>
      <div className="flex space-between">
        <Navbar
          to="/project/"
          text="INSPECTION SESSION"
          subtext={truncateString(props.filtered_projects[props.selected_project]?.name, (props.filtered_projects[props.selected_project]?.name)?.length > 30 ? 26 : 30 )}
        />
        {props.project
          && props.project.name !== 'View All Projects'
          ? (<CreateInspection project={props.project} tab={tab} tabslist={tabslist} callback={(formData) => h.createInspection(formData)} />) : null}
      </div>
      <HighlightTabs
        items={tabslist}
        tab={tab}
        setTab={setTab}
      />
      <DeleteDialog
        {...h}
        selected={selectedInspection}
        setSelected={setSelectedInspection}
      />
      <Grid item xs={12} style={{ padding: 20 }}>
        <MaterialTable
          tableHead
          columns={[...columns, ...accessible_colums]}
          tableData={props.data ?? data}
          tableMinWidth={300}
          tableMaxHeight={900}
        />
      </Grid>
    </MainContentContainer>
  );
}
