import MainContentContainer from '@Components/MainContentContainer';
import { Grid } from '@material-ui/core';
import {
  VictoryChart, VictoryBar, VictoryLabel, VictoryAxis,
} from 'victory';

const data = [
  {
    title: 'Revenues',
    barData: [
      { x: 'YTD 2020', y: 20371295, color: '#008FFB' },
      { x: 'YTD 2021', y: 14010544, color: '#1F3566' },
      { x: 'As of Jan 2021', y: 46049124, color: '#60788B' },
    ],
  },
  {
    title: 'Cost of Sales',
    barData: [
      { x: 'YTD 2020', y: -2375508, color: '#008FFB' },
      { x: 'YTD 2021', y: -3370069, color: '#1F3566' },
      { x: 'As of Jan 2021', y: -10653696, color: '#60788B' },
    ],
  },
  {
    title: 'Gross Profit',
    barData: [
      { x: 'YTD 2020', y: 17995787, color: '#008FFB' },
      { x: 'YTD 2021', y: 10640475, color: '#1F3566' },
      { x: 'As of Jan 2021', y: 35395428, color: '#60788B' },
    ],
  },
  {
    title: 'Profit / Loss Before Tax',
    barData: [
      { x: 'YTD 2020', y: 6681978, color: '#008FFB' },
      { x: 'YTD 2021', y: -3899861, color: '#1F3566' },
      { x: 'As of Jan 2021', y: 4921697, color: '#60788B' },
    ],
  },
  {
    title: 'Profit / Loss After Tax',
    barData: [
      { x: 'YTD 2020', y: 6455312, color: '#008FFB' },
      { x: 'YTD 2021', y: -4069301, color: '#1F3566' },
      { x: 'As of Jan 2021', y: 3740490, color: '#60788B' },
    ],
  },
  {
    title: 'EBITDA',
    barData: [
      { x: 'YTD 2020', y: 6455312, color: '#008FFB' },
      { x: 'YTD 2021', y: -232498, color: '#1F3566' },
      { x: 'As of Jan 2021', y: 3740490, color: '#60788B' },
    ],
  },
];

export default () => {
  return (
    <MainContentContainer>
      <h2 style={{ fontWeight: 600 }}>Financial Statement</h2>
      <Grid container spacing={3}>
        {data.map(e => (
          <Grid item xs={12} md={6} lg={4}>
            <div className="paper finance-donut" style={{ height: 'auto' }}>
              <h4
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: 10,
                  fontWeight: 700,
                }}
              >
                {e.title}
              </h4>
              <div style={{ position: 'relative' }}>
                <VictoryChart
                  width={600}
                  height={250}
                  padding={{
                    left: 170, right: 30, top: 60, bottom: 60,
                  }}
                >
                  <VictoryBar
                    style={{
                      data: {
                        fill: ({ datum }) => datum.color,
                      },
                    }}
                    cornerRadius={10}
                    horizontal
                    barWidth={52}
                    data={e.barData}
                    labels={({ datum }) => `RM ${datum.y.toLocaleString()}`}
                    sortKey="x"
                    sortOrder="descending"
                    animate
                    labelComponent={(
                      <VictoryLabel
                        x={170}
                        dx={10}
                        style={{
                          fontFamily: 'Poppins',
                          fontSize: 28,
                          fill: '#FFFFFF',
                          fontWeight: 700,
                          textShadow: '1px 1px 5px black',
                          stroke: 'none',
                        }}
                        textAnchor="start"
                      />
                    )}
                  />
                  <VictoryAxis
                    // tickValues specifies both the number of ticks and where
                    // they are placed on the axis
                    style={{
                      tickLabels: {
                        fontSize: 24,
                        fill: 'rgba(30, 52, 101, 0.65)',
                        padding: 20,
                      },
                      ticks: { size: 0 },
                      axis: { stroke: 'transparent' },
                      grid: { stroke: 'transparent' },
                    }}
                    offsetX={173}
                    orientation="left"
                  />
                </VictoryChart>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </MainContentContainer>
  );
};
