import React from 'react'
import { Grid, CircularProgress } from "@material-ui/core";
import useHook from './hook';
import ReactWordcloud from 'react-wordcloud';
import DropdownCategory from '../components/DropdownCategory';

function MaintenanceTable(props) {
  const h = useHook(props)

  const wordclouds = [
    {
      words: h.countIssuesInSelectedCategory,
      title: 'Issues by'
    },
  ]
  return (
    <>
      {wordclouds.map(w => (
        <Grid item xs={8}>
          <div className="paper" style={{ padding: "10px", margin: "10px" }}>
            <h3 style={{ display: 'flex', alignItems: 'center', margin: '4px 0' }}>
              {w.title}
              <DropdownCategory {...h} {...props} />
            </h3>
            {props.is_loading
              ? <div className="flex-standard" style={{ height: 300 }}><CircularProgress /></div>
              : <ReactWordcloud
                {...w}
                {...props.wcProps}
              />
            }
          </div>
        </Grid>
      ))}
    </>
  );
}

export default MaintenanceTable;
