import React from "react";
import { VictoryPie } from "victory";

const colors = ["#008FFB", "#FB9C5D", "#B85EEE", "#2ECCA9"];

function ActivePieCharts(props) {
  const { data, active, setActive } = props;
  return (
    <div
      className="paper finance-donut"
      style={{
        borderColor: active === data.title && "rgba(30, 52, 101, 1)",
        height: 400,
      }}
      onClick={() => active !== data.title && setActive(data.title)}
    >
      <p className="text-light" style={{ textAlign: "center" }}>
        Total {data.title} of all building
      </p>
      <h2
        style={{
          textAlign: "center",
          color: data.positive ? "#2ECCA9" : "#FF4560",
        }}
      >
        {data.amount}
      </h2>
      <div style={{ position: "relative" }}>
        <svg viewBox="0 0 400 370">
          <VictoryPie
            colorScale={colors}
            standalone={false}
            width={400}
            height={400}
            padding={{ top: 0, right: 30, left: 30, bottom: 0 }}
            data={data.barData}
            labels={({ datum }) => {
              return `${Math.round(
                ((datum.endAngle - datum.startAngle) / 360) * 100
              )}%`;
            }}
            labelRadius={80}
            style={{
              labels: {
                fill: "white",
                fontSize: 16,
                fontWeight: 700,
              },
            }}
          />
        </svg>
      </div>
    </div>
  );
}

export default ActivePieCharts;
