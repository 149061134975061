import { Grid } from "@material-ui/core";
import Cctv from "@Components/CCTV/Cctv";
import MainContentContainer from "@Components/MainContentContainer";
import MainContentNavbar from "@Components/MainContentNavbar";
import Hook from './hook'

function CCTV(props) {
  const h = Hook(props)

  return (
    <>
      <MainContentNavbar to="/property/" text="CCTV" />
      <MainContentContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h3 style={{ color: 'rgba(30, 52, 101, 1)', fontWeight: 600 }}>
              Total: {h.cameras?.length}&nbsp; &nbsp; Active: {h.cameras?.filter(e => e.is_broken === '0').length}&nbsp; &nbsp; Down: {h.cameras?.filter(e => e.is_broken === '1').length}
            </h3>
          </Grid>
          <Grid item xs={12}>
            <Cctv {...h} noTab={props.noTab} />
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default CCTV;
