import { useState, useEffect } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default () => {
  const [financialData, setFinancialData] = useState([]);
  const [debtorAgingData, setDebtorAgingData] = useState([]);
  const [securityManningData, setSecurityManningData] = useState([]);
  const [incidenceData, setIncidenceData] = useState([]);
  const [debtorsAgingGroup, setDebtorsAgingGroup] = useState([]);
  const [incidenceTypes, setIncidenceTypes] = useState([]);
  const [securityManningGroup, setSecurityManningGroup] = useState([]);
  // const [justUpdated, setJustUpdated] = useState(0);
  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setDebtorsAgingGroup(data.SecurityDebtorAgingGroup ?? []);
        setSecurityManningGroup(data.ProjectSecurity ?? []);
        setIncidenceTypes(data.SecurityIncidenceType ?? []);
        // load other static here
      },
    });
    Api({
      endpoint: endpoints.getSecurityincidence(),
      onSuccess: ({ data }) => {
        setIncidenceData(data);
      },
    });
    Api({
      endpoint: endpoints.getSecuritymanning(),
      onSuccess: ({ data }) => {
        setSecurityManningData(data);
      },
    });
    Api({
      endpoint: endpoints.getSecuritydebtoraging(),
      onSuccess: ({ data }) => {
        setDebtorAgingData(data);
      },
    });
    Api({
      endpoint: endpoints.getSecurityfinancial(),
      onSuccess: ({ data }) => {
        setFinancialData(data);
      },
    });
  }, []);
  return {
    debtorsAgingGroup,
    incidenceTypes,
    securityManningGroup,
    financialData,
    debtorAgingData,
    securityManningData,
    incidenceData,
  };
};
