import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default function Hook(props) {
  const [inspections, setInspections] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [mainImage, setMainImage] = useState({});
  const [mainVideo, setMainVideo] = useState({});
  useEffect(() => {
    if (props.building.uuid) {
      Api({
        endpoint: endpoints.getInspectionFile(),
        data: { building_uuid: props.building.uuid },
        onSuccess: (response) => setInspections(response.data),
      });
    }
  }, [props.building]);
  useEffect(() => {
    if (inspections.length > 0) {
      setImages(inspections.filter(f => !f.isVideo)
        .map(m => ({ ...m, src: m.path, metaData: [] })));
      setVideos(inspections.filter(f => f.isVideo)
        .map((m, i) => ({
          ...m, imgSrc: props.building.image, vidSrc: m.path, asset: props.building.name, title: `Video ${i + 1}`,
        })));
    }
  }, [inspections]);
  useEffect(() => {
    if (images.length > 0) setMainImage(images[0]);
    if (videos.length > 0) setMainVideo(videos[0]);
  }, [images, videos]);

  return {
    images,
    videos,
    mainImage,
    setMainImage,
    mainVideo,
    setMainVideo,
  };
}
