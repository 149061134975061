import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const CellNoPadding = withStyles(() => ({
  head: {
    backgroundColor: '#1F3566',
    color: '#fff',
    padding: '0 !important',
    '&:first-child': { padding: '0 !important' },
    '&:last-child': { padding: '0 !important' },
  },
  body: {
    backgroundColor: '#FFFFFF00',
    padding: '0 !important',
    '&:first-child': { padding: '0 !important' },
    '&:last-child': { padding: '0 !important' },
  },
}))(TableCell);

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#1F3566',
    color: '#fff',
    padding: '0 !important',
  },
  body: {
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    border: '1px solid #1F3566',
    borderRadius: '10px',
  },
});

export function AreaTable({ data }) {
  const classes = useStyles();
  data = data.slice(1).concat(data.slice(0, 1));

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {['Mall', 'Office Tower', 'Total'].map(e => (
              <CellNoPadding align="center">{e}</CellNoPadding>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {data.map(e => <CellNoPadding align="center">{parseFloat(e.metre).toLocaleString()} m<sup>2</sup></CellNoPadding>)}
          </TableRow>
          <TableRow>
            {data.map(e => <CellNoPadding align="center">{parseFloat(e.feet).toLocaleString()} ft<sup>2</sup></CellNoPadding>)}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ProvisionTable({ data, isLoading }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            {['S/No', 'Office Tower', 'Provision', 'Remarks'].map(e => (
              <StyledTableCell align="center">{e}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow>
              <StyledTableCell width="30px" align="center">{idx + 1}</StyledTableCell>
              <StyledTableCell>{row['Service.name']}</StyledTableCell>
              <StyledTableCell width="25%" align="center">{isLoading ? <Skeleton variant="text" /> : row.Provision}</StyledTableCell>
              <StyledTableCell width="25%">{isLoading ? <Skeleton variant="text" /> : row.Remarks}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
