import React from 'react';
import {
  VictoryPie, VictoryLabel, VictoryChart, VictoryBar, VictoryAxis,
} from 'victory';

function DonutCharts(props) {
  const { data, active, setActive } = props;
  const percentage = Number(data.percent.replace(/[^0-9]/g, ''));

  const colorFill = (label) => {
    switch (label) {
      case 'Actual':
        return '#1F3566';
      case 'Budget':
        return '#60788B';
      default:
        return '#008FFB';
    }
  };

  return (
    <div
      className="paper finance-donut"
      style={{
        borderColor: active === data.selector && 'rgba(30, 52, 101, 1)',
        height: 'auto',
      }}
      onClick={() => active !== data.selector && setActive(data.selector)}
    >
      <p className="text-light" style={{ textAlign: 'center' }}>
        {data.title}
      </p>
      <h2 style={{ textAlign: 'center', fontWeight: 600 }}>{data.amount}</h2>
      <div style={{ position: 'relative' }}>
        <svg viewBox="0 0 800 400">
          <VictoryPie
            colorScale={['#1F3566', 'rgba(30, 52, 101, 0.15)']}
            cornerRadius={10}
            standalone={false}
            width={800}
            padding={30}
            height={400}
            data={data.donutData}
            innerRadius={140}
            labels={[]}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{
              fontSize: 64,
              fill: data.positive ? '#2ECCA9' : '#FF4560',
            }}
            x={400}
            y={184}
            text={percentage > 100 ? `${percentage - 100}%` : data.percent}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 32, fill: 'rgba(30, 52, 101, 0.65)' }}
            x={400}
            y={242}
            text="vs forecast"
          />
        </svg>
      </div>
      <p className="text-light" style={{ textAlign: 'center' }}>
        As of {data.asOf}
      </p>

      <div style={{ position: 'relative' }}>
        <VictoryChart
          width={600}
          height={250}
          padding={{
            left: 120, right: 60, top: 60, bottom: 60,
          }}
        >
          <VictoryBar
            style={{
              data: {
                fill: ({ datum }) => colorFill(datum.x),
              },
            }}
            cornerRadius={10}
            horizontal
            barWidth={52}
            data={data.barData}
            labels={({ datum }) => (data.title === 'Yield' ? `${datum.y}%` : `RM ${datum.y.toLocaleString()}`)}
            sortKey="x"
            sortOrder="descending"
            animate
            labelComponent={(
              <VictoryLabel
                x={130}
                dx={10}
                style={{
                  fontFamily: 'Poppins',
                  fontSize: 28,
                  fill: '#FFFFFF',
                  fontWeight: 700,
                  textShadow: '1px 1px 5px black',
                  stroke: 'none',
                }}
                textAnchor="start"
              />
            )}
          />

          <VictoryAxis
            // tickValues specifies both the number of ticks and where
            // they are placed on the axis
            style={{
              tickLabels: {
                fontSize: 28,
                fill: 'rgba(30, 52, 101, 0.65)',
                padding: 20,
              },
              ticks: { size: 0 },
              axis: { stroke: 'transparent' },
              grid: { stroke: 'transparent' },
            }}
            offsetX={120}
            orientation="left"
          />
        </VictoryChart>
      </div>
    </div>
  );
}

export default DonutCharts;
