import React from 'react';
import queryString from 'query-string';
import { CircularProgress } from '@material-ui/core';
import ReactWordcloud from 'react-wordcloud';
import DropdownCategory from './components/DropdownCategory';

import useHook from './hook';

const wcProps = {
  options: {
    rotations: 3,
    fontFamily: 'Helvetica Neue',
    fontWeight: 'bold',
    fontSizes: [10, 40],
  },
  maxWords: 60,
};

export default (props) => {
  const {
    type, buildingId, categorySort, t,
  } = queryString.parse(window.location.search);
  const h = useHook({ ...props, buildingId, t });

  const category = [{ words: h.countCategory, title: 'Category' }];
  const issue = [{ words: h.countIssuesInSelectedCategory, title: 'Issues by' }];
  return (
    <>
      {type === 'category' ? (
        <>
          {category.map((w) => (
            <>
              {h.is_loading ? (
                <div className="flex-standard" style={{ height: 300 }}>
                  <CircularProgress />
                </div>
              ) : (
                <ReactWordcloud {...w} {...wcProps} />
              )}
            </>
          ))}
        </>
      ) : (
        <>
          {issue.map((w) => (
            <>
              {categorySort === 'true' && (
                <h3 style={{ display: 'flex', alignItems: 'center', margin: '4px 0' }}>
                  <DropdownCategory {...h} {...props} />
                </h3>
              )}
              {h.is_loading ? (
                <div className="flex-standard" style={{ height: 300 }}>
                  <CircularProgress />
                </div>
              ) : (
                <ReactWordcloud {...w} {...wcProps} />
              )}
            </>
          ))}
        </>
      )}
    </>
  );
};
