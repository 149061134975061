/* eslint-disable object-curly-newline */
import React, { useEffect } from 'react';
import { fabric } from 'fabric'; // this also installed on your project
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import uuid from 'react-uuid';
import Button from '@material-ui/core/Button';
import AnnotateIcon from '@Assets/Icons/AnnotateIcon';
import DeleteIcon from '@material-ui/icons/Delete';

const rectDefaultProps = {
  fill: 'transparent',
  strokeWidth: 4,
  stroke: '#03A398',
  hasControls: true,
  hasRotatingPoint: false,
  hasBorders: false,
  strokeUniform: true,
};

export default function AnnotateImage({
  mainImage, mainImageAnnotations, setMainImageAnnotations, setMainAnnotationId, setIsLoading, user,
}) {
  let userCanEditAnnotation = false;
  // osh
  if (mainImage['Inspection.InspectionCategoryId'] === 1) userCanEditAnnotation = !!user.can_edit_osh;
  if (mainImage['Inspection.InspectionCategoryId'] === 2) userCanEditAnnotation = !!user.can_edit_environment;
  if (mainImage['Inspection.InspectionCategoryId'] === 3) userCanEditAnnotation = !!user.can_edit_quality;
  const { editor, onReady } = useFabricJSEditor();
  const onAddRectangle = () => {
    const newId = uuid();
    const newObj = { id: newId, isNew: true };
    const newRectPostion = {
      top: editor.canvas.height * 0.5 - (150 / 2),
      left: editor.canvas.width * 0.5 - (150 / 2),
      width: 150,
      height: 150,
    };
    const rect = new fabric.Rect({ ...newObj, ...newRectPostion, ...rectDefaultProps });
    editor.canvas.add(rect);

    // add to state action bar
    setMainAnnotationId(newId);
    const newMainAnnotationList = [...mainImageAnnotations];
    newMainAnnotationList.push({ ...newObj, points: JSON.stringify(newRectPostion) });
    setMainImageAnnotations(newMainAnnotationList);
    editor.canvas.setActiveObject(rect);
    rect.on('selected', somethingChangeInCanvas);
  };
  const onDelRectangle = () => {
    const activeObject = editor.canvas.getActiveObject();
    const activeId = activeObject.get('id');
    console.log('Deleting this annotation', activeId, activeObject);
    editor.canvas.remove(activeObject);

    setMainAnnotationId();
    setMainImageAnnotations(prevVal => prevVal.filter(ann => ann.id !== activeId));
  };
  const somethingChangeInCanvas = (e) => {
    const activeId = e.target.get('id');
    console.log(`box id:${activeId} modified!!`);

    // new postion n size after modified
    const newPoints = {
      left: e.target.get('left'),
      top: e.target.get('top'),
      width: e.target.get('width') * e.target.get('scaleX'),
      height: e.target.get('height') * e.target.get('scaleY'),
      strokeWidth: e.target.get('strokeWidth'),
      strokeWidthUnscaled : e.target.get('strokeWidthUnscaled'),
    };

    console.log(`box new size: ${newPoints}`);
    setMainAnnotationId(activeId);
    setMainImageAnnotations(prevVal => prevVal.map(ann => (ann.id === activeId ? ({ ...ann, points: JSON.stringify(newPoints) }) : ann)));
  };
  useEffect(() => {
    if (!editor) return;
    fabric.Image.fromURL(mainImage.src, (oImg) => {
      console.log('original editor.canvas', editor.canvas);
      const wratio = 985 / oImg.width;
      const hratio = 738.75 / oImg.height;
      // lock ratio by canvas
      // const ratio = editor.canvas.width / oImg.width;
      editor.canvas.setHeight(oImg.height * hratio);
      console.log('adjusted editor.canvas', editor.canvas);
      editor.canvas.setBackgroundImage(oImg, editor.canvas.renderAll.bind(editor.canvas), { scaleX: wratio, scaleY: hratio });
      editor.canvas.remove(...editor.canvas.getObjects());
      for (let annIdx = 0; annIdx < mainImageAnnotations.length; annIdx++) {
        const { id, points } = mainImageAnnotations[annIdx];
        const {
          left, top, width, height,
        } = JSON.parse(points);
        const isLock = (userCanEditAnnotation) ? {} : { lockMovementX: true, lockMovementY: true };
        const rect = new fabric.Rect({ ...rectDefaultProps, id, left, top, width, height, rectDefaultProps, ...isLock });
        editor.canvas.add(rect);
        // set active for first index
        if (!annIdx) editor.canvas.setActiveObject(rect);
        rect.on('selected', somethingChangeInCanvas);
      }
      setIsLoading(false);
    });
    if (userCanEditAnnotation) editor.canvas.on('object:modified', somethingChangeInCanvas);
    // old style, fabric 2.0 has to bind directly with rect object for dom event
    // editor.canvas.on('object:selected', somethingChangeInCanvas);
  }, [mainImage]);
  return (
    <div className="draw-image-container">
      {userCanEditAnnotation && (
        <div style={{ position: 'absolute', zIndex: 1, left: 20, top: 40 }}>
          <Button variant="contained" color="primary" onClick={onAddRectangle}><AnnotateIcon /> &nbsp; Annotate</Button>
          &nbsp;
          {editor?.canvas.getActiveObject() && (
            <Button variant="contained" color="secondary" onClick={onDelRectangle}><DeleteIcon /> &nbsp; Remove</Button>)}
        </div>
      )}
      <FabricJSCanvas className="sample-canvas" onReady={onReady} />
    </div>
  );
}
