import React, { useState } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import BarChart from '@Components/Management/Finance/BarChart';
import DonutCharts from '@Components/Management/Finance/DonutCharts';
import HorizontalBarCharts from '@Components/Management/Finance/HorizontalBarCharts';
import PieChart from '@Components/Finance/PieChart';
import ActivePieCharts from '@Components/Management/Finance/ActivePieCharts';
import MainContentContainer from '@Components/MainContentContainer';
import Legend from '@Components/Legend';
import Navbar from '@Components/Management/Navbar';
import { yearComparisons } from '@Helpers';
import Hook from './hook';

function MgmtFinance(props) {
  const h = Hook(props);
  const [active, setActive] = useState('Revenue');

  return (
    <>
      <Navbar />
      <h1 style={{ fontWeight: 700, marginBottom: 10 }}>
        Total Financial <span style={{ opacity: 0.35, fontWeight: 600 }}>(as of {h.latest_updated})</span>
      </h1>
      <MainContentContainer>
        <Grid container spacing={3}>
          {h.data.map((item) => (
            <Grid item xs={12} md={6} lg={4}>
              <DonutCharts data={item} active={active} setActive={setActive} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <BarChart
              title={`Total ${active} of all building ${yearComparisons(2)[0]} vs ${yearComparisons(2)[1]}`}
              style={{ border: '2px solid rgba(30, 52, 101, 1)' }}
              legend={yearComparisons(2)}
              active={active}
              data={h.monthly_bar_data[active]}
              loading={h.isLoadingSto}
            />
          </Grid>
          <Grid item md={12} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ArrowLink title='Revenue' to='/dashboard/finance/revenue' />
                <div className='paper' style={{ padding: 20, marginBottom: 20 }}>
                  <HorizontalBarCharts
                    labelPrefix={'RM'}
                    width={800}
                    title={`Current Month (${h.latest_updated})`}
                    data={[
                      { x: 'Actual', y: h.revenue_actual },
                      { x: 'Budget', y: h.revenue_budget },
                    ]}
                  />
                  <HorizontalBarCharts
                    labelPrefix={'RM'}
                    width={800}
                    title={'Year to Date'}
                    data={[
                      { x: 'Actual', y: h.ytd_revenue_actual },
                      { x: 'Budget', y: h.ytd_revenue_budget },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <ArrowLink title='Operating Expenses' to='/dashboard/finance/operating-expenses' />
                <div className='paper' style={{ padding: 20 }}>
                  <HorizontalBarCharts
                    labelPrefix={'RM'}
                    width={800}
                    title={`Current Month (${h.latest_updated})`}
                    data={[
                      { x: 'Actual', y: h.expenses_actual },
                      { x: 'Budget', y: h.expenses_budget },
                    ]}
                  />
                  <HorizontalBarCharts
                    labelPrefix={'RM'}
                    width={800}
                    title={'Year to Date'}
                    data={[
                      { x: 'Actual', y: h.ytd_expenses_actual },
                      { x: 'Budget', y: h.ytd_expenses_budget },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <ArrowLink title="Collection" to="/dashboard/finance/collection" />
                <div className="paper" style={{ padding: 20 }}>
                  <HorizontalBarCharts
                    labelPrefix="RM"
                    width={800}
                    title={`Current Month (${h.latest_updated})`}
                    barWidth={55}
                    data={[
                      { x: 'Actual', y: h.collection_actual },
                    ]}
                  />
                  <HorizontalBarCharts
                    labelPrefix="RM"
                    width={800}
                    title="Year to Date"
                    barWidth={55}
                    data={[
                      { x: 'Actual', y: h.ytd_collection_actual },
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <ArrowLink title='Billing &amp; Collection' to='/dashboard/finance/billing-and-collection' margin={'0px'} />
                    {h.isBillingLoading && <CircularProgress disableShrink size={35} style={{ marginLeft: 20 }} />}
                  </div>
                  <Legend color='#008FFB' title='Billing' style={{ marginLeft: 60 }} />
                  <Legend color='#1F3566' title='Collection' />
                </div>
                <BarChart
                  // title={`${active} 2020 vs 2021`}
                  // style={{ border: '2px solid rgba(30, 52, 101, 1)' }}
                  // legend={["2020", "2021"]}
                  data={h.billing_collection}
                  active='BNC'
                  width={1200}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} lg={4}>
            <div style={{ display: 'flex' }}>
              <ArrowLink title='Service Charge' to='/dashboard/finance/service-charge' />
              {h.isServiceChargeLoading && <CircularProgress disableShrink size={35} style={{ marginLeft: 20 }} />}
            </div>
            <div className='paper' style={{ padding: 20 }}>
              <Grid container>
                <Grid item xs={12}>
                  <HorizontalBarCharts
                    width={800}
                    title={`Current Month (${h.latest_updated})`}
                    withFraction
                    labelPrefix={'RM'}
                    data={[
                      { x: 'Actual', y: h.sc_actual_total, },
                      { x: 'Budget', y: h.sc_budget_total, },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    Total Saving
                  </p>
                  <h1
                    style={{
                      textAlign: 'center',
                      color: '#2ECCA9',
                      fontWeight: 600,
                    }}
                  >
                    {`RM ${(parseInt(h.sc_budget_total - h.sc_actual_total)).toLocaleString()}`}
                  </h1>
                  <p style={{ textAlign: 'center', fontSize: 16 }} className='text-light'>
                    NLA: {h.NLA?.toLocaleString()} ft²
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginTop: 30 }}>
                    <PieChart data={h.service_charge_piechart} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default MgmtFinance;

function ArrowLink(props) {
  return (
    <h2
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: props.margin || 10,
        fontWeight: 600,
      }}
    >
      {props.title}
      {/* <Link to={props.to} className="arrow-link">
        <KeyboardArrowRight />
      </Link> */}
    </h2>
  );
}
