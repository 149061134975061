import Navbar from '@Components/IntegratedFacilities/Navbar';
import useHook from './hook';

export default function DasbFoodServices(props) {
  const h = useHook();
  return (
    <>
      <Navbar dax_role={props.user?.dax_role} />
      <h1 style={{ fontWeight: 700, marginBottom: 10 }}>
        DASB Food Services
      </h1>
    </>
  );
}
