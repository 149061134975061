import { Grid, CircularProgress } from '@material-ui/core';
import Table from '@Components/Table';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import Api, { endpoints } from '@Helpers/api';
import { useEffect, useState } from 'react';
import moment from 'moment';

const columns = [
  {
    name: 'Month',
    selector: 'month',
    sortable: true,
    right: false,
  },
  {
    name: 'Current Billing',
    selector: 'currentBilling',
    sortable: true,
    right: true,
  },
  {
    name: 'Total Collection',
    selector: 'totalCollection',
    sortable: true,
    right: true,
  },
  {
    name: 'Rebate/Rental Waiver',
    selector: 'waiver',
    sortable: true,
    right: true,
  },
  {
    name: 'Arrears (Active Tenants)',
    selector: 'arrears',
    sortable: true,
    right: true,
  },
  // {
  //   name: "Debtor Days",
  //   selector: "debtorDays",
  //   sortable: true,
  //   right: true,
  // },
];

const getMonth = (m) => moment(m, 'MM').format('MMMM').toUpperCase();
const localCurrency = (num) => num.toLocaleString('en-MY', { style: 'currency', currency: 'MYR' });

function BillingCollection({ building: { uuid } }) {
  const [isLoading, set_isLoading] = useState(false);
  const [billing_collection, set_billing_collection] = useState([]);
  useEffect(() => {
    set_isLoading(true);
    Api({
      endpoint: endpoints.getBilling(),
      data: { uuid },
      onSuccess: ({ data }) => {
        if (data.length > 0) {
          const graphData = data.map(e => ({
            month: `${getMonth(e.MONTH) } ${ e.YEAR}`,
            currentBilling: localCurrency(e.BILLING),
            totalCollection: localCurrency(e.COLLECTION),
            waiver: localCurrency(e.REBATE),
            arrears: localCurrency(e.ARREARS || 0),
          }));
          set_billing_collection(graphData);
        } else {
          set_billing_collection([]);
        }
        set_isLoading(false);
      },
      onFail: () => set_isLoading(false),
    });
  }, [uuid]);

  return (
    <>
      <MainContentNavbar to="/property/finance" text="BILLING &amp; COLLECTION" />
      <MainContentContainer>
        <Grid container>
          <Grid item xs={12}>
            {!isLoading ? <Table title="" columns={columns} data={billing_collection} />
              : <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>}
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default BillingCollection;
