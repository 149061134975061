import { useEffect, useState } from 'react';
import { endpoints } from '@Configs/endpoints';
import Api from '@Helpers/api';
import _ from 'lodash';

const numAddComma = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// hardcoded data
const trainingByCategory = [
  {
    name: 'Management',
    hour: 209.5,
    cost: 7809.84,
  },
  {
    name: 'Executive',
    hour: 1031.5,
    cost: 12907.35,
  },
  {
    name: 'Support',
    hour: 545.5,
    cost: 6249.17,
  },
];

const createDataForPie2 = (obj, type) => {
  const data = [];
  for (let i = 0; i < obj.length; i++) {
    data.push({
      x: i + 1,
      y: obj[i][type],
      label: [
        obj[i].name,
        `(${numAddComma(obj[i][type])})`,
      ],
    });
  }
  return data;
};

const pieCateTrainHour = createDataForPie2(trainingByCategory, 'hour');
const pieCateTrainCost = createDataForPie2(trainingByCategory, 'cost');

export default function Hook() {
  const [training, set_training] = useState([]);
  const [budget, set_budget] = useState([]);
  const [is_update, set_is_update] = useState(0);
  const [pieCompTrainHour, set_pieCompTrainHour] = useState([]);
  const [pieCompTrainCost, set_pieCompTrainCost] = useState([]);
  const [totalHour, set_totalHour] = useState(0);
  const [totalCost, set_totalCost] = useState(0);

  useEffect(() => {
    Api({
      endpoint: endpoints.getDevelopmentBudget(),
      onSuccess: ({ data }) => {
        if (data) {
          const bg = {};
          bg.BUDGET = numAddComma(data.BUDGET);
          bg.UTILIZATION = numAddComma(data.UTILIZATION);
          bg.PERCENT = parseFloat((data.PERCENT * 100).toPrecision(2));
          set_budget(bg);
        }
      },
      onFail: (err) => console.log(err),
    });

    Api({
      endpoint: endpoints.getTraining(),
      onSuccess: ({ data }) => {
        if (data) {
          set_training(data);
          set_is_update(is_update + 1);
        }
      },
      onFail: (err) => console.log(err),
    });
  }, []);

  useEffect(() => {
    const summed = (type) => _(training)
      .groupBy('COMPANY')?.map((obj, key) => {
        return {
          COMPANY: key,
          [type]: _.sumBy(obj, type),
        };
      })
      .value();

    const createDataForPie = (type) => summed(type).map((s, i) => {
      return { x: i + 1, y: s[type], label: [s.COMPANY, `(${numAddComma(parseFloat(s[type]?.toFixed(2)))})`] };
    });
    set_pieCompTrainHour(createDataForPie('HOURS'));
    set_pieCompTrainCost(createDataForPie('COST (RM)'));

    const totalOf = (key) => _.sumBy(training, t => t[key]);
    set_totalHour(numAddComma(totalOf('HOURS')));
    set_totalCost(numAddComma(totalOf('COST (RM)').toFixed(2)));
  }, [is_update]);

  return {
    training,
    pieCompTrainHour,
    pieCompTrainCost,
    totalHour,
    totalCost,
    budget,
    pieCateTrainHour,
    pieCateTrainCost,
  };
}
