import React from "react";
import { VictoryPie, VictoryLabel } from "victory";
import { yearComparisons } from '@Helpers';

const target = 383334;

function DonutChartPeopleCounting({ short_month_year = '', footfall: { Total = {} } }) {
  const traffic = Total[yearComparisons()[1]]?.IN || 0
  const percentage = ((traffic / target) - 1) * 100;
  const radial_data = [{ x: 1, y: traffic }, { x: 2, y: target - traffic }]
  // const 
  return (
    <>
      <p style={{ textAlign: "center", marginTop: 20 }}>Traffic {short_month_year}</p>
      <h2 style={{ fontWeight: 400, textAlign: "center" }}>{traffic.toLocaleString()}</h2>
      <div style={{ position: "relative" }}>
        <svg viewBox="0 0 400 400">
          <VictoryPie
            colorScale={["#1F3566", "rgba(30, 52, 101, 0.15)"]}
            cornerRadius={10}
            standalone={false}
            width={400}
            height={400}
            data={radial_data}
            innerRadius={130}
            labels={[]}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 64, fill: percentage > 0 ? '#2ecca9' : "#FF4560" }}
            x={200}
            y={164}
            text={`${percentage.toFixed(2)}%`}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 32, fill: "rgba(30, 52, 101, 0.65)" }}
            x={200}
            y={218}
            text="vs target"
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 32, fill: "rgba(30, 52, 101, 0.65)" }}
            x={200}
            y={250}
            text={target.toLocaleString()}
          />
        </svg>
      </div>
    </>
  );
}

export default DonutChartPeopleCounting;
