import React from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryGroup, VictoryLabel, VictoryTheme } from 'victory';
import Legend from '../../Legend';
import { CircularProgress } from '@material-ui/core';

import data from './BarChartData.json';
import dataRevenue from './BarChartDataRevenue.json';
import dataExpenses from './BarChartDataExpenses.json';
import dataNPI from './BarChartDataNPI.json';
import dataYield from './BarChartDataYield.json';
import dataBNC from './BarChartDataBNC.json';

const colors = ['#008FFB', '#1F3566'];

function BarChart(props) {
  const dataSelector = {
    'Total Revenue of all building': dataRevenue,
    'Total Expenses of all building': dataExpenses,
    'Total NPI of all building': dataNPI,
    Yield: dataYield,
    BNC: dataBNC,
  };

  console.log(`props.active`, dataSelector[props.active]);
  return (
    <div
      className='paper'
      style={{
        padding: 20,
        ...props.style,
      }}
    >
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <h2 style={{ fontWeight: 600 }}>{props.title}</h2>
        {props.loading && <CircularProgress disableShrink style={{ width: 35, height: 35, marginLeft: 35 }} />}
        {props.legend &&
          props.legend.map((item, index) => (
            <Legend key={index} color={colors[index]} title={item} style={index === 0 && { marginLeft: 60 }} />
          ))}
      </div>
      <VictoryChart
        // domainPadding will add space to each side of VictoryBar to
        // prevent it from overlapping the axis
        domainPadding={{ x: [60, 60], y: [0, 20] }}
        width={props.width || 1800}
        height={props.height}
        theme={VictoryTheme.material}
      >
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          tickFormat={(x) => x}
          style={{
            tickLabels: {
              fontSize: 16,
              padding: 10,
              fontWeight: 700,
              fill: '#1F3566',
            },
            ticks: { size: 0 },
            axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
            grid: { stroke: 'transparent' },
          }}
          offsetY={50}
          orientation="bottom"
        />
        <VictoryAxis
          dependentAxis
          label={props.active === 'Yield' ? '%' : 'RM'}
          axisLabelComponent={<VictoryLabel angle={0} verticalAnchor='start' dy={-150} dx={0} />}
          style={{
            axis: { stroke: '#FFFFFF' },
            tickLabels: {
              fontSize: 14,
              padding: -20,
              fill: 'rgba(30, 52, 101, 0.65)',
            },
            ticks: { stroke: '#FFFFFF', size: 0 },
            grid: { stroke: 'rgba(30, 52, 101, 0.15)', strokeDasharray: false },
          }}
          crossAxis={false}
        />
        <VictoryGroup offset={25} colorScale={colors}>
          <VictoryBar
            // data={dataSelector[props.active]}
            data={props.data}
            x='month'
            y={props.active === 'BNC' ? 'billing' : 'prevYear'}
            barWidth={25}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            cornerRadius={3}
            style={{ labels: { fontSize: 9 } }}
            labels={({ datum }) => Math.floor(datum._y)?.toLocaleString()}
          />
          <VictoryBar
            // data={dataSelector[props.active]}
            data={props.data}
            x='month'
            y={props.active === 'BNC' ? 'collection' : 'currentYear'}
            barWidth={25}
            animate={{
              duration: 2000,
              onLoad: { duration: 2000 },
            }}
            cornerRadius={3}
            style={{ labels: { fontSize: 9 } }}
            labels={({ datum }) => Math.floor(datum._y)?.toLocaleString()}
          />
        </VictoryGroup>
      </VictoryChart>
    </div>
  );
}

export default BarChart;
