import { ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";

import "react-calendar/dist/Calendar.css";
import "./dropdown.css";
import Calendar from "react-calendar";
import { format } from "date-fns";

export default function DateDropdown({
  w,
  p,
  m,
  onChange,
  selected,
  variant = "",
}) {
  const [active, setActive] = useState(false);
  const [title, setTitle] = useState("Select date");
  const [view, setView] = useState("decade");

  const handleDate = (date) => {
    let newDate = format(date, "dd/MM/yyyy");
    onChange(newDate);
    setActive(false);
  };

  return (
    <div className={`dropdown ${variant}`} style={{ minWidth: w, margin: m }}>
      <div
        className={`flex flex-standard row middle dropdown-button ${active && "active"}`}
        style={{
          padding: p, justifyContent: 'space-between',
        }}
        onClick={() => {
          setActive(!active);
          setTitle("Select year");
          setView("decade");
          if (active) {
            onChange("");
            setTitle("Select date");
            setActive(false);
          }
        }}
      >
        <p className={selected && !active ? "active" : ""}>
          {selected && !active ? selected : title}
        </p>
        <ExpandMore />
      </div>
      <div className={`dropdown-container ${active && "active"}`}>
        <Calendar
          view={view}
          minDetail={view}
          formatMonth={(locale, date) => format(date, "MMM")}
          formatMonthYear={(locale, date) => format(date, "MMM yy")}
          calendarType="US"
          onClickYear={() => {
            setTitle("Select month");
            setView("year");
          }}
          onClickMonth={() => {
            setTitle("Select day");
            setView("month");
          }}
          onClickDay={handleDate}
          value={null}
          showNavigation={false}
        />
      </div>
    </div>
  );
}
