import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import { formatTime } from '@Helpers';
import status_calloff_fill from '@Assets/Icons/status_calloff_fill.svg';
import status_completed_fill from '@Assets/Icons/status_completed_fill.svg';
import status_inprogress_fill from '@Assets/Icons/status_inprogress_fill.svg';
import status_onhold_fill from '@Assets/Icons/status_onhold_fill.svg';
import status_open_fill from '@Assets/Icons/status_open_fill.svg';
import status_pendingreview_fill from '@Assets/Icons/status_pendingreview_fill.svg';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 30,
    borderRadius: 5,
    zIndex: 1,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#0f0',
  },
}))(LinearProgress);

export function ProgressBarCol({ completed_task, total_task }) {
  const value = (completed_task / total_task) * 100;
  return (
    <div style={{ width: '120px', position: 'relative' }}>
      <div className='flex-standard' style={{ position: 'absolute', zIndex: 2, width: '100%', height: '100%' }}>
        {`${completed_task}/${total_task} (${value.toFixed(0)}%)`}
      </div>
      <div>
        <BorderLinearProgress variant='determinate' value={value} />
      </div>
    </div>
  );
}

export function StatusCol({ status, od = false }) {
  const type = {
    Completed: { src: status_completed_fill },
    Open: { src: status_open_fill },
    'Call Off': { src: status_calloff_fill },
    'Pending Review': { src: status_pendingreview_fill },
    'In Progress': { src: status_inprogress_fill },
    'On Hold': { src: status_onhold_fill },
  };

  return (
    <div>
      <img src={type[status]?.src} alt='' style={{ width: '100%' }} />
      {od && <p>Overdue</p>}
    </div>
  );
}

export const FormattedDate = ({ time }) => {
  return time ? (
    <div>
      <p>{formatTime(time, 'D/MM/YYYY')}</p>
      <p className='text-light'>{formatTime(time, 'h:mm A')}</p>
    </div>
  ) : (
    <>N/A</>
  );
};
