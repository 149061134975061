import { useState } from 'react';
import {
  Grid, IconButton, Chip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MainContentContainer from '@Components/MainContentContainer';
import MaterialTable from '@Components/MaterialTable';
import HighlightTabs from '@Components/HighlightTabs';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareIcon from '@material-ui/icons/Share';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { truncateString } from '@Helpers';
import Navbar from '../components/Navbar';
import Hook from './hook';
import GenerateReport from './GenerateReport';
import DeleteReport from './DeleteReport';
import DeleteStoredReport from './DeleteStoredReport';

const columns = [
  {
    name: 'Report ID',
    selector: 'id',
    align: 'left',
    minWidth: 70,
  },
  {
    name: 'Report generated',
    selector: 'createdAt',
    align: 'center',
    minWidth: 220,
  },
  {
    name: 'Report Type',
    selector: 'options',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'Share',
    selector: 'share',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'Download',
    selector: 'download',
    align: 'center',
    minWidth: 80,
  },
  {
    name: 'Delete',
    selector: 'delete',
    align: 'center',
    minWidth: 60,
  },
];

const columnsSR = [
  {
    name: 'Project',
    selector: 'project_name',
    align: 'left',
    minWidth: 80,
  },
  {
    name: 'File',
    selector: 'file_path',
    align: 'left',
    minWidth: 300,
  },
  {
    name: 'Delete',
    selector: 'delete',
    align: 'center',
    minWidth: 60,
  },
];

const tabslist = [
  { label: 'GENERATED REPORT', value: 'generated' },
  { label: 'STORED REPORT', value: 'stored' },
];

export default function Report(props) {
  const h = Hook(props);
  const [tab, setTab] = useState('generated');
  const mail = (e) => 'mailto:?subject=&body=' + `${process.env.REACT_APP_ENDPOINT_URL}report/${e}`;

  return (
    <MainContentContainer>
      <div className="flex space-between">
        <Navbar
          to="/project/"
          text="Report" 
          subtext={truncateString(props.filtered_projects[props.selected_project]?.name, (props.filtered_projects[props.selected_project]?.name)?.length > 30 ? 28 : 30 )}
        />
        {props.project
        && props.project.name !== 'View All Projects'
        && tab === 'generated' ? (<GenerateReport project={props.project} callback={(data) => h.onSave(data)} />) : null}
      </div>
      <HighlightTabs items={tabslist} tab={tab} setTab={setTab} />
      <Grid item xs={12} style={{ padding: 20 }}>
        {tab === 'generated' ? (
          <MaterialTable
            tableHead
            columns={columns}
            tableData={h.reports.map((x) => ({
              ...x,
              createdAt: (
                <>
                  <div>{moment(x.createdAt).format('DD/MM/YYYY')}</div>
                  <div>{moment(x.createdAt).format('hh:mmA')}</div>
                </>
              ),
              options: (
                <div
                  className="flex"
                  style={{
                    maxWidth: 'auto',
                    flexWrap: 'wrap',
                    transform: 'scale(0.85)',
                    justifyContent: 'center',
                  }}
                >
                  {x.options
                    ? x.options.split(',').map((chip) => (
                      <div style={{ padding: 2 }}>
                        <Chip
                          label={chip}
                          style={{
                            color: 'rgb(30, 52, 101)',
                            fontWeight: 600,
                            backgroundColor: 'rgba(30, 52, 101, 0.1',
                          }}
                        />
                      </div>
                    ))
                    : ''}
                </div>
              ),
              download: (
                <Link
                  to={{
                    pathname: `${process.env.REACT_APP_ENDPOINT_URL}report/${x.id}`,
                  }}
                  target="_blank"
                >
                  <IconButton
                    style={{
                      color: 'white',
                      background: '#1e3465',
                      width: 36,
                      height: 36,
                    }}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Link>
              ),
              delete: (<DeleteReport {...h} user={props.user} row={x} />),
              share: (
                <a
                  href={mail(x.id)}
                  title="Share viaEmail"
                >
                  <IconButton
                    style={{
                      color: 'white',
                      background: '#1e3465',
                      width: 36,
                      height: 36,
                    }}
                  >
                    <ShareIcon />
                  </IconButton>
                </a>
              ),
            }))}
            tableMinWidth={300}
            tableMaxHeight={900}
          />
        ) : (
          <MaterialTable
            tableHead
            columns={columnsSR}
            tableData={h.storedReports.map((x) => {
              const eachfileName = x.original_name.split(',');
              const eachfile = x.file_path.split(',');
              return {
                ...x,
                project_name: x.Project.name,
                file_path:
                  x.file_path === ''
                    ? 'N/A'
                    : eachfile.map((file, idx) => (
                      <>
                        <Link to={{ pathname: `${file}` }} target="_blank">
                          <IconButton
                            style={{
                              color: 'white',
                              background: '#1e3465',
                              width: 36,
                              height: 36,
                            }}
                          >
                            <InsertDriveFileIcon />
                          </IconButton>{' '}
                          {eachfileName[idx]}
                        </Link>
                        <br />
                      </>
                    )),
                delete: (<DeleteStoredReport {...h} user={props.user} row={x} />),
              };
            })}
            tableMinWidth={300}
            tableMaxHeight={900}
          />
        )}
      </Grid>
    </MainContentContainer>
  );
}
