import React, { useEffect, useState } from 'react';
import {
  Grid, Dialog, DialogTitle, DialogContent, DialogActions, OutlinedInput, Box, FormControl, InputAdornment, CircularProgress,
} from '@material-ui/core';
import Api, { endpoints } from '@Helpers/api';
import { Add, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import UploadIcon from '@Components/UploadIcon';
import SelectYear from '@Components/SelectYear';
import SelectMonth from '@Components/SelectMonth';

const titles = [
  'EMPLOYEE EARNING',
  'EMPLOYER CONTRIBUTION',
  'TOTAL OVERTIME(HOUR)',
  'TOTAL OVERTIME(RM)',
];

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '17em', marginRight: '2em' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
}));
export default (props) => {
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [input, setInput] = useState([]);
  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);
  const classes = useStyles();
  const [buildings, setBuildings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Api({
      endpoint: endpoints.getEmolumentBuilding(),
      onSuccess: data => setBuildings(data.data.map(d => d['DEPARTMENT/BUILDING'])),
    });
  }, []);

  useEffect(() => {
    if (!year) return;
    if (!month) return;
    setIsLoading(true);
    Api({
      endpoint: endpoints.getEmolumentRaw(),
      data: {
        YEAR: year,
        MONTH: month,
      },
      onSuccess: ({ data }) => {
        if (!!data.length) {
          const initialRawData = buildings.map(b => {
            const d = { 'DEPARTMENT/BUILDING': b };
            const foundB = data.find(cI => cI['DEPARTMENT/BUILDING'] === b);
            if (!foundB) {
              titles.forEach(t => d[t] = 0);
              return d;
            }
            d['DEPARTMENT/BUILDING'] = foundB['DEPARTMENT/BUILDING'];
            d['EMPLOYEE EARNING'] = foundB['EMPLOYEE EARNING'];
            d['EMPLOYER CONTRIBUTION'] = foundB['EMPLOYER CONTRIBUTION'];
            d['TOTAL OVERTIME(HOUR)'] = foundB['TOTAL OVERTIME(HOUR)'];
            d['TOTAL OVERTIME(RM)'] = foundB['TOTAL OVERTIME(RM)'];
            return d;
          });
          setInput(initialRawData);
        } else {
          initializeInput();
        }
        setIsLoading(false);
      },
    });
  }, [year, month]);

  useEffect(() => {
    initializeInput();
  }, [buildings]);

  const initializeInput = () => {
    setInput(buildings.map(b => {
      const d = { 'DEPARTMENT/BUILDING': b };
      titles.forEach(t => d[t] = 0);
      return d;
    }));
  };

  const handleGenerate = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.updateEmolument(year, month),
      data: { input },
      onSuccess: () => {
        setIsLoading(false);
        props.setJustUpdated(props.justUpdated + 1);
        toast('success', 'Successfully update Emolument'); // eslint-disable-line no-undef
        handleClose();
      },
      onFail: () => {
        setIsLoading(false);
        props.setJustUpdated(props.justUpdated + 1);
        toast('error', 'Fail update Emolument'); // eslint-disable-line no-undef
      },
    });
  };

  const handleChangeInput = (building, title, value) => {
    setInput(inp => inp.map(i => {
      if (i?.['DEPARTMENT/BUILDING'] === building) {
        value = value.replaceAll(',', '');
        value = value.replaceAll(' ', '');
        i[title] = Number(value);
      }
      return i;
    }));
  };

  return (
    <>
      <button
        onClick={handleClickOpen}
        className="blue-button flex-standard"
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          margin: 0,
        }}
      >
        <UploadIcon />
        <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>Upload Data</p>
      </button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <DialogTitle>
          <Grid container>
            <h1>Manual Upload Data</h1>
            <Grid item container xs={3} spacing={1} style={{ marginLeft: 50 }}>
              <Grid item xs={6}>
                <SelectYear onChange={setYear} year={year} />
              </Grid>
              <Grid item xs={6}>
                <SelectMonth onChange={setMonth} month={month} />
              </Grid>
            </Grid>
            <Close className={classes.closeBtn} onClick={handleClose} style={{ marginLeft: 'auto' }} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '0 20px', height: '300px', color: '#1e3465' }}>
            <Grid container spacing={3}>
              <Grid item xs={4} />
              {titles.map(t => <Grid item xs={2} children={<b>{t}</b>} />)}
            </Grid>
            {isLoading
              ? <div className="flex-standard" style={{ height: 300 }}><CircularProgress /></div>
              : buildings.map(b => (
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <div style={{ fontWeight: 'bold' }}>{b}</div>
                  </Grid>
                  {titles.map(t => {
                    const item = input?.find(i => i?.['DEPARTMENT/BUILDING'] === b);
                    return (
                      <Grid item xs={2}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <OutlinedInput
                            value={item?.[t] ?? 0}
                            onChange={e => handleChangeInput(b, t, e.target.value)}
                            startAdornment={<InputAdornment position="start" children={t.toLowerCase().includes('hour') ? 'Hour' : 'RM'} />}
                            labelWidth={0}
                            type="number"
                          />
                        </FormControl>
                      </Grid>
                    );
                  })}
                </Grid>
              ))}
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: '0 3em', width: '100%', display: 'flex' }}>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={handleGenerate}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                <Add />
                {' '}
                Save Data
              </h1>
            </Box>
            <Box
              component="button"
              className="cancel-button"
              onClick={handleClose}
              style={{
                borderRadius: '6px',
                width: '120px',
              }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>

          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
