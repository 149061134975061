import React, { useEffect, useState } from 'react';
import {
  Grid, Dialog, DialogTitle, DialogContent, DialogActions, OutlinedInput, Box, FormControl, Select, MenuItem, TextField,
} from '@material-ui/core';
import Api, { endpoints } from '@Helpers/api';
import { Add, Close, Upload } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import UploadIcon from '@Components/UploadIcon';
import NumberFormat from 'react-number-format';
import Dropzone from '@Components/DropzoneBox/v2';
import moment from 'moment';

const inputTemplate = {
  id: 0,
  contractService: '',
  currentCharge: '',
  serviceCharge: '',
  remarks: '',
  expiryAt: '',
  service: '',
};

const titles = [
  { label: 'Service Type', col: 1 },
  { label: 'Vendor', col: 1 },
  { label: 'Expiring Date', col: 1 },
  { label: 'Current Charge (RM)', col: 1 },
  { label: 'Service Charges', col: 1 },
  { label: 'Remarks', col: 1 },
];

const useStyles = makeStyles(() => ({
  smallBtn: { borderRadius: '6px', margin: 0 },
  blueBtn: { borderRadius: '6px', width: '17em', marginRight: '2em' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
}));

export default (props) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(props.serviceContracts);
  const [files, setFiles] = useState([]);
  const classes = useStyles();

  const handleClose = () => {
    setInput([]);
    setFiles([]);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setInput(props.serviceContracts);
    setOpen(true);
  };

  const handleSave = () => {
    if (!props.building?.id) return toast('error', 'Building Id unavailable');
    Api({
      endpoint: endpoints.createContractService(props.building.id),
      data: { input },
      files,
      onSuccess: (response) => {
        setTimeout(() => {
          toast('success', 'Successfully Upload Service Contract ');
          setFiles([]);
          handleClose();
          props.set_isSaved(prev => prev + 1);
        }, 3000);
      },
      onFail: (error) => {
        toast('error', error);
        console.log('error getting service contracts', error);
        setFiles([]);
        handleClose();
      },
    });
  };

  const handleAddInput = (i) => {
    const temp = [...input];
    temp.splice(i, 0, inputTemplate);
    setInput(temp);
  };

  const handleChangeInput = (i, name, value) => {
    const temp = [...input];
    temp.splice(i, 1, { ...temp[i], [name]: value, BuildingId: props.building?.id });
    setInput(temp);
  };

  const handleRemoveInput = (index) => setInput(i => i.filter((_, idx) => idx !== index));

  return (
    <>
      <button
        onClick={handleClickOpen}
        className="blue-button flex-standard"
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          marginRight: 20,
        }}
      >
        <UploadIcon />
        <p style={{
          color: '#FFFFFF', fontSize: 14, fontWeight: 600, cursor: 'pointer',
        }}
        >Upload CSV
        </p>
      </button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <DialogTitle>
          <Grid container justify="space-between">
            <h1>Upload CSV - Service Contracts</h1>
            <Close className={classes.closeBtn} onClick={handleClose} />
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: 500 }}>
          <Dropzone type="csv" height="100%" files={files} setFiles={setFiles} />
        </DialogContent>
        <DialogActions>
          <div style={{ padding: '0 3em', width: '100%', display: 'flex' }}>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={handleSave}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`} style={{ cursor: 'pointer' }}>
                Upload Data
              </h1>
            </Box>
            <Box
              component="button"
              className="cancel-button"
              onClick={handleClose}
              style={{
                borderRadius: '6px',
                width: '120px',
              }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>

          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}
