import { useState } from 'react';
import {
  Grid, TextField, Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core';
import Button from './Button';
import Dropzone from './Dropzone';

export default function CloudProcessing() {
  const [files, setFiles] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));
  const [imageryname, setImageryname] = useState();
  const [imagerytype, setImagerytype] = useState('2D');
  const handleImageryDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleImageryNameChange = (event) => {
    setImageryname(event.target.value);
  };
  const handleImageryTypeChange = (event) => {
    setImagerytype(event.target.value);
  };
  return (
    <Grid container>
      <Grid container item xs={12} spacing={3}>
        <Grid item md={12} lg={7}>
          <Dropzone files={files} setFiles={setFiles} />
        </Grid>
        <Grid container item md={12} lg={5} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item md={12} lg={12} style={{ flex: 1 }}>
            <p>Total Images to Upload: {files.length}</p>
          </Grid>
          <Grid container item md={12} lg={12} style={{ flex: 2 }}>
            <Grid item md={12} lg={6}>
              <p>Date Progress</p>
              <TextField
                type="date"
                variant="outlined"
                size="small"
                defaultValue="2017-05-24"
                style={{ width: '100%' }}
                value={selectedDate}
                onChange={handleImageryDateChange}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <p>Imagery Name</p>
              <TextField
                id="outlined-size-small"
                placeholder="Project An Imagery"
                variant="outlined"
                size="small"
                value={imageryname}
                onChange={handleImageryNameChange}
              />
            </Grid>
          </Grid>
          <Grid container item md={12} lg={12} style={{ flex: 3 }}>
            <Grid item md={12} lg={6}>
              <p>Output</p>
              <RadioGroup value={imagerytype} onChange={handleImageryTypeChange}>
                <FormControlLabel value="2D" control={<Radio />} label="2D Imagery" />
                <FormControlLabel value="3D" control={<Radio />} label="3D" />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid item md={12} lg={12} style={{ flex: 1 }}>
            <p>Processing Fee: RM 4,321</p>
          </Grid>
          <Grid item md={12} lg={12} style={{ flex: 1 }}>
            <Button
              variant="contained"
              onClick={() => console.log('upload click')}
              style={{
                margin: '15px 0px',
                padding: '4px 20px',
                opacity: files.length > 0 ? 1 : 0,
              }}
              disabled={files.length === 0}
            >
              UPLOAD DATA
            </Button>
          </Grid>
          <div style={{ flex: 6 }} />
        </Grid>
      </Grid>
    </Grid>
  );
}
