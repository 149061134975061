import React from 'react';
import { VictoryLabel, VictoryBar, VictoryAxis, VictoryChart } from 'victory';

function HorizontalBarChart(props) {
  var data = props.data;
  if (props.excludeRegex) data = data.filter(({ x }) => !props.excludeRegex.some((regex) => regex.test(x)));
  if (props.includeRegex) data = data.filter(({ x }) => props.includeRegex.some((regex) => regex.test(x)));
  return (
    <>
      <div style={{ position: 'relative' }}>
        <VictoryChart
          width={2200}
          height={props.height}
          domainPadding={{ y: [0, 20] }}
          padding={{ left: 600, right: 60, top: 60, bottom: 60 }}
        >
          <VictoryBar
            style={{
              data: {
                fill: '#008FFB',
              },
              labels: {
                fontSize: 15,
              },
            }}
            cornerRadius={5}
            horizontal
            barRatio={props.ratio}
            data={data}
            labels={({ datum }) => datum.y.toLocaleString()}
            sortKey='y'
            sortOrder='ascending'
            animate
            labelComponent={
              <VictoryLabel
                x={600}
                style={{
                  fontSize: 24,
                  fill: '#FFFFFF',
                }}
              />
            }
            standalone={false}
            width={2200}
            height={600}
          />

          <VictoryAxis
            // tickValues specifies both the number of ticks and where
            // they are placed on the axis
            style={{
              tickLabels: { fontSize: 24, fill: '#1F3566', padding: 40 },
              ticks: { size: 0 },
              axis: { stroke: 'transparent' },
              grid: { stroke: 'transparent' },
            }}
          />
        </VictoryChart>
      </div>
    </>
  );
}

export default HorizontalBarChart;
