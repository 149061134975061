import { useEffect, useState } from 'react';
import { endpoints, URL } from '@Configs/endpoints';
import Api from '@Helpers/api';
import moment from 'moment';
import _ from 'lodash';

export default function Hook({ buildings, selectedBuilding }) {
  const uuid = buildings[selectedBuilding]?.uuid;
  const [OccupancyRate, set_OccupancyRate] = useState();
  const [SalesTurnOver, set_SalesTurnOver] = useState([]);
  const [TotalSalesTurnOver, set_TotalSalesTurnOver] = useState([]);
  const [TotalSalesThisYear, set_TotalSalesThisYear] = useState(80042260);
  const [PercentageSalesThisYear, set_PercentageSalesThisYear] = useState(-66);
  const [RenewalOfTenancySummary, set_RenewalOfTenancySummary] = useState({});
  const [RenewalOfTenancyDetail, set_RenewalOfTenancyDetail] = useState({});
  const [tmb, set_Tmb] = useState({});
  const [tla, set_Tla] = useState({});
  const [debtorAging, set_debtorAging] = useState([]);
  const [isSaved, set_isSaved] = useState(0);

  useEffect(() => {
    if (!uuid) return;
    const body = { data: { building_uuid: uuid, YEAR: moment().format('YYYY') } };
    Api({
      ...body,
      endpoint: endpoints.getOccupancy(),
      onSuccess: (response) => set_OccupancyRate(response.data[0]),
      onFail: () => set_OccupancyRate(),
    });
    Api({
      ...body,
      endpoint: endpoints.getDebtoraging(),
      onSuccess: (response) => set_debtorAging(response.data),
    });
    Api({
      ...body,
      endpoint: endpoints.getTmb(),
      onSuccess: (response) => {
        const groupedV = _.groupBy(response.data, 'CATEGORY');
        delete groupedV.null;
        set_Tmb(groupedV);
      },
    });
    Api({
      ...body,
      endpoint: endpoints.getTla(),
      onSuccess: (response) => set_Tla(_.groupBy(response.data, 'CATEGORY')),
    });

    Api({
      ...body,
      endpoint: endpoints.getTenancy(),
      onSuccess: (response) => {
        const groupedV = _.groupBy(response.data, 'STATUS');
        delete groupedV.null;
        const RenewalOfTenancy = {};
        Object.keys(groupedV).map(eachstatus => RenewalOfTenancy[eachstatus] = {
          AREA: _.sumBy(groupedV[eachstatus], (o) => o.AREA),
          RENTAL: _.sumBy(groupedV[eachstatus], (o) => o.RENTAL),
        });
        set_RenewalOfTenancySummary(RenewalOfTenancy);
        set_RenewalOfTenancyDetail(groupedV);
      },
    });

    Api({
      endpoint: endpoints.getSalesturnover(),
      data: { uuid },
      onSuccess: (response) => {
        const sortedV = _.groupBy(_.sortBy(response.data, ['YEAR', 'MONTH']), 'YEAR');
        const latestYear = sortedV[Object.keys(sortedV).slice(-1)];
        const prevYear = sortedV[Object.keys(sortedV).slice(-2, -1)];
        const latestYearMonthly = _.groupBy(latestYear, 'MONTH');
        const prevYearMonthly = _.groupBy(prevYear, 'MONTH');

        const barChartData = Object.keys(latestYearMonthly).map(m => ({
          x: moment(m, 'MM').format('MMM'),
          y1: latestYearMonthly[m].reduce((a, b) => a + b.GROSS, 0),
          y2: prevYearMonthly[m].reduce((a, b) => a + b.GROSS, 0),
        }));

        let currentTotal = 0;
        if (latestYear) {
          currentTotal = _.sumBy(latestYear, (o) => o.GROSS);
          set_TotalSalesThisYear(currentTotal.toLocaleString());
        }
        let prevTotal = 0;
        if (prevYear) {
          prevTotal = _.sumBy(prevYear, (o) => o.GROSS);
        }
        set_SalesTurnOver(barChartData);
        set_TotalSalesTurnOver([{ x: 1, y: currentTotal }, { x: 2, y: prevTotal - currentTotal }]);
        set_PercentageSalesThisYear(prevTotal === 0 ? 0 : (currentTotal / prevTotal * 100).toFixed(2));
      },
      onFail: () => set_OccupancyRate(),
    });
  }, [uuid, isSaved]);

  return {
    tla,
    tmb,
    debtorAging,
    RenewalOfTenancyDetail,
    RenewalOfTenancySummary,
    SalesTurnOver,
    TotalSalesTurnOver,
    TotalSalesThisYear,
    PercentageSalesThisYear,
    OccupancyRate,
    url: URL,
    set_isSaved,
  };
}
