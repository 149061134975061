import { useEffect, useState } from 'react';
import { endpoints } from '@Configs/endpoints';
import Api from '@Helpers/api';
import _ from 'lodash';

export default function Hook() {
  const [manpowers, set_manpowers] = useState([]);
  const [turnovers, set_turnovers] = useState([]);
  const [is_update, set_is_update] = useState(0);
  const [female, set_female] = useState(0);
  const [male, set_male] = useState(0);
  const [pie_manpower, set_pie_manpower] = useState([]);
  const [bargraph, set_bargraph] = useState([]);
  const [businessFunctions, set_businessFunctions] = useState([]);

  const comp = {
    'DAMANSARA ASSETS SDN BHD': 'Damansara\nAssets Sdn Bhd',
    'DASB PROPERTY MANAGEMENT SDN BHD': 'DASB Property\nManagement\nSdn Bhd',
    'DASB PARKING SDN BHD': 'DASB Parking\nSdn Bhd',
    'DASB GROUP': 'DASB Group',
  };
  const OEcomp = {
    'DAMANSARA ASSETS SDN BHD': 'DASB',
    'DASB PROPERTY MANAGEMENT SDN BHD': 'DASB PM SB',
    'DASB PARKING SDN BHD': 'DASB Parking SB',
  };

  useEffect(() => {
    Api({
      endpoint: endpoints.getManpowers(),
      onSuccess: ({ data }) => {
        if (data) {
          set_manpowers(data);
          set_is_update(is_update + 1);
        }
      },
      onFail: (err) => {
        console.log(err);
      },
    });

    Api({
      endpoint: endpoints.getTurnovers(),
      onSuccess: ({ data }) => {
        if (data) {
          set_turnovers(
            data.map(d => ({ x: comp[d.COMPANY], y: parseFloat((d['TURNOVER RATE'] * 100).toFixed(2)) })),
          );
        }
      },
      onFail: (err) => console.log(err),
    });
  }, []);

  useEffect(() => {
    const groupBy = (key) => _.groupBy(manpowers, (e) => e[key]);
    const percentage = (n, total) => parseInt(((n / total) * 100).toFixed(0));

    set_female(groupBy('GENDER').Female?.length ?? 0);
    set_male(groupBy('GENDER').Male?.length ?? 0);

    const gByComp = groupBy('COMPANY');
    const compNames = Object.keys(gByComp);
    set_pie_manpower(
      compNames.map((name, i) => ({
        x: i + 1,
        y: gByComp[name].length,
        label: [name, `${percentage(gByComp[name].length, manpowers.length)}% (${gByComp[name].length})`],
      })),
    );

    const gByBF = groupBy('BUSINESS FUNCTION');
    const BFList = Object.keys(gByBF);
    let sortedList = [];

    set_bargraph(
      compNames.map((name) => {
        const compByBF = _.groupBy(gByComp[name], (e) => e['BUSINESS FUNCTION']);

        const ans = { x: OEcomp[name] ?? name };
        BFList.forEach((bf) => ans[bf] = compByBF[bf]?.length ?? 1);
        sortedList = [...sortedList, ...BFList.filter(bf => compByBF[bf])];
        set_businessFunctions(sortedList);
        return ans;
      }),
    );
  }, [is_update]);

  return {
    manpowers,
    turnovers,
    female,
    male,
    pie_manpower,
    bargraph,
    businessFunctions,
  };
}
