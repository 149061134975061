import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import { lastYear } from '@Helpers';

import data from './TotalSalesTurnoverData.json';

function TotalSalesTurnOverChart({ TotalSalesTurnOver, TotalSalesThisYear, PercentageSalesThisYear }) {
  return (
    <>
      <h4 style={{ fontWeight: 400, textAlign: 'center' }}>Total Sales Turnover</h4>
      <h2 style={{ fontWeight: 600, textAlign: 'center' }}>RM {TotalSalesThisYear.toLocaleString()}</h2>
      <div style={{ position: 'relative' }}>
        <svg viewBox='0 0 400 400'>
          <VictoryPie
            colorScale={['#1F3566', 'rgba(30, 52, 101, 0.15)']}
            cornerRadius={10}
            standalone={false}
            width={400}
            height={400}
            data={TotalSalesTurnOver ?? data}
            innerRadius={130}
            labels={[]}
          />
          <VictoryLabel
            textAnchor='middle'
            style={{ fontSize: 64, fill: '#FF4560' }}
            x={200}
            y={184}
            text={`${PercentageSalesThisYear}%`}
          />
          <VictoryLabel
            textAnchor='middle'
            style={{ fontSize: 32, fill: 'rgba(30, 52, 101, 0.65)' }}
            x={200}
            y={242}
            text={`vs ${lastYear}`}
          />
        </svg>
      </div>
    </>
  );
}

export default TotalSalesTurnOverChart;
