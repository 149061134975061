/* eslint-disable max-len */
import { useState, useEffect } from 'react';

export default function Hook(props) {
  const [workPartFilteredOptions, setWorkPartFilteredOptions] = useState([]);
  const [elementCategoryFilteredOptions, setElementCategoryFilteredOptions] = useState([]);
  const [annotationTypeFilteredOptions, setAnnotationTypeFilteredOptions] = useState([]);
  useEffect(() => {
    // initiate
    const WorkCategoryId = props.annotation.WorkCategoryId ?? 1;
    const fwp = props.workPartOptions.filter(x => x.WorkCategoryId === WorkCategoryId);
    setWorkPartFilteredOptions(fwp);
    const fec = props.elementCategoryOptions.filter(x => x.WorkCategoryId === WorkCategoryId);
    setElementCategoryFilteredOptions(fec);
    const fat = props.annotationTypeOptions.filter(x => x.WorkCategoryId === WorkCategoryId);
    setAnnotationTypeFilteredOptions(fat);
  }, []);
  // form data changes
  const handleChangeOshCategory = (e) => {
    props.setMainImageAnnotations(prevVal => prevVal.map(ann => (ann.id === props.annotation.id ? ({ ...ann, OshCategoryId: Number(e.target.value) }) : ann)));
  };
  const handleChangeEnvCategory = (e) => {
    props.setMainImageAnnotations(prevVal => prevVal.map(ann => (ann.id === props.annotation.id ? ({ ...ann, EnvironmentCategoryId: Number(e.target.value) }) : ann)));
  };
  // quality
  const handleChangeWorkCategory = (e) => {
    const WorkCategoryId = Number(e.target.value);
    console.log('selected WorkCategoryId', WorkCategoryId);
    const fwp = props.workPartOptions.filter(x => x.WorkCategoryId === WorkCategoryId);
    setWorkPartFilteredOptions(fwp);
    const fec = props.elementCategoryOptions.filter(x => x.WorkCategoryId === WorkCategoryId);
    setElementCategoryFilteredOptions(fec);
    const fat = props.annotationTypeOptions.filter(x => x.WorkCategoryId === WorkCategoryId);
    setAnnotationTypeFilteredOptions(fat);
    props.setMainImageAnnotations(prevVal => prevVal.map(ann => (ann.id === props.annotation.id ? ({ ...ann, WorkCategoryId }) : ann)));
  };
  const handleChangeWorkPart = (e) => {
    const WorkPartId = Number(e.target.value);
    console.log('selected WorkPartId', WorkPartId);
    const fec = props.elementCategoryOptions.filter(x => x.WorkPartId === WorkPartId);
    setElementCategoryFilteredOptions(fec);
    const fat = props.annotationTypeOptions.filter(x => x.WorkPartId === WorkPartId);
    setAnnotationTypeFilteredOptions(fat);
    props.setMainImageAnnotations(prevVal => prevVal.map(ann => (ann.id === props.annotation.id ? ({ ...ann, WorkPartId }) : ann)));
  };
  const handleChangeElementCategory = (e) => {
    const ElementCategoryId = Number(e.target.value);
    console.log('selected ElementCategoryId', ElementCategoryId);
    const fat = props.annotationTypeOptions.filter(x => x.ElementCategoryId === ElementCategoryId);
    setAnnotationTypeFilteredOptions(fat);
    props.setMainImageAnnotations(prevVal => prevVal.map(ann => (ann.id === props.annotation.id ? ({ ...ann, ElementCategoryId }) : ann)));
  };
  const handleChangeAnnotationType = (e) => {
    props.setMainImageAnnotations(prevVal => prevVal.map(ann => (ann.id === props.annotation.id ? ({ ...ann, AnnotationTypeId: Number(e.target.value) }) : ann)));
  };

  // other annotation attributes
  const handleChangeCompliance = (e) => {
    props.setMainImageAnnotations(prevVal => prevVal.map(ann => (ann.id === props.annotation.id ? ({ ...ann, is_compliance: Number(e.target.value) }) : ann)));
  };
  const handleChangePractice = (e) => {
    props.setMainImageAnnotations(prevVal => prevVal.map(ann => (ann.id === props.annotation.id ? ({ ...ann, is_good_practice: Number(e.target.value) }) : ann)));
  };
  const handleChangeDescription = (e) => {
    props.setMainImageAnnotations(prevVal => prevVal.map(ann => (ann.id === props.annotation.id ? ({ ...ann, description: e.target.value }) : ann)));
  };
  return {
    workPartFilteredOptions,
    elementCategoryFilteredOptions,
    annotationTypeFilteredOptions,
    handleChangeOshCategory,
    handleChangeEnvCategory,
    handleChangeWorkCategory,
    handleChangeWorkPart,
    handleChangeElementCategory,
    handleChangeAnnotationType,
    handleChangeCompliance,
    handleChangePractice,
    handleChangeDescription,
  };
}
