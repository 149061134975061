import React from "react";
import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";

const TealCheckbox = withStyles({
  root: {
    color: "#03A398",
    padding: 3,
    "&$checked": {
      color: "#03A398",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomFormControlLabel = withStyles({
  root: {
    marginRight: 12,
    marginLeft: -6,
    transition: "all .2s ease-in-out",
  },
  label: {
    fontSize: 15,
    fontFamily: "Poppins",
  },
})(FormControlLabel);

export default function CustomCheckbox({ name, checked, onChange }) {
  return (
    <CustomFormControlLabel
      control={
        <TealCheckbox name={name} checked={checked} onChange={onChange} />
      }
      label={name}
      style={{
        color: checked ? "#FFFFFF" : "#9f9f9f",
      }}
    />
  );
}
