import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

export default (props) => {
  const arr = [];

  if (props.user?.can_see_osh) arr.push({ title: 'OSH', to: '/project/osh' });
  if (props.user?.can_see_environment) arr.push({ title: 'Environment', to: '/project/environment' });
  if (props.user?.can_see_quality) arr.push({ title: 'Quality', to: '/project/quality' });

  return (
    arr.map(e => (
      <Grid xs={12} lg={12 / arr.length} item>
        <SidebarCard {...e} />
      </Grid>
    ))
  );
};

function SidebarCard(props) {
  const cardStyling = props.disabled ? {
    background: '#D9DADC',
    opacity: '0.4',
    cursor: 'default',
  } : {
    background: '#F5FAFF',
  };
  return (
    <Link to={props.to}>
      <div
        className="flex-standard"
        style={{
          margin: 3, padding: '20px 10px', borderRadius: 10, ...cardStyling,
        }}
      >
        <h4 style={{ fontSize: '0.85em' }}>{props.title}</h4>
      </div>
    </Link>
  );
}
