import { Link, useRouteMatch } from 'react-router-dom';

export default function Navbar() {
  const { path } = useRouteMatch();
  return (
    <div
      className="flex-standard"
      style={{
        background: 'rgba(255,255,255,0.65)',
        marginBottom: 10,
      }}
    >
      {[
        { title: 'Total Financial', to: '/dashboard/finance' },
        // { title: 'DASB Financial', to: '/dashboard/dasb-financial' },
        { title: 'Leasing', to: '/dashboard/leasing' },
        { title: 'Maintenance', to: '/dashboard/maintenance' },
        // { title: 'REID', to: '/dashboard/reid' },
      ].map(e => (
        <Link to={e.to}>
          <h3 style={{ margin: '0 30px', opacity: path === e.to ? 1 : 0.65 }}>{e.title}</h3>
        </Link>
      ))}
    </div>
  );
}
