import { Star } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";

export default function Rating(props) {
  return (
    <Link to="/property/maintenance/user-feedback">
      <div
        className="paper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "20px 30px 25px 30px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 32,
              height: 32,
              background: "#F5F8FF",
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Star style={{ color: "#FFCC48" }} />
          </div>
          <p style={{ fontSize: 18, marginLeft: 6 }}>
            {props.rating}{" "}
            <span style={{ fontSize: 14 }}>/{props.ratingOver}</span>
          </p>
        </div>
        <p style={{ fontSize: 14, marginTop: 6, marginBottom: 12 }}>
          {props.text}
        </p>
        <RatingBar star={4} percent={props.ratings[0]} />
        <RatingBar star={3} percent={props.ratings[1]} />
        <RatingBar star={2} percent={props.ratings[2]} />
        <RatingBar star={1} percent={props.ratings[3]} />
      </div>
    </Link>
  );
}

function RatingBar(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{ fontSize: 10 }}>{props.star} Star</p>
      <div
        style={{
          height: 5,
          width: 120,
          background: "#F5F8FF",
          marginRight: 6,
          marginLeft: 6,
          borderRadius: 25,
        }}
      >
        <div
          style={{
            height: 5,
            background: "#FFCC48",
            width: props.percent,
            borderRadius: 25,
          }}
        ></div>
      </div>
      <p style={{ fontSize: 10 }}>{props.percent}</p>
    </div>
  );
}
