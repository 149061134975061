import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { PlayCircleFilled } from '@material-ui/icons';
import CenteredLoading from '@Components/CenteredLoading';
import AnnotateImage from './AnnotateImage';
import NoDataInterface from './NoDataInterface';

export default function MainWorkspace(props) {
  const [modal, setModal] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  return (
    <Grid item md={12} lg={12} style={{ height: '100%' }}>
      <Grid container spacing={3} style={{ height: '100%' }}>
        {props.isLoading && (
          <CenteredLoading style={{
            position: 'absolute', height: '750px', width: '70vw', backgroundColor: '#000000b3', zIndex: 2,
          }}
          />
        )}
        <Grid item xs={12}>
          {props.tab === 0 && (props.images.length > 0
            ? <AnnotateImage {...props} />
            : <NoDataInterface onClickCallback={() => setModal(true)} />
          )}
          {props.tab === 1 && (props.videos.length > 0 ? (
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              className="main-img flex column center middle"
              style={{ backgroundImage: `url(${props.mainVideo.imgSrc})` }}
            >
              <PlayCircleFilled onClick={() => setModalVideo(true)} />
            </Grid>
          ) : <NoDataInterface onClickCallback={() => setModal(true)} />)}
        </Grid>
      </Grid>
    </Grid>
  );
}
