import React, { useContext } from 'react';
import './App.css';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Property from '@Pages/Property';
import PropertyLTAT from '@Pages/LTAT/Property';
import TopBar from '@Components/TopBar';
import IntegratedFacilities from '@Pages/IntegratedFacilities/Facilities';
import IntegratedFacilitiesLTAT from '@Pages/LTAT/IntegratedFacilities/Facilities';
import FacilitiesIssue from '@Pages/IntegratedFacilities/Facilities/Issue';
import FacilitiesPreventive from '@Pages/IntegratedFacilities/Facilities/Preventive';
import IncidenceOccurence from '@Pages/IntegratedFacilities/Facilities/IncidentOccurence';
import HumanResources from '@Pages/HumanResources';
import Attendance from '@Pages/Attendance';
import MgmtLeasing from '@Pages/Management/Leasing';
import MgmtFinance from '@Pages/Management/Finance';
import MgmtMaintenance from '@Pages/Management/Maintenance';
import MgmtDasbFinancial from '@Pages/Management/DasbFinancial';
import MgmtReid from '@Pages/Management/Reid';
import IFrame from '@Pages/iFrame';
import Inspection from '@Pages/Inspection';
import AdminPanel from '@Pages/AdminPanel';
import CCTVStatus from '@Pages/AdminPanel/CCTVStatus';
import LandingPage from '@Pages/Auth/LandingPage';
import Logout from '@Pages/Auth/Logout';
import Orthophoto from '@Pages/Orthophoto';
import ProjectSite from '@Pages/ProjectSite';
import OshDashboard from '@Pages/ProjectSite/Dashboard';
import InspectionJland from '@Pages/ProjectSite/Inspection';
import { AuthProvider, AuthContext } from '@Context/Auth';
import UserList from '@Pages/ProjectSite/UserManagement';
import UserProfile from '@Pages/ProjectSite/UserProfile';
import DasbParking from '@Pages/IntegratedFacilities/DasbParking';
import DasbSecurity from '@Pages/IntegratedFacilities/DasbSecurity';
import DasbSecurityUploadData from '@Pages/IntegratedFacilities/DasbSecurity/UploadData';
import DasbFoodServices from '@Pages/IntegratedFacilities/DasbFoodServices';
import ExternalWordCloud from '@Pages/ExternalWordCloud';
import CCTVWebView from '@Pages/CCTVWebview';
import Hook from './hook';

const redirect = {
  top_management: '/dashboard/leasing',
  property_management: '/property',
  facilities_management: '/integrated-facilities/facilities',
  human_resource: '/human-resources',
  osh_site: '/project',
  demo: '/property',
  parking: '/integrated-facilities/parking',
  security: '/integrated-facilities/security',
  surian: '/property',
  chulan: '/property',
  only_surian: '/property',
};

export default function App() {
  const h = Hook();
  console.log('hook', h);
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/login">
            <LandingPage {...h} />
          </Route>
          <Route exact path="/reset-password">
            <LandingPage {...h} />
          </Route>
          <Route exact path="/logout">
            <Logout {...h} />
          </Route>
          {/* <Route exact path="/external/view-wordcloud/:AssetId"> */}
          <Route exact path="/external/view-wordcloud">
            <ExternalWordCloud {...h} />
          </Route>
          <Route exact path="/cctv-webview/:building_uuid/:cctv_uuid/:token">
            <CCTVWebView {...h} />
          </Route>
          <PrivateRoute exact path="/">
            {(h.user === 'logged out')
              ? <Redirect to="/login" />
              : <Redirect to={redirect[h.user?.dax_role]} />}
          </PrivateRoute>
          <PrivateRoute path="/project">
            <MainContainer user={h.user} child={<ProjectSite {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/osh">
            <MainContainer user={h.user} child={<OshDashboard {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/environment">
            <MainContainer user={h.user} child={<OshDashboard {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/quality">
            <MainContainer user={h.user} child={<OshDashboard {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/maintenance">
            <MainContainer user={h.user} child={<MgmtMaintenance {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/finance">
            <MainContainer user={h.user} child={<MgmtFinance {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/leasing">
            <MainContainer user={h.user} child={<MgmtLeasing {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/reid">
            <MainContainer user={h.user} child={<MgmtReid {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/dasb-financial">
            <MainContainer user={h.user} child={<MgmtDasbFinancial {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/human-resources">
            <MainContainer user={h.user} child={<HumanResources {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/attendance">
            <MainContainer user={h.user} child={<Attendance {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/integrated-facilities/facilities/incident-occurence">
            <MainContainer user={h.user} child={<IncidenceOccurence />} />
          </PrivateRoute>
          <PrivateRoute path="/integrated-facilities/facilities/issue">
            <MainContainer user={h.user} child={<FacilitiesIssue />} />
          </PrivateRoute>
          <PrivateRoute path="/integrated-facilities/facilities/preventive">
            <MainContainer user={h.user} child={<FacilitiesPreventive />} />
          </PrivateRoute>
          <PrivateRoute path="/integrated-facilities/facilities">
            <MainContainer user={h.user} child={<IntegratedFacilities {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/integrated-facilities/parking">
            <MainContainer user={h.user} child={<DasbParking {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/integrated-facilities/security/upload-data">
            <MainContainer user={h.user} child={<DasbSecurityUploadData {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/integrated-facilities/security">
            <MainContainer user={h.user} child={<DasbSecurity {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/integrated-facilities/food-services">
            <MainContainer user={h.user} child={<DasbFoodServices {...h} />} />
          </PrivateRoute>
          <PrivateRoute exact path="/integrated-facilities">
            <MainContainer user={h.user} child={<IntegratedFacilitiesLTAT {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/property">
            {h.user?.dax_role === 'surian' || h.user?.dax_role === 'chulan' || h.user?.dax_role === 'only_surian'
              ? <MainContainer user={h.user} child={<PropertyLTAT {...h} />} />
              : <MainContainer user={h.user} child={<Property {...h} />} />}
          </PrivateRoute>
          <PrivateRoute path="/3d">
            <MainContainer user={h.user} child={<IFrame type="threeD" {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/360">
            <MainContainer user={h.user} child={<IFrame type="threeSixty" {...h} />} />
          </PrivateRoute>
          {h.user?.dax_role === 'osh_site' && (
            <PrivateRoute path="/inspection/:inspection_session">
              <MainContainer user={h.user} child={<InspectionJland {...h} />} />
            </PrivateRoute>
          )}
          <PrivateRoute path="/inspection">
            <MainContainer user={h.user} child={<Inspection {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/admin-panel">
            <MainContainer user={h.user} child={<AdminPanel {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/cctv-status/:building">
            <MainContainer user={h.user} child={<CCTVStatus {...h} />} />
          </PrivateRoute>
          <PrivateRoute path="/orthophoto">
            <MainContainer user={h.user} child={<Orthophoto {...h} />} />
          </PrivateRoute>
          {h.user?.dax_role === 'osh_site' & h.user?.RoleId === 1 && (
            <>
              <PrivateRoute path="/user">
                <MainContainer user={h.user} child={<UserList {...h} />} />
              </PrivateRoute>
              <PrivateRoute path="/user/:user_id">
                <MainContainer user={h.user} child={<UserProfile {...h} />} />
              </PrivateRoute>
            </>
          )}
        </Switch>
      </Router>
    </AuthProvider>
  );
}

function MainContainer({ user, child }) {
  return (
    <Grid
      className="container"
      style={{
        position: 'fixed', top: '50px', left: '0px', right: '0px', bottom: '0px', overflow: 'auto',
      }}
    >
      <TopBar {...user} />
      <Grid item xs={12}>
        {child}
      </Grid>
    </Grid>
  );
}

function PrivateRoute({ children, ...rest }) {
  const Auth = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) => (Auth.token ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}
