import React from 'react';
import {
  VictoryBar,
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryLabel,
  VictoryTheme,
} from 'victory';
import Legend from '@Components/Legend';

import data from './CombinationChart.json';

export default function CombinationChart({ title }) {
  return (
    <div style={{ paddingBottom: 10 }}>
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center',
      }}
      >
        <h3>{title}</h3>
        <div style={{ display: 'inline-flex' }}>
          <Legend color="#009AFC" title="Actual" fontSize={10} />
          <Legend color="#FCA668" title="Budget" fontSize={10} />
          <Legend color="#9CD6FF" title="Cum Actual" fontSize={10} />
          <Legend color="#FFCDAB" title="Cum Budget" fontSize={10} />
        </div>
      </div>
      <VictoryChart
        // domainPadding will add space to each side of VictoryBar to
        // prevent it from overlapping the axis
        domainPadding={[30, 60]}
        width={1000}
        padding={{
          top: 30, left: 60, right: 60, bottom: 40,
        }}
        theme={VictoryTheme.material}
        height={400}
      >
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          tickFormat={(x) => x}
          style={{
            tickLabels: {
              fontSize: 20,
              padding: 10,
              fill: '#1F3566',
              fontWeight: 700,
            },
            ticks: { size: 0 },
            axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
            grid: { stroke: 'transparent' },
          }}
        />
        <VictoryAxis
          dependentAxis
          label="Actual/Budget (mil)"
          axisLabelComponent={
            <VictoryLabel angle={0} verticalAnchor="middle" dy={-180} dx={10} />
          }
          tickFormat={(y) => `${y.toFixed(1)}`}
          style={{
            axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
            tickLabels: { fontSize: 14, padding: 5 },
            ticks: { stroke: '#FFFFFF', size: 0 },
            grid: { stroke: 'transparent' },
          }}
        />
        <VictoryAxis
          dependentAxis
          label="Cum Actual/Budget (mil)"
          axisLabelComponent={
            <VictoryLabel angle={0} verticalAnchor="middle" dy={-180} dx={-20} />
          }
          tickFormat={(y) => `${y.toFixed(1)}`}
          style={{
            axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
            tickLabels: { fontSize: 14, padding: 5 },
            ticks: { stroke: '#FFFFFF', size: 0 },
            grid: { stroke: 'transparent' },
          }}
          offsetX={950}

        />
        <VictoryGroup offset={30} colorScale={['#009AFC', '#FCA668']}>
          <VictoryBar
            data={data}
            x="x"
            y="y2"
            barWidth={30}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            cornerRadius={3}
          />
          <VictoryBar
            data={data}
            x="x"
            y="y1"
            barWidth={30}
            animate={{
              duration: 2000,
              onLoad: { duration: 2000 },
            }}
            cornerRadius={3}
          />
        </VictoryGroup>
        <VictoryGroup offset={0} colorScale={['#9CD6FF', '#FFCDAB']}>
          <VictoryLine
            style={{
              data: { strokeWidth: 5, strokeLinecap: 'round' },
            }}
            data={data}
            x="x"
            y="y3"
            animate={{
              duration: 2000,
              onLoad: { duration: 2000 },
            }}
            interpolation="natural"
          />
          <VictoryLine
            style={{
              data: { strokeWidth: 5, strokeLinecap: 'round' },
            }}
            data={data}
            x="x"
            y="y4"
            animate={{
              duration: 2000,
              onLoad: { duration: 2000 },
            }}
            interpolation="natural"
          />
        </VictoryGroup>
      </VictoryChart>
    </div>
  );
}
