/* eslint-disable max-lines */
import moment from 'moment';
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';
import { yearComparisons, monthComparisons } from '@Helpers';

const getMonth = (m) => moment(m, 'MM').format('MMM');

export default function Hook({ buildings, selectedBuilding, building }) {
  const [latest_updated, set_latest_updated] = useState(monthComparisons(2).format('MMMM YYYY'));
  const [YEAR, set_YEAR] = useState(yearComparisons(2)[1]);
  const [prev_revenue_actual, set_prev_revenue_actual] = useState(0);
  const [prev_expenses_actual, set_prev_expenses_actual] = useState(0);
  const [prev_NPI_actual, set_prev_NPI_actual] = useState(0);
  const [revenue_actual, set_revenue_actual] = useState(0);
  const [expenses_actual, set_expenses_actual] = useState(0);
  const [ytd_revenue_actual, set_ytd_revenue_actual] = useState(0);
  const [ytd_expenses_actual, set_ytd_expenses_actual] = useState(0);
  const [ytd_revenue_budget, set_ytd_revenue_budget] = useState(0);
  const [ytd_expenses_budget, set_ytd_expenses_budget] = useState(0);
  const [NPI_actual, set_NPI_actual] = useState(0);
  const [yield_actual, set_yield_actual] = useState(0);
  const [prev_yield_actual, set_prev_yield_actual] = useState(0);
  const [yield_budget, set_yield_budget] = useState(0);
  const [revenue_budget, set_revenue_budget] = useState(0);
  const [expenses_budget, set_expenses_budget] = useState(0);
  const [NPI_budget, set_NPI_budget] = useState(0);
  const [monthly_rev, set_monthly_rev] = useState([]);
  const [monthly_exp, set_monthly_exp] = useState([]);
  const [monthly_npi, set_monthly_npi] = useState([]);
  const [monthly_yield, set_monthly_yield] = useState([]);
  const [billing_collection, set_billing_collection] = useState([]);
  const [billing_data, set_billing_data] = useState([]);
  const [service_charge_piechart, set_service_charge_piechart] = useState([]);
  const [sc_actual_total, set_sc_actual_total] = useState(0);
  const [sc_budget_total, set_sc_budget_total] = useState(0);
  const [NLA, set_NLA] = useState(0);
  const [isLoading, set_isLoading] = useState(false);
  const [isBillingLoading, set_isBillingLoading] = useState(false);
  const [isServiceChargeLoading, set_isServiceChargeLoading] = useState(false);
  const [budgetByMonth, set_budgetByMonth] = useState({});

  const uuid = buildings[selectedBuilding]?.uuid;
  useEffect(() => {
    set_isLoading(true);
    set_isBillingLoading(true);
    set_isServiceChargeLoading(true);
    if (!uuid) return;
    const body = { data: { uuid } };
    Api({
      ...body,
      endpoint: endpoints.getSalesturnover(),
      onSuccess: (response) => {
        const sortedV = _.groupBy(_.sortBy(response.data, ['YEAR', 'MONTH']), 'YEAR');
        const latestYear = sortedV[YEAR];
        const prevYear = sortedV[YEAR - 1];
        const ytd_rev_thisyear = _.sumBy(latestYear, o => o.GROSS);
        const ytd_exp_thisyear = _.sumBy(latestYear, o => o.OE);
        const ytd_rev_lastyear = _.sumBy(prevYear, o => o.GROSS);
        const ytd_exp_lastyear = _.sumBy(prevYear, o => o.OE);
        set_ytd_revenue_actual(ytd_rev_thisyear);
        set_ytd_expenses_actual(ytd_exp_thisyear);

        // current month: dep 20/9/2022
        // set_revenue_actual(latestYear.slice(-1)[0].GROSS);
        // set_expenses_actual(latestYear.slice(-1)[0].OE);
        // set_NPI_actual(latestYear.slice(-1)[0].GROSS - latestYear.slice(-1)[0].OE);
        // set_prev_revenue_actual(prevYear.slice(-1)[0].GROSS);
        // set_prev_expenses_actual(prevYear.slice(-1)[0].OE);
        // set_prev_NPI_actual(prevYear.slice(-1)[0].GROSS - prevYear.slice(-1)[0].OE);

        // cummulative month: imp 20/9/2022
        set_revenue_actual(ytd_rev_thisyear);
        set_expenses_actual(ytd_exp_thisyear);
        set_NPI_actual(ytd_rev_thisyear - ytd_exp_thisyear);
        set_prev_revenue_actual(ytd_rev_lastyear);
        set_prev_expenses_actual(ytd_exp_lastyear);
        set_prev_NPI_actual(ytd_rev_lastyear - ytd_exp_lastyear);
        // cummulative month: imp 20/9/2022

        set_prev_yield_actual((ytd_rev_lastyear - ytd_exp_lastyear) * 100 / building.PROPERTY_VALUE);
        set_yield_actual((ytd_rev_thisyear - ytd_exp_thisyear) * 100 / building.PROPERTY_VALUE);

        // monthly bar chart
        set_monthly_rev(latestYear.map(eachM => ({
          month: `${moment(eachM.MONTH, 'M').format('MMM')}`,
          billing: prevYear.find(x => x.MONTH === eachM.MONTH).GROSS,
          collection: eachM.GROSS,
        })));
        set_monthly_exp(latestYear.map(eachM => ({
          month: `${moment(eachM.MONTH, 'M').format('MMM')}`,
          billing: prevYear.find(x => x.MONTH === eachM.MONTH).OE,
          collection: eachM.OE,
        })));
        const mnpi = latestYear.map(eachM => ({
          month: `${moment(eachM.MONTH, 'M').format('MMM')}`,
          billing: prevYear.find(x => x.MONTH === eachM.MONTH).GROSS - prevYear.find(x => x.MONTH === eachM.MONTH).OE,
          collection: eachM.GROSS - eachM.OE,
        }));
        set_monthly_npi(mnpi);
        set_monthly_yield([...mnpi].map(m => ({
          month: m.month,
          billing: (m.billing * 100 / building.PROPERTY_VALUE),
          collection: (m.collection * 100 / building.PROPERTY_VALUE),
        })));
        set_isLoading(false);
      },
      // onFail: set_OccupancyRate(),
    });
    Api({
      ...body,
      YEAR: yearComparisons(2)[0],
      endpoint: endpoints.getBudget(),
      onSuccess: (response) => {
        const sortedV = _.groupBy(_.sortBy(response.data, ['YEAR', 'MONTH']), 'YEAR');
        const latestYear = sortedV[YEAR];
        set_budgetByMonth(latestYear.reduce((a, b) => ({
          ...a,
          [moment(b.MONTH, 'M').format('MMM')]: { REV: b.RENTAL_REV, EXP: b.OE },
        }), {})); // { Jan: { REV: 1000, OE: 1200 } }
        const ytd_rev_bud_thisyear = _.sumBy(latestYear, o => o.RENTAL_REV);
        const ytd_exp_bud_thisyear = _.sumBy(latestYear, o => o.OE);
        set_revenue_budget(ytd_rev_bud_thisyear);
        set_expenses_budget(ytd_exp_bud_thisyear);
        set_NPI_budget(ytd_rev_bud_thisyear - ytd_exp_bud_thisyear);
        set_yield_budget((ytd_rev_bud_thisyear - ytd_exp_bud_thisyear) * 100 / building.PROPERTY_VALUE);
        set_ytd_revenue_budget(_.sumBy(latestYear, o => o.RENTAL_REV));
        set_ytd_expenses_budget(_.sumBy(latestYear, o => o.OE));
        // set_expenses_actual(sortedV[Object.keys(sortedV).slice(-1)].OE)
      },
      // onFail: set_OccupancyRate(),
    });
    Api({
      ...body,
      endpoint: endpoints.getBilling(),
      onSuccess: ({ data }) => {
        if (data.length > 0) {
          const sorted = _.groupBy(_.sortBy(data, ['YEAR', 'MONTH']), 'YEAR');
          set_billing_data(sorted)
          const [latestYear, prevYear] = Object.entries(sorted).map(e => e[0]).reverse();
          const graphData = sorted[prevYear]
            .slice(sorted[latestYear].length)
            .concat(sorted[latestYear])
            .map(e => ({ month: `${getMonth(e.MONTH) } ${ e.YEAR}`, billing: e.BILLING, collection: e.COLLECTION }));
          set_billing_collection(graphData);
        } else {
          set_billing_collection([]);
        }
        set_isBillingLoading(false);
      },
    });
    Api({
      ...body,
      endpoint: endpoints.getServiceCharge(),
      onSuccess: ({ data }) => {
        if (data?.length > 0) {
          const total = (data.reduce((a, b) => ({ ACTUAL: a.ACTUAL + b.ACTUAL, BUDGET: a.BUDGET + b.BUDGET })));
          set_sc_actual_total(total.ACTUAL);
          set_sc_budget_total(total.BUDGET);
          set_service_charge_piechart(data.filter(e => e.ACTUAL !== 0).map(e => ({ service: `${e.Service } (${((e.ACTUAL / total.ACTUAL * 100).toPrecision(3))}%)`, expenses: Math.abs(e.ACTUAL) })));
        } else {
          set_service_charge_piechart([]);
          set_sc_actual_total(0);
          set_sc_budget_total(0);
        }
        set_isServiceChargeLoading(false);
      },
    });
    Api({
      data: { building_uuid: uuid, YEAR: yearComparisons(2)[1] },
      endpoint: endpoints.getOccupancy(),
      onSuccess: (response) => set_NLA(response.data[0].NLA),
      onFail: set_NLA(0),
    });
    if (['321ac1e2-935f-486a-ad3a-a3d89827d1b3'].includes(uuid)) {
      const custom_fiscal_financial_month = 1; // late 2 month now sept, but show 2 month before
      set_latest_updated(monthComparisons(1, custom_fiscal_financial_month).format('MMMM YYYY'));
    }
  }, [uuid]);
  return {
    isLoading,
    isBillingLoading,
    isServiceChargeLoading,
    NLA,
    service_charge_piechart,
    sc_actual_total,
    sc_budget_total,
    billing_collection,
    latest_updated,
    ytd_revenue_actual,
    ytd_expenses_actual,
    ytd_revenue_budget,
    ytd_expenses_budget,
    prev_revenue_actual,
    prev_expenses_actual,
    prev_NPI_actual,
    revenue_actual,
    expenses_actual,
    NPI_actual,
    revenue_budget,
    expenses_budget,
    NPI_budget,
    billing_data,
    budgetByMonth,
    monthly_bar_data: {
      Revenue: monthly_rev, Expenses: monthly_exp, NPI: monthly_npi, Yield: monthly_yield,
    },
    data: [
      {
        title: 'Revenue',
        percent: (revenue_actual / revenue_budget * 100),
        positive: false,
        donutData: [{ x: 1, y: revenue_actual }, { x: 2, y: revenue_budget }],
        amount: `RM ${revenue_actual.toLocaleString()}`,
        asOf: latest_updated,
        barData: [{ x: String(yearComparisons(2)[0]), y: prev_revenue_actual }, { x: 'Actual', y: revenue_actual }, { x: 'Budget', y: revenue_budget }],
      },
      {
        title: 'Expenses',
        percent: (expenses_actual / expenses_budget * 100),
        positive: true,
        donutData: [{ x: 1, y: expenses_actual }, { x: 2, y: expenses_budget }],
        amount: `RM ${expenses_actual.toLocaleString()}`,
        asOf: latest_updated,
        barData: [{ x: String(yearComparisons(2)[0]), y: prev_expenses_actual }, { x: 'Actual', y: expenses_actual }, { x: 'Budget', y: expenses_budget }],
      },
      {
        title: 'NPI',
        percent: (NPI_actual / NPI_budget * 100),
        positive: false,
        donutData: [{ x: 1, y: NPI_actual }, { x: 2, y: NPI_budget }],
        amount: `RM ${NPI_actual.toLocaleString()}`,
        asOf: latest_updated,
        barData: [{ x: String(yearComparisons(2)[0]), y: prev_NPI_actual }, { x: 'Actual', y: NPI_actual }, { x: 'Budget', y: NPI_budget }],
      },
      {
        title: 'Yield',
        percent: (yield_actual / yield_budget * 100),
        positive: false,
        donutData: [{ x: 1, y: yield_actual }, { x: 2, y: yield_budget }],
        amount: `${yield_actual.toFixed(2)}%`,
        asOf: latest_updated,
        barData: [
          { x: String(yearComparisons(2)[0]), y: prev_yield_actual.toFixed(2) },
          { x: 'Actual', y: yield_actual.toFixed(2) },
          { x: 'Budget', y: yield_budget.toFixed(2) },
        ],
      },
    ],
  };
}
