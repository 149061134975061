import Api, { endpoints } from '@Helpers/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from '@Components/MaterialTable';
import CsvDownload from 'react-json-to-csv';
import { GetApp, Close } from '@material-ui/icons';

const columns = [

  {
    name: 'User',
    selector: 'user',
    align: 'left',
    minWidth: 100,
    color: '#00AFF0',
  },
  {
    name: 'Activity',
    selector: 'description',
    align: 'left',
    minWidth: 300,
  },
  {
    name: 'Date',
    selector: 'date',
    align: 'right',
    minWidth: 100,
  },
  {
    name: 'Time',
    selector: 'time',
    align: 'right',
    minWidth: 100,
  },
];

export default ({ open, onClose }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [activityLog, setActivityLog] = useState({});

  const loadMore = () => {
    if (isLoading) return;
    setIsLoading(true);
    Api({
      endpoint: activityLog.nextpage ? ['GET', activityLog.nextpage] : endpoints.activityLog(),
      onSuccess: (response) => {
        if (activityLog.data) {
          const prev_data = [...activityLog.data];
          const prev_data_ids = prev_data.map((id) => id);
          const filtered_response_data = response.data.filter(f => !prev_data_ids.includes(f.id));
          setActivityLog({ ...response, data: [...prev_data, ...filtered_response_data] });
          setIsLoading(false);
          return;
        }

        setActivityLog(response);
        setIsLoading(false);
      },
      onFail: () => setIsLoading(false),
    });
  };

  useEffect(() => {
    loadMore();
  }, []);
  const tableData = activityLog.data?.map(eLog => {
    const date = moment(eLog.createdAt).format('DD-MMM-YY');
    const time = moment(eLog.createdAt).format('hh:mm A');
    return {
      user: eLog.User.name, description: eLog.description, date, time,
    };
  });
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 10 } }}
    >
      <DialogTitle>
        <div className={classes.spaceBetween}>
          <b className={`${classes.fontLg} ${classes.mx30}`}>
            Activity Log
          </b>
          <div>
            <CsvDownload filename={`${moment().format('DD-MM-YYYY-hh-mm-ss')}.csv`} data={tableData} className={`${classes.blueBtn} blue-button`}>
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                <GetApp />
                Download Report
              </h1>
            </CsvDownload>
            <Close
              className={classes.closeBtn}
              onClick={onClose}
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        {!!activityLog.data?.length && (
          <MaterialTable
            tableHead
            columns={columns}
            tableData={tableData}
            tableMinWidth={300}
            tableMaxHeight={500}
          />
        )}
      </DialogContent>

    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  spaceBetween: { display: 'flex', justifyContent: 'space-between' },
  fontLg: { fontSize: '30px' },
  mx30: { margin: '0 30px' },
  blueBtn: { borderRadius: '6px', width: '210px', margin: '10px 30px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
}));
