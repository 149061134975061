import React from "react";
import FilterIcon from "./FilterIcon";

import "./filter.css";

export default function Filter({ w, p, m, children, active, setActive }) {
  return (
    <div className="filter" style={{ width: w }}>
      <div
        className={`flex row middle filter-button ${active && "active"}`}
        style={{
          padding: p,
          margin: m,
        }}
        onClick={() => {
          setActive(!active);
        }}
      >
        <FilterIcon style={{color:'#1E3465'}} />
      </div>
      <div className={`filter-container ${active && "active"}`}>{children}</div>
    </div>
  );
}
