import Navbar from '@Components/IntegratedFacilities/Navbar';
import {
  Grid, Select, MenuItem, FormControl, InputLabel,
} from '@material-ui/core';
import {
  VictoryAxis, VictoryChart, VictoryLabel, VictoryLine,
} from 'victory';
import ticket from '@Assets/Icons/ticket.svg';
import rm from '@Assets/Icons/rm.svg';
import clock from '@Assets/Icons/clock.svg';
import parking from '@Assets/Icons/parking.svg';
import { makeStyles } from '@material-ui/core/styles';
import MainContentContainer from '@Components/MainContentContainer';
import useHook from './hook';
import UploadDataDialog from './UploadDataDialog';

const data = (d) => [
  {
    title: 'Average Ticket Value',
    icon: <img src={ticket} height="25" style={{ marginRight: 5 }} />,
    value: `RM ${(d.filter(e => e.average_ticket_value).map(e => e.average_ticket_value).reduce((a, b) => a + b, 0) / d.length).toFixed(2)}`,
    yLabel: 'RM',
    domain: [0, 10],
    lineData: d.map(({ monthLabel, x, average_ticket_value }) => ({ monthLabel, x, y: average_ticket_value })),
  },
  {
    title: 'Average Length of Stay',
    icon: <img src={clock} height="25" style={{ marginRight: 5 }} />,
    value: `${(d.filter(e => e.average_length_stay).map(e => e.average_length_stay).reduce((a, b) => a + b, 0) / d.length).toFixed(2)} hours`,
    yLabel: 'Hour',
    domain: [0, 5],
    lineData: d.map(({ monthLabel, x, average_length_stay }) => ({ monthLabel, x, y: average_length_stay })),
  },
  {
    title: 'Bay Turnover',
    icon: <img src={parking} height="25" style={{ marginRight: 5 }} />,
    value: `${(d.filter(e => e.bay_turnover).map(e => e.bay_turnover).reduce((a, b) => a + b, 0) / d.length).toFixed(0)}`,
    yLabel: '',
    domain: [-10, 2000],
    lineData: d.map(({ monthLabel, x, bay_turnover }) => ({ monthLabel, x, y: bay_turnover })),
  },
  {
    title: 'Revenue per Bay',
    icon: <img src={rm} height="25" style={{ marginRight: 5 }} />,
    value: `RM ${(d.filter(e => e.rev_per_bay).map(e => e.rev_per_bay).reduce((a, b) => a + b, 0) / d.length).toFixed(2)}`,
    yLabel: 'RM',
    domain: [-10, 500],
    lineData: d.map(({ monthLabel, x, rev_per_bay }) => ({ monthLabel, x, y: rev_per_bay })),
  },
];

export default function DasbParking(props) {
  const h = useHook();
  const classes = useStyles();
  return (
    <>
      <Navbar dax_role={props.user?.dax_role} />
      <div className="flex space-between">
        <h1 style={{ fontWeight: 700, marginBottom: 10 }}>
          DASB Parking
        </h1>
        <div className="mb-5 flex end align-items-center">
          <FormControl variant="outlined" style={{ width: 400 }}>
            <InputLabel id="property-label">Property Name</InputLabel>
            <Select
              style={{ marginRight: 20 }}
              labelId="property-label"
              id="property"
              onChange={(e) => h.setFacility(e.target.value)}
              label="Property Name"
              value={h.facility}
              MenuProps={{
                anchorOrigin:{
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin:{
                  vertical: 'top',
                  horizontal: 'center',
                },
                getContentAnchorEl: null,
              }}
            >
              {h.projectParking.length > 0 &&
                h.projectParking.map((p) => <MenuItem value={p.id}>{p.name}</MenuItem>)}
            </Select>
          </FormControl>
          <UploadDataDialog {...h} onSuccess={() => h.setJustUpdated(v => v + 1)} />
        </div>
      </div>
      <MainContentContainer>
        <Grid container spacing={3}>
          {data(h.graphData).map(e => (
            <Grid item xs={12} md={6} lg={6}>
              <div className="paper finance-donut" style={{ height: 'auto' }}>
                <div className="flex space-between">
                  <h4 className={`text-light flex ${classes.title}`}>{e.icon}{e.title}</h4>
                  <h1 className={`flex ${classes.value}`}>{e.value}</h1>
                </div>
                <div style={{ position: 'relative' }}>
                  <VictoryChart
                    height={700}
                    width={1700}
                    padding={{
                      left: 85, right: 30, top: 70, bottom: 50,
                    }}
                  >
                    <VictoryAxis
                      style={{
                        tickLabels: { fontSize: 32, opacity: 0.65 },
                        ticks: { size: 0 },
                        axis: { stroke: 'transparent' },
                        grid: { stroke: 'transparent' },
                      }}
                      tickValues={e.lineData}
                      tickCount={e.lineData.length}
                      axisLabelComponent={(
                        <VictoryLabel
                          angle={0}
                          verticalAnchor="start"
                          dy={60}
                          dx={0}
                          style={{ fontSize: 32 }}
                        />
                      )}
                    />
                    <VictoryAxis
                      dependentAxis
                      crossAxis
                      label={e.yLabel}
                      axisLabelComponent={(
                        <VictoryLabel
                          angle={0}
                          verticalAnchor="start"
                          y={-3}
                          dx={-10}
                          style={[{ fontSize: '40' }]}
                        />
                      )}
                      domain={e.domain}
                      style={{
                        tickLabels: { fontSize: 32, opacity: 0.65 },
                        ticks: { size: 0 },
                        axis: { stroke: 'transparent' },
                        grid: { stroke: 'transparent' },
                      }}
                      standalone={false}
                    />
                    {e.lineData && (
                      <VictoryLine
                        interpolation="linear"
                        style={{
                          data: { stroke: '#008ffb' },
                          parent: { border: '1px solid #A93226' },
                        }}
                        data={e.lineData.map(({ y, monthLabel }) => ({ y, x: monthLabel }))}
                      />
                    )}
                  </VictoryChart>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </MainContentContainer>
    </>
  );
}

const useStyles = makeStyles(() => ({
  title: { alignItems: 'center', margin: '0 0 10px 5px', fontWeight: 700 },
  value: {
    alignItems: 'center', margin: '0 0 10px 35px', fontWeight: 700, color: '#008ffb',
  },
}));
