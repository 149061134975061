import React from 'react';
import {
  VictoryLabel, VictoryPie, VictoryTooltip, VictoryLegend,
} from 'victory';

const colors = [
  'rgba(255,69,96,1)',
  'rgba(255,69,96,0.35)',
  'rgba(184,94,238,1)',
  'rgba(184,94,238,0.35)',
  'rgba(251,156,93,1)',
  'rgba(251,156,93,0.35)',
  'rgba(0,143,251,1)',
  'rgba(0,143,251,0.35)',
  'rgba(46,204,169,1)',
  'rgba(46,204,169,0.35)',
  'rgba(255,69,96,1)',
  'rgba(255,69,96,0.35)',
  'rgba(184,94,238,1)',
  'rgba(184,94,238,0.35)',
  'rgba(251,156,93,1)',
  'rgba(251,156,93,0.35)',
  'rgba(0,143,251,1)',
  'rgba(0,143,251,0.35)',
  'rgba(46,204,169,1)',
  'rgba(46,204,169,0.35)',
];
const simpleColors = ['#008FFB', '#FB9C5D', '#B85EEE', '#2ECCA9'];

export default function PieChart(props) {
  let legendData;
  if (props.legend) {
    legendData = props.data.map((d, idx) => ({ name: d.label[0], symbol: { fill: colors[idx], type: 'square' } }));
  }

  return (
    <div className="flex-standard" style={{ height: 'calc(100% - 70px)' }}>
      <svg viewBox="0 0 600 400" style={{ overflow: 'visible' }}>
        <VictoryPie
          colorScale={props.simple ? simpleColors : colors}
          standalone={false}
          origin={{ y: 220, x: props.legend && 400 }}
          width={600}
          height={400}
          radius={140}
          data={props.data}
          innerRadius={props.innerLabel || props.innerLabelDescription ? 70 : 0}
          labelComponent={
            props.legend ? (
              <></>
            ) : (
              <VictoryTooltip
                active
                constrainToVisibleArea
                centerOffset={{
                  x: ({ datum }) => (datum.startAngle >= 180 ? -20 : 20),
                }}
                text={({ datum }) => datum.label}
                flyoutPadding={{
                  top: 5, bottom: 5, right: 10, left: 10,
                }}
                pointerLength={20}
                renderInPortal={false}
                pointerWidth={1}
                style={{ fontFamily: 'Poppins' }}
                flyoutStyle={{
                  fill: '#FFFFFF',
                  stroke: ({ index }) => (props.simple ? simpleColors[index] : colors[index]),
                }}
              />
            )
          }
          style={{
            labels: { fontSize: 16, fill: '#1E3465' },
          }}
        />
        {props.innerLabel && (
          <VictoryLabel
            textAnchor="middle"
            style={{
              fontFamily: 'Poppins',
              fontSize: props.innerLabelSize || 20,
              fontWeight: 400,
              fill: '#1E3465',
            }}
            x={300}
            y={210}
            text={props.innerLabel}
          />
        )}
        {props.innerLabelDescription && (
          <VictoryLabel
            textAnchor="middle"
            style={{
              fontFamily: 'Poppins',
              fontSize: props.innerLabelDescriptionSize || 30,
              fontWeight: 600,
              fill: '#1E3465',
            }}
            x={300}
            y={props.innerLabel ? 230 : 220}
            text={props.innerLabelDescription}
          />
        )}
        {props.legend && (
          <VictoryLegend
            x={0}
            y={0}
            orientation="vertical"
            standalone={false}
            itemsPerRow={15}
            gutter={20}
            data={legendData}
            style={{ labels: { fontFamily: 'Poppins', fontSize: 12 } }}
          />
        )}
      </svg>
    </div>
  );
}
