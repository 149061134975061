import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';
import { yearComparisons } from '@Helpers';

export default function Hook({ building }) {
  const YEAR = yearComparisons(2)[1];
  const [occupancyRate, setOccupancyRate] = useState(0);
  const [NPI_actual, set_NPI_actual] = useState(0);

  useEffect(() => {
    if (building) {
      Api({
        endpoint: endpoints.getOccupancy(),
        data: { building_uuid: building.uuid, YEAR },
        onSuccess: (response) => setOccupancyRate(response.data[0]),
        onFail: setOccupancyRate(),
      });

      Api({
        endpoint: endpoints.getSalesturnover(),
        data: { uuid: building.uuid },
        onSuccess: (response) => {
          const sortedV = _.groupBy(_.sortBy(response.data, ['YEAR', 'MONTH']), 'YEAR');
          const latestYear = sortedV[YEAR];
          set_NPI_actual(latestYear.slice(-1)[0].GROSS - latestYear.slice(-1)[0].OE);
        },
      });
    }
  }, [building]);

  return {
    occupancyRate,
    NPI_actual,
  };
}
