import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

export default function CenteredLoading(props) {
  return (
    <Grid container justify="center" {...props}>
      <CircularProgress size={25} style={{ margin: 'auto' }} />
    </Grid>
  );
}
