/* eslint-disable max-lines */
import { Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { OfflineCloud } from '@Assets/Icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.css';
import '../Tabs/styles.css';

const styles = {
  focusedTab: {
    color: '#FFFFFF',
    background: 'rgba(30, 52, 101, 1)',
  },
};

export default function Cctv(props) {
  const playerRef = useRef(null);
  const [openTab, setOpenTab] = useState(0);
  const [openCam, setOpenCam] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnline, setIsOnline] = useState();
  const tabData = [...new Set(props.cameras?.map((m) => m.floor))]
    .sort((a, b) => a - b)
    .map((m) => (m === 0
      ? { floor: m, title: 'Ground Floor' }
      : m < 0
        ? { floor: m, title: `Basement ${m * -1}` }
        : { floor: m, title: `Floor ${m}` }));
  const noTab = !props.noTab;

  const camByFloor = props.cameras?.filter((f) => f.floor === tabData[openTab].floor);
  const selectedCam = camByFloor ? camByFloor[openCam] : null;
  const url = `${props.url}hls/${props.building_uuid}/${selectedCam?.uuid}/playlist.m3u8`;

  console.log('props.url', props.url);
  console.log('url', url);

  useEffect(() => {
    setIsLoading(true);
    setOpenCam(0);
  }, [openTab, props.cameras]);

  useEffect(() => {
    setIsLoading(true);
    setOpenTab(0);
  }, [props.cameras]);

  useEffect(() => {
    setIsLoading(true);
  }, [selectedCam]);

  useEffect(() => {
    const online = Number(selectedCam?.is_broken);
    setIsOnline(!online);
  }, [selectedCam, isLoading]);

  useEffect(() => {
    if (isLoading) setTimeout(() => setIsLoading(false), 30000);
  }, [isLoading]);
  const copyToClipBoard = async copyMe => {
    navigator.clipboard.writeText(copyMe);
  };

  const checkVLCExist = async () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      mode: 'no-cors',
    };

    try {
      const resp = await fetch('http://127.0.0.1:7777/', requestOptions);
      console.log('mmmmm', resp.text());
      return true;
    } catch (error) {
      console.log('error', error);
      return false;
    }
  };

  const openVLC = async () => {
    if (!isOnline) {
      toast('error', 'CCTV camera is offline or broken');
      return;
    }
    if (!(await checkVLCExist())) {
      toast('error', 'Local vlc not found');
      return;
    }
    window.open(`http://127.0.0.1:7777/vlcLocal?rtsp_link=${selectedCam?.rtsp}`, '_blank');
  };

  return (
    <Grid container>
      <Grid container item xs={12}>
        {noTab && tabData.map((item, index) => (
          <Grid key={index} item xs={6} lg={2} style={{ zIndex: 2 }}>
            <h3
              className="tabButton"
              style={{
                color: openTab === index && 'rgba(30, 52, 101, 1)',
                backgroundColor: openTab === index && '#FFFFFF',
                borderBottomColor: '#FFFFFF',
                fontWeight: 600,
              }}
              onClick={() => setOpenTab(index)}
            >
              {item.title}
            </h3>
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12} className="paper" spacing={1} style={{ borderTopLeftRadius: 0, padding: 10 }}>
        <Grid item container xs={12} md={3} lg={2} spacing={1} style={{ overflowY: 'auto', height: playerRef.current?.offsetHeight ?? 0 }}>
          {camByFloor?.map((item, index) => (
            <Grid key={index} item xs={12}>
              <div
                className="cctvTabButton"
                style={openCam === index ? styles.focusedTab : { backgroundColor: item.is_broken === 1 && 'red' }}
                onClick={() => setOpenCam(index)}
              >
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: 18,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512.004 512.004"
                      className="cctvTabButtonSVG"
                      style={{
                        fill: openCam === index && '#FFFFFF',
                      }}
                    >
                      <path d="M506.625 235.884L217.344 70.434c-22.313-12.782-52.854-5.891-67.563 15.159L93.74 165.707a46.384 46.384 0 00-8.406 26.701c0 16.588 8.917 32.039 23.26 40.317l61.01 35.233-11.021 14.707c-5.969 7.955-9.25 17.818-9.25 27.765 0 10.229 3.746 19.851 9.78 27.805l-16.238 24.433H64v-32.105c0-5.901-4.771-10.561-10.667-10.561H10.667C4.771 320.002 0 324.662 0 330.563v106.763c0 5.901 4.771 10.676 10.667 10.676h42.667c5.896 0 10.667-4.775 10.667-10.676v-10.657h78.875c21.448 0 41.354-10.654 53.25-28.513l17.484-26.25c3.367.731 6.763 1.394 10.234 1.394 16.979 0 32.958-9.05 41.688-23.625l11.72-19.551 90.78 52.425a10.62 10.62 0 005.333 1.428c1.031 0 2.063-.146 3.073-.448a10.713 10.713 0 006.469-5.453l7.897-15.829c1.296.098 2.543.378 3.863.378 41.167 0 74.667-33.53 74.667-74.734 0-4.525-.668-9.188-1.661-13.955l38.432-19.231a10.69 10.69 0 005.896-9.258 10.687 10.687 0 00-5.376-9.563zM42.667 426.669H21.333v-85.333h21.333v85.333zm135.708-40.302a42.572 42.572 0 01-35.5 19H64v-21.333h78.875c7.167 0 13.802-3.552 17.75-9.5l14.771-22.164 18.318 10.988-15.339 23.009zm68.865-47.584c-7.458 12.406-25.01 16.823-37.427 9.354l-27.052-16.229c-7.458-4.469-12.094-12.656-12.094-21.354a25.12 25.12 0 014.979-14.948l12.564-16.751 70.56 40.711-11.53 19.217zm121.645 19.688L119.25 214.439c-7.76-4.479-12.583-12.833-12.583-21.802a25.076 25.076 0 014.552-14.448L138 139.939l263.967 152.284-33.082 66.248zm32.735-17.808l22.328-44.714 23.695-11.846c.119 1.309.357 2.664.357 3.93 0 27.025-20.283 49.18-46.38 52.63zm14.742-64.769l-266.078-153.5 16.977-24.246a30.042 30.042 0 0124.594-12.813 30.057 30.057 0 0114.906 3.958L478.74 244.71l-62.378 31.184z" />
                      <circle cx={352.002} cy={309.335} r={10.667} />
                    </svg>
                  </div>
                  <p
                    className="cctvTabButtonText"
                    style={{
                      marginLeft: 6,
                      paddingRight: 4,
                      color: openCam === index && '#FFFFFF',
                    }}
                  >
                    {item.name}
                  </p>
                  <p
                    style={{
                      position: 'absolute',
                      paddingBottom: '1.5rem',
                      right: 0.5,
                      fontSize: 45,
                      color: item.is_broken === '1' ? '#F00' : '#0F0',
                    }}
                  >
                    .
                  </p>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={9} lg={10} ref={playerRef}>
          {selectedCam && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    width: 36,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.004 512.004" className="cctvTabButtonSVG" style={{ fill: !isOnline && 'red' }}>
                    <path d="M506.625 235.884L217.344 70.434c-22.313-12.782-52.854-5.891-67.563 15.159L93.74 165.707a46.384 46.384 0 00-8.406 26.701c0 16.588 8.917 32.039 23.26 40.317l61.01 35.233-11.021 14.707c-5.969 7.955-9.25 17.818-9.25 27.765 0 10.229 3.746 19.851 9.78 27.805l-16.238 24.433H64v-32.105c0-5.901-4.771-10.561-10.667-10.561H10.667C4.771 320.002 0 324.662 0 330.563v106.763c0 5.901 4.771 10.676 10.667 10.676h42.667c5.896 0 10.667-4.775 10.667-10.676v-10.657h78.875c21.448 0 41.354-10.654 53.25-28.513l17.484-26.25c3.367.731 6.763 1.394 10.234 1.394 16.979 0 32.958-9.05 41.688-23.625l11.72-19.551 90.78 52.425a10.62 10.62 0 005.333 1.428c1.031 0 2.063-.146 3.073-.448a10.713 10.713 0 006.469-5.453l7.897-15.829c1.296.098 2.543.378 3.863.378 41.167 0 74.667-33.53 74.667-74.734 0-4.525-.668-9.188-1.661-13.955l38.432-19.231a10.69 10.69 0 005.896-9.258 10.687 10.687 0 00-5.376-9.563zM42.667 426.669H21.333v-85.333h21.333v85.333zm135.708-40.302a42.572 42.572 0 01-35.5 19H64v-21.333h78.875c7.167 0 13.802-3.552 17.75-9.5l14.771-22.164 18.318 10.988-15.339 23.009zm68.865-47.584c-7.458 12.406-25.01 16.823-37.427 9.354l-27.052-16.229c-7.458-4.469-12.094-12.656-12.094-21.354a25.12 25.12 0 014.979-14.948l12.564-16.751 70.56 40.711-11.53 19.217zm121.645 19.688L119.25 214.439c-7.76-4.479-12.583-12.833-12.583-21.802a25.076 25.076 0 014.552-14.448L138 139.939l263.967 152.284-33.082 66.248zm32.735-17.808l22.328-44.714 23.695-11.846c.119 1.309.357 2.664.357 3.93 0 27.025-20.283 49.18-46.38 52.63zm14.742-64.769l-266.078-153.5 16.977-24.246a30.042 30.042 0 0124.594-12.813 30.057 30.057 0 0114.906 3.958L478.74 244.71l-62.378 31.184z" />
                    <circle cx={352.002} cy={309.335} r={10.667} />
                  </svg>
                </div>
                <h2
                  className="cctvTabButtonText"
                  style={{
                    marginLeft: 6,
                    fontSize: 24,
                    fontWeight: 600,
                  }}
                >
                  {selectedCam.name}
                </h2>
                <p
                  className="cctvTabButtonText"
                  style={{
                    marginLeft: 6,
                    fontSize: 10,
                    fontWeight: 700,
                  }}
                >
                  <button
                    className="cctvCopyButton"
                    style={{ color: '#FFF' }}
                    onClick={copyToClipBoard(`${selectedCam.rtsp}`)}
                  >CLICK TO COPY RTSP LINK
                  </button> &nbsp;
                  <button
                    className="cctvCopyButton"
                    style={{ color: '#FFF' }}
                    onClick={() => openVLC()}
                  >CLICK TO OPEN VLC
                  </button> {selectedCam.rtsp}
                </p>
              </div>
              <Grid container justify="center" alignItems="center" key={selectedCam.id} className="cctvTabContent">
                {isLoading ? (
                  <CircularProgress style={{ position: 'absolute' }} />
                ) : (
                  <h3 style={{ position: 'absolute' }}>Error loading live CCTV footage.</h3>
                )}
                <div style={{ position: 'relative', width: '100%' }}>
                  <ReactHlsPlayer
                    src={url}
                    autoPlay={false}
                    controls
                    width="100%"
                    height="auto"
                    style={{ zIndex: 2 }}
                    hlsConfig={{
                      manifestLoadingTimeOut: 30000,
                      maxLoadingDelay: 30,
                      minAutoBitrate: 0,
                      lowLatencyMode: true,
                    }}
                  />
                  {(!isOnline) && (
                    <div style={{
                      position: 'absolute',
                      backgroundColor: 'rgba(234, 22, 1,0.3)',
                      top: 0,
                      right: 0,
                      padding: '10px',
                      zIndex: 4,
                      borderRadius: '0 0 0 5px',
                    }}
                    >
                      <OfflineCloud width="40" height="32" />
                      <p style={{ color: '#EA1601', fontSize: 18, textWeight: 600 }}>OFFLINE</p>
                    </div>
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
