import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import {
  getUserFromCookie, getTokenCookie, setCookies, removeCookie,
} from '@Helpers/authUtils';

const kml = [{
  url: 'https://raw.githubusercontent.com/devofotech/dasb-kml/main/PIZZA.kml',
  type: 'kml',
  name: 'PIZZA HUT',
}, {
  url: 'https://raw.githubusercontent.com/devofotech/dasb-kml/main/COMMERCIAL_PROPERTY.kml',
  type: 'kml',
  name: 'COMMERCIAL PROPERTY',
}, {
  url: 'https://raw.githubusercontent.com/devofotech/dasb-kml/main/JLAND_COMMERCIAL_PROPERTY.kml',
  type: 'kml',
  name: 'JLand Commercial Property',
}, {
  url: 'https://raw.githubusercontent.com/devofotech/dasb-kml/main/JLAND_RESIDENTIAL_PROPERTY.kml',
  type: 'kml',
  name: 'JLand Residential Property',
}, {
  url: 'https://raw.githubusercontent.com/devofotech/dasb-kml/main/AYAMAS.kml',
  type: 'kml',
  name: 'Restaurant: Ayamas',
}, {
  url: 'https://raw.githubusercontent.com/devofotech/dasb-kml/main/KPJ.kml',
  type: 'kml',
  name: 'KPJ Hospital',
}, {
  url: 'https://raw.githubusercontent.com/devofotech/dasb-kml/main/KFC.kml',
  type: 'kml',
  name: 'Restaurant: KFC',
}];

export default function Hook() {
  const tokenCookie = getTokenCookie();
  const userCookie = getUserFromCookie();
  const [token, setToken] = useState(tokenCookie);
  const [user, setUser] = useState(userCookie);
  const [buildings, setBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [layers, setLayers] = useState([]);
  const [loginError, setLoginError] = useState('');

  const login = ({ email, password }) => {
    setIsLoading(true);
    setLoginError('');
    Api({
      endpoint: endpoints.getToken(),
      data: { email, password },
      onSuccess: (response) => {
        setToken(response.token);
        setCookies('token', response.token);
        setIsLoading(false);
      },
      onFail: (err) => {
        setToken();
        removeCookie();
        setIsLoading(false);
        setLoginError(err);
      },
    });
  };

  const getUser = (token) => {
    Api({
      endpoint: endpoints.getProfile(),
      token,
      onSuccess: (response) => {
        setUser(response.data);
        if (response.data.dax_role !== 'surian') setLayers(kml);
        setCookies('user', response.data);
      },
      onFail: setUser(),
    });
  };

  useEffect(() => {
    if (token) {
      getUser(token);
      Api({
        endpoint: endpoints.getBuildings(),
        token,
        onSuccess: (response) => {
          setBuildings(response.data);
        },
      });
    }
    // eslint-disable-next-line
    }, [token])

  return {
    login,
    loginError,
    token,
    user,
    setUser,
    buildings,
    selectedBuilding,
    setSelectedBuilding,
    layers,
    building: buildings[selectedBuilding] || {},
    isLoading,
    setIsLoading,
    setLoginError,
  };
}
