import { icon } from 'leaflet';
import React from 'react';
import { Link } from 'react-router-dom';

export default function RoundedButton(props) {
  return (
    <Link to={props.disabled || props.to}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 5,
          cursor: props.disabled ? 'default' : 'pointer',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
            borderRadius: '100%',
            background: props.disabled ? 'rgba(128,128,128,0.1)' : '#1E3465',
            height: 24,
            width: 24,
          }}
        >
          {/* {props.disabled && <text style={{ textAlign: 'center', color: 'red', marginTop: 10 }}>Coming Soon</text>} */}
          {props.disabled ? <icon style={{ opacity: 0.3 }}>{props.children}</icon> : props.children }
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontSize: 12,
              textAlign: 'center',
              color: 'rgba(30,52,101,0.65)',
            }}
          >
            {props.text}
          </p>
        </div>
      </div>
    </Link>
  );
}
