import React from 'react';
import { Grid, Box } from '@material-ui/core';
import MaterialTable from '@Components/MaterialTable';

const columns = [
  {
    name: 'Track',
    selector: 'track',
    align: 'center',
    minWidth: 55,
  },
  {
    name: 'Elements',
    selector: 'elements',
    align: 'left',
    minWidth: 70,
  },
  {
    name: 'Status',
    selector: 'status',
    align: 'center',
    minWidth: 80,
  },
  {
    name: 'Remarks',
    selector: 'remarks',
    align: 'left',
    minWidth: 100,
  },
];

const track_progress = [
  {
    elements: 'Change in Business Model',
    status: 'On Time',
    remarks: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    elements: 'Corporate Restructuring',
    status: 'Delayed',
    remarks: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    elements: 'Financial Restructuring',
    status: 'Pending',
    remarks: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];

export default function TransformationTracksTable() {
  const statusIndicator = (status) => {
    const color = { 'On Time': '#34D2B2', Delayed: '#FD4E6B', Pending: '#FCD55C' }; 
    return (
      <div className="flex row center"> <Box style={{
        backgroundColor: color[status], width: 20, height: 20, borderRadius: 50,
      }}
      />
      </div>
    );
  };

  return (
    <>
      <Grid xs={12} lg={12} justify="space-between" container>
        <h2 style={{ fontWeight: 600 }}>Transformation Tracks Progress</h2>
      </Grid>
      <Grid item xs={12} style={{ padding: 20 }}>
        <MaterialTable
          tableHead
          color="alternate"
          columns={columns}
          tableData={track_progress.map((x, index) => ({ ...x, track: index + 1, status: statusIndicator(x.status) }))}
          tableMinWidth={300}
          tableMaxHeight={900}
        />
      </Grid>
    </>
  );
}
