import React from 'react';
import { VictoryPie, VictoryTooltip } from 'victory';

import data from './PieChartData.json';

const colors = [
  'rgba(255,69,96,1)',
  'rgba(255,69,96,0.35)',
  'rgba(184,94,238,1)',
  'rgba(184,94,238,0.35)',
  'rgba(251,156,93,1)',
  'rgba(251,156,93,0.35)',
  'rgba(0,143,251,1)',
  'rgba(0,143,251,0.35)',
  'rgba(46,204,169,1)',
  'rgba(46,204,169,0.35)',
];

export default function PieChart(props) {
  const total = data.reduce((a, b) => (a + b.expenses), 0);
  console.log('total', total);
  return (
    <div>
      <svg viewBox="0 0 900 660" style={{ overflow: 'visible' }}>
        <VictoryPie
          colorScale={colors}
          standalone={false}
          width={900}
          height={600}
          x="service"
          y="expenses"
          data={props.data || data}
          labelComponent={(
            <VictoryTooltip
              active
              constrainToVisibleArea
              centerOffset={{
                x: ({ datum }) => {
                  return datum.startAngle >= 180 ? -60 : 60;
                },
              }}
              flyoutPadding={{
                top: 10, bottom: 10, right: 20, left: 20,
              }}
              renderInPortal={false}
              pointerLength={20}
              pointerWidth={1}
              style={{ fontFamily: 'Poppins' }}
              flyoutStyle={{
                fill: '#FFFFFF',
                stroke: ({ index }) => colors[index],
              }}
            />
          )}
          style={{
            labels: { fontSize: 16 },
          }}
        />
      </svg>
    </div>
  );
}
