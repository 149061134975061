import React from 'react';
import {
  VictoryBar, VictoryChart, VictoryAxis, VictoryLabel,
} from 'victory';

export default function HorizontalBarChart(props) {
  const axisLabel = {};
  axisLabel.tickLabelComponent = <VictoryLabel dx={10} textAnchor="end" />;
  return (
    <>
      <h2 style={{ fontWeight: 600 }}>{props.title}</h2>
      <div style={{ position: 'relative' }}>
        <VictoryChart
          width={props.width}
          height={props.height}
          padding={{
            left: 100, right: 20, top: 40, bottom: 40,
          }}
        >
          <VictoryBar
            style={{
              data: {
                fill: ({ index }) => (props.colors ? props.colors[index] : '#008FFB'),
              },
              labels: {
                fontSize: 14,
              },
            }}
            cornerRadius={5}
            horizontal
            barWidth={25}
            data={props.data}
            labels={({ datum }) => {
              return `${props.labelPrefix || ''} ${datum.y.toFixed(props.labelToFixed || 0)} ${props.labelPostfix || ''}`;
            }}
            sortKey="x"
            sortOrder="descending"
            labelComponent={(
              <VictoryLabel
                x={100}
                style={{
                  fontSize: 14,
                  fill: '#FFFFFF',
                  textShadow: '1px 1px 5px black',
                  fontFamily: 'Poppins',
                }}
              />
            )}
          />

          <VictoryAxis
            // tickValues specifies both the number of ticks and where
            // they are placed on the axis
            style={{
              tickLabels: {
                fontSize: 11,
                fill: 'rgba(30, 52, 101, 0.65)',
                padding: 20,
                fontFamily: 'Poppins',
              },
              ticks: { size: 0 },
              axis: { stroke: 'transparent' },
              grid: { stroke: 'transparent' },
            }}
            {...axisLabel}
          />
        </VictoryChart>
      </div>
    </>
  );
}
