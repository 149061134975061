import { useEffect, useState } from 'react';
import { endpoints, CCTV } from '@Configs/endpoints';
import { useParams } from 'react-router-dom';
import Api from '@Helpers/api';

export default function Hook() {
  const { building_uuid, cctv_uuid, token } = useParams();
  const [cameras, setCameras] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnline, setIsOnline] = useState()

  const url = `${CCTV}hls/${building_uuid}/${cctv_uuid}/playlist.m3u8`;

  useEffect(() => {
    if (!building_uuid) return;
    if (building_uuid) {
      Api({
        endpoint: endpoints.getCameras(),
        data: { building_uuid },
        onSuccess: (response) => setCameras(response.data),
        onFail: () => setCameras(),
        token,
      });
    }
  }, [building_uuid]);

  useEffect(() => {
    setIsLoading(true);
    const online = Number(cameras?.find(c => c.uuid === cctv_uuid)?.is_broken);
    setIsOnline(!online);
  }, [cameras]);

  useEffect(() => {
    setIsLoading(true);
  }, [cctv_uuid]);

  useEffect(() => {
    if (isLoading) setTimeout(() => setIsLoading(false), 30000);
  }, [isLoading]);

  return {
    cameras,
    building_uuid,
    url,
    isOnline,
    cctv_uuid,
  };
}
