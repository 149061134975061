import { useEffect, useState } from 'react';
import { endpoints } from '@Configs/endpoints';
import Api from '@Helpers/api';
import { groupByYearAndMonth, yearComparisons } from '@Helpers';
import _ from 'lodash';
import moment from 'moment';

const EE = 'EMPLOYEE EARNING';
const EC = 'EMPLOYER CONTRIBUTION';
const OTH = 'TOTAL OVERTIME(HOUR)';
const OTC = 'TOTAL OVERTIME(RM)';
const DPB = 'DEPARTMENT/BUILDING';
const TE = 'TOTAL EMOLUMENT';

const getMonthName = (num) => moment(num, 'MM').format('MMM');
const getKeyListOf = (obj) => {
  const arr = [];
  for (const [key] of Object.entries(obj)) {
    arr.push(key);
  }
  return arr;
};

const createBarGraphData = (obj, y1, y2) => {
  const totalOf = (item, month) => _.sumBy(obj[month], t => t[item]);

  const res = [];
  for (let m = 1; m <= 12; m++) {
    res.push({ x: getMonthName(m), y1: totalOf(y1, m), y2: y2 === 0 ? 0 : totalOf(y2, m) });
  }
  return res;
};
const createPieChartData = (obj, y, dept, sum) => {
  const totalOf = (item, d) => _.sumBy(obj[d], t => t[item]);
  const res = [];

  dept.map((d, i) => {
    const total = totalOf(y, d);
    res.push({
      x: i + 1,
      y: total,
      label: [`${d} (${(total / sum * 100).toPrecision(2)}%)`],
    });
  });
  return res;
};

const companies = [
  {
    name: 'DAMANSARA ASSETS SDN BHD',
  },
  {
    name: 'DASB PROPERTY MANAGEMENT SDN BHD',
  },
  {
    name: 'DASB PARKING SDN BHD',
  },
];

export default function Hook() {
  const [justUpdated, setJustUpdated] = useState(0);
  const [emoluments, set_emoluments] = useState([]);
  const [is_update, set_is_update] = useState(0);
  const [selected_company, set_selected_company] = useState(0);
  const [bar_total_emolument, set_bar_total_emolument] = useState([]);
  const [bar_ot_hour, set_bar_ot_hour] = useState([]);
  const [bar_ot_cost, set_bar_ot_cost] = useState([]);
  const [pie_emolument, set_pie_emolument] = useState([]);
  const [pie_ot_hour, set_pie_ot_hour] = useState([]);
  const [pie_ot_cost, set_pie_ot_cost] = useState([]);

  useEffect(() => {
    Api({
      endpoint: endpoints.getEmolument(companies[selected_company].name),
      onSuccess: ({ data }) => {
        if (data) {
          set_emoluments(data);
          set_is_update(is_update + 1);
        }
      },
      onFail: (err) => console.log(err),
    });
  }, [selected_company, justUpdated]);

  useEffect(() => {
    updateBarGraph();
    updatePieChart();
  }, [is_update]);

  const updateBarGraph = () => {
    const currentYear = yearComparisons(2)[1];
    const emol = groupByYearAndMonth(emoluments);

    if (!_.isEmpty(emol[currentYear])) {
      set_bar_total_emolument(createBarGraphData(emol[currentYear], EC, EE));
      set_bar_ot_hour(createBarGraphData(emol[currentYear], OTH, 0));
      set_bar_ot_cost(createBarGraphData(emol[currentYear], OTC, 0));
    }
  };

  const updatePieChart = () => {
    const totalOf = (item) => _.sumBy(emoluments, t => t[item]);

    const emol = _.groupBy(emoluments, e => e[DPB]);
    const dept = getKeyListOf(emol);

    set_pie_emolument(createPieChartData(emol, TE, dept, totalOf(TE)));
    set_pie_ot_hour(createPieChartData(emol, OTH, dept, totalOf(OTH)));
    set_pie_ot_cost(createPieChartData(emol, OTC, dept, totalOf(OTC)));
  };

  return {
    justUpdated,
    setJustUpdated,
    companies,
    selected_company,
    set_selected_company,
    bar_total_emolument,
    bar_ot_hour,
    bar_ot_cost,
    pie_emolument,
    pie_ot_hour,
    pie_ot_cost,
  };
}
