import Api, { endpoints } from '@Helpers/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Grid, Divider, CircularProgress, Drawer,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';

export default ({ open, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activityLog, setActivityLog] = useState({});

  const loadMore = () => {
    if (isLoading) return;
    setIsLoading(true);
    Api({
      endpoint: activityLog.nextpage ? ['GET', activityLog.nextpage] : endpoints.activityLog(),
      onSuccess: (response) => {
        if (activityLog.data) {
          const prev_data = [...activityLog.data];
          const prev_data_ids = prev_data.map((id) => id);
          const filtered_response_data = response.data.filter(f => !prev_data_ids.includes(f.id));
          setActivityLog({ ...response, data: [...prev_data, ...filtered_response_data] });
          setIsLoading(false);
          return;
        }

        setActivityLog(response);
        setIsLoading(false);
      },
      onFail: () => setIsLoading(false),
    });
  };

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={!!activityLog.nextpage}
        loader={(
          <Grid container justify="center">
            <CircularProgress size={25} />
          </Grid>
        )}
        useWindow={false}
      >
        <Grid container direction="column" style={{ width: 300, padding: '10px 20px' }}>
          <h3 style={{ margin: 'auto' }}>Activity Log</h3>
          {activityLog.data?.map(m => (
            <>
              <Divider />
              <h5 style={{ fontWeight: 600 }}>
                {m.User?.name}
              </h5>
              <small>
                Description: {m.description}<br />
                IP Address: {m.ip_address}<br />
                {moment(m.createdAt).format('DD/MM/YYYY hh:mm a')}<br />
                {moment(m.createdAt).fromNow()}
              </small>
            </>
          ))}
        </Grid>
      </InfiniteScroll>
    </Drawer>
  );
};
