import { Grid } from '@material-ui/core';
import TotalSalesTurnOverChart from '@Components/Leasing/TotalSalesTurnoverChart';
import SalesTurnoverPerMonthChart from '@Components/Leasing/SalesTurnoverPerMonthChart';
import TenantLotChart from '@Components/Leasing/TenantLotChart';
// import TenantLotChart from "@Components/Management/Leasing/TenantLotChart";
import RenewalOfTenanciesChart from '@Components/Leasing/RenewalOfTenanciesChart';
// import RentalRateChart from "@Components/Management/Leasing/RentalRateChart";
import MainContentContainer from '@Components/MainContentContainer';
import Navbar from '@Components/Management/Navbar';
import RentalRateChart from '@Components/Leasing/RentalRateChart';
import OccupancyRateChart from './OccupancyRateChart';
import DebtorsAgingTable from './DebtorsAgingTable';
import Hook from './hook';

function MgmtLeasing(props) {
  const h = Hook(props);
  return (
    <>
      <Navbar />
      <h1 style={{ fontWeight: 700, marginBottom: 10 }}>
        Total Leasing <span style={{ opacity: 0.35, fontWeight: 600 }}>(as of {h.latest_updated})</span>
      </h1>
      <MainContentContainer>
        <Grid container spacing={2} style={{ marginBottom: 30 }}>
          <Grid item xs={12} md={3}>
            <Grid item className="paper" style={{ height: '100%', padding: 10 }}>
              <OccupancyRateChart {...h.totalOR} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container className="paper" style={{ height: '100%', padding: '20px 0 0 10px' }}>
              <Grid item xs={12} md={3}>
                <TotalSalesTurnOverChart {...h} />
              </Grid>
              <Grid item xs={12} md={9}>
                <SalesTurnoverPerMonthChart {...h} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid item className="paper" style={{ padding: 10 }}>
              <RenewalOfTenanciesChart {...h} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid container item xs={12} md={8} spacing={2}>
            <Grid item xs={12}>
              <div className="paper" style={{ padding: 10 }}>
                <TenantLotChart {...h} />
              </div>
            </Grid>
            {/* <Grid item xs={12}>
              <div className="paper" style={{ padding: 10 }}>
                <RentalRateChart {...h} />
              </div>
            </Grid> */}
          </Grid>
          <Grid item xs={12} md={4}>
            <DebtorsAgingTable {...h} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Grid container>
              <Grid item xs={12} />
              <Grid item xs={12} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} />
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default MgmtLeasing;
