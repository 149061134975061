import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default function Hook({ division, project }) {
  const [inspectionSessions, setInspectionSessions] = useState([]);

  const refresh = () => {
    const data = {};
    if (project?.uuid) {
      data.project_uuid = project.uuid;
    } else if (project?.name === 'View All Projects') {
      data.DivisionId = division.id;
    } else {
      data.filterOnly = 'osh';
    }
    Api({
      endpoint: endpoints.getInspectionSession(),
      data,
      onSuccess: (response) => setInspectionSessions(response.data),
      onFail: setInspectionSessions([]),
    });
  };

  useEffect(() => {
    refresh();
  }, [project, division]);

  const deleteInspection = (id) => {
    Api({
      endpoint: endpoints.deleteInspection(id),
      onSuccess: () => {
        refresh();
        toast('success', 'Successfully deleted');
      },
      onFail: () => toast('error', 'Delete fail'),
    });
  };

  const createInspection = (data) => {
    if (!data.name || !data.description || !data.pilot_name) return;
    Api({
      endpoint: endpoints.createInspection(data.project_uuid),
      data,
      onSuccess: () => {
        refresh();
        toast('success', 'Successfully created');
      },
      onFail: () => toast('error', 'Create fail'),
    });
  };
  return {
    inspectionSessions,
    deleteInspection,
    createInspection,
  };
}
