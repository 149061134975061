import moment from 'moment';

const startOfMonth = moment().startOf('month').valueOf();
const isThisMonth = (time) => time > startOfMonth;
const isOverdue = (e) => ['Open', 'In Progress'].includes(e.status) && parseInt(e.due_date) < new Date().getTime();

export const countIssuesCards = (issues) => {
  const cards = { 'Call Off': 0, 'In Progress': 0, 'On Hold': 0, Open: 0, Resolved: 0, Overdue: 0 };

  issues.map(e => {
    if (isThisMonth(e.resolved_date)) {
      cards.Resolved++;
    } else if (isThisMonth(e.created_date)) {
      cards[e.status] += 1;
      if (isOverdue(e)) cards.Overdue += 1;
    }
  });

  return cards;
};

export const countTasksCards = (tasks) => {
  const cards = tasks
    .filter(e => isThisMonth(e.created_date))
    .reduce(
      (a, b) => { a[b.status] += 1; return a; },
      { 'In Progress': 0, 'Pending Review': 0, Overdue: 0 },
    );

  const overdueCount = tasks.filter(
    (e) => isThisMonth(e.created_date) && isOverdue(e),
  ).length;

  return { ...cards, Overdue: overdueCount };
};
