import React from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryGroup,
} from 'victory';

const colors = [
  '#008FFB',
  '#FB9C5D',
  '#B85EEE',
  '#2ECCA9',
  '#FF4560',
  '#008FFBAA',
  '#FB9C5DAA',
  '#B85EEEAA',
  '#2ECCA9AA',
  '#FF4560AA',
];

export default function OverallEmployee(props) {
  return (
    <>
      <h2 style={{ fontWeight: 600 }}>{props.topLabel}</h2>
      <div style={{ position: 'relative' }}>
        <VictoryChart
          // domainPadding will add space to each side of VictoryBar to
          // prevent it from overlapping the axis
          domainPadding={{ x: [125, 125], y: [0, 40] }}
          width={900}
          height={500}
          theme={VictoryTheme.material}
        >
          <VictoryLegend
            x={0}
            y={0}
            orientation='horizontal'
            itemsPerRow={4}
            gutter={32}
            style={{ labels: { fontFamily: 'Poppins', fontSize: 16 } }}
            data={props.legend.map((item, index) => {
              return {
                name: item,
                symbol: { fill: colors[index], type: 'square' },
              };
            })}
          />
          <VictoryAxis
            // tickValues specifies both the number of ticks and where
            // they are placed on the axis
            style={{
              tickLabels: { fontSize: 14, padding: 15 },
              ticks: { size: 0 },
              axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
              grid: { stroke: 'transparent' },
            }}
          />
          <VictoryAxis
            dependentAxis
            axisLabelComponent={<VictoryLabel angle={0} verticalAnchor='start' dy={-150} dx={0} />}
            style={{
              axis: { stroke: '#FFFFFF' },
              tickLabels: {
                fontSize: 14,
                padding: 5,
                fill: 'rgba(30, 52, 101, 0.65)',
              },
              ticks: { stroke: '#FFFFFF', size: 0 },
              grid: { stroke: 'rgba(30, 52, 101, 0.15)', strokeDasharray: false },
            }}
          />
          {!!props.legend.length && (
            <VictoryGroup offset={34} colorScale={colors}>
              {props.legend?.map((e) => (
                <VictoryBar
                  data={props.data}
                  x="x"
                  y={e}
                  barWidth={30}
                  animate={{
                    duration: 2000,
                    onLoad: { duration: 1000 },
                  }}
                  cornerRadius={5}
                  labels={({ datum }) => Math.floor(datum._y)}
                  style={{ labels: { fill: 'white' } }}
                  labelComponent={<VictoryLabel dy={20} />}
                />
              ))}
            </VictoryGroup>
          )}
          {/* {props.topLabel && (
        <VictoryLabel
          textAnchor="left"
          style={{ fontSize: 30, fill: "#1F3566" }}
          x={0}
          y={10}
          text={props.topLabel}
        />
      )} */}
        </VictoryChart>
      </div>
    </>
  );
}
