import React from 'react';
import {
  Paper, Grid, Box, Typography,
} from '@material-ui/core';

export default function BoxTabs({ tab, setTab, items }) {
  const handleChange = (newValue) => {
    setTab(newValue);
  };

  return (
    <Paper elevation={0} className="flex">
      {items.map((x) => (
        <Grid xs={6} item onClick={() => handleChange(x.value)} className="pointer">
          <Box style={{
            backgroundColor: x.value === tab ? '#1E3465' : '#FFF',
            border: '1px solid #1E3465',
            borderRadius: x.borderRadius,
          }}
          >
            <Typography
              variant="subtitle1"
              display="block"
              style={{ color: x.value === tab ? '#FFF' : '#1E3465', fontWeight: 'bold', textAlign: 'center' }}
            >
              {x.label}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Paper>
  );
}
