import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useHook from './hook';
import { ProvisionTable } from './Tables';

const useStyles = makeStyles({
  root: {
    borderRadius: '10px',
    border: '2px solid #1e3465',
    width: '100%',
    paddingBottom: '30px',
  },
  label: {
    padding: '0 0 0 30px',
  },
  detail: {
    padding: '0 30px 0 0',
  },
  px0: {
    padding: '0 30px 0',
  },
});

export default function BuildingData(props) {
  const h = useHook(props);
  const classes = useStyles();

  return (
    <div>
      <h2>Building Data for {props.facilities[props.selectedFacilities]?.NAME}</h2>
      <div className={classes.root}>
        {h.isLoading
          ? <div style={{ paddingBottom: '20px' }}><Skeleton variant="rect" width="35vw" height="42vh" /></div>
          : (
            <img
              className="sidebar-img"
              style={{ paddingBottom: '20px' }}
              src={props.buildings[props.selectedBuilding < 0 ? 7 : props.selectedBuilding]?.image}
              alt="asset"
            />
          )}
        {h.buildingDetails.map(b => (
          <Grid container spacing={4}>
            <Grid item sm={4}>
              <h4 className={classes.label}>{b.label}</h4>
            </Grid>
            <Grid item sm={8}>
              <p className={classes.detail}>
                {h.isLoading ? <><Skeleton variant="text" /><Skeleton variant="text" width="40%" /></> : b.data ?? 'N/A'}
              </p>
            </Grid>
          </Grid>
        ))}
        {!!h.provisionServices.length && (
          <Grid container spacing={4}>
            <Grid item sm={12}>
              <div className={classes.px0}>
                <h4>Provision of Services:</h4>
                <ProvisionTable data={h.provisionServices} {...h} />
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}
