import React from 'react';
import UploadIcon from '@Components/UploadIcon';
import AddIcon from '@material-ui/icons/Add';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileDropZone from '@Components/DropzoneBox';

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '145px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  root: { '&$checked': { color: '#4fd8af' }, color: '#1e3465' },
  checked: {},
}));

export default function AddServiceContract(props) {
  const classes = useStyles();
  return (
    <>
      <button
        onClick={() => props.set_open(true)}
        className="blue-button flex-standard"
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          margin: 0,
        }}
      >
        <UploadIcon />
        <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>Upload Data</p>
      </button>
      <Dialog open={props.open} onClose={() => props.set_open(false)} fullWidth maxWidth="md">
        <DialogTitle>
          <h2>Upload Service Contracts</h2>
        </DialogTitle>
        <DialogContent>
          <FileDropZone files={props.files} setFiles={props.set_files} height={400} type="doc" />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', padding: '10px 24px' }}>
          <Box component="button" className={`${classes.blueBtn } blue-button`} onClick={props.uploadServiceContracts}>
            <h1 className={`${classes.blueBtnText } flex-standard`}>
              <AddIcon />
              Save Data
            </h1>
          </Box>
          <Button onClick={() => props.set_open(false)} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
