import React from "react";
import { VictoryLabel, VictoryBar } from "victory";
import { yearComparisons, monthComparisons } from '@Helpers';

const current_year_label = monthComparisons().format('MMM YY');
const previous_year_label = monthComparisons().subtract(1, 'year').format('MMM YY');

function BarChartPeopleCounting({ footfall: { Total = {} } }) {
  const data = yearComparisons().map((m, i) => ({ x: i + 1, y: Total[m]?.IN || 0 }));
  let percent = (Total[yearComparisons()[1]]?.IN / Total[yearComparisons()[0]]?.IN) - 1;
  if (percent === Infinity) percent = 100;
  if (Number.isNaN(percent)) percent = 0;

  return (
    <>
      <div style={{ position: "relative" }}>
        <svg viewBox="0 0 200 300">
          <VictoryBar
            style={{
              data: {
                fill: ({ datum }) => (datum.x === 1 ? "#1F3566" : "#008FFB"),
              },
              labels: {
                fontSize: 15,
              },
            }}
            cornerRadius={5}
            barRatio={1.8}
            domainPadding={{ x: [20, 20] }}
            data={data}
            labels={({ datum }) => datum.y.toLocaleString()}
            standalone={false}
            width={200}
            height={260}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 14 }}
            x={68}
            y={226}
            text={previous_year_label}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 14 }}
            x={132}
            y={226}
            text={current_year_label}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 28, fill: `${percent < 0 ? '#FF4560' : '#2ecca9'}` }}
            x={96}
            y={260}
            text={`${percent}%`}
          />
        </svg>
      </div>
    </>
  );
}

export default BarChartPeopleCounting;
