import React from 'react';
import { Grid } from '@material-ui/core';
import { VictoryPie, VictoryLegend } from 'victory';
import _ from 'lodash';

import data from './TenancyMixBreakdownData.json';
const colorSet = [
  '#008FFB',
  '#B85EEE',
  '#FB9C5D',
  '#2ECCA9',
  '#008FFB',
  '#B85EEE',
  '#FB9C5D',
  '#2ECCA9',
  '#008FFB',
  '#B85EEE',
  '#FB9C5D',
  '#2ECCA9',
];
function TenancyMixBreakdownChart({ tmb }) {
  const legend = [];
  const dataVal = [];
  Object.keys(tmb).forEach((name, idx) => {
    dataVal.push({ x: idx + 1, y: _.sumBy(tmb[name], (o) => o.VALUE) });
    legend.push({
      name: `${name} (${_.sumBy(tmb[name], (o) => o.VALUE).toLocaleString()} ft²)`,
      symbol: { fill: colorSet[idx], type: 'square' },
    });
  });
  console.log(`dataVal`, dataVal);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <h2 style={{ fontWeight: 600, width: '100%', textAlign: 'center' }}>Tenant Mix Breakdown</h2>
          <svg viewBox='0 0 400 400'>
            <VictoryPie
              colorScale={colorSet}
              standalone={false}
              width={400}
              height={400}
              data={dataVal.length ? dataVal : data}
              innerRadius={64}
              labels={[]}
            />
          </svg>
        </Grid>

        <Grid item xs={12} md={8}>
          <p style={{ fontSize: 14, marginTop: 2, marginBottom: 20 }}>
            Tenant mix breakdown based on Trade Category in percentage of Lettable Area
          </p>
          <svg viewBox='0 0 450 250'>
            <VictoryLegend
              x={0}
              y={0}
              height={300}
              width={300}
              orientation='horizontal'
              standalone={false}
              itemsPerRow={1}
              gutter={40}
              style={{ labels: { fontFamily: 'Poppins', fontSize: 12 } }}
              data={legend}
            />
          </svg>
        </Grid>
      </Grid>
    </>
  );
}

export default TenancyMixBreakdownChart;
