import { Grid } from "@material-ui/core";
import MainContentContainer from "@Components/MainContentContainer";
import MainContentNavbar from "@Components/MainContentNavbar";
import MaterialTable from "@Components/MaterialTable";

let tableData = [
  { level: "Ground Level" },
  { level: "Level 1" },
  { level: "Level 2" },
  { level: "Level 3" },
];

for (let i = 0; i < tableData.length; i++) {
  tableData[i] = {
    ...tableData[i],
    area: "100,000 ft²",
    avgRental: "5.50/ft²",
    rentPerMonth: "RM 250,000",
    rentalPerAnnum: "RM 3,000,000",
    contribution: "25.0%",
    data: [],
  };

  for (let j = 0; j < 10; j++) {
    tableData[i].data.push({
      lotNo: "123",
      tradeName: "Angry Bird Family Entertainment Park",
      area: "30,000 ft²",
      baseRental: "RM 100,000",
      serviceCharges: "RM 2,000",
      total: "RM 102,000",
      contribution: <span className="text-light">3.0%</span>,
    });
  }
}

const columns = [
  {
    name: "Lot No.",
    selector: "lotNo",
    sortable: true,
    align: "right",
    minWidth: 70,
  },
  {
    name: "Trade Name",
    selector: "tradeName",
    sortable: true,
    align: "right",
    minWidth: 150,
  },
  {
    name: "Area",
    selector: "area",
    sortable: true,
    align: "right",
    minWidth: 100,
  },
  {
    name: "Base Rental",
    selector: "baseRental",
    sortable: true,
    align: "right",
    minWidth: 100,
  },
  {
    name: "Service Charges",
    selector: "serviceCharges",
    sortable: true,
    align: "right",
    minWidth: 120,
  },
  {
    name: "Total",
    selector: "total",
    sortable: true,
    align: "right",
    minWidth: 120,
  },
  {
    name: "Contribution",
    selector: "contribution",
    sortable: true,
    align: "right",
  },
];

const subColumns = [
  {
    name: "Level",
    selector: "level",
    sortable: true,
    align: "right",
    colSpan: 2,
  },
  {
    name: "Area",
    selector: "area",
    sortable: true,
    align: "right",
  },
  {
    name: "Avg Rental",
    selector: "avgRental",
    sortable: true,
    align: "right",
  },
  {
    name: "Rent/month",
    selector: "rentPerMonth",
    sortable: true,
    align: "right",
  },
  {
    name: "Rental p.a.",
    selector: "rentalPerAnnum",
    sortable: true,
    align: "right",
  },
  {
    name: "Contribution",
    selector: "contribution",
    sortable: true,
    align: "right",
  },
];

const footerData = [
  [
    { name: "NET TOTAL", colSpan: 2, align: "right" },
    { name: "400,000 ft²", align: "right" },
    { name: "RM 5.50/ft²", align: "right" },
    { name: "RM 1,000,000", align: "right" },
    { name: "RM 12,000,000", align: "right" },
    { name: "100.0%", align: "right" },
  ],
];

function RentalRate(props) {
  return (
    <>
      <MainContentNavbar to="/property/leasing" text="RENTAL RATE" />
      <MainContentContainer>
        <Grid container>
          <Grid item xs={12}>
            <MaterialTable
              header={true}
              columns={columns}
              subColumns={subColumns}
              tableData={tableData}
              footerData={footerData}
              tableMinWidth={500}
              tableMaxHeight={700}
            />
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default RentalRate;
