import React from 'react';
import { Link } from 'react-router-dom';
import MainContentContainer from '@Components/MainContentContainer';
import { Grid } from '@material-ui/core';
import StatusCard from '@Components/AdminPanel/StatusCard';
import ManualInputCard from '@Components/AdminPanel/ManualInputCard';
import MaterialTable from '@Components/MaterialTable';
import moment from 'moment';
import Hook from './hook';

const columns = [
  {
    name: 'User ID',
    selector: 'User.id',
    align: 'left',
    minWidth: 70,
  },
  {
    name: 'Name',
    selector: 'User.name',
    align: 'left',
    minWidth: 70,
  },
  {
    name: 'LogIn Count',
    selector: 'total',
    align: 'left',
    minWidth: 70,
  },
  {
    name: 'Last Login',
    selector: 'last',
    align: 'center',
    minWidth: 220,
  },
];

export default function AdminPanel(props) {
  const h = Hook(props);
  return (
    <>
      <h2 style={{ fontWeight: 700, marginBottom: 10 }}>Admin Panel</h2>
      <MainContentContainer>
        <Grid container style={{ marginBottom: 30 }}>
          <h2 style={{ fontWeight: 600, marginBottom: 10 }}>Server status</h2>
          <Grid item container xs={12} spacing={2} alignItems="baseline">
            {Object.keys(h.serverData).map((m) => (
              <Grid item xs={12} md={4} lg={2}>
                <StatusCard
                  name={m}
                  row_title={['Last update', 'Last backup']}
                  online={h.serverData[m].online}
                  last_update={h.serverData[m].last_updated}
                  last_backup={h.serverData[m].last_backup}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 30 }}>
          <h2 style={{ fontWeight: 600, marginBottom: 10 }}>CCTV panel status</h2>
          <Grid item container xs={12} spacing={2}>
            {Object.keys(h.cctvData).map((m) => (
              <Grid item xs={12} md={4} lg={2}>
                <Link to={`/cctv-status/${m}`}>
                  <StatusCard
                    name={m}
                    online={h.cctvData[m].online}
                    last_update={h.cctvData[m].last_updated}
                    nvr_type={h.cctvData[m].nvr_type}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 30 }}>
          <h2 style={{ fontWeight: 600, marginBottom: 10 }}>VTA status</h2>
          <Grid item container xs={12} spacing={2}>
            {Object.keys(h.vtaData).map((m) => (
              <Grid item xs={12} md={4} lg={2}>
                <StatusCard name={m} row_title={['Last update']} last_update={h.vtaData[m].last_updated} />
              </Grid>
            ))}
          </Grid>
          <Grid item container xs={12} justify="space-between" />
        </Grid>
        <Grid container style={{ marginBottom: 30 }}>
          <h2 style={{ fontWeight: 600, marginBottom: 10 }}>Manual input data</h2>
          <Grid item container xs={12} spacing={2}>
            {Object.keys(h.miData).map((m) => (
              <Grid item xs={12} md={4} lg={3}>
                <ManualInputCard name={m} row_title={['Last update']} last_update={h.miData[m].last_updated} />
              </Grid>
            ))}
          </Grid>
          <Grid item container xs={12} justify="space-between" />
        </Grid>
        <Grid container style={{ marginBottom: 30 }}>
          <h2 style={{ fontWeight: 600, marginBottom: 10 }}>Activity Log</h2>
          <Grid item xs={12} style={{ padding: 20 }}>
            <MaterialTable
              tableHead
              columns={columns}
              tableData={[...h.activityLogs.map(eachRow => ({
                ...eachRow,
                last: moment(eachRow.last).fromNow(),
              }))]}
              tableMinWidth={300}
              tableMaxHeight={900}
            />
          </Grid>
          <Grid item container xs={12} justify="space-between" />
        </Grid>
      </MainContentContainer>
    </>
  );
}
