import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';

function DonutChart(props) {
  const { data, positive, percent, description } = props;
  return (
    <>
      <div style={{ position: 'relative' }}>
        <svg viewBox='0 0 500 450'>
          <VictoryPie
            colorScale={['#1F3566', 'rgba(30, 52, 101, 0.15)']}
            cornerRadius={10}
            standalone={false}
            width={500}
            height={400}
            data={data}
            innerRadius={130}
            labels={[]}
          />
          <VictoryLabel
            textAnchor='middle'
            style={{
              fontSize: 43,
              fill: positive ? '#00E396' : '#FF4560',
            }}
            x={260}
            y={180}
            text={percent}
          />
          <VictoryLabel
            textAnchor='middle'
            lineHeight={1.2}
            style={{
              fontSize: 24,
              fill: 'rgba(30, 52, 101, 0.55)',
              width: 100,
            }}
            x={255}
            y={220}
            text={description}
          />
        </svg>
      </div>
    </>
  );
}

export default DonutChart;
