import React, { useState, useEffect } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { Grid, TextField, Button, MenuItem } from '@material-ui/core';
import moment from 'moment';

const style = {
  button: {
    marginBottom: '1rem',
    backgroundColor: 'rgb(30, 52, 101)',
    borderRadius: 5,
    color: 'white',
    fontWeight: 600,
    fontSize: 12,
  },
  cancel: {
    marginBottom: '1rem',
    backgroundColor: 'red',
    marginRight: 4,
    borderRadius: 5,
    color: 'white',
    fontWeight: 600,
    fontSize: 12,
  },
  input: {
    marginTop: 5,
    backgroundColor: 'transparent !important',
    width: '100%',
    textSize: 6,
  },
};

export default function EditProject({ handleClick, project, ...h }) {
  const [commencementAt, setcommencementAt] = useState( project?.commencementAt ?? null );
  const [expectedCompletionAt, setexpectedCompletionAt] = useState( project?.expectedCompletionAt ?? null );
  const [project_manager, setproject_manager] = useState( project?.project_manager ?? '-' );
  const [contract_no, setcontract_no] = useState(project?.contract_no ?? '-');
  const [contractors, set_contractors] = useState([]);
  const [ContractorId, set_ContractorId] = useState(project?.ContractorId ?? null);

  const handleSave = () => {
    const data = {
      commencementAt,
      expectedCompletionAt,
      project_manager,
      ContractorId,
      contract_no,
    };

    Api({
      endpoint: endpoints.updateProjects(project?.id),
      data,
      onSuccess: () => {
        toast('success', 'Successfully update Project Details'); // eslint-disable-line no-undef
        h.setjustSaved((v) => v + 1);
        handleClick();
      },
      onFail: () => {
        toast('error', 'Unsuccessfully update Project Details'); // eslint-disable-line no-undef
      },
    });
  };

  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        set_contractors(data.Contractors ?? []);
        set_ContractorId(project.ContractorId);
      },
    });
  }, []);

  return (
    <>
      <Grid
        container
        spacing={1}
        style={{ padding: '10px', justifyContent: 'space-evenly' }}
      >
        <Grid item xs={6}>
          <p style={{ fontWeight: 600, paddingTop: 2 }}>Commencement Date</p>
        </Grid>
        <Grid item xs={6}>
          <TextField
            hiddenLabel
            type="date"
            defaultValue={
              commencementAt ? moment(commencementAt).format('YYYY-MM-DD') : '-'
            }
            variant="outlined"
            onChange={(e) => setcommencementAt(`${e.target.value}T00:00:00.000Z`)}
            style={style.input}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <p style={{ fontWeight: 600, paddingTop: 2 }}>Expected Completion</p>
        </Grid>
        <Grid item xs={6}>
          <TextField
            hiddenLabel
            type="date"
            defaultValue={
              expectedCompletionAt
                ? moment(expectedCompletionAt).format('YYYY-MM-DD')
                : '-'
            }
            variant="outlined"
            onChange={(e) => setexpectedCompletionAt(`${e.target.value}T00:00:00.000Z`)}
            style={style.input}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <p style={{ fontWeight: 600, paddingTop: 2 }}>Project Manager</p>
        </Grid>
        <Grid item xs={6}>
          <TextField
            hiddenLabel
            defaultValue={project_manager}
            variant="outlined"
            onChange={(e) => setproject_manager(e.target.value)}
            style={style.input}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <p style={{ fontWeight: 600, paddingTop: 2 }}>Main Contractor</p>
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            value={ContractorId}
            variant="outlined"
            onChange={(e) => set_ContractorId(e.target.value)}
            size="small"
            style={style.input}
          >
            {contractors.map((e) => (
              <MenuItem value={e.id}>{e.name}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <p style={{ fontWeight: 600, paddingTop: 2 }}>No Contract</p>
        </Grid>
        <Grid item xs={6}>
          <TextField
            hiddenLabel
            defaultValue={contract_no}
            variant="outlined"
            onChange={(e) => setcontract_no(e.target.value)}
            style={style.input}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 4 }}>
        <Button style={style.cancel} onClick={handleClick}>Cancel</Button>
        <Button style={style.button} onClick={handleSave}>Save Changes</Button>
      </Grid>
    </>
  );
}
