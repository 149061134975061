import { Grid, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useState } from "react";
import MainContentContainer from "@Components/MainContentContainer";
import MainContentNavbar from "@Components/MainContentNavbar";
import MaterialTable from "@Components/MaterialTable";

const PLACEHOLDER = "https://via.placeholder.com/1920x1080";

let data = [];

for (let i = 0; i < 100; i++) {
  data.push({
    issueNo: 2397,
    site: "Building A",
    issue: (
      <div className="flex-standard">
        <img
          src={PLACEHOLDER}
          alt=""
          width={80}
          style={{
            borderRadius: 10,
            boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",
          }}
        />
        <p style={{ marginLeft: 10, textAlign: "left" }}>
          Servicing Aps120 chassis, note dispenser &amp; acceptor
        </p>
      </div>
    ),
    category: "Accessory",
    department: "Carpark",
    area: "L3",
    status: <p style={{ color: "#2ECCA9" }}>Solved</p>,
    assignee: "Zulkifli Mohd Salleh",
    createdDate: (
      <div>
        <p>24/6/2020</p>
        <p className="text-light">2:03 PM</p>
      </div>
    ),
  });
}

const columns = [
  {
    name: "Issue Number",
    selector: "issueNo",
    sortable: true,
    align: "center",
    minWidth: "120px",
  },
  {
    name: "Site",
    selector: "site",
    sortable: true,
    align: "center",
    minWidth: "120px",
    wrap: true,
  },
  {
    name: "Issue",
    selector: "issue",
    sortable: true,
    wrap: true,
    align: "center",
    minWidth: "400px",
  },
  {
    name: "Category",
    selector: "category",
    sortable: true,
    align: "center",
    minWidth: "120px",
  },
  {
    name: "Department",
    selector: "department",
    sortable: true,
    align: "center",
    minWidth: "120px",
  },
  {
    name: "Area",
    selector: "area",
    sortable: true,
    align: "center",
    minWidth: "100px",
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    align: "center",
    minWidth: "120px",
  },
  {
    name: "Assignee",
    selector: "assignee",
    sortable: true,
    align: "center",
    wrap: true,
    minWidth: "120px",
  },
  {
    name: "Created Date",
    selector: "createdDate",
    sortable: true,
    align: "left",
    wrap: true,
    minWidth: "120px",
  },
];

function IncidenceOccurence(props) {
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState({});

  const handleOpen = (row) => {
    setRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MainContentNavbar to="/integrated-facilities/facilities" text="Incident Occurence" />
      <MainContentContainer>
        <Grid container>
          <Grid item xs={12}>
            <MaterialTable
              header={true}
              columns={columns}
              tableData={data}
              tableMinWidth={1740}
              rowClick={handleOpen}
            />
          </Grid>
        </Grid>
      </MainContentContainer>
      <Modal
        open={open}
        onClose={handleClose}
        className="flex-standard"
        style={{ outline: 0 }}
      >
        <Grid
          container
          className="main-content"
          spacing={3}
          style={{ width: "50%", padding: "20px 60px" }}
        >
          <Grid item xs={12}>
            <Close
              style={{ cursor: "pointer", float: "right" }}
              onClick={handleClose}
            />
          </Grid>
          <Grid item xs={12}>
            <div className="flex-standard">
              <div style={{ maxWidth: "60%" }}>
                <img
                  src={PLACEHOLDER}
                  alt=""
                  width={"100%"}
                  style={{
                    borderRadius: 10,
                    boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item md={12} lg={6}>
            <h2 style={{ marginBottom: 20 }}>General Info</h2>
            <div style={{ marginBottom: 20 }}>
              <p
                className="text-light"
                style={{ fontSize: 12, marginBottom: 10 }}
              >
                Issue
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor
              </p>
            </div>
            <div style={{ marginBottom: 20 }}>
              <p
                className="text-light"
                style={{ fontSize: 12, marginBottom: 10 }}
              >
                Remarks
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor
              </p>
            </div>
            <div
              className="flex-standard"
              style={{
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <div>
                <p
                  className="text-light"
                  style={{ fontSize: 12, marginBottom: 10 }}
                >
                  Category
                </p>
                <p>{row.category}</p>
              </div>
              <div>
                <p
                  className="text-light"
                  style={{ fontSize: 12, marginBottom: 10 }}
                >
                  Department
                </p>
                <p>{row.department}</p>
              </div>
            </div>
            <div
              className="flex-standard"
              style={{
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: 30,
              }}
            >
              <div>
                <p
                  className="text-light"
                  style={{ fontSize: 12, marginBottom: 10 }}
                >
                  Area
                </p>
                <p>{row.area}</p>
              </div>
              <div>
                <p
                  className="text-light"
                  style={{ fontSize: 12, marginBottom: 10 }}
                >
                  Priority
                </p>
                <p>Medium</p>
              </div>
            </div>
          </Grid>
          <Grid item md={12} lg={6}>
            <h2 style={{ marginBottom: 20 }}>Outcome Info</h2>
            <div
              className="flex-standard"
              style={{
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p
                  className="text-light"
                  style={{ fontSize: 12, marginBottom: 10 }}
                >
                  Accepted by
                </p>
                <p>CCA Staff</p>
              </div>
              <div>
                <p
                  className="text-light"
                  style={{ fontSize: 12, marginBottom: 10 }}
                >
                  Accepted by
                </p>
                <p>CCA Staff</p>
              </div>
              <div>
                <p
                  className="text-light"
                  style={{ fontSize: 12, marginBottom: 10 }}
                >
                  Accepted by
                </p>
                <p>CCA Staff</p>
              </div>
            </div>
            <div>
              <p
                className="text-light"
                style={{ fontSize: 12, marginBottom: 10 }}
              >
                Comments
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor
              </p>
            </div>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default IncidenceOccurence;
