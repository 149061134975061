/* eslint-disable max-len */
/* eslint-disable complexity */
/* eslint-disable object-curly-newline */
import {
  Paper, Typography, Select, Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const dummy_data = {
  name: 'comp',
  items: [
    { label: 'Non Compliance', value: 0 },
    { label: 'Compliance', value: 1 },
  ],
};

const dummy_data2 = {
  name: 'practice',
  items: [
    { label: 'Not Allowed', value: 0 },
    { label: 'Good Practice', value: 1 },
  ],
};
export const CustomSelect = ({
  value, items, onChange, ...props
}) => (
  <Select
    disableUnderline
    native
    value={value}
    onChange={onChange}
    className={props.classes.outlineSelect}
    style={{ ...(props.selectedStyle.actbgchild) }}
  >
    {items.map(opt => <option value={opt.id}>{opt.name}</option>)}
  </Select>
);
export const CustomRadioOSH = ({ name, items, ...props }) => (
  <RadioGroup name={name} value={props.annotation.is_compliance ?? 0} onChange={props.FAh.handleChangeCompliance}>
    {items.map(({ value, label }) => (
      <FormControlLabel
        value={value}
        control={
          label === 'Compliance'
            ? <GreenRadio />
            : <Radio />
        }
        label={<Typography style={{ fontSize: '10px', ...(props.selectedStyle.actbg) }}>{label}</Typography>}
      />
    ))}
  </RadioGroup>
);
export const CustomRadioPractice = ({ name, items, ...props }) => {
  console.log(props);
  return (
    <RadioGroup name={name} value={props.annotation.is_good_practice ?? 0} onChange={props.FAh.handleChangePractice}>
      {items.map(({ value, label }) => (
        <FormControlLabel
          value={value}
          control={<Radio />}
          label={<Typography style={{ fontSize: '10px', ...(props.selectedStyle.actbg) }}>{label}</Typography>}
        />
      ))}
    </RadioGroup>
  );
};

export const DynamicHeader = ({
  classes, selectedStyle, FAh, props,
}) => {
  if (props.mainImage['Inspection.InspectionCategoryId'] === 3) {
    return (
      <Select
        disableUnderline
        native
        value={props.workCategoryOptions.find(({ id }) => id === props.annotation.WorkCategoryId)?.id}
        onChange={FAh.handleChangeWorkCategory}
        className={classes.normalSelect}
        style={{ ...(selectedStyle.actbgchild) }}
      >
        {props.workCategoryOptions.map(opt => <option value={opt.id}>{opt.name}</option>)}
      </Select>
    );
  }
  return (
    <Paper className={classes.normalSelectNoItem} style={{ ...(selectedStyle.actbgchild) }}>
      {props.mainImage['Inspection.InspectionCategoryId'] === 1 ? 'OSH CATEGORY' : 'ENVIRONMENT CATEGORY'}
    </Paper>
  );
};
export const DynamicSelect = ({
  classes, selectedStyle, FAh, props,
}) => {
  switch (props.mainImage['Inspection.InspectionCategoryId']) {
    case 1:
      return (
        <CustomSelect
          value={props.oshCategoryOptions.find(({ id }) => id === props.annotation.OshCategoryId)?.id}
          items={props.oshCategoryOptions}
          onChange={FAh.handleChangeOshCategory}
          {...{ classes, selectedStyle, FAh, ...props }}
        />
      );
    case 2:
      return (
        <CustomSelect
          value={props.environmentCategoryOptions.find(({ id }) => id === props.annotation.EnvironmentCategoryId)?.id}
          items={props.environmentCategoryOptions}
          onChange={FAh.handleChangeEnvCategory}
          {...{ classes, selectedStyle, FAh, ...props }}
        />
      );
    case 3:
      return (
        <>
          {!!FAh.workPartFilteredOptions?.length && (
            <CustomSelect
              value={FAh.workPartFilteredOptions?.find(({ id }) => id === props.annotation.WorkPartId)?.id ?? FAh.workPartFilteredOptions[0].id}
              items={FAh.workPartFilteredOptions}
              onChange={FAh.handleChangeWorkPart}
              {...{ classes, selectedStyle, FAh, ...props }}
            />
          )}
          {!!FAh.elementCategoryFilteredOptions?.length && (
            <CustomSelect
              value={FAh.elementCategoryFilteredOptions?.find(({ id }) => id === props.annotation.ElementCategoryId)?.id ?? FAh.elementCategoryFilteredOptions[0].id}
              items={FAh.elementCategoryFilteredOptions}
              onChange={FAh.handleChangeElementCategory}
              {...{ classes, selectedStyle, FAh, ...props }}
            />
          )}
          {!!FAh.annotationTypeFilteredOptions?.length && (
            <CustomSelect
              value={FAh.annotationTypeFilteredOptions?.find(({ id }) => id === props.annotation.AnnotationTypeId)?.id ?? FAh.annotationTypeFilteredOptions[0].id}
              items={FAh.annotationTypeFilteredOptions}
              onChange={FAh.handleChangeAnnotationType}
              {...{ classes, selectedStyle, FAh, ...props }}
            />
          )}
        </>
      );
    default:
      return (
        <CustomSelect
          value={props.oshCategoryOptions.find(({ id }) => id === props.annotation.OshCategoryId)?.id}
          items={props.oshCategoryOptions}
          onChange={FAh.handleChangeOshCategory}
          {...{ classes, selectedStyle, FAh, ...props }}
        />
      );
  }
};
export const DynamicRadio = ({
  classes, selectedStyle, FAh, props,
}) => {
  switch (props.mainImage['Inspection.InspectionCategoryId']) {
    case 1:
    case 2:
      return (
        <CustomRadioOSH {...dummy_data} {...{ classes, selectedStyle, FAh, ...props }} />
      );
    case 3:
      return (
        <CustomRadioPractice {...dummy_data2} {...{ classes, selectedStyle, FAh, ...props }} />
      );
    default:
      return (
        <CustomRadioOSH {...dummy_data} {...{ classes, selectedStyle, FAh, ...props }} />
      );
  }
};
