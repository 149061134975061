import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default function Hook(InspectionCategory) {
  const [compliancesBySite, setCompliancesBySite] = useState([]);
  const [compliancesByCategory, setCompliancesByCategory] = useState([]);
  const [progressQualityBySite, setProgressQualityBySite] = useState([]);
  const [qlassicBySite, setQlassicBySite] = useState([]);

  useEffect(() => {
    let InspectionCategoryId = 1;
    switch (InspectionCategory) {
      case 'osh':
        InspectionCategoryId = 1;
        break;
      case 'environment':
        InspectionCategoryId = 2;
        break;
      case 'quality':
        InspectionCategoryId = 3;
        break;
      default:
        InspectionCategoryId = 1;
        break;
    }
    if (InspectionCategoryId === 3) {
      Api({
        endpoint: endpoints.getQualityProgressSummary(),
        onSuccess: ({ data }) => {
          const mutateData = data.map(eachQuality => ({
            ...eachQuality,
            items: eachQuality.items.map(eachSite => ({
              ...eachSite,
              image: eachSite['Project.Division.image'],
              name: eachSite['Project.name'],
              division_name: eachSite['Project.Division.name'],
            })),
          }));
          setProgressQualityBySite(mutateData);
        },
        onFail: setProgressQualityBySite([]),
      });
      Api({
        endpoint: endpoints.getPreqlassicSummary(),
        onSuccess: ({ data }) => {
          const mutateData = data.map(eachQlassic => ({
            ...eachQlassic,
            items: eachQlassic.items.map(eachSite => ({
              ...eachSite,
              image: eachSite['Project.Division.image'],
              name: eachSite['Project.name'],
              division_name: eachSite['Project.Division.name'],
            })),
          }));
          setQlassicBySite(mutateData);
        },
        onFail: setQlassicBySite([]),
      });
    } else {
      Api({
        endpoint: endpoints.getCompliances(),
        data: { filterOnly: 'osh', compliance_by: 'project', InspectionCategoryId },
        onSuccess: (response) => setCompliancesBySite(response.data),
        onFail: setCompliancesBySite([]),
      });
      Api({
        endpoint: endpoints.getCompliances(),
        data: { filterOnly: 'osh', compliance_by: 'category', InspectionCategoryId },
        onSuccess: (response) => setCompliancesByCategory(response.data),
        onFail: setCompliancesByCategory([]),
      });
    }
  }, []);
  return {
    compliancesBySite,
    compliancesByCategory,
    progressQualityBySite,
    qlassicBySite,
  };
}
