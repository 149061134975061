import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import ReactWordcloud from 'react-wordcloud';
import useHook from './hook';
import IssuesByCategory from './IssuesByCategory';

const wcProps = {
  options: {
    rotations: 3,
    // fontFamily: 'Poppins',
    fontFamily: 'Helvetica Neue',
    fontWeight: 'bold',
    fontSizes: [10, 40],
  },
  maxWords: 60,
};

function MaintenanceTable(props) {
  const h = useHook(props);

  const wordclouds = [
    {
      words: h.countCategory,
      title: 'Category',
      // onClick: e => { if (e.target.tagName === 'text') set_selectedCategory(e.target.innerHTML) },
    },
  ];
  return (
    <>
      {wordclouds.map(w => (
        <Grid item xs={4}>
          <div className="paper" style={{ padding: '10px', margin: '10px' }}>
            <h3 style={{ padding: '7px 0' }}>{w.title}</h3>
            {h.is_loading
              ? <div className="flex-standard" style={{ height: 300 }}><CircularProgress /></div>
              : <ReactWordcloud {...w} {...wcProps} />}
          </div>
        </Grid>
      ))}

      {/* prevent above wordcloud rerender when IssuesByCategory rerender */}
      <IssuesByCategory {...h} wcProps={wcProps} />
    </>
  );
}

export default MaintenanceTable;
