import { Link, useRouteMatch } from 'react-router-dom';

export default function Navbar({ dax_role }) {
  const { path } = useRouteMatch();
  return (
    <div
      className="flex-standard"
      style={{
        background: 'rgba(255,255,255,0.65)',
        marginBottom: 10,
      }}
    >
      {[
        { title: 'Facilities Management', to: '/integrated-facilities/facilities', roles: ['top_management', 'facilities_management'] },
        { title: 'DASB Parking', to: '/integrated-facilities/parking', roles: ['top_management', 'facilities_management', 'parking'] },
        { title: 'DASB Security', to: '/integrated-facilities/security', roles: ['top_management', 'facilities_management', 'security'] },
        // { title: 'DASB Food Services', to: '/integrated-facilities/food-services' },
      ].filter(d => d.roles.includes(dax_role)).map(e => (
        <Link to={e.to}>
          <h3 style={{ margin: '0 30px', opacity: path === e.to ? 1 : 0.65 }}>{e.title}</h3>
        </Link>
      ))}
    </div>
  );
}
