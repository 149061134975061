import { Grid } from "@material-ui/core";
import Table from "@Components/Table";
import MainContentContainer from "@Components/MainContentContainer";
import MainContentNavbar from "@Components/MainContentNavbar";

let data = [];

for (let i = 0; i < 100; i++) {
  data.push({
    no: "1.",
    lotNo: 238,
    tradeName: "Abmann",
    area: 276,
    commencement: "18/7/20",
    expiry: "17/7/22",
    tenure: "2 + 2",
    budget: "RM 14.00",
    achieved: "RM 40.00",
    variance: "RM 26",
    variancePercentage: "65%",
  });
}

const columns = [
  {
    name: "No.",
    selector: "no",
    sortable: true,
    width: "60px",
  },
  {
    name: "Lot No.",
    selector: "lotNo",
    sortable: true,
    right: false,
  },
  {
    name: "Trade Name",
    selector: "tradeName",
    sortable: true,
    right: false,
  },
  {
    name: "Area (ft²)",
    selector: "area",
    sortable: true,
    right: false,
  },
  {
    name: "Commencement",
    selector: "commencement",
    sortable: true,
    right: false,
    width: "100px",
  },
  {
    name: "Expiry",
    selector: "expiry",
    sortable: true,
    right: false,
    width: "100px",
  },
  {
    name: "Tenure",
    selector: "tenure",
    sortable: true,
    right: false,
    width: "100px",
  },
  {
    name: "Budget",
    selector: "budget",
    sortable: true,
    right: false,
    width: "100px",
  },
  {
    name: "Achieved",
    selector: "achieved",
    sortable: true,
    right: false,
    width: "100px",
  },
  {
    name: "Variance",
    selector: "variance",
    sortable: true,
    right: false,
    width: "100px",
  },
  {
    name: "Variance (%)",
    selector: "variancePercentage",
    sortable: true,
    right: false,
    width: "100px",
  },
];

function RenewalStatus(props) {
  return (
    <>
      <MainContentNavbar to="/property/leasing" text="RENEWAL STATUS" />
      <MainContentContainer>
        <Grid container>
          <Grid item xs={12}>
            <Table title="" columns={columns} data={data} />
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default RenewalStatus;
