import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';

export default function CIDBScoreDialog({ open, setOpen, ...props }) {
  const [CIDB, setCIDB] = useState(50);
  const update = () => props.onSave({ CIDB });
  useEffect(() => {
    if (!!open) return;
    setCIDB(open);
  }, [open]);
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Update CIDB</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Update CIDB Score
        </DialogContentText>
        <TextField
          autoFocus
          defaultValue={CIDB}
          margin="dense"
          id="name"
          label="CIDB"
          type="number"
          fullWidth
          onChange={(e) => setCIDB(Number(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => update()} color="primary" autoFocus>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
