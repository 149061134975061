import moment from 'moment';
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default function Hook({ buildings, selectedBuilding }) {
  const uuid = buildings[selectedBuilding]?.uuid;
  const long_current_year_label = moment().format('MMMM YYYY');
  const short_current_year_label = moment().format('MMM YY');
  const [footfall, setFootfall] = useState({});
  const [trafficCount, setTrafficCount] = useState([]);
  const [TBE, setTBE] = useState([]);

  useEffect(() => {
    if (!uuid) return;
    Api({
      endpoint: endpoints.getFootfall(),
      data: { MONTH: moment().format('MM'), uuid },
      onSuccess: ({ data }) => setFootfall(data),
    });
  }, [uuid]);
  useEffect(() => {
    if (Object.keys(footfall).length > 0) {
      const tbe_data = Object.keys(footfall.Detail).map(m => ({ x: m, y: footfall.Detail[m].IN }));
      setTBE(tbe_data);
      const traffic_data = Object
        .keys(footfall.Days)
        .map(m => ({ x: moment(m).format('D MMM'), y: footfall.Days[m].IN, dateActual: moment(m).valueOf() }))
        .sort((a, b) => (a.dateActual > b.dateActual ? 1 : -1));
      setTrafficCount(traffic_data);
    }
  }, [footfall]);

  return {
    footfall,
    long_current_year_label,
    short_current_year_label,
    TBE,
    trafficCount,
  };
}
