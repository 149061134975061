/* eslint-disable max-lines */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Tabs,
  Tab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import UploadIcon from '@Components/UploadIcon';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SelectYear from '@Components/SelectYear';
import SelectMonth from '@Components/SelectMonth';
import NumberFormat from 'react-number-format';
import TabPanel from '@Components/Inspection/TabPanel';
import Hook from './hook';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  smallBtn: { borderRadius: '6px', margin: 0 },
  blueBtn: { borderRadius: '6px', width: '145px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  root: { '&$checked': { color: '#4fd8af' }, color: '#1e3465' },
  checked: {},
}));

export default function ManualDataForm(props) {
  const classes = useStyles();
  const h = Hook(props);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <button
        onClick={h.handleClickOpen}
        className='blue-button flex-standard'
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          margin: 0,
        }}
      >
        <UploadIcon />
        <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>Upload Data</p>
      </button>
      <Dialog open={h.open} onClose={h.handleClose} fullWidth maxWidth='md'>
        <DialogTitle>
          <h2>Manual Upload Data</h2>
          <Grid item container xs={12} spacing={3} alignItems='center'>
            <Grid item xs={6}>
              <FormControl variant='outlined' style={{ width: '100%' }} margin='dense'>
                <InputLabel id='demo-simple-select-outlined-label'>Property Name</InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  onChange={(e) => h.setFacility(e.target.value)}
                  label='Property Name'
                  value={h.Facility}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {props.facilities.length > 0 &&
                    props.facilities.map((m) => <MenuItem value={m.uuid}>{m.NAME}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant='outlined' style={{ width: '100%' }} margin='dense'>
                <InputLabel id='demo-simple-select-outlined-label'>Year</InputLabel>
                <SelectYear onChange={(year) => h.setYear(year)} />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant='outlined' style={{ width: '100%' }} margin='dense'>
                <InputLabel id='demo-simple-select-outlined-label'>Month</InputLabel>
                <SelectMonth onChange={(month) => h.setMonth(month)} />
              </FormControl>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justify='center' style={{ marginBottom: 10 }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label='EEI & Cost' {...a11yProps(0)} />
              <Tab label='LSC' {...a11yProps(1)} />
            </Tabs>
          </Grid>
          <TabPanel value={value} index={0}>
            <Grid item container xs={10} spacing={3} style={{ margin: 'auto' }}>
              <Grid item xs={12}>
                <h2 style={{ color: '#363636', fontWeight: 600, marginBottom: 10 }}>Energy Eficient Index</h2>
                <Grid item container xs={11} style={{ margin: 'auto' }} alignItems='center' spacing={1}>
                  <EEIForm label='GFA (m²)' name='GFA' value={h.input.GFA} onChange={h.handleChange} />
                  <EEIForm
                    label='EEI Monthly (kWh)'
                    name='ENERGY'
                    value={h.input.ENERGY}
                    onChange={h.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <h2 style={{ color: '#363636', fontWeight: 600, marginBottom: 10 }}>Repair Cost Expenditure</h2>
                <Grid item container xs={11} style={{ margin: 'auto' }} alignItems='center' spacing={1}>
                  <EEIForm
                    label='Repair Cost (RM)'
                    name='EXPENDITURE'
                    value={h.input.EXPENDITURE}
                    onChange={h.handleChange}
                  />
                  <EEIForm label='Monthly Budget (RM)' name='BUDGET' value={h.input.BUDGET} onChange={h.handleChange} />
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid item container xs={10} spacing={3} style={{ margin: 'auto' }}>
              <Grid item xs={12}>
                <h2 style={{ color: '#363636', fontWeight: 600, marginBottom: 10 }}>
                  Licensing, Statutory and Calibration Expired Date
                </h2>
                <Grid
                  item
                  container
                  xs={11}
                  alignItems='center'
                  spacing={1}
                  style={{ margin: 'auto', maxHeight: 400, overflow: 'auto' }}
                >
                  {h.LSC.map((m, i) => (
                    <LSCForm
                      name={m.ITEM}
                      date={m.EXPIRY_DATE}
                      onChange={(e) => h.EditLSC(e, i)}
                      removeLSC={() => h.removeLSC(i)}
                      newData={m.newData}
                    />
                  ))}
                  <Button variant='outlined' startIcon={<AddIcon />} onClick={h.AddLSC}>
                    Add New
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', padding: '10px 24px' }}>
          <Box component='button' className={classes.blueBtn + ' blue-button'} onClick={h.handleSave}>
            <h1 className={classes.blueBtnText + ' flex-standard'}>
              <AddIcon />
              Save Data
            </h1>
          </Box>
          <Button onClick={h.handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EEIForm = ({ label, name, value, onChange = () => null }) => {
  return (
    <>
      <Grid item xs={6}>
        <h3>{label}</h3>
      </Grid>
      <Grid item xs={6}>
        <TextField
          name={name}
          value={value}
          onChange={onChange}
          size='small'
          variant='outlined'
          fullWidth
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
      </Grid>
    </>
  );
};

const LSCForm = ({
  name, date, onChange = () => null, removeLSC = () => null,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={6}>
        <TextField name={name} defaultValue={name} onChange={onChange} size="small" variant="outlined" fullWidth type="text" />
      </Grid>
      <Grid item xs={5}>
        <TextField name={name} value={date} onChange={onChange} size="small" variant="outlined" fullWidth type="date" />
      </Grid>
      <Grid item xs={1}>
        <Box
          component="button"
          className={`${classes.smallBtn} blue-button`}
          onClick={removeLSC}
        >
          <h1 className={`${classes.blueBtnText} flex-standard`}>
            <Close />
          </h1>
        </Box>
      </Grid>
    </>
  );
};
