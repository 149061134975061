import { Grid } from '@material-ui/core';
import Compliance from '../components/Compliance';
import Hook from './hook';
import QualityDashboard from './QualityDashboard';

export default function content() {
  const h = Hook('quality');
  return (
    <Grid container>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={9}>
          <QualityDashboard navbar={false} h={h} />
        </Grid>
        <Grid item xs={3}>
          <Compliance
            data={h.progressQualityBySite}
            title={['Sites with progress ahead', 'Sites with progress delayed']}
          />
          <Compliance
            data={h.qlassicBySite}
            title={['Sites with highest PREQLASSIC score', 'Sites with highest CIDB score']}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
