/* eslint-disable complexity */
import { useState, useEffect } from 'react';
import moment from 'moment';
import Api, { endpoints } from '@Helpers/api';

export default () => {
  const [activeStep, setActiveStep] = useState(0);
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month() + 1);
  const [isLoading, setIsLoading] = useState(false);
  const [justUpdated, setJustUpdated] = useState(0);
  const [debtorsAgingGroup, setDebtorsAgingGroup] = useState([]);
  const [incidenceTypes, setIncidenceTypes] = useState([]);
  const [securityManningGroup, setSecurityManningGroup] = useState([]);

  // data input
  const [inputFinancial, setInputFinancial] = useState({});
  const [inputDebtorAging, setInputDebtorAging] = useState([]);
  const [inputSecurityManning, setInputSecurityManning] = useState([]);
  const [inputIncidence, setInputIncidence] = useState([]);

  // data from source
  const [financialData, setFinancialData] = useState([]);
  const [debtorAgingData, setDebtorAgingData] = useState([]);
  const [securityManningData, setSecurityManningData] = useState([]);
  const [incidenceData, setIncidenceData] = useState([]);

  // selected Data
  const [selectedFinancialData, setSelectedFinancialData] = useState({});
  const [selectedDebtorAgingData, setSelectedDebtorAgingData] = useState([]);
  const [selectedSecurityManningData, setSelectedSecurityManningData] = useState([]);
  const [selectedIncidenceData, setSelectedIncidenceData] = useState([]);
  const findByYearAndMonth = x => x.year === year && x.month === month;
  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setDebtorsAgingGroup(data.SecurityDebtorAgingGroup ?? []);
        setSecurityManningGroup(data.ProjectSecurity ?? []);
        setIncidenceTypes(data.SecurityIncidenceType ?? []);
        // load other static here
      },
    });
    Api({
      endpoint: endpoints.getSecurityincidence(),
      onSuccess: ({ data }) => {
        setIncidenceData(data)
        setSelectedIncidenceData(data.filter(findByYearAndMonth) ?? []);
      },
    });
    Api({
      endpoint: endpoints.getSecuritymanning(),
      onSuccess: ({ data }) => {
        setSecurityManningData(data)
        setSelectedSecurityManningData(data.filter(findByYearAndMonth) ?? []);
      },
    });
    Api({
      endpoint: endpoints.getSecuritydebtoraging(),
      onSuccess: ({ data }) => {
        setDebtorAgingData(data)
        setSelectedDebtorAgingData(data.filter(findByYearAndMonth) ?? []);
      },
    });
    Api({
      endpoint: endpoints.getSecurityfinancial(),
      onSuccess: ({ data }) => {
        setFinancialData(data);
        setSelectedFinancialData(data.find(findByYearAndMonth) ?? {});
      },
    });
  }, [justUpdated]);
  useEffect(() => {
    if (!year) {
      setYear(moment().year());
      return;
    }
    if (!month) {
      setMonth(moment().month() + 1);
      return;
    }
    setInputFinancial({});
    setInputDebtorAging([]);
    setInputSecurityManning([]);
    setInputIncidence([]);
    if (financialData) {
      setSelectedFinancialData(financialData.find(findByYearAndMonth) ?? {});
    }
    if (debtorAgingData) {
      setSelectedDebtorAgingData(debtorAgingData.filter(findByYearAndMonth) ?? []);
    }
    if (securityManningData) {
      setSelectedSecurityManningData(securityManningData.filter(findByYearAndMonth) ?? []);
    }
    if (incidenceData) {
      setSelectedIncidenceData(incidenceData.filter(findByYearAndMonth) ?? []);
    }
  }, [year, month]);
  const handleNextStep = () => { setActiveStep((prev) => prev + 1); };
  const handleBackStep = () => { setActiveStep((prev) => prev - 1); };
  const handleSave = () => {
    Api({
      endpoint: endpoints.createSecurityincidence(),
      data: inputIncidence,
      onSuccess: () => {
        toast('success', 'Successfully update incidence details'); // eslint-disable-line no-undef
        setJustUpdated(v => v + 1);
      },
      onFail: () => {
        toast('error', 'Fail to update incidence details'); // eslint-disable-line no-undef
      },
    });
    Api({
      endpoint: endpoints.createSecuritymanning(),
      data: inputSecurityManning,
      onSuccess: () => {
        toast('success', 'Successfully update manning details'); // eslint-disable-line no-undef
        setJustUpdated(v => v + 1);
      },
      onFail: () => {
        toast('error', 'Fail to update manning details'); // eslint-disable-line no-undef
      },
    });
    Api({
      endpoint: endpoints.createSecuritydebtoraging(),
      data: inputDebtorAging,
      onSuccess: () => {
        toast('success', 'Successfully update debtor aging'); // eslint-disable-line no-undef
        setJustUpdated(v => v + 1);
      },
      onFail: () => {
        toast('error', 'Fail to update debtor aging'); // eslint-disable-line no-undef
      },
    });
    Api({
      endpoint: endpoints.createSecurityfinancial(),
      data: inputFinancial,
      onSuccess: () => {
        toast('success', 'Successfully update financial details'); // eslint-disable-line no-undef
        setJustUpdated(v => v + 1);
      },
      onFail: () => {
        toast('error', 'Fail to update financial details'); // eslint-disable-line no-undef
      },
    });
  };
  return {
    activeStep,
    setActiveStep,
    year,
    setYear,
    month,
    setMonth,
    handleNextStep,
    handleBackStep,
    handleSave,
    isLoading,
    incidenceTypes,
    debtorsAgingGroup,
    securityManningGroup,
    inputFinancial,
    setInputFinancial,
    inputDebtorAging,
    setInputDebtorAging,
    inputSecurityManning,
    setInputSecurityManning,
    inputIncidence,
    setInputIncidence,
    financialData,
    debtorAgingData,
    securityManningData,
    incidenceData,
    selectedFinancialData,
    selectedDebtorAgingData,
    selectedSecurityManningData,
    selectedIncidenceData,
  };
};
