import React from 'react';
import {
  Dialog, DialogActions, DialogContent, Button, DialogTitle,
} from '@material-ui/core';

function ExpireTableDialog({
  setOpenExpiryDialog, openExpiryDialog, selectedTenant, setSelectedTenant,
}) {
  return (
    <Dialog open={openExpiryDialog} onClose={() => setOpenExpiryDialog(false)}>
      <DialogTitle><h3>Tenant Details</h3></DialogTitle>
      <DialogContent>
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4>Name: </h4>&nbsp;
            <span>{selectedTenant.name}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4>Lot No:</h4>&nbsp;
            <span>{selectedTenant.lot_no}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4>Rental:</h4>&nbsp;
            <span>RM {selectedTenant.rental}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4>Area: </h4>&nbsp;
            <span>{selectedTenant.area} sqft</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4>Trade Category:</h4>&nbsp;
            <span>{selectedTenant.TenantTradeCategory?.name}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4>Tenant Renewed Status:</h4>&nbsp;
            <span>{selectedTenant.TenantRenewedStatus?.name}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4>Tenant Bumi Status:</h4>&nbsp;
            <span>{selectedTenant.TenantBumiStatus?.name}</span>
          </div>
        </>
      </DialogContent>
      <DialogActions><Button onClick={() => setOpenExpiryDialog(false)}>Close</Button></DialogActions>
    </Dialog>
  );
}

export default ExpireTableDialog;
