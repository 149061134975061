const styles = {
  img: {
    borderRadius: 50,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'white',
    shadowColor: '#000',
    shadowOpacity: 0.8,
    shadowRadius: 4,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    elevation: 4,
    marginRight: 4,
    width: 40,
  },
  footer: {
    position: 'fixed',
    bottom: 10,
    width: '100%',
  },
  wrapper: {
    position: 'relative',
  },

  box: {
    position: 'absolute',
    backgroundColor: 'transparent',
  },
};

export default (item) => {
  let title = item['InspectionFile.Inspection.name'];
  let subTitle = item.is_compliance ? 'Compliance' : 'Non Compliance';
  let annotation_color = item.is_compliance ? 'green' : 'red';
  if (item['InspectionFile.Inspection.InspectionCategoryId'] === 3) {
    title = item['InspectionFile.Inspection.name'];
    subTitle = `Formwork dimensions - ${item.is_good_practice ? 'Good Practice' : 'Not Allowed'}`;
    annotation_color = item.is_good_practice ? 'green' : 'red';
  }

  const img_size = { width: 750, height: 600 };
  const wratio = 985 / img_size.width;
  const hratio = 738.75 / img_size.height;

  return (
    <div className="flex" style={{ borderRadius: 5, backgroundColor: 'white', width: '-webkit-fill-available' }}>
      <div style={styles.wrapper}>
        <img src={item.path} loading="lazy" style={{ objectFit: 'cover', width: `${img_size.width}px`, height: `${img_size.height}px` }} />
        <div style={{
          ...styles.box,
          top: `${item.points.top / hratio}px`,
          left: `${item.points.left / wratio}px`,
          width: `${item.points.width / wratio}px`,
          height: `${item.points.height / hratio}px`,
          border: `2px solid ${annotation_color}`,
        }}
        />
      </div>

      <div style={{
        textAlign: 'left', padding: 20, marginRight: 20, height: '100%',
      }}
      >
        <p><b>{title}</b></p>
        <p className="text-light">{subTitle}</p>
        <p style={{ marginTop: 30 }}>{item.description}</p>

        <div style={styles.footer}>
          <p className="text-light" style={{ fontSize: '14px' }}>Commented by</p>
          <div className="flex row middle">
            <img
              src={item['User.image'] ?? `https://ui-avatars.com/api/?name=${item['User.name']}`}
              style={styles.img}
              loading="lazy"
            />
            <p><b>{item['User.name']}</b></p>
          </div>
        </div>

      </div>
    </div>
  );
};
