export const OnlineCloud = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 31.969 23.995"
      {...props}
    >
      <path
        id="Path_1783"
        data-name="Path 1783"
        d="M24.114,87.846H7.777A7.852,7.852,0,0,1,0,79.938a7.956,7.956,0,0,1,5.176-7.466,11.031,11.031,0,0,1,21.539.05,7.889,7.889,0,0,1-2.6,15.324ZM15.946,66.375a8.586,8.586,0,0,0-8.455,7.279l-.124.834-.821.193a5.425,5.425,0,0,0,1.244,10.67H24.114A5.432,5.432,0,0,0,29.4,79.926a5.375,5.375,0,0,0-4.063-5.195l-.821-.193-.124-.834A8.611,8.611,0,0,0,15.946,66.375Z"
        transform="translate(0 -63.851)"
        fill="#4fd8af"
      />
    </svg>
  );
};

export const OfflineCloud = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 31.969 23.995"
      {...props}
    >
      <g
        id="nvr_offline"
        data-name="nvr offline"
        transform="translate(0 -63.851)"
      >
        <path
          id="Path_1783"
          data-name="Path 1783"
          d="M24.114,87.846H7.777A7.852,7.852,0,0,1,0,79.938a7.956,7.956,0,0,1,5.176-7.466,11.031,11.031,0,0,1,21.539.05,7.889,7.889,0,0,1-2.6,15.324ZM15.946,66.375a8.586,8.586,0,0,0-8.455,7.279l-.124.834-.821.193a5.425,5.425,0,0,0,1.244,10.67H24.114A5.432,5.432,0,0,0,29.4,79.926a5.375,5.375,0,0,0-4.063-5.195l-.821-.193-.124-.834A8.611,8.611,0,0,0,15.946,66.375Z"
          fill="#ea1601"
        />
        <path
          id="Path_1784"
          data-name="Path 1784"
          d="M197.224,222.227l-1.761-1.761-2.389,2.383-2.389-2.383-1.761,1.761,2.389,2.383L188.924,227l1.761,1.761,2.389-2.389,2.389,2.389L197.224,227l-2.389-2.389Z"
          transform="translate(-177.128 -146.836)"
          fill="#ea1601"
        />
      </g>
    </svg>
  );
};

export const Updated = (props) => {
  return (
    <svg
      id="updated"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 21.455 24.002"
      {...props}
    >
      <path
        id="Path_1785"
        data-name="Path 1785"
        d="M1.514,40.486a9.1,9.1,0,0,0,3.876,7.457.751.751,0,0,1,.038,1.206l-.011.009a.749.749,0,0,1-.892.028A10.612,10.612,0,0,1,0,40.486,10.731,10.731,0,0,1,10.655,29.863v1.515a9.216,9.216,0,0,0-9.141,9.109Zm0,0"
        transform="translate(0 -28.463)"
        fill="#4fd8af"
      />
      <path
        id="Path_1786"
        data-name="Path 1786"
        d="M225.061.045l2.783,1.9a.257.257,0,0,1,0,.424l-2.783,1.9a.257.257,0,0,1-.4-.212V.257a.257.257,0,0,1,.4-.212Zm0,0"
        transform="translate(-214.128 0)"
        fill="#1ab975"
      />
      <path
        id="Path_1787"
        data-name="Path 1787"
        d="M239.7,75.887a9.1,9.1,0,0,0-3.876-7.457.751.751,0,0,1-.038-1.206l.011-.009a.749.749,0,0,1,.892-.028,10.612,10.612,0,0,1,4.526,8.7,10.724,10.724,0,0,1-10.647,10.624V85a9.209,9.209,0,0,0,9.133-9.109Zm0,0"
        transform="translate(-219.755 -63.909)"
        fill="#4fd8af"
      />
      <path
        id="Path_1788"
        data-name="Path 1788"
        d="M165.6,424.076l-2.783-1.9a.257.257,0,0,1,0-.424l2.783-1.9a.257.257,0,0,1,.4.212v3.808A.257.257,0,0,1,165.6,424.076Zm0,0"
        transform="translate(-155.074 -400.119)"
        fill="#1ab975"
      />
      <path
        id="Path_1789"
        data-name="Path 1789"
        d="M128.611,187.318l-4.559,3.781-1.149.953a1.5,1.5,0,0,1-2.164-.255l-1.773-2.363a1.209,1.209,0,0,1,1.934-1.451l1.2,1.6.01-.008,4.961-4.114a1.209,1.209,0,1,1,1.543,1.861Zm0,0"
        transform="translate(-113.159 -176.498)"
        fill="#1ed688"
      />
      <path
        id="Path_1790"
        data-name="Path 1790"
        d="M197.387,187.318l-4.559,3.781a6.124,6.124,0,0,1-1.945-1.528l4.961-4.114a1.209,1.209,0,0,1,1.543,1.861Zm0,0"
        transform="translate(-181.935 -176.498)"
        fill="#4fd8af"
      />
    </svg>
  );
};

export const Pending = (props) => {
  return (
    <svg
      id="updated"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 21.455 24.002"
      {...props}
    >
      <path
        id="Path_1785"
        data-name="Path 1785"
        d="M1.514,40.486a9.1,9.1,0,0,0,3.876,7.457.751.751,0,0,1,.038,1.206l-.011.009a.749.749,0,0,1-.892.028A10.612,10.612,0,0,1,0,40.486,10.731,10.731,0,0,1,10.655,29.863v1.515a9.216,9.216,0,0,0-9.141,9.109Zm0,0"
        transform="translate(0 -28.463)"
        fill="#ea1601"
      />
      <path
        id="Path_1786"
        data-name="Path 1786"
        d="M225.061.045l2.783,1.9a.257.257,0,0,1,0,.424l-2.783,1.9a.257.257,0,0,1-.4-.212V.257a.257.257,0,0,1,.4-.212Zm0,0"
        transform="translate(-214.128 0)"
        fill="#df1f0b"
      />
      <path
        id="Path_1787"
        data-name="Path 1787"
        d="M239.7,75.887a9.1,9.1,0,0,0-3.876-7.457.751.751,0,0,1-.038-1.206l.011-.009a.749.749,0,0,1,.892-.028,10.612,10.612,0,0,1,4.526,8.7,10.724,10.724,0,0,1-10.647,10.624V85a9.209,9.209,0,0,0,9.133-9.109Zm0,0"
        transform="translate(-219.755 -63.909)"
        fill="#ea1601"
      />
      <path
        id="Path_1788"
        data-name="Path 1788"
        d="M165.6,424.076l-2.783-1.9a.257.257,0,0,1,0-.424l2.783-1.9a.257.257,0,0,1,.4.212v3.808A.257.257,0,0,1,165.6,424.076Zm0,0"
        transform="translate(-155.074 -400.119)"
        fill="#df1f0b"
      />
    </svg>
  );
};

export const User = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 25.168 28.065"
      {...props}
    >
      <g
        id="Icon_feather-user"
        data-name="Icon feather-user"
        transform="translate(1 1)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M27.168,23.688v-2.9A5.792,5.792,0,0,0,21.376,15H9.792A5.792,5.792,0,0,0,4,20.792v2.9"
          transform="translate(-4 2.376)"
          fill="none"
          stroke="#1e3465"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M19.584,8.792A5.792,5.792,0,1,1,13.792,3a5.792,5.792,0,0,1,5.792,5.792Z"
          transform="translate(-2.208 -3)"
          fill="none"
          stroke="#1e3465"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export const Lock = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 28.208 31.12"
      {...props}
    >
      <g
        id="Icon_feather-lock"
        data-name="Icon feather-lock"
        transform="translate(1 1)"
      >
        <path
          id="Path_3"
          data-name="Path 3"
          d="M5.912,11H26.3a2.912,2.912,0,0,1,2.912,2.912V24.1A2.912,2.912,0,0,1,26.3,27.016H5.912A2.912,2.912,0,0,1,3,24.1V13.912A2.912,2.912,0,0,1,5.912,11Z"
          transform="translate(-3 2.104)"
          fill="none"
          stroke="#1e3465"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M7,15.1V9.28a7.28,7.28,0,1,1,14.56,0V15.1"
          transform="translate(-1.176 -2)"
          fill="none"
          stroke="#1e3465"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export const CCTVIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="#FFFFFF"
      {...props}
    >
      <path d="M506.625 235.884L217.344 70.434c-22.313-12.782-52.854-5.891-67.563 15.159L93.74 165.707a46.384 46.384 0 00-8.406 26.701c0 16.588 8.917 32.039 23.26 40.317l61.01 35.233-11.021 14.707c-5.969 7.955-9.25 17.818-9.25 27.765 0 10.229 3.746 19.851 9.78 27.805l-16.238 24.433H64v-32.105c0-5.901-4.771-10.561-10.667-10.561H10.667C4.771 320.002 0 324.662 0 330.563v106.763c0 5.901 4.771 10.676 10.667 10.676h42.667c5.896 0 10.667-4.775 10.667-10.676v-10.657h78.875c21.448 0 41.354-10.654 53.25-28.513l17.484-26.25c3.367.731 6.763 1.394 10.234 1.394 16.979 0 32.958-9.05 41.688-23.625l11.72-19.551 90.78 52.425a10.62 10.62 0 005.333 1.428c1.031 0 2.063-.146 3.073-.448a10.713 10.713 0 006.469-5.453l7.897-15.829c1.296.098 2.543.378 3.863.378 41.167 0 74.667-33.53 74.667-74.734 0-4.525-.668-9.188-1.661-13.955l38.432-19.231a10.69 10.69 0 005.896-9.258 10.687 10.687 0 00-5.376-9.563zM42.667 426.669H21.333v-85.333h21.333v85.333zm135.708-40.302a42.572 42.572 0 01-35.5 19H64v-21.333h78.875c7.167 0 13.802-3.552 17.75-9.5l14.771-22.164 18.318 10.988-15.339 23.009zm68.865-47.584c-7.458 12.406-25.01 16.823-37.427 9.354l-27.052-16.229c-7.458-4.469-12.094-12.656-12.094-21.354a25.12 25.12 0 014.979-14.948l12.564-16.751 70.56 40.711-11.53 19.217zm121.645 19.688L119.25 214.439c-7.76-4.479-12.583-12.833-12.583-21.802a25.076 25.076 0 014.552-14.448L138 139.939l263.967 152.284-33.082 66.248zm32.735-17.808l22.328-44.714 23.695-11.846c.119 1.309.357 2.664.357 3.93 0 27.025-20.283 49.18-46.38 52.63zm14.742-64.769l-266.078-153.5 16.977-24.246a30.042 30.042 0 0124.594-12.813 30.057 30.057 0 0114.906 3.958L478.74 244.71l-62.378 31.184z" />
      <circle cx={352.002} cy={309.335} r={10.667} />
    </svg>
  );
};

export const Cube3D = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#ffffff"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="m255.989 0-221.693 127.993v255.985l221.693 127.994 221.688-127.994v-255.985zm176.683 136.651-176.683 101.965-176.688-101.965 176.688-102.01zm-368.376 25.977 176.693 101.969v204.074l-176.693-102.013zm206.693 306.043v-204.074l176.688-101.968v204.03z" />
    </svg>
  );
};

export const Icon360 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#ffffff"
      viewBox="0 0 512 420"
      {...props}
    >
      <path d="m322.285156 335.644531c-7.441406 0-13.898437-5.53125-14.863281-13.105469-1.042969-8.21875 4.769531-15.726562 12.984375-16.773437 47.398438-6.039063 89.84375-18.882813 119.515625-36.171875 27.136719-15.808594 42.078125-34.394531 42.078125-52.332031 0-19.769531-17.484375-35.945313-32.15625-46.039063-6.824219-4.695312-8.550781-14.03125-3.855469-20.859375 4.695313-6.824219 14.035157-8.550781 20.859375-3.855469 29.539063 20.320313 45.152344 44.785157 45.152344 70.757813 0 29.476563-19.699219 56.535156-56.972656 78.25-33.550782 19.546875-78.789063 33.382813-130.828125 40.011719-.644531.078125-1.285157.117187-1.914063.117187zm0 0" />
      <path d="m252.34375 314.15625-40-40c-5.859375-5.859375-15.355469-5.859375-21.214844 0-5.855468 5.855469-5.855468 15.355469 0 21.210938l11.6875 11.6875c-44.8125-4.628907-85.523437-15.0625-117.046875-30.222657-35.441406-17.042969-55.769531-38.757812-55.769531-59.570312 0-17.652344 14.554688-36 40.980469-51.664063 7.128906-4.222656 9.480469-13.425781 5.257812-20.550781-4.226562-7.128906-13.429687-9.480469-20.554687-5.257813-46.023438 27.28125-55.683594 57.1875-55.683594 77.472657 0 33.28125 25.84375 64.039062 72.769531 86.609375 36.421875 17.511718 83.535157 29.242187 134.863281 33.78125l-16.503906 16.503906c-5.855468 5.855469-5.855468 15.355469 0 21.214844 2.929688 2.925781 6.769532 4.390625 10.609375 4.390625 3.835938 0 7.675781-1.464844 10.605469-4.390625l40-40c5.855469-5.859375 5.855469-15.359375 0-21.214844zm0 0" />
      <path d="m157.097656 187.222656v-3.609375c0-12.730469-7.792968-15.199219-18.242187-15.199219-6.460938 0-8.550781-5.699218-8.550781-11.398437 0-5.703125 2.089843-11.402344 8.550781-11.402344 7.21875 0 14.820312-.949219 14.820312-16.339843 0-11.019532-6.269531-13.679688-14.0625-13.679688-9.308593 0-14.058593 2.28125-14.058593 9.691406 0 6.457032-2.851563 10.828125-13.871094 10.828125-13.679688 0-15.386719-2.851562-15.386719-11.972656 0-14.816406 10.636719-34.007813 43.316406-34.007813 24.132813 0 42.371094 8.738282 42.371094 34.390626 0 13.867187-5.128906 26.789062-14.628906 31.160156 11.210937 4.179687 19.378906 12.539062 19.378906 27.929687v3.609375c0 31.160156-21.46875 42.941406-48.070313 42.941406-32.679687 0-45.21875-19.949218-45.21875-35.910156 0-8.550781 3.609376-10.832031 14.058594-10.832031 12.160156 0 15.199219 2.660156 15.199219 9.882813 0 8.929687 8.363281 11.019531 16.910156 11.019531 12.921875 0 17.484375-4.75 17.484375-17.101563zm0 0" />
      <path d="m302.066406 183.613281v1.710938c0 32.679687-20.332031 44.839843-46.550781 44.839843s-46.742187-12.160156-46.742187-44.839843v-50.351563c0-32.679687 21.089843-44.839844 48.453124-44.839844 32.109376 0 44.839844 19.949219 44.839844 35.71875 0 9.121094-4.371094 11.96875-13.871094 11.96875-8.167968 0-15.390624-2.089843-15.390624-10.828124 0-7.21875-7.597657-11.019532-16.527344-11.019532-11.210938 0-17.863282 5.890625-17.863282 19v17.097656c6.082032-6.648437 14.632813-8.359374 23.753907-8.359374 21.65625 0 39.898437 9.5 39.898437 39.902343zm-63.652344 3.800781c0 13.109376 6.460938 18.808594 17.101563 18.808594s16.910156-5.699218 16.910156-18.808594v-1.710937c0-13.871094-6.269531-19.191406-17.101562-19.191406-10.257813 0-16.910157 4.941406-16.910157 17.480469zm0 0" />
      <path d="m325.054688 185.324219v-50.351563c0-32.679687 20.328124-44.839844 46.550781-44.839844 26.21875 0 46.738281 12.160157 46.738281 44.839844v50.351563c0 32.679687-20.519531 44.839843-46.738281 44.839843-26.222657 0-46.550781-12.160156-46.550781-44.839843zm63.648437-50.351563c0-13.109375-6.457031-19-17.097656-19s-16.910157 5.890625-16.910157 19v50.351563c0 13.109375 6.269532 19 16.910157 19s17.097656-5.890625 17.097656-19zm0 0" />
      <path d="m454.351562 90c-24.816406 0-45-20.1875-45-45s20.183594-45 45-45c24.8125 0 45 20.1875 45 45s-20.1875 45-45 45zm0-60c-8.273437 0-15 6.730469-15 15 0 8.273438 6.726563 15 15 15 8.269532 0 15-6.726562 15-15 0-8.269531-6.730468-15-15-15zm0 0" />
    </svg>
  );
};

export const Search = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.018 29.018"
      {...props}
    >
      <g
        id="Icon_feather-search"
        data-name="Icon feather-search"
        transform="translate(-3.25 -3.25)"
      >
        <path
          id="Path_1648"
          data-name="Path 1648"
          d="M27.611,16.056A11.556,11.556,0,1,1,16.056,4.5,11.556,11.556,0,0,1,27.611,16.056Z"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.5"
        />
        <path
          id="Path_1649"
          data-name="Path 1649"
          d="M31.258,31.258l-6.283-6.283"
          transform="translate(-0.758 -0.758)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2.5"
        />
      </g>
    </svg>
  );
};
export const IconAttendance = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="25"
      viewBox="0 0 25.5 30"
      {...props}
    >
      <g
        id="Icon_feather-user-check"
        data-name="Icon feather-user-check"
        transform="translate(0 -3)"
      >
        <path
          id="Path_1684"
          data-name="Path 1684"
          d="M24,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_1685"
          data-name="Path 1685"
          d="M18.75,10.5a6,6,0,1,1-6-6A6,6,0,0,1,18.75,10.5Z"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </svg>
  );
};
