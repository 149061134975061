/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
  Grid, Dialog, DialogTitle, DialogContent, DialogActions, Box, FormControl, InputAdornment, TextField,
} from '@material-ui/core';
import Api, { endpoints } from '@Helpers/api';
import { Add, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import UploadIcon from '@Components/UploadIcon';
import SelectYear from '@Components/SelectYear';
import SelectMonth from '@Components/SelectMonth';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(() => ({
  smallBtn: { borderRadius: '6px', margin: 0 },
  blueBtn: { borderRadius: '6px', width: '17em', marginRight: '2em' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
}));

export default ({ uuid, data }) => {
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [billing, setBilling] = useState(0);
  const [collection, setCollection] = useState(0);

  useEffect(() => {
    if (!data[year]) return;
    const thisYearAndMonth = data[year].find(d => d.MONTH === month) ?? {};
    setBilling(thisYearAndMonth.BILLING ?? 0);
    setCollection(thisYearAndMonth.COLLECTION ?? 0);
  }, [year, month]);

  const classes = useStyles();

  const handleClose = () => setOpen(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = () => {
    const input = {
      BILLING: billing,
      COLLECTION: collection,
    }
    Api({
      endpoint: endpoints.createBilling(month, year, uuid),
      data: { input },
      onSuccess: () => {
        toast('success', 'Successfully update Billing and Collection'); // eslint-disable-line no-undef
        handleClose();
      },
      onFail: () => {
        toast('error', 'Fail to update Billing and Collection'); // eslint-disable-line no-undef
      },
    });
  };

  return (
    <>
      <button
        onClick={handleClickOpen}
        className="blue-button flex-standard"
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          margin: 0,
        }}
      >
        <UploadIcon />
        <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>Upload Data</p>
      </button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <DialogTitle>
          <Grid container justify="space-between" style={{ paddingLeft: 24 }}>
            <h1>Manual Upload Data - Billing Collection</h1>
            <Close className={classes.closeBtn} onClick={handleClose} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <table className="custom-form-table" style={{ width: '100%', padding: '0 24px' }}>
            <tr>
              <td />
              <td><h4>Billing (RM)</h4></td>
              <td><h4>Collection (RM)</h4></td>
            </tr>
            <tr>
              <td>
                <div style={{ display: 'flex'}}>
                  <div style={{ width: 150, marginLeft: 10 }}><SelectYear onChange={(y) => setYear(y)} /> </div>
                  <div style={{ width: 150, marginLeft: 10 }}><SelectMonth onChange={(m) => setMonth(m)} /></div>
                </div>
              </td>
              <td>
                <FormControl variant="outlined" size="small" fullWidth>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={billing}
                    onChange={e => setBilling(e.target.value)}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      startAdornment: <InputAdornment position="start" children="RM" />,
                    }}
                  />
                </FormControl>
              </td>
              <td>
                <FormControl variant="outlined" size="small" fullWidth>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={collection}
                    onChange={e => setCollection(e.target.value)}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      startAdornment: <InputAdornment position="start" children="RM" />,
                    }}
                  />
                </FormControl>
              </td>
            </tr>
          </table>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: '0 3em', width: '100%', display: 'flex' }}>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={handleSave}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                <Add style={{ marginRight: 5 }} />Save Data
              </h1>
            </Box>
            <Box
              component="button"
              className="cancel-button"
              onClick={handleClose}
              style={{
                borderRadius: '6px',
                width: '120px',
              }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>

          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}
