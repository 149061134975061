import React from 'react';
import moment from 'moment';
import MaterialTable from '../MaterialTable';

const data = [];

for (let i = 0; i < 100; i++) {
  data.push({
    serviceType: 'Service A',
    expiringDate:
      i % 4 === 0 ? (
        <p style={{ color: '#FF4560' }}>1 Jan 2021</p>
      ) : (
        '1 Jan 2022'
      ),
  });
}

const columns = [
  {
    name: 'Service Type',
    selector: 'service',
    align: 'left',
    minWidth: 100,
  },
  {
    name: 'Vendor',
    selector: 'contractService',
    align: 'left',
    minWidth: 120,
  },
  {
    name: 'Expiring Date',
    selector: 'expiryAt',
    align: 'center',
    minWidth: 110,
  },
  {
    name: 'Current Charge',
    selector: 'currentCharge',
    align: 'right',
    minWidth: 60,
  },
  {
    name: '',
    selector: 'none',
    align: 'left',
    minWidth: 10,
  },
  {
    name: 'Service charges',
    selector: 'serviceCharge',
    align: 'left',
    minWidth: 100,
  },
  {
    name: 'Remarks',
    selector: 'remarks',
    align: 'left',
    minWidth: 100,
  },
  // {
  //   name: 'Status',
  //   selector: 'status',
  //   align: 'right',
  //   minWidth: 60,
  // },
];
export default function ContactServicingInfo(props) {
  return (
    <>
      {/* <MaterialTable
        tableHead
        columns={columns}
        tableData={props.data ?? data}
        tableMinWidth={300}
        tableMaxHeight={300}
      /> */}
      <MaterialTable
        tableHead
        columns={columns}
        tableData={props.data?.map(d => ({
          ...d,
          expiryAt: moment(d.expiryAt).format('D MMM YYYY'),
        })) ?? data}
        tableMinWidth={300}
        tableMaxHeight={300}
      />
    </>
  );
}
