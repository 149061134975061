import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { AreaTable } from './Tables';

const areas = ({ metre, feet }) => {
  if (!metre && !feet) return 'N/A';
  return (
    <>
      {!!metre && <span style={{ display: 'block' }}>{parseFloat(metre).toLocaleString()} m<sup>2</sup></span>}
      {!!feet && <span style={{ display: 'block' }}>{parseFloat(feet).toLocaleString()} ft<sup>2</sup></span>}
    </>
  );
};

const filtered = (arr, filter) => {
  if (!arr.length) return 'N/A';
  const ans = arr.filter(e => e.type.split(' ')[0] === filter.split(' ')[0]);
  return (ans.length <= 1)
    ? areas(ans[0])
    : <AreaTable data={ans} />;
};

export default function Hook({ facilities, selectedFacilities }) {
  const [provisionServices, setProvisionServices] = useState([]);
  const [buildingDetails, setBuildingDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!facilities.length || selectedFacilities === undefined) return;
    setIsLoading(true);
    Api({
      endpoint: endpoints.showFacilities(facilities[selectedFacilities]?.uuid),
      onSuccess: ({ data }) => {
        setProvisionServices(data.summary.services);
        setIsLoading(false);
        setBuildingDetails([
          {
            label: 'Description',
            data: data.summary.Description,
          },
          {
            label: 'Building Plan Approval No.',
            data: data.summary.BuildingPlanApprovalNo,
          },
          {
            label: 'Building Type',
            data: data.summary.BuildingType,
          },
          {
            label: 'Planning Approval No.',
            data: data.summary.PlanningApprovalNo,
          },
          {
            label: 'Building Description',
            data: data.summary.Description,
          },
          {
            label: 'Location of Building',
            data: data.summary.Location,
          },
          {
            label: 'Tenure',
            data: data.summary.Tenure,
          },
          {
            label: 'Year of Operation',
            data: data.summary.YearOperation,
          },
          {
            label: 'Land Area (Provisional)',
            data: areas(data.summary.areas[0]),
          },
          {
            label: 'Current Gross Floor Area',
            data: filtered(data.summary.areas, 'Current Gross Floor Area'),
          },
          {
            label: 'Net Lettable Area',
            data: filtered(data.summary.areas, 'Net Lettable Area'),
          },
          {
            label: 'Common Area (excluding carpark)',
            data: areas(data.summary.areas.slice(-1)[0]),
          },
          {
            label: 'No. of Car Park Lots',
            data: data.summary.lots.find(e => e.type.search('Car') >= 0)?.value,
          },
          {
            label: 'No. of Motorcycle Lots',
            data: data.summary.lots.find(e => e.type.search('Motorcycle') >= 0)?.value,
          },
        ]);
      },
      onFail: () => {
        setProvisionServices([]);
        setBuildingDetails([]);
        setIsLoading(false);
      },
    });
    // eslint-disable-next-line
  }, [facilities, selectedFacilities]);

  return {
    provisionServices,
    buildingDetails,
    isLoading,
  };
}
