import React from 'react';
import {
  VictoryLegend, VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryGroup, VictoryLabel, VictoryTooltip
} from 'victory';
import { yearComparisons } from '@Helpers';
import lodash from 'lodash';

import dataKomtar from './dataKomtarJBCC.json';

function getRandomArbitrary(min, max) {
  const random = Math.random() * (max - min) + min;
  return parseInt(random);
}

function generateRandomData(count) {
  const data = [...Array(count ?? 4)].map((_, i) => ({
    x: i + 1,
    npi: getRandomArbitrary(4000000, 13000000),
    expenses: getRandomArbitrary(7000000, 14000000),
  }));
  return data;
}

const data = {
  0: { name: 'B5', data: generateRandomData() },
  1: { name: 'GALLERIA', data: generateRandomData() },
  2: { name: 'KOMTAR JBCC', data: dataKomtar },
  3: { name: 'MENARA KOMTAR', data: generateRandomData() },
  4: { name: 'LARKIN SENTRAL', data: generateRandomData() },
  5: { name: 'MENARA ANSAR', data: generateRandomData() },
  6: { name: 'MENARA JLAND', data: generateRandomData() },
  7: { name: '@MART', data: generateRandomData() },
  8: { name: '@MART', data: generateRandomData() },
  9: { name: '@MART', data: generateRandomData() },
  10: { name: '@MART', data: generateRandomData() },
  16: { name: '@MART', data: generateRandomData(3) },
};

function SidebarChart({ selectedBuilding, sideChartData, sideChartLabel }) {
  const chart_data = sideChartData && sideChartData.length ? sideChartData : data[selectedBuilding]?.data;
  const tickFormat = sideChartLabel ?? [`Audited \n ${yearComparisons()[0]}`, "As at \n June '22", 'Post MCO \n Budget', `Budget \n ${yearComparisons()[1]}`];
  const tickValues = tickFormat?.map((_, i) => i + 1);
  const maxNum = String(lodash.max([
    ...chart_data.map(c => c.npi),
    ...chart_data.map(c => c.expenses),
  ]));
  const firstNumMax = String(Number(maxNum[0])+1)
  const maxDomain = Number(`${firstNumMax}${[...Array(maxNum.length-1)].map(x => '0').join('')}`)
  return (
    <VictoryChart
      // domainPadding will add space to each side of VictoryBar to
      // prevent it from overlapping the axis
      domainPadding={40}
      domain={{ y: [0, maxDomain] }}
      theme={VictoryTheme.material}
      width={400}
      padding={{
        bottom: 60, right: 60, left: 60, top: 60,
      }}
    >
      <VictoryLegend
        x={125}
        y={330}
        orientation="horizontal"
        gutter={1}
        data={[
          { name: 'NPI', symbol: { fill: '#008FFB', type: 'square' } },
          { name: 'Expenses', symbol: { fill: '#60788B', type: 'square' } },
        ]}
        style={{ labels: { fontFamily: 'Poppins', fontSize: 12 } }}
      />
      <VictoryAxis
        // tickValues specifies both the number of ticks and where
        // they are placed on the axis
        tickValues={tickValues}
        tickFormat={tickFormat}
        style={{
          tickLabels: { fontSize: 14, padding: 5 },
          ticks: { size: 0 },
          axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
          grid: { stroke: 'transparent' },
        }}
      />
      <VictoryAxis
        dependentAxis
        // tickFormat specifies how ticks should be displayed
        tickFormat={(x) => `${(x / 1000).toFixed(0)} K`}
        label="(RM '000)"
        axisLabelComponent={<VictoryLabel angle={0} verticalAnchor="start" dy={-140} dx={-10} />}
        style={{
          axis: { stroke: '#FFFFFF' },
          tickLabels: { fontSize: 14, padding: 5 },
          ticks: { stroke: '#FFFFFF', size: 0 },
          grid: { stroke: 'rgba(30, 52, 101, 0.15)', strokeDasharray: false },
        }}
      />
      <VictoryGroup offset={35} colorScale={['#60788B', '#008FFB']}>
        <VictoryBar
          data={chart_data}
          x="x"
          y="expenses"
          barWidth={30}
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 },
          }}
          labels={({ datum }) => `${((datum.expenses / 1000)).toFixed(0)} K`}
          labelComponent={(
            <VictoryLabel
              backgroundPadding={3}
              style={{ fontSize: 11 }}
            />
          )}
        />
        <VictoryBar
          data={chart_data}
          x="x"
          y="npi"
          barWidth={30}
          cornerRadius={4}
          animate={{
            duration: 2000,
            onLoad: { duration: 2000 },
          }}
          labels={({ datum }) => `${((datum.npi / 1000)).toFixed(0)} K`}
          labelComponent={(
            <VictoryLabel
              backgroundPadding={3}
              style={{ fontSize: 11 }}
            />
          )}
        />
      </VictoryGroup>
    </VictoryChart>
  );
}

export default SidebarChart;
