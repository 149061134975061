import { useState, useEffect } from 'react';
import {
  Avatar, Typography, TextField, IconButton, Button,
} from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export default ({ user, updateUser }) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState(user?.name);
  const [phone, setPhone] = useState(user?.phone);

  useEffect(() => {
    resetForm();
  }, [user]);

  const resetForm = () => {
    setName(user?.name);
    setPhone(user?.phone);
  };

  const onSave = () => {
    closeEdit();
    updateUser({ name, phone });
  };

  const openEdit = () => setIsEdit(true);
  const closeEdit = () => { resetForm(); setIsEdit(false); };

  return (
    <div className={classes.root}>
      <IconButton className={classes.editButton} onClick={isEdit ? closeEdit : openEdit}>
        {isEdit ? <Close /> : <Edit />}
      </IconButton>
      <br /><br />
      <Avatar src={user?.image} className={classes.avatar} />
      {isEdit
        ? (
          <>
            <p style={{ color: '#1e3465', marginTop: 10, marginLeft: 10 }}>Name</p>
            <CustomTextField placeholder="Name" style={{ marginTop: 5 }} value={name} onChange={(e) => setName(e.target.value)} />
          </>
        )
        : <Typography variant="h6" style={{ textAlign: 'center', marginTop: 3, marginBottom: 4 }}>{user?.name}</Typography>}
      {[
        { placeholder: 'Phone', value: phone, onChange: (e) => setPhone(e.target.value) },
        { placeholder: 'Email', value: user?.email, disabled: true },
        { placeholder: 'Password', value: '********', disabled: true },
      ].map(e => (
        <>
          <p style={{ color: '#1e3465', marginTop: 10, marginLeft: 10 }}>{e.placeholder}</p>
          {isEdit
            ? <CustomTextField {...e} />
            : <p className={classes.userDetail}>{e.value ?? '-'}</p>}
        </>
      ))}
      {isEdit && (
        <Button className={classes.saveButton} onClick={onSave}>Save</Button>
      )}
    </div>

  );
};

const CustomTextField = (props) => (<TextField variant="outlined" fullWidth size="small" {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white', padding: 10, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', borderRadius: '10px', height: '100%',
  },
  avatar: { height: 120, width: 120, margin: '0 auto' },
  userDetail: {
    fontWeight: 'bold', paddingBottom: 3, marginBottom: 10, marginLeft: 10,
  },
  editButton: {
    cursor: 'pointer', marginTop: -5, float: 'right',
  },
  saveButton: {
    float: 'right', marginTop: 5, backgroundColor: '#1e3465', color: 'white', marginLeft: 'auto',
  },
}));
