import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { IconButton } from '@material-ui/core';
import { UploadFileIcon } from '@Assets/Icons/UploadFileIcon';
import _ from 'lodash';
import moment from 'moment';
import { yearComparisons } from '@Helpers';

export default function Hook({ division, project }) {
  const [openScoreDialog, setOpenScoreDialog] = useState(false);
  const [openMonthlyProgressDialog, setOpenMonthlyProgressDialog] = useState(false);
  const [inspectionFiles, setInspectionFiles] = useState([]);
  const [spanDays, setSpanDays] = useState(7);
  const [progressStatus, setProgressStatus] = useState([]);
  const [progressStages, setProgressStages] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState([]);
  const [openLoadingSnackbar, setOpenLoadingSnackbar] = useState([]);
  const UploadMonthlyProgress = (input) => {
    const { files, ...data } = input;
    setOpenLoadingSnackbar(['info', 'Uploading...']);
    Api({
      endpoint: endpoints.createQualityProgress(project.uuid),
      data,
      files,
      onSuccess: () => {
        setOpenLoadingSnackbar([]);
        setOpenSnackbar(['success', 'Uploaded!']);
        setOpenMonthlyProgressDialog(false);
        refreshQalityProgress();
      },
      onFail: () => {
        setOpenSnackbar(['error', 'Failed To Upload!']);
      },
    });
  };
  const refreshQalityProgress = () => {
    const data = queryFilter();
    Api({
      endpoint: endpoints.getQualityProgress(),
      data,
      onSuccess: ({ data: qp }) => {
        const eachMonth = _.chain(qp).groupBy('month').map((v, k) => ({
          k, y1: _.sumBy(v, 'current_progress'), y2: _.sumBy(v, 'schedule_progress'),
        })).value();
        const chartData = [{ name: 'Current', color: '#FF0000', data: [] }, { name: 'Schedule', color: '#0000FF', data: [] }];
        for (let idx = 0; idx < eachMonth.length; idx++) {
          const { k, y1, y2 } = eachMonth[idx];
          const x = moment(k, 'M').format('MMM');
          chartData[0].data.push({ x, y: y1 });
          chartData[1].data.push({ x, y: y2 });
        }
        setProgressStatus(chartData);
      },
      onFail: () => console.log('xx'),
    });
  };

  const UploadPreqlassicScore = (input) => {
    const { files, ...data } = input;
    setOpenLoadingSnackbar(['info', 'Uploading...']);
    Api({
      endpoint: endpoints.createPreqlassic(project.uuid),
      data,
      files,
      onSuccess: () => {
        setOpenLoadingSnackbar([]);
        setOpenSnackbar(['success', 'Uploaded!']);
        setOpenScoreDialog(false);
        refreshStageQ();
      },
      onFail: () => {
        setOpenSnackbar(['error', 'Failed To Upload!']);
      },
    });
  };

  const refreshStageQ = () => {
    console.log('================ refreshStageQ');
    const groupByQuality = _.groupBy(inspectionFiles, 'InspectionFile.Inspection.QualityCategoryId');
    const miniStages = ['Q. CONTROL 1 (35-55%)', 'Q. CONTROL 2 (55-70%)', 'Q. CONTROL 3 (70-90%)'];
    const child = miniStages.map((label, idx) => {
      const annInThisGroup = groupByQuality[idx + 1];
      const goodP = annInThisGroup ? _.sumBy(annInThisGroup, 'is_good_practice') : 0;
      const notAllowed = annInThisGroup ? annInThisGroup.length - _.sumBy(annInThisGroup, 'is_good_practice') : 0;
      return ({ label, child: [{ label: 'GOOD PRACTICE', value: goodP }, { label: 'NOT ALLOWED', value: notAllowed, color: 'red' }] });
    });
    Api({
      endpoint: endpoints.getPreqlassic(),
      data: queryFilter(),
      onSuccess: (response) => {
        const groupByQlassic = _.groupBy(response.data, 'score_type');
        console.log('================ groupByQlassic', groupByQlassic)
        const miniItems = [{ key: 'preqlassic', name: 'PREQLASSIC', color: '#1e3465' }, { key: 'cidb', name: 'CIDB QLASSIC', color: 'red' }];
        const child2 = miniItems.map(({ key, name, color }) => {
          let label = name;
          let value = 0;
          // eslint-disable-next-line max-len
          if (project?.uuid) {
            label = (
              <> {name}
                <IconButton
                  onClick={() => setOpenScoreDialog(key)}
                  color="primary"
                  style={{ padding: 0 }}
                  children={<UploadFileIcon />}
                />
              </>
            );
          }
          console.log('================', key, groupByQlassic)
          if (groupByQlassic[key]) {
            const eachProjectByQlassic = _.groupBy(groupByQlassic[key], 'project_uuid');
            console.log('groupByQlassic by key: ', key, eachProjectByQlassic, Object.keys(eachProjectByQlassic))
            const lasteachProject = Object.keys(eachProjectByQlassic).map(e => {
              console.log('groupByQlassic eachproject', eachProjectByQlassic[e])
              return _.last(eachProjectByQlassic[e].filter(x => x.year === yearComparisons(2)[1]))
            });
            console.log('groupByQlassic lasteachProject', lasteachProject)
            value = _.sumBy(lasteachProject, 'score')
          }
          return ({ label, value, color });
        });
        setProgressStages([...child, { label: 'PREQLASSIC', child: child2 }]);
      },
      onFail: () => { },
    });
  };
  const queryFilter = () => {
    const data = {};
    if (project?.uuid) {
      data.project_uuid = project.uuid;
    } else if (project?.name === 'View All Projects') {
      data.DivisionId = division.id;
    } else {
      data.filterOnly = 'osh';
    }
    data.InspectionCategoryId = 3;
    // data.is_compliance = 0; // temp while backend not ready
    // data.spanDays = spanDays ?? 7;
    data.year = yearComparisons(1)[1];
    return data;
  };
  useEffect(() => {
    refreshQalityProgress();
    Api({
      endpoint: endpoints.getAnnotations(),
      data: queryFilter(),
      onSuccess: (response) => setInspectionFiles(response.data),
      onFail: setInspectionFiles([]),
    });
    refreshStageQ();
    // }, [spanDays, project]);
  }, [project]);
  useEffect(() => {
    if (!!!inspectionFiles.length) return;
    refreshStageQ();
  }, [inspectionFiles]);
  return {
    openScoreDialog,
    setOpenScoreDialog,
    openMonthlyProgressDialog,
    setOpenMonthlyProgressDialog,
    inspectionFiles,
    lines: progressStatus,
    progressStages,
    spanDays,
    setSpanDays,
    UploadMonthlyProgress,
    UploadPreqlassicScore,
    openLoadingSnackbar,
    setOpenLoadingSnackbar,
    openSnackbar,
    setOpenSnackbar,
  };
}
