import { Grid } from '@material-ui/core';
import { InfoOutlined, Sync } from '@material-ui/icons';
import { Switch, Route, Link, Router } from 'react-router-dom';
import MainContentContainer from '@Components/MainContentContainer';
import AreaChart from '@Components/Maintenance/AreaChart';
import Card from '@Components/Maintenance/Card';
import Chart from '@Components/Maintenance/Chart';
import MultiLineChart from '@Components/Maintenance/MultiLineChart';
import Rating from '@Components/Maintenance/Rating';
import Wordcloud from '@Components/WordcloudIssues';
import IssueTable from '@Components/Maintenance/IssueTable';
import PreventiveTable from '@Components/Maintenance/PreventiveTable';
import OpenIcon from '@Assets/Icons/OpenIcon.png';
import InProgressIcon from '@Assets/Icons/InProgressIcon.png';
import OnHoldIcon from '@Assets/Icons/OnHoldIcon.png';
import OverdueIcon from '@Assets/Icons/OverdueIcon.png';
import ResolvedIcon from '@Assets/Icons/ResolvedIcon.png';
import Navbar from '@Components/Management/Navbar';
import Hook from './hook';

export default function MgmtMaintenance(props) {
  const h = Hook(props);
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path='/dashboard/maintenance'>
          <h1 style={{ fontWeight: 700, marginBottom: 10 }}>
            Total Maintenance <span style={{ opacity: 0.35, fontWeight: 600 }}>(as of {h.latest_updated})</span>
          </h1>
          <MainContentContainer>
            <Grid container style={{ marginBottom: 30 }}>
              <Wordcloud allBuilding />
              <h2 style={{ fontWeight: 600, marginBottom: 10 }}>Issue</h2>
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                    flexWrap: 'wrap',
                  }}
                >
                  <Card
                    to='/dashboard/maintenance/issue?=Open'
                    title='Open'
                    description={h.issue_summary.Open.toLocaleString()}
                    color='#008FFB'
                    icon={<img src={OpenIcon} height='40px' />}
                  />
                  <Card
                    to='/dashboard/maintenance/issue?=In Progress'
                    title='In Progress'
                    description={h.issue_summary['In Progress'].toLocaleString()}
                    color='#FB9C5D'
                    icon={<img src={InProgressIcon} height='40px' />}
                  />
                  <Card
                    to='/dashboard/maintenance/issue?=On Hold'
                    title='On Hold'
                    description={h.issue_summary['On Hold'].toLocaleString()}
                    color='#B85EEE'
                    icon={<img src={OnHoldIcon} height='40px' />}
                  />
                  <Card
                    to='/dashboard/maintenance/issue?=Overdue'
                    title='Overdue'
                    description={h.issue_summary.Overdue.toLocaleString()}
                    color='#FF4560'
                    icon={<img src={OverdueIcon} height='40px' />}
                  />
                  <Card
                    to='/dashboard/maintenance/issue?=Resolved'
                    title='Resolved'
                    description={h.issue_summary.Resolved.toLocaleString()}
                    color='#2ECCA9'
                    icon={<img src={ResolvedIcon} height='40px' />}
                  />
                </div>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Link to='/dashboard/maintenance/issue'>
                  <div className='paper' style={{ padding: '10px 20px' }}>
                    <p>Issues Completed Last 30 Days</p>
                    <Chart
                      tickValues={h.days_arr}
                      graphOne={h.issues_created_graph}
                      graphTwo={h.issues_resolved_graph}
                      legends={['Issues Created', 'Issues Resolved']}
                      pointers={['Created', 'Resolved']}
                    />
                  </div>
                </Link>
              </Grid>
              <h2 style={{ fontWeight: 600, marginBottom: 10, marginTop: 30 }}>Preventive Maintenance</h2>
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                    flexWrap: 'wrap',
                  }}
                >
                  <Card
                    to='/dashboard/maintenance/preventive?=In Progress'
                    title='In Progress Tasks'
                    description={h.task_summary['In Progress']}
                    color='#FB9C5D'
                    icon={<img src={InProgressIcon} height='40px' />}
                  />
                  <Card
                    to='/dashboard/maintenance/preventive?=Pending Review'
                    title='Pending Review Tasks'
                    description={h.task_summary['Pending Review']}
                    color='#B85EEE'
                    icon={<img src={OnHoldIcon} height='40px' />}
                  />
                  <Card
                    to='/dashboard/maintenance/preventive?=Overdue'
                    title='Overdue Tasks'
                    description={h.task_summary['Overdue']}
                    color='#FF4560'
                    icon={<img src={OverdueIcon} height='40px' />}
                  />
                </div>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Link to='/dashboard/maintenance/preventive'>
                  <div className='paper' style={{ padding: '10px 20px' }}>
                    <p>Tasks Reviewed Last 30 Days</p>
                    <Chart
                      tickValues={h.days_arr}
                      graphOne={h.tasks_reviewed_graph}
                      graphTwo={h.tasks_scheduled_graph}
                      legends={['Tasks Reviewed', 'Tasks Scheduled']}
                      pointers={['Tasks reviewed', 'Tasks scheduled']}
                    />
                  </div>
                </Link>
              </Grid>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 10,
                  marginTop: 30,
                }}
              >
                <h2 style={{ fontWeight: 600 }}>User Feedbacks</h2>
                <InfoOutlined style={{ marginLeft: 10, color: '#1E3465' }} />
              </div>
              <Grid container item xs={12} spacing={2} justify='space-between'>
                <Grid item>
                  <Rating
                    text='Overall Accessibility'
                    rating={3.5}
                    ratingOver={4}
                    ratings={['75%', '50%', '25%', '10%']}
                  />
                </Grid>
                <Grid item>
                  <Rating
                    text='Overall Accessibility'
                    rating={3.5}
                    ratingOver={4}
                    ratings={['75%', '50%', '25%', '10%']}
                  />
                </Grid>
                <Grid item>
                  <Rating
                    text='Overall Accessibility'
                    rating={3.5}
                    ratingOver={4}
                    ratings={['75%', '50%', '25%', '10%']}
                  />
                </Grid>
                <Grid item>
                  <Rating
                    text='Overall Accessibility'
                    rating={3.5}
                    ratingOver={4}
                    ratings={['75%', '50%', '25%', '10%']}
                  />
                </Grid>
                <Grid item>
                  <div
                    className='paper'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      padding: '20px 20px 25px 20px',
                      textAlign: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Sync style={{ fontSize: 16, color: '#1E3465' }} />
                      <p style={{ fontSize: 16, marginLeft: 6 }}>80%</p>
                    </div>
                    <p style={{ fontSize: 12 }}>
                      Will visit again <br /> in future
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 18,
                      }}
                    >
                      <Sync style={{ fontSize: 16, color: '#1E3465' }} />
                      <p style={{ fontSize: 16, marginLeft: 6 }}>80%</p>
                    </div>
                    <p style={{ fontSize: 12 }}>
                      Will recommend
                      <br />
                      to friend/family
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className='paper' style={{ padding: '10px 20px' }}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <div>
                          <p>No. Feedbacks Received Daily</p>
                          <AreaChart pointer='Feedback' />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <p>Average Rating To Date</p>
                          <MultiLineChart
                            pointers={[
                              'Overall Accessibility',
                              'Facilities Provided',
                              'Cleanliness',
                              'Shopping Experience',
                            ]}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </MainContentContainer>
        </Route>
        <Route path='/dashboard/maintenance/issue'>
          <IssueTable allBuilding prevUrl='/dashboard/maintenance' {...props} />
        </Route>
        <Route path='/dashboard/maintenance/preventive'>
          <PreventiveTable allBuilding prevUrl='/dashboard/maintenance' {...props} />
        </Route>
      </Switch>
    </>
  );
}
