import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import {
  Close,
} from "@material-ui/icons";
import moment from 'moment'
import { initialsFromUser } from '@Helpers';

export default function EmployeeDetailModal(p) {
  return (
    <Grid
      container
      className="main-content"
      spacing={2}
      style={{ padding: "20px 60px 40px 60px", width: 450, height: p.modal || '100%' }}
    >
      <Grid item xs={12}>
        <Close
          style={{ cursor: "pointer", float: "right" }}
          onClick={p.handleClose}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="flex-standard">
          <div style={{ maxWidth: 120 }}>
            {p.profilePic === undefined
              ? (
                <div
                  className="flex-standard"
                  style={{ height: '120px' }}
                >
                  <CircularProgress />
                </div>
              ) : p.profilePic === '0' ? (
                <div
                  className="flex-standard"
                  style={{
                    width: '120px',
                    height: '120px',
                    borderRadius: "100%",
                    fontSize: '36px',
                    backgroundColor: '#512DA8',
                    color: 'white',
                  }}>
                  {initialsFromUser(p.row)}
                </div>
              ) : (
                <img
                  src={p.profilePic}
                  alt=""
                  width={"120"}
                  height={"120"}
                  style={{
                    borderRadius: "100%",
                    boxShadow: "1px 1px 4px 1px rgba(0,0,0,0.1)",
                    objectFit: 'cover'
                  }}
                />
              )
            }
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <h1 style={{ marginBottom: 10 }}>{p.row?.name}</h1>
        <p style={{ marginBottom: 6 }}>{p.row?.POSITION ?? 'N/A'}</p>
        <p style={{ marginBottom: 6 }}>{p.row?.DEPARTMENT ?? 'N/A'}</p>
        <p style={{ marginBottom: 6 }}>{moment().diff(p.row?.birthdate, 'years')} years old</p>
      </Grid>
      <Grid item xs={12}>
        <div
          className="flex-standard"
          style={{ justifyContent: "space-between" }}
        >
          <p className="text-light">Date of Joined</p>
          <p>{p.row?.['DATE OF APPOINTMENT'] ?? 'N/A'}</p>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className="flex-standard"
          style={{ justifyContent: "space-between" }}
        >
          <p className="text-light">Employment Company</p>
          <p style={{ textAlign: 'right' }}>{p.row?.['COMPANY'] ?? 'N/A'}</p>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className="flex-standard"
          style={{ justifyContent: "space-between" }}
        >
          <p className="text-light">Employment Status</p>
          <p>{p.row?.['EMPLOYMENT STATUS'] ?? 'N/A'}</p>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className="flex-standard"
          style={{ justifyContent: "space-between" }}
        >
          <p className="text-light">Employment Category</p>
          <p>{p.row?.['EMPLOYMENT CATEGORY'] ?? 'N/A'}</p>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className="flex-standard"
          style={{ justifyContent: "space-between" }}
        >
          <p className="text-light">Education</p>
          <p>N/A</p>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className="flex-standard"
          style={{ justifyContent: "space-between" }}
        >
          <p className="text-light">Performance Rating</p>
          <p>N/A</p>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          className="flex-standard"
          style={{ justifyContent: "space-between" }}
        >
          <p className="text-light">Years in current position</p>
          <p>{p.row?.['YEAR IN CURRENT POSITION'] ?? 'N/A'} years</p>
        </div>
      </Grid>
    </Grid>
  );
};
