import React from 'react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { PlayCircleFilled } from '@material-ui/icons';
import TabPanel from '@Components/Inspection/TabPanel';
import { a11yProps } from '../../Inspection/helper';
import UploadInspectionPhoto from './UploadInspectionPhoto';

export default function SideBar(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div
          className="sidebar paper"
          style={{ border: '1px solid #1E3465' }}
        >
          <Grid container>
            <Grid item xs={10}>
              <Tabs value={props.tab} onChange={(event, newValue) => props.setTab(newValue)} style={{ padding: 10 }}>
                <Tab label="Image" {...a11yProps(0)} style={{ minWidth: '50%' }} />
                <Tab label="Video" {...a11yProps(1)} style={{ minWidth: '50%' }} />
              </Tabs>
            </Grid>
            <Grid item xs={2}>
              <UploadInspectionPhoto {...props} onSave={props.uploadPhoto} />
            </Grid>
          </Grid>
          <div className="sidebar-container">
            <TabPanel value={props.tab} index={0}>
              <Grid container spacing={1}>
                {props.images.length > 0
                      && props.images.map((image) => {
                        const isselected = image.id == props.mainImage?.id;
                        return (
                          <Grid item xs={6}>
                            <div
                              className={`img-container ${isselected ? 'inner-border' : ''}`}
                              style={{ backgroundImage: `url(${image.src})` }}
                              onClick={() => props.handleChangeMainImage(image.id)}
                            />
                          </Grid>
                        );
                      })}
              </Grid>
            </TabPanel>
            <TabPanel value={props.tab} index={1}>
              <Grid container spacing={3}>
                {props.videos.length > 0
                      && props.videos.map((video) => (
                        <Grid item xs={12}>
                          <div
                            className="video-container"
                            onClick={() => props.setMainVideo(video)}
                          >
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              className="flex row center middle video-img"
                              style={{
                                backgroundImage: `url(${video.imgSrc})`,
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                              }}
                            >
                              <PlayCircleFilled />
                            </Grid>
                            <div className="flex row middle video-description">
                              <p style={{ color: '#03A398', fontWeight: 600 }}>
                                {video.asset}
                              </p>
                              <p style={{ fontSize: '0.8em', opacity: 0.65 }}>
                                {video.title}
                              </p>
                            </div>
                          </div>
                        </Grid>
                      ))}
              </Grid>
            </TabPanel>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
