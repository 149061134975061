import React from "react";

function FilterIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.976}
      height={18.976}
      viewBox="0 0 18.976 18.976"
      {...props}
    >
      <path
        data-name="Icon awesome-filter"
        d="M18.086 0H.891a.89.89 0 00-.629 1.518l6.854 6.856v7.637a.89.89 0 00.379.729l2.965 2.075a.89.89 0 001.4-.729V8.374l6.855-6.855A.89.89 0 0018.086 0z"
        fill="#1E3465"
      />
    </svg>
  );
}

export default FilterIcon;
