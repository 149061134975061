import { Grid, IconButton } from '@material-ui/core';
import MainContentContainer from '@Components/MainContentContainer';
import MultilineChart from '@Components/MultilineChart';
import SimpleImageTile from '@Components/SimpleImageTile';
import CustomCard2Item from '@Components/CustomCard2Item';
import UploadScoreDialog from '@Components/ReportSite/UploadScoreDialog';
import UploadMonthlyProgressDialog from '@Components/ReportSite/UploadMonthlyProgressDialog';
import { UploadFileIcon } from '@Assets/Icons/UploadFileIcon';
import SnackBarUploadProgress from '@Components/SnackBarUploadProgress';
import SnackBarUploaded from '@Components/SnackBarUploaded';
import { truncateString } from '@Helpers';
import Navbar from '../components/Navbar';
import Hook from './hook';


export default function Quality(props) {
  const h = Hook(props);
  const CardsMap = ({ items }) => items.map(({ label, child }) => (
    <CustomCard2Item
      head={label}
      child={child}
      style={{ backgroundColor: 'rgb(245, 250, 255)' }}
      elevation={0} // increase elevation to increase shadow
    />
  ));
  return (
    <MainContentContainer>
      {!!props.project.uuid && (
        <>
          <SnackBarUploadProgress open={h.openLoadingSnackbar} setOpen={h.setOpenLoadingSnackbar} />
          <SnackBarUploaded open={h.openSnackbar} setOpen={h.setOpenSnackbar}/>
          <UploadMonthlyProgressDialog
            {...h}
            onSave={h.UploadMonthlyProgress}
            open={h.openMonthlyProgressDialog}
            setOpen={h.setOpenMonthlyProgressDialog}
            project={props.project}
          />
          <UploadScoreDialog
            {...h}
            onSave={h.UploadPreqlassicScore}
            open={h.openScoreDialog}
            setOpen={h.setOpenScoreDialog}
            project={props.project}
          />
          <SnackBarUploadProgress open={h.openLoadingSnackbar} setOpen={h.setOpenLoadingSnackbar} />
          <SnackBarUploaded open={h.openSnackbar} setOpen={h.setOpenSnackbar}/>
        </>
      )}
      {props.navbar !== false
        ? <Navbar to="/project/quality" text="Quality" subtext={truncateString(props.filtered_projects[props.selected_project]?.name, 40 )} />
        : <h1 style={{ paddingLeft: 20 }}>Quality Dashboard</h1>}
      <Grid container>
        <Grid item xs={12} md={12} style={{ padding: 20 }}>
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12} md={12}>
              <div className="flex" style={{ justifyContent: 'space-between' }}>
                <h2 className="text-light" style={{ fontWeight: 600 }}>
                  PROGRESS STATUS
                  {!!props.project.uuid && (
                    <IconButton onClick={() => h.setOpenMonthlyProgressDialog(true)} color="primary" children={<UploadFileIcon />} />
                  )}
                </h2>
              </div>
              <MultilineChart {...h} />
            </Grid>
            <Grid item container xs={12} md={12}>
              <CardsMap items={h.progressStages} />
            </Grid>
            <Grid item xs={12} md={12}>
              <h2 style={{ fontWeight: 600 }}>IMAGES</h2>
              <SimpleImageTile
                images={h.inspectionFiles.map(x => ({ ...x, path: x['InspectionFile.path'] }))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainContentContainer>
  );
}
