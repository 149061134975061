import {
  Grid, Tabs, Tab,
} from '@material-ui/core';
import SelectYear from '@Components/SelectYear';
import SelectMonth from '@Components/SelectMonth';
import MainContentNavbar from '@Components/MainContentNavbar';
import MainContentContainer from '@Components/MainContentContainer';
import useHook from './hook';
import Financial from './Financial';
import DebtorsAging from './DebtorsAging';
import SecurityManning from './SecurityManning';
import Incidence from './Incidence';

export default () => {
  const h = useHook();

  const steps = ['Financial', 'Debtors Aging', 'Security Manning', 'Incidence'];

  return (
    <Grid item xs={12} xl={9} style={{ margin: 'auto' }}>
      <MainContentNavbar to="/integrated-facilities/security" text="Security - Manual Upload Data" />
      <MainContentContainer>
        <Tabs
          value={h.activeStep}
          onChange={(e, newValue) => { h.setActiveStep(newValue); }}
          style={{ marginBottom: 24 }}
          centered
        >
          {steps.map(e => <Tab label={e} />)}
        </Tabs>
        <div className="flex" style={{ width: '30%', marginLeft: 'auto', marginBottom: 24 }}>
          <SelectYear onChange={h.setYear} year={h.year} />
          &nbsp;&nbsp;&nbsp;
          <SelectMonth onChange={h.setMonth} month={h.month} />
        </div>
        {{
          0: <Financial {...h} />,
          1: <DebtorsAging {...h} />,
          2: <SecurityManning {...h} />,
          3: <Incidence {...h} />,
        }[h.activeStep]}
      </MainContentContainer>
    </Grid>
  );
};
