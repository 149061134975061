import React from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button,
} from '@material-ui/core';

function ROTDIalog({
  openROTDialog, setOpenROTDialog, RenewalOfTenancyDetail, selectedROT,
}) {
  return (
    <Dialog open={openROTDialog} onClose={() => setOpenROTDialog(false)}>
      <DialogTitle><h3>{selectedROT.title} {selectedROT.title !== 'New Tenant' && 'Tenant'} ({selectedROT.count})</h3></DialogTitle>
      <DialogContent>
        <>
          <hr />
          <div style={{ maxHeight: '60vh', overflowY: 'auto', minWidth: '20vw' }}>
            {RenewalOfTenancyDetail[selectedROT.title]?.map(d => (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4>Name: </h4>&nbsp;
                  <span>{d.name}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4>Lot No: </h4>&nbsp;
                  <div>{d.lot_no}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4>Rental: </h4>&nbsp;
                  <div>RM {d.rental}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4>Area: </h4>&nbsp;
                  <div>{d.area} sqft</div>
                </div>
                <hr />
              </>
            ))}
          </div>
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenROTDialog(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ROTDIalog;
