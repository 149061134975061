import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Navbar from '../components/Navbar';

import Hook from './hook';
import SideBar from './SideBar';
import ActionBar from './ActionBar';
import MainWorkspace from './MainWorkspace';

export default function Inspection(props) {
  const { inspection_session } = useParams();
  const h = Hook({ ...props, InspectionId: inspection_session });
  return (
    <>
      <Navbar
        to="/project/inspection/"
        text="INSPECTION"
        subtext={h.inspections?.length ? h.inspections[0]['Inspection.name'] : ''}
      />
      <Grid container>
        <Grid container item xs={12} spacing={3}>
          <Grid item md={12} lg={3}>
            <SideBar {...h} InspectionId={inspection_session} />
          </Grid>
          <Grid item md={12} lg={9}>
            <MainWorkspace {...h} user={props.user} />
          </Grid>
          <Grid item style={{ position: 'absolute', width: '250px', right: 100, top: 100 }}>
            {/* incase dont want absolute, adjust other grid accordingly */}
            {/* <Grid item md={12} lg={2}> */}
            {!!Object.keys(h.mainImage).length && <ActionBar {...h} user={props.user} />}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
