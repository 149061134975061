/* eslint-disable max-lines */
/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Divider,
} from '@material-ui/core';
import { GetApp, Close } from '@material-ui/icons';
import { DaxLogo } from '@Assets/Icons/daxLogo';
import Table from '@Components/Table';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@Components/Avatar';
import moment from 'moment';
import CsvDownload from 'react-json-to-csv';
import Badge from './Badge';

const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    wrap: true,
  },
  // {
  //   name: 'Type',
  //   selector: 'type',
  //   sortable: true,
  //   width: '100px',
  // },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    right: true,
    wrap: true,
    width: '140px',
  },
  {
    name: 'Hours',
    selector: 'hours',
    sortable: true,
    right: true,
    wrap: true,
    width: '80px',
  },
  {
    name: 'Time IN',
    selector: 'timeIn',
    sortable: true,
    right: true,
    wrap: true,
    width: '140px',
  },
  {
    name: 'Time OUT',
    selector: 'timeOut',
    sortable: true,
    right: true,
    wrap: true,
    width: '140px',
  },
];

const useStyles = makeStyles(() => ({
  spaceBetween: { display: 'flex', justifyContent: 'space-between' },
  columnSpaceBetween: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' },
  detailContainer: { width: '100%', display: 'flex', justifyContent: 'space-around' },
  bgcPrimary: { backgroundColor: '#1e3465' },
  fontXL: { fontSize: '36px' },
  fontLg: { fontSize: '30px' },
  px40: { paddingLeft: '40px', paddingRight: '40px' },
  p30: { padding: '30px' },
  mx30: { margin: '0 30px' },
  blueBtn: { borderRadius: '6px', width: '210px', margin: '10px 30px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
  root: { '&$checked': { color: '#4fd8af' }, color: '#1e3465' },
  checked: {},
}));

export default function GenerateReport(p) {
  const classes = useStyles();
  const [management, set_management] = useState(true);
  const [executive, set_executive] = useState(true);
  const [support, set_support] = useState(true);
  const is_top_management = p.user?.dax_role === 'top_management';

  const checkbox = {
    management: (e) => set_management(e),
    executive: (e) => set_executive(e),
    support: (e) => set_support(e),
  };
  const handleClose = () => p.set_show_report(false);
  const checkboxChange = (event) => checkbox[event.target.name](event.target.checked);

  const data = p.attendance_preview.map((eAtt) => ({
    ...eAtt,
    name: (<div className="flex-standard"><Avatar style={{ position: 'absolute', left: 0 }} {...{ image: eAtt.IMAGE, name: eAtt.NAME }} /><div style={{ marginLeft: '1.5em' }}>{eAtt.NAME}</div></div>),
    // type: (<Badge text={eAtt.type} />),
    date: moment(eAtt.DATE).format('DD.MM.YYYY'),
    timeIn: eAtt.IN === '-' ? eAtt.IN : moment(eAtt.IN).format('hh:mm:ss A'),
    timeOut: eAtt.OUT === '-' ? eAtt.OUT : moment(eAtt.OUT).format('hh:mm:ss A'),
    hours: eAtt.hours ? eAtt.hours.toFixed(2) : (0).toFixed(2),
  }));

  // const workType = { OT: 'Overtime', WO: 'Working hours' };
  const csvData = p.attendance_preview.map(eAtt => ({
    EMPLOYEE_NUMBER: eAtt.EMP_NUM,
    NAME: eAtt.NAME,
    DATE: moment(eAtt.DATE).format('DD.MM.YYYY'),
    HOURS: eAtt.hours ? eAtt.hours.toFixed(2) : (0).toFixed(2),
    'TIME IN': eAtt.IN === '-' ? '' : moment(eAtt.IN).format('hh:mm:ss A'),
    'TIME OUT': eAtt.OUT === '-' ? '' : moment(eAtt.OUT).format('hh:mm:ss A'),
    // TYPE: eAtt.IN === '-' || eAtt.OUT === '-' ? '' : workType[eAtt.type] ?? eAtt.type,
  }));

  return (
    <Dialog
      open={p.show_report}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { borderRadius: 10 } }}
    >
      <DialogTitle>
        <div className={classes.spaceBetween}>
          <b className={`${classes.fontLg} ${classes.mx30}`}>
            Report Preview
          </b>
          <div>
            <CsvDownload filename={`${p.report_date}.csv`} data={csvData} className={`${classes.blueBtn} blue-button`}>
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                <GetApp />
                Download Report
              </h1>
            </CsvDownload>
            <Close
              className={classes.closeBtn}
              onClick={handleClose}
            />
          </div>
        </div>
      </DialogTitle>
      <Divider className={classes.bgcPrimary} />
      <DialogContent>
        <div className={classes.p30}>
          <Grid container spacing={3}>
            <Grid container xs={4} justify="center">
              <DaxLogo height="90" />
              <h3 className={classes.fontLg}>Attendance Report</h3>
            </Grid>
            <Grid container xs={5}>
              <div className={classes.detailContainer}>
                <div className={classes.columnSpaceBetween}>
                  <div>
                    <p>Name</p>
                    <h4>-</h4>
                  </div>
                  <div>
                    <p>Attendance Rate</p>
                    <h3 className={classes.fontXL}>-</h3>
                  </div>
                </div>
                <div className={classes.columnSpaceBetween}>
                  <div>
                    <p>Date</p>
                    <h4>{p.report_date}</h4>
                  </div>
                  <div>
                    <p>Attendance</p>
                    <h3 className={classes.fontXL}>-</h3>
                  </div>
                </div>
              </div>
            </Grid>
            {is_top_management && (
              <Grid container xs={3}>
                <div className={classes.px40}>
                  <p>Category</p>
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={management} onChange={checkboxChange} name="management" classes={{ root: classes.root, checked: classes.checked }} />}
                        label="Management"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={executive} onChange={checkboxChange} name="executive" classes={{ root: classes.root, checked: classes.checked }} />}
                        label="Executive"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={support} onChange={checkboxChange} name="support" classes={{ root: classes.root, checked: classes.checked }} />}
                        label="Support"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
        <Table columns={columns} data={data} />
      </DialogContent>
    </Dialog>
  );
}
