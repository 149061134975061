import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Table from '@Components/Table';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import EmployeeDetailCard from '@Components/HumanResources/EmployeeDetail';
import Avatar from '@Components/Avatar';
import moment from 'moment';
import Hook from './hook';
import GenerateReport from './components/GenerateReport';
import ReportPreview from './components/ReportPreview';
import Badge from './components/Badge';

const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    wrap: true,
  },
  // {
  //   name: 'Type',
  //   selector: 'type',
  //   sortable: true,
  //   width: '100px',
  // },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    right: true,
    wrap: true,
    width: '140px',
  },
  {
    name: 'Hours',
    selector: 'hours',
    sortable: true,
    right: true,
    wrap: true,
    width: '80px',
  },
  {
    name: 'Time IN',
    selector: 'timeIn',
    sortable: true,
    right: true,
    wrap: true,
    width: '140px',
  },
  {
    name: 'Time OUT',
    selector: 'timeOut',
    sortable: true,
    right: true,
    wrap: true,
    width: '140px',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  table: {
    flex: 1,
  },
  card: {
    width: '450px',
    marginLeft: '20px',
  },
});

function Attendance(props) {
  const classes = useStyles();
  const h = Hook(props);

  const data = h.attendance.map((eAtt) => ({
    ...eAtt,
    name: (
      <div className="flex-standard">
        <Avatar style={{ position: 'absolute', left: 0 }} {...{ image: eAtt.IMAGE, name: eAtt.NAME }} />
        <div style={{ marginLeft: '1.5em' }} className="pointer" onClick={() => h.set_show_card(true)}> {eAtt.NAME}</div>
      </div>),
    // type: (<Badge text={eAtt.type} />),
    date: moment(eAtt.DATE).format('DD.MM.YYYY'),
    timeIn: eAtt.IN === '-' ? eAtt.IN : moment(eAtt.IN).format('hh:mm:ss A'),
    timeOut: eAtt.OUT === '-' ? eAtt.OUT : moment(eAtt.OUT).format('hh:mm:ss A'),
    hours: eAtt.hours ? eAtt.hours.toFixed(2) : (0).toFixed(2),
  }));
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <MainContentNavbar to="/property/" text="Attendance" />
        <GenerateReport {...h} {...props} />
        <ReportPreview {...h} {...props} />
      </div>
      <MainContentContainer>
        <Grid container spacing={3}>
          <Grid xs={12} item>
            <div className={classes.root}>
              <div className={classes.table}>
                <Table columns={columns} data={data} />
              </div>
              {h.show_card
                && (
                  <div className={`${classes.card} paper flex-standard`}>
                    <EmployeeDetailCard {...h} />
                  </div>
                )}
            </div>
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default Attendance;
