import React from 'react';
import { Grid } from '@material-ui/core';
import CombinationChart from '@Components/Management/Reid/CombinationChart';
import LineChart from '@Components/Management/Reid/LineChart';

const styles = { container: { height: '100%', padding: 10 } };

export default function PerformanceReview(props) {
  return (
    <>
      <Grid xs={12} lg={12} container justifyContent="space-between">
        <h2 style={{ fontWeight: 600, marginBottom: 10 }}>Performance Review</h2>
        {/* <div>Dropdown box</div> */}
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item xs={12} md={4}>
          <Grid item className="paper" style={styles.container}>
            <CombinationChart title="Revenue" {...props} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid item className="paper" style={styles.container}>
            <CombinationChart title="Profit After Tax" {...props} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid item className="paper" style={styles.container}>
            <CombinationChart title="Cost Optimization" {...props} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item xs={12} md={6}>
          <Grid item className="paper" style={styles.container}>
            <CombinationChart title="OPEX (General &amp; Admin)" {...props} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item className="paper" style={styles.container}>
            <LineChart title="OPEX (General &amp; Admin vs Revenue Ratio)" {...props} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
