import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
} from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Clear'
import moment from 'moment';
import DeleteProjectMonitor from './DeleteProjectMonitor';

export default function ProjectMonitorDialog({
  openModal, setOpenModal, rowData, stage, onDelete,
}) {
  const status = 'On Time';
  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="md">
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <h2 style={{ textAlign: 'center' }}>{rowData?.project_name}</h2>
          <IconButton onClick={() => setOpenModal(false)} style={{ right: -10, position: 'absolute' }}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container justify="center" style={{ marginBottom: 2 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ marginRight: 20 }}>
              <p style={{ fontSize: 14, textAlign: 'center', marginTop: 10 }} className="text-light">
                Contract Amount
              </p>
              <p style={{
                fontSize: 24, textAlign: 'center', fontWeight: 'bold',
              }}
              >
                RM {rowData?.contract_amount.toLocaleString()}
              </p>
            </div>
            <div style={{ marginLeft: 20 }}>
              <p style={{ fontSize: 14, textAlign: 'center', marginTop: 10 }} className="text-light">
                Status
              </p>
              <p
                style={{
                  fontSize: 24,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#2ECCA9',
                }}
              >
                {status}
              </p>
            </div>
          </div>
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} style={{ marginTop: '4rem' }}>
              <ProjectTimeline item={rowData} stage={stage} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end', padding: '10px 24px' }}>
        <DeleteProjectMonitor setOpenModal={setOpenModal} id={rowData?.id} onDelete={onDelete} />
      </DialogActions>
    </Dialog>
  );
}

const ProjectTimeline = ({ item, stage }) => {
  return (
    <Timeline style={{ marginTop: '-50px' }}>
      {stage.map((m, idx) => (
        <TimelineItem>
          <TimelineOppositeContent style={{ marginTop: -8 }}>
            <p style={{ fontSize: 20, textAlign: 'right', marginTop: 5 }} className="text-bold">
              {!!item[m.selector] ? moment(item[m.selector]).format('DD MMMM YYYY') : '-'}
            </p>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant={moment().isSameOrAfter(moment(item[m.selector])) ? 'default' : 'outlined'} />
            {idx < stage.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <p style={{ fontSize: 20, textAlign: 'left', marginTop: -3 }} className="text-bold">
              {m.name}
            </p>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
