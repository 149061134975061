import { Grid } from '@material-ui/core';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import HorizontalBarCharts from './components/HorizontalBarCharts';


function Rental(props) {
  const rentalRate = props.buildings[props.selectedBuilding]?.monthly_psf;
  return (
    <>
      <MainContentNavbar to="/property/" text="AVERAGE RENTAL RATE" />
      <MainContentContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 style={{ fontWeight: 600, marginTop: 30, marginBottom: 10 }}>
              Average Rental Rate Per Year
            </h2>
            <Grid item xs={12} md={12}>
              <div className="paper" style={{ padding: 20, marginBottom: 20 }}>
                <HorizontalBarCharts
                  width={1200}
                  title="Average Rental Rate"
                  labelPrefix="RM"
                  data={[
                    { x: '2023', y: rentalRate },
                    { x: '2022', y: rentalRate },
                    // { x: '2021', y: 4.80 },
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default Rental;
