import {
  Card, CardContent, Divider, Grid, Button,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

export default ({ activityLog }) => {
  const classes = useStyles();
  const ActivityLogs = () => (
    <>
      {activityLog?.map((log) => (
        <>
          <Divider style={{ marginTop: 10 }} />
          <Grid container justify="space-between">
            <Grid item xs={8}><>{log.id}:  {log.description}</></Grid>
            <Grid item direction="column">
              <Grid><small>{moment(log.created_at).format('DD/MM/YYYY')}</small></Grid>
              <Grid><small>{moment(log.created_at).format('hh:mm A')}</small></Grid>
            </Grid>
          </Grid>
        </>
      ))}
    </>
  );
  const NoData = () => (
    <Grid item xs={12} style={{ marginTop: '10rem' }}>
      <div className={classes.noDataBody}><h1 className={classes.noDataText}> No data have been detected </h1></div>
    </Grid>
  );
  return (
    <Grid item>
      <div className={classes.root}>
        <Grid container justify="space-between">
          <Grid item><div style={{ fontWeight: 600, color: '#1e3465' }}>Activity Log</div></Grid>
          <Grid item><Button variant="contained" className={classes.downloadButton} startIcon={<GetAppIcon />} disabled>Download</Button></Grid>
        </Grid>
        <Grid className={classes.activityLogList}>
          {activityLog.length ? (<ActivityLogs />) : (<NoData />)}
        </Grid>
      </div>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white', padding: 10, boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', borderRadius: '10px', height: '100%',
  },
  noDataBody: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  noDataText: { fontSize: '3em', color: '#e5e5e5', marginBottom: 20 },
  downloadButton: {
    backgroundColor: '#1e3465', color: '#ffffff', marginRight: 10, fontSize: 16, cursor: 'pointer',
  },
  activityLogList: {
    marginTop: '1rem', height: '15rem', overflowY: 'scroll', padding: '0 20px',
  },
}));
