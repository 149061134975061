import { useState, useEffect } from 'react';
import Api, { endpoints } from '@Helpers/api';
import moment from 'moment';

export default function Hook({ buildings, selectedBuilding }) {
  const uuid = buildings[selectedBuilding]?.uuid;
  const [attendance, setAttendance] = useState([]);
  const [attendance_preview, set_attendance_preview] = useState([]);
  const [show_card, set_show_card] = useState(false);
  const [show_report, set_show_report] = useState(false);
  const [report_date, set_report_date] = useState(moment.utc().format('YYYY-MM-DD'));

  useEffect(() => {
    Api({
      endpoint: endpoints.getAttendances(),
      data: { DATE: moment.utc().format('YYYY-MM-DD'), uuid },
      onSuccess: ({ data }) => setAttendance(data),
    });
  }, [uuid]);

  const getReport = (dateRange, search_input) => {
    const startDate = moment(dateRange.startDate).format('YYYY-MM-DD');
    const endDate = moment(dateRange.endDate).format('YYYY-MM-DD');
    const EMP_NUM = !!search_input.length ? { EMP_NUM: search_input.join(',') } : {};
    Api({
      endpoint: endpoints.getAttendances(),
      data: {
        startDate, endDate, uuid, ...EMP_NUM,
      },
      onSuccess: ({ data }) => { set_attendance_preview(data); },
    });
  };

  return {
    handleClose: () => set_show_card(false),
    show_card,
    set_show_card,
    show_report,
    set_show_report,
    attendance,
    attendance_preview,
    report_date,
    set_report_date,
    getReport,
  };
}
