import React from 'react';

import {
  VictoryBar, VictoryChart, VictoryAxis, VictoryLabel,
} from 'victory';

export default function HorizontalBarCharts(props) {
  return (
    <>
      <p style={{ fontSize: 14 }} className="text-light">
        {props.title}
      </p>
      <div style={{ position: 'relative' }}>
        <VictoryChart
          width={props.width}
          height={200}
          padding={{
            left: 120, right: 60, top: 60, bottom: 60,
          }}
        >
          <VictoryBar
            style={{
              data: {
                fill: '#008FFB',
              },
              labels: {
                fontSize: 15,
              },
            }}
            cornerRadius={10}
            horizontal
            barRatio={3}
            data={props.data}
            labels={({ datum }) => {
              return props.labelPrefix
                ? `${props.labelPrefix} ${datum.y?.toFixed(2).toLocaleString()}`
                : `${datum.y?.toFixed(2).toLocaleString()}`;
            }}
            sortKey="x"
            sortOrder="descending"
            labelComponent={(
              <VictoryLabel
                x={130}
                style={{
                  fontSize: 32,
                  fill: '#FFFFFF',
                  textShadow: '1px 1px 5px black',
                  stroke: 'none',
                }}
              />
            )}
          />

          <VictoryAxis
            // tickValues specifies both the number of ticks and where
            // they are placed on the axis
            style={{
              tickLabels: {
                fontSize: 32,
                fill: 'rgba(30, 52, 101, 0.65)',
                padding: 20,
              },
              ticks: { size: 0 },
              axis: { stroke: 'transparent' },
              grid: { stroke: 'transparent' },
            }}
          />
        </VictoryChart>
      </div>
    </>
  );
}
