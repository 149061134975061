import React from 'react';
import {
  VictoryPie, VictoryLabel, VictoryChart, VictoryBar, VictoryAxis,
} from 'victory';

import { t } from '@Helpers';

function DonutCharts(props) {
  const { data, active, setActive } = props;

  let percentage = data.percent;
  if (percentage > 100) percentage -= 100;
  if (!Number.isFinite(percentage)) percentage = Math.sign(percentage || 0) * 100;

  const colorFill = (label) => {
    switch (label) {
      case 'Actual':
        return '#1F3566';
      case 'Budget':
        return '#60788B';
      default:
        return '#008FFB';
    }
  };

  return (
    <div
      className="paper finance-donut"
      style={{
        border: active === data.title && '1px solid rgba(30, 52, 101, 1)',
      }}
      onClick={() => active !== data.title && setActive(data.title)}
    >
      <p className="text-light" style={{ textAlign: 'center' }}>
        {t(data.title)}
      </p>
      <h2 style={{ fontWeight: 600, textAlign: 'center' }}>{data.amount}</h2>
      <div style={{ position: 'relative' }}>
        <svg viewBox="0 0 600 400">
          <VictoryPie
            colorScale={['#1F3566', 'rgba(30, 52, 101, 0.15)']}
            cornerRadius={10}
            standalone={false}
            width={600}
            height={400}
            data={data.donutData}
            innerRadius={130}
            labels={[]}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{
              fontSize: 64,
              fill: data.positive ? '#2ECCA9' : '#FF4560',
            }}
            x={300}
            y={184}
            text={`${percentage.toFixed(0)}%`}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 32, fill: 'rgba(30, 52, 101, 0.65)' }}
            x={300}
            y={242}
            text="vs budget"
          />
        </svg>
      </div>
      <p className="text-light" style={{ textAlign: 'center' }}>
        As of {data.asOf}
      </p>

      <div style={{ position: 'relative' }}>
        <VictoryChart
          width={600}
          height={300}
          padding={{
            left: 120, right: 60, top: 60, bottom: 60,
          }}
        >
          <VictoryBar
            style={{
              data: {
                fill: ({ datum }) => colorFill(datum.x),
              },
              labels: {
                fontSize: 15,
              },
            }}
            cornerRadius={10}
            horizontal
            barRatio={2}
            data={data.barData}
            labels={({ datum }) => (data.title === 'Yield' ? `${datum.y} %` : `RM ${(datum.y || 0)?.toLocaleString()}`)}
            sortKey="x"
            sortOrder="descending"
            animate
            labelComponent={(
              <VictoryLabel
                // relative to left most of the container/component
                x={130}
                // relative to the end of each bar
                dx={10}
                style={{
                  fontSize: 32,
                  fill: '#FFFFFF',
                  textShadow: '1px 1px 5px black',
                  stroke: 'none',
                }}
                textAnchor="start"
              />
            )}
          />

          <VictoryAxis
            // tickValues specifies both the number of ticks and where
            // they are placed on the axis
            style={{
              tickLabels: {
                fontSize: 32,
                fill: 'rgba(30, 52, 101, 0.65)',
                padding: 20,
              },
              ticks: { size: 0 },
              axis: { stroke: 'transparent' },
              grid: { stroke: 'transparent' },
            }}
            offsetX={120}
            orientation="left"
          />
        </VictoryChart>
      </div>
    </div>
  );
}

export default DonutCharts;
