import { Box, Grid } from '@material-ui/core';
import Navbar from './Navbar';
import useHook from './hook';
import ProfileCard from './ProfileCard';
import PageAccess from './PageAccess';
import ActivityLog from './ActivityLog';

export default function UserProfile() {
  const h = useHook();
  return (
    <div style={{
      backgroundColor: '#f5faff', borderRadius: '10px', padding: '20px', height: '80vh',
    }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box style={{ display: 'flex', margin: '2px 0' }}>
          <Navbar to="/user-management/" text="User Management" subtext={h.userData?.name} />
        </Box>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} style={{ backgroundColor: '#f5faff' }}>
          <ProfileCard user={h.userData} {...h} />
        </Grid>
        <Grid item container direction="column" xs={12} md={8} spacing={2}>
          <PageAccess {...h} />
          <ActivityLog {...h} />
        </Grid>
      </Grid>
    </div>
  );
}
