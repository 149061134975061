import { Grid } from '@material-ui/core';
// import Cctv from '@Components/Footfall/Cctv';
import AreaChartPeopleCounting from '@Components/Footfall/AreaChartPeopleCounting';
import BarChartPeopleCounting from '@Components/Footfall/BarChartPeopleCounting';
import DonutChartPeopleCounting from '@Components/Footfall/DonutChartPeopleCounting';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
// import { Tabs } from '@Components/Tabs';
import HorizontalBarChart from '@Components/Footfall/HorizontalBarChart';
import Hook from './hook';

const PLACEHOLDER = 'https://via.placeholder.com/1920x600';

const tabData = [
  {
    title: 'Ground Level',
    content: <img className='tabImage' src={PLACEHOLDER} alt='placeholder' />,
  },
  {
    title: 'Level 1',
    content: <img className='tabImage' src={PLACEHOLDER} alt='placeholder' />,
  },
  {
    title: 'Level 2',
    content: <img className='tabImage' src={PLACEHOLDER} alt='placeholder' />,
  },
  {
    title: 'Level 3',
    content: <img className='tabImage' src={PLACEHOLDER} alt='placeholder' />,
  },
];

function Footfall(props) {
  const h = Hook(props);
  return (
    <>
      <MainContentNavbar to='/property/' text='AVERAGE RENTAL RATE' />
      <MainContentContainer>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Tabs data={tabData} />
          </Grid>
          <Grid item xs={12}>
            <Cctv />
          </Grid> */}
          <Grid item xs={12}>
            <h2 style={{ fontWeight: 600, marginTop: 30, marginBottom: 10 }}>
              People counting - {h.long_current_year_label}
            </h2>
            <Grid container className='paper'>
              <Grid item xs={12} md={2}>
                <DonutChartPeopleCounting {...h} />
              </Grid>
              <Grid item xs={12} md={2}>
                <BarChartPeopleCounting {...h} />
              </Grid>
              <Grid item xs={12} md={8}>
                <AreaChartPeopleCounting pointer='Traffic Count' data={h.trafficCount} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className='paper'>
              <Grid item xs={12} style={{ padding: 20 }}>
                <h2 style={{ fontWeight: 600 }}>Traffic by Entrance</h2>
                {!!h.TBE.length && (
                  <HorizontalBarChart data={h.TBE} height={1000} ratio={0.9} excludeRegex={[/foodcourt/i]} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className='paper'>
              <Grid item xs={12} style={{ padding: 20 }}>
                <h2 style={{ fontWeight: 600 }}>Traffic by Entrance (Foodcourt)</h2>
                {!!h.TBE.length && (
                  <HorizontalBarChart data={h.TBE} height={180} ratio={1.7} includeRegex={[/foodcourt/i]} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default Footfall;
