import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import UploadIcon from '@Components/UploadIcon';
import Hook from './hook';

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '145px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  root: { '&$checked': { color: '#4fd8af' }, color: '#1e3465' },
  checked: {},
}));

const timeline = [
  { name: 'appointmentAt', label: 'Appointment of Consultant' },
  { name: 'open_tenderAt', label: 'Open Tender' },
  { name: 'close_tenderAt', label: 'Close Tender' },
  { name: 'mtc_approveAt', label: 'MTC Approval' },
  { name: 'letter_appointmentAt', label: 'Letter of Appointment' },
  { name: 'kick_off_meetingAt', label: 'Kick Off Meeting/Site Possesion' },
  { name: 'eotAt', label: 'EOT' },
  { name: 'cpcAt', label: 'CPC' },
  { name: 'dlpAt', label: 'DLP' },
  { name: 'cmgdAt', label: 'CMGD' },
];

export default function MonitoringForm(props) {
  const classes = useStyles();
  const h = Hook(props);

  return (
    <div>
      <button
        onClick={h.handleClickOpen}
        className="blue-button flex-standard"
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          margin: 0,
        }}
      >
        <UploadIcon />
        <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>
          Upload Data
        </p>
      </button>
      <Dialog open={h.open} onClose={h.handleClose} maxWidth="md">
        <DialogTitle>
          <h2>Manual Upload - Project Management Monitoring</h2>
        </DialogTitle>
        <DialogContent>
          <Grid container justify="center" style={{ marginBottom: 2 }}>
            <Grid item container xs={12} alignItems="center" spacing={1} style={{ margin: 'auto' }}>
              {[
                { label: 'Project Name', name: 'project_name', onChange: h.handleChange },
                { label: 'Contract Amount(RM)', name: 'contract_amount', onChange: h.handleChange },
              ].map(e => (
                <ProjectInputForm {...e} />
              ))}
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={12} style={{ marginTop: '4rem' }}>
                {timeline.map((m, idx) => <ProjectTimeline idx={idx} onChange={h.handleChange} {...m} />)}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', padding: '10px 24px' }}>
          <Box
            component="button"
            className={`${classes.blueBtn } blue-button`}
            onClick={h.handleSave}
          >
            <h1 className={`${classes.blueBtnText } flex-standard`}>
              <AddIcon />
              Save Data
            </h1>
          </Box>
          <Button onClick={h.handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ProjectInputForm = ({ label, name, onChange = () => null }) => {
  return (
    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography className="text-center">{label}</Typography>
      <TextField
        name={name}
        type={name === 'contract_amount' ? 'number' : 'text'}
        onChange={(values) => {
          onChange({
            target: {
              name,
              value: values.target.value,
            },
          });
        }}
        required
        size="small"
        variant="outlined"
        style={{ width: '80%' }}
        fullWidth
        inputProps={{
          style: { textAlign: 'center' },
        }}
      />
    </Grid>
  );
};

const ProjectTimeline = ({
  label, name, idx, onChange = () => null,
}) => {
  return (
    <Timeline style={{ marginTop: -30 }}>
      <TimelineItem>
        <TimelineOppositeContent style={{ marginTop: -8 }}>
          <TextField
            name={name}
            onChange={(values) => {
              onChange({
                target: {
                  name,
                  value: `${values.target.value}T00:00:00.000Z`,
                },
              });
            }}
            type="date"
            size="small"
            variant="outlined"
            style={{ width: '80%' }}
            fullWidth
          />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outline" />
          {idx < timeline.length - 1 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Typography>{label}</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};
