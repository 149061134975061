import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';
import { yearComparisons } from '@Helpers';

export default function Hook({ building }) {
  const YEAR = yearComparisons(2)[1];
  const [occupancyRate, setOccupancyRate] = useState(0);
  const [NPI_actual, set_NPI_actual] = useState(0);
  const [sideChartData, set_sideChartData] = useState([]);

  useEffect(() => {
    if (!building) return;
    const body = { data: { uuid: building.uuid } };
    Api({
      endpoint: endpoints.getOccupancy(),
      data: { building_uuid: building.uuid, YEAR },
      onSuccess: (response) => setOccupancyRate(response.data[0]),
      onFail: setOccupancyRate(),
    });

    Api({
      ...body,
      endpoint: endpoints.getSalesturnover(),
      onSuccess: (response) => {
        const sortedV = _.groupBy(_.sortBy(response.data, ['YEAR', 'MONTH']), 'YEAR');
        const latestYear = sortedV[YEAR].filter(d => !!d.GROSS || !!d.OE);
        const prevYear = sortedV[YEAR - 1];
        const rev_thisYear = latestYear.slice(-1)[0].GROSS;
        const exp_thisYear = latestYear.slice(-1)[0].OE;
        const rev_prevYear = prevYear.slice(-1)[0].GROSS;
        const exp_prevYear = prevYear.slice(-1)[0].OE;
        set_NPI_actual(rev_thisYear - exp_thisYear);
        set_sideChartData(prev => {
          // set audited
          const filteroutnonbudget = prev.filter(d => ![1, 2].includes(d.x));
          return [
            ...filteroutnonbudget,
            { x: 1, npi: rev_prevYear - exp_prevYear, expenses: exp_prevYear },
            { x: 2, npi: rev_thisYear - exp_thisYear, expenses: exp_thisYear },
          ];
        });
      },
    });
    Api({
      ...body,
      YEAR: yearComparisons(2)[0],
      endpoint: endpoints.getBudget(),
      onSuccess: (response) => {
        const sortedV = _.groupBy(_.sortBy(response.data, ['YEAR', 'MONTH']), 'YEAR');
        const latestYear = sortedV[YEAR];
        const ytd_rev_bud_thisyear = _.sumBy(latestYear, o => o.RENTAL_REV);
        const ytd_exp_bud_thisyear = _.sumBy(latestYear, o => o.OE);
        set_sideChartData(prev => {
          // set audited
          const filteroutnonbudget = prev.filter(d => ![3].includes(d.x));
          return [
            ...filteroutnonbudget,
            { x: 3, npi: ytd_rev_bud_thisyear - ytd_exp_bud_thisyear, expenses: ytd_exp_bud_thisyear },
          ];
        });
      },
    });
  }, [building]);
  return {
    occupancyRate,
    NPI_actual,
    sideChartData,
    sideChartLabel: ["As at \n Aug '21", "As at \n Aug '22", `Budget \n ${yearComparisons()[1]}`]
  };
}
