import { useEffect, useState } from 'react';
import Api from '@Helpers/api';
import { changeDayToIndex, getDates } from '@Helpers';
import { endpoints } from '@Configs/endpoints';
import moment from 'moment';
import { countIssuesCards, countTasksCards } from '@Helpers/CountIssuesAndTasks';

const buildings = {
  0: 261, // B5
  1: 157, // GALLERIA
  2: 161, // KOMTAR JBCC
  3: 156, // MENARA KOMTAR
  4: 162, // LARKIN SENTRAL
  5: 159, // MENARA ANSAR
  6: 155, // MENARA JLAND
  7: 163, // @MART
  8: 158, // Tg Leman Beach Resort
  9: 160, // PPTD
};

const getDayOfDate = (i) => moment(moment().subtract(totalDays - i, 'days')).format('D');
const isWithinLast30Days = (time) => parseInt(time) > graphFirstDay;

const totalDays = 30;
const graphFirstDay = moment().subtract(totalDays, 'days').startOf('day').valueOf();
const days_arr = Array.from(Array(totalDays), (_, i) => getDayOfDate(i));

export default function Hook({ selectedBuilding }) {
  const [issues, set_issues] = useState([]);
  const [tasks, set_tasks] = useState([]);

  useEffect(() => {
    Api({
      endpoint: endpoints.getServedeck(),
      data: {
        query: `{
                issue(institution_id:${buildings[selectedBuilding]}){status resolved_date created_date due_date accepted_timestamp}
                task_execution(institution_id:${buildings[selectedBuilding]}){status created_date schedule due_date review_date}
            }`,
      },
      onSuccess: ({ data: { issue, task_execution } }) => {
        if (issue) set_issues(issue);
        if (task_execution) set_tasks(task_execution);
      },
    });
  }, [selectedBuilding]);

  // graphs
  const issues_created = issues.filter(f => isWithinLast30Days(f.created_date))
    .map(m => ({ day: getDates(m.created_date) }))
    .reduce((a, b) => { (a[b.day] = a[b.day] ? a[b.day] + 1 : 1); return a; }, {});

  const issues_resolved = issues.filter(f => (f.accepted_timestamp && isWithinLast30Days(f.accepted_timestamp)))
    .map(m => ({ day: getDates(m.accepted_timestamp) }))
    .reduce((a, b) => { (a[b.day] = a[b.day] ? a[b.day] + 1 : 1); return a; }, {});

  const tasks_reviewed = tasks.filter(f => isWithinLast30Days(f.review_date))
    .map(m => ({ day: getDates(m.review_date) }))
    .reduce((a, b) => { (a[b.day] = a[b.day] ? a[b.day] + 1 : 1); return a; }, {});

  const tasks_scheduled = tasks.filter(f => (f.schedule && isWithinLast30Days(f.schedule)))
    .map(m => ({ day: getDates(m.schedule) }))
    .reduce((a, b) => { (a[b.day] = a[b.day] ? a[b.day] + 1 : 1); return a; }, {});

  const createGraphDataFrom = (data) => Object.keys(data).map(m => ({ x: changeDayToIndex(m), y: data[m] }));
  const issues_created_graph = createGraphDataFrom(issues_created);
  const issues_resolved_graph = createGraphDataFrom(issues_resolved);
  const tasks_reviewed_graph = createGraphDataFrom(tasks_reviewed);
  const tasks_scheduled_graph = createGraphDataFrom(tasks_scheduled);

  return {
    days_arr,
    issue_summary: countIssuesCards(issues),
    task_summary: countTasksCards(tasks),
    issues_created_graph,
    issues_resolved_graph,
    tasks_reviewed_graph,
    tasks_scheduled_graph,
  };
}
