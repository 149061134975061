import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Button from "./Button";

import "./dropzone.css";

export default function Dropzone(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
      <div
        style={{
          border: "1px solid #ced4da",
          borderRadius: 10,
          padding: "30px 10px",
          height: 180,
        }}
        className="flex column center middle"
      >
        <div
          {...getRootProps({ className: "dropzone flex column center middle" })}
        >
          <input {...getInputProps()} />

          {files.length > 0 ? (
            files.map((file, index) => (
              <div key={index} className="flex column center middle">
                <div style={{ width: "100%", height: 150 }} key={file.name}>
                  <img
                    src={file.preview}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    alt="uploaded"
                  />
                </div>
                <div
                  className="flex row center middle"
                  style={{ marginTop: 20 }}
                >
                  <p style={{ fontSize: "0.9em" }}>{file.name}</p>
                  <Close
                    className="cancel-button"
                    onClick={() => setFiles([])}
                  />
                </div>
              </div>
            ))
          ) : (
            <>
              {" "}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={127.804}
                  height={89.463}
                  viewBox="0 0 127.804 89.463"
                >
                  <g data-name="Group 2190">
                    <g data-name="Group 2189" fill="#ced4da">
                      <path
                        data-name="Path 1606"
                        d="M117.153 0H10.65A10.663 10.663 0 000 10.65v68.162a10.663 10.663 0 0010.65 10.65h106.5a9.861 9.861 0 001.783-.156 10.621 10.621 0 008.867-10.495V10.65A10.663 10.663 0 00117.153 0zM10.65 85.2a6.39 6.39 0 01-6.365-5.828l42.469-27.767 43.189 33.6zm112.893-6.39a6.375 6.375 0 01-5.346 6.3 5.792 5.792 0 01-1.044.092H96.882L68.66 63.239l25.24-20.18 29.646 17.788zm0-22.932L94.82 38.646a2.13 2.13 0 00-2.428.162L65.2 60.556 48.17 47.311a2.13 2.13 0 00-2.471-.1L4.26 74.3V10.65a6.39 6.39 0 016.39-6.39h106.5a6.39 6.39 0 016.39 6.39z"
                      />
                      <path
                        data-name="Path 1607"
                        d="M63.902 12.78a12.78 12.78 0 1012.78 12.78 12.78 12.78 0 00-12.78-12.78zm0 21.3a8.52 8.52 0 118.52-8.52 8.52 8.52 0 01-8.52 8.52z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <p style={{ textAlign: "center", color: "#ADB5BD" }}>
                Drag &amp; drop your file here
                <br />
                or
                <br />
              </p>
              <button
                type="button"
                onClick={open}
                style={{
                  border: "2px solid #A5A5A5",
                  background: "#E9E9E9",
                  fontFamily: "Poppins",
                }}
              >
                Choose File
              </button>
            </>
          )}
        </div>
      </div>
      <Button
        variant="contained"
        onClick={props.handleModalClose}
        style={{
          margin: "15px 0px",
          padding: "4px 20px",
          opacity: files.length > 0 ? 1 : 0,
        }}
        disabled={files.length === 0}
      >
        Save
      </Button>
    </>
  );
}
