import React, { useState } from 'react';
import {
  Dialog, Button, DialogActions, DialogContent, DialogContentText, Box, Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  blueBtn: {
    borderRadius: '6px', width: '145px', backgroundColor: 'red', '&:hover': { backgroundColor: '#ff4560' },
  },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
}));
export default ({
  setOpenModal, id, onDelete,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  const handleDelete = () => { setOpen(false); setOpenModal(false); onDelete(id); };

  return (
    <>
      <Box
        onClick={handleClickOpen}
        component="button"
        className={`${classes.blueBtn } blue-button`}
      >
        <h1 className={`${classes.blueBtnText } flex-standard`}>
          <DeleteIcon />
          Delete
        </h1>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="subtitle2">Are you sure you want to delete this?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
