import React from 'react';
import {
  VictoryBar, VictoryChart, VictoryAxis, VictoryLabel, VictoryTheme,
} from 'victory';

export default function OccupancyRateByBuildingBar({ OccupancyRate }) {
  return (
    <>
      <h3 style={{ fontWeight: 600, textAlign: 'center' }}>Debtors Aging</h3>
      <VictoryChart
        domain={{ y: [0, 2000] }}
        domainPadding={{ x: 60, y: 20 }}
        width={1500}
        padding={{
          top: -20, left: 60, right: 60, bottom: 40,
        }}
        theme={VictoryTheme.material}
      >
        <VictoryAxis
          tickFormat={(x) => x}
          style={{
            tickLabels: { fontSize: 14, padding: 5 },
            ticks: { size: 0 },
            axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
            grid: { stroke: 'transparent' },
          }}
        />
        <VictoryAxis
          dependentAxis
          label="Total Day Aging"
          axisLabelComponent={<VictoryLabel angle={0} verticalAnchor="start" dy={-180} dx={30} />}
          tickFormat={(y) => y}
          style={{
            axis: { stroke: '#FFFFFF' },
            tickLabels: { fontSize: 14, padding: 5 },
            ticks: { stroke: '#FFFFFF', size: 0 },
            grid: { stroke: 'rgba(30, 52, 101, 0.15)', strokeDasharray: false },
          }}
        />
        <VictoryBar
          data={[
            { x: 'B5', y: 17 },
            { x: 'PPTD', y: 1350 },
            { x: 'GALLERIA', y: 1440 },
            { x: 'KOMTAR JBCC', y: 0 },
            { x: 'MENARA KOMTAR', y: 420 },
            { x: 'LARKIN SENTRAL', y: 4 },
          ]}
          x="x"
          y="y"
          labels={({ datum }) => `${datum.y.toFixed(0)}`}
          barWidth={50}
          animate={{
            duration: 2000,
            onLoad: { duration: 2000 },
          }}
          cornerRadius={3}
          style={{
            data: { fontFamily: 'Poppins', fill: '#008ffb' },
          }}
        />
      </VictoryChart>

    </>
  );
}
