import React from 'react';
import {
  Dialog, DialogContent, DialogTitle, Box, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import MaterialTable from '@Components/MaterialTable';
import UploadIcon from '@Components/UploadIcon';
import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  blueBtn: {
    borderRadius: '6px', width: '17em', marginRight: '2em', float: 'right',
  },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
}));

const columns = [
  {
    name: 'Inspection Name',
    selector: 'name',
    align: 'left',
    minWidth: 100,
  },
  {
    name: 'Open Cases',
    selector: 'open_case',
    align: 'center',
    minWidth: 100,
  },
  {
    name: 'Date',
    selector: 'date',
    align: 'left',
    minWidth: 100,
  },
  {
    name: 'Status',
    selector: 'status',
    align: 'left',
    minWidth: 100,
  },
  {
    name: 'Upload PDF',
    selector: 'upload',
    align: 'center',
    minWidth: 100,
  },
];

export default ({ open, setOpen, ...props }) => {
  const classes = useStyles();
  const tableData = props.inspections?.map(inspection => ({
    ...inspection,
    date: moment(inspection.inspectionAt).format('DD-MM-YYYY'),
    upload: ['closed'].includes(inspection.status) ? null : (
      <IconButton
        onClick={() => {
          setOpen(false);
          props.setOpenReport(true);
          props.setSelectedInspections(inspection.id);
        }}
        className="bg-primary"
      >
        <UploadIcon />
      </IconButton>
    ),
  }));
  return (
    <>
      <Box component="button" className={`${classes.blueBtn} blue-button`} onClick={() => setOpen(true)}>
        <h1 className={`${classes.blueBtnText} flex-standard`}>
          {_.sumBy(props.inspections, 'open_case')} OPEN CASES
        </h1>
      </Box>
      <Dialog open={open}>
        <DialogTitle>
          Reported Cases
          <Close
            className={classes.closeBtn}
            onClick={() => setOpen(false)}
          />
        </DialogTitle>
        <DialogContent>
          <MaterialTable
            tableHead
            columns={columns}
            tableData={tableData}
            tableMinWidth={300}
            tableMaxHeight={300}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
