import React from 'react';
import MaterialTable from '../MaterialTable';

const data = [];

for (let i = 0; i < 100; i++) {
  data.push({
    serviceType: 'Service A',
    expiringDate:
      i % 4 === 0 ? (
        <p style={{ color: '#FF4560' }}>1 Jan 2021</p>
      ) : (
        '1 Jan 2022'
      ),
  });
}

const columns = [
  {
    name: 'Service Type',
    selector: 'serviceType',
    align: 'left',
    minWidth: 100,
  },
  {
    name: 'Renewal Date',
    selector: 'renewalDate',
    align: 'right',
    minWidth: 100,
  },
  {
    name: 'Expiring Date',
    selector: 'expiringDate',
    align: 'right',
    minWidth: 110,
  },
  {
    name: 'Contract Value',
    selector: 'contractValue',
    align: 'right',
    minWidth: 100,
  },
  {
    name: 'Cost / Sqft',
    selector: 'costSqft',
    align: 'right',
    minWidth: 100,
  },
  {
    name: 'Vendor',
    selector: 'serviceProvider',
    align: 'right',
    minWidth: 120,
  },
  {
    name: 'Status',
    selector: 'status',
    align: 'right',
    minWidth: 60,
  },
];
export default function ContactServicingInfo(props) {
  return (
    <>
      <MaterialTable
        tableHead
        columns={columns}
        tableData={props.data ?? data}
        tableMinWidth={300}
        tableMaxHeight={300}
      />
    </>
  );
}
