/* eslint-disable complexity */
import { useState, useEffect } from 'react';
import Api, { endpoints } from '@Helpers/api';
import moment from 'moment';
import _ from 'lodash';

// can change to how many months back they want
const monthBefore = 5;

const getGraphData = (obj) => {
  let average_ticket_value = 0;
  let average_length_stay = 0;
  let bay_turnover = 0;
  let rev_per_bay = 0;
  if (obj) {
    average_ticket_value = obj.daily_revenue / obj.daily_paid_tickets;
    average_length_stay = average_ticket_value / obj.parking_rate;
    const public_carpark = obj.parking_bays - obj.season_parking;
    bay_turnover = public_carpark ? obj.daily_paid_tickets / public_carpark : 0;
    rev_per_bay = obj.daily_revenue * obj.parking_bays;
  }
  return {
    average_ticket_value, average_length_stay, bay_turnover, rev_per_bay,
  };
};

export default () => {
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month() + 1);
  const [parkingData, setParkingData] = useState([]);
  const [inputParking, setInputParking] = useState([]);
  const [selectedParking, setSelectedParking] = useState([]);
  const [projectParking, setProjectParking] = useState([]);
  const [facility, setFacility] = useState('');
  const [graphData, setGraphData] = useState([]);
  const [justUpdated, setJustUpdated] = useState(0);
  const findByYearAndMonth = x => x.year === year && x.month === month;
  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setProjectParking(data.ProjectParking ?? []);
        // load other static here
      },
    });
    Api({
      endpoint: endpoints.getParking(),
      onSuccess: ({ data }) => {
        setParkingData(data);
        setSelectedParking(data.filter(findByYearAndMonth) ?? []);
      },
    });
  }, [justUpdated]);
  useEffect(() => {
    const now = moment();
    const graphMonthlyData = [];
    graphMonthlyData.push({
      monthLabel: now.format('MMM'),
      x: now.format('YYYY-MM'),
      ...getGraphData(parkingData.find(x => x.year === now.year() && x.month === now.month() + 1 && x.ProjectParkingId === facility)),
    });
    for (let idx = 0; idx < monthBefore; idx++) {
      now.subtract(1, 'month');
      graphMonthlyData.push({
        monthLabel: now.format('MMM'),
        x: now.format('YYYY-MM'),
        ...getGraphData(parkingData.find(x => x.year === now.year() && x.month === now.month() + 1 && x.ProjectParkingId === facility)),
      });
    }
    setGraphData(_.sortBy(graphMonthlyData, ['x']));
  }, [facility]);
  const handleSave = () => {
    Api({
      endpoint: endpoints.createSecurityparking(),
      data: inputParking,
      onSuccess: () => {
        toast('success', 'Successfully update parking details'); // eslint-disable-line no-undef
        setJustUpdated(v => v + 1);
      },
      onFail: () => {
        toast('error', 'Fail to update parking details'); // eslint-disable-line no-undef
      },
    });
  };
  useEffect(() => {
    if (!year) {
      setYear(moment().year());
      return;
    }
    if (!month) {
      setMonth(moment().month() + 1);
      return;
    }
    setInputParking([]);
    if (parkingData) {
      setSelectedParking(parkingData.filter(findByYearAndMonth) ?? []);
    }
  }, [year, month]);
  return {
    year,
    setYear,
    month,
    setMonth,
    projectParking,
    parkingData,
    graphData,
    setJustUpdated,
    inputParking,
    setInputParking,
    selectedParking,
    handleSave,
    facility,
    setFacility,
  };
};
