import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import FileDropZone from '@Components/DropzoneBox';

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '150px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
  fontLabel: { fontSize: '10px' },
  formControl: { paddingTop: 10, paddingBottom: 5 },
  root: { '&$checked': { color: 'rgb(30, 52, 101)' }, transform: 'scale(0.8)' },
}));

export default function UploadInspectionImage(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const clickUpload = () => {
    props.onSave({ files })
    setOpen(false);
    setFiles([]);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Tooltip title="Upload Image">
          <AddAPhotoIcon fontSize="large" style={{ padding: 10, cursor: 'pointer' }} onClick={() => setOpen(true)} />
        </Tooltip>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ style: { borderRadius: 10, maxHeight: '40rem', width: 'auto' } }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Upload Inspection Photo
          <Close className={classes.closeBtn} onClick={() => setOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <Grid item md={12} lg={12}>
            <FileDropZone files={files} setFiles={setFiles} height={400} type="image" />
          </Grid>
        </DialogContent>
        <DialogActions>
          <div
            className="flex space-between"
            style={{ padding: '0 20px', width: '100%' }}
          >
            <Box
              component="button"
              className="blue-button-outlined"
              onClick={() => setOpen(false)}
              style={{ borderRadius: '6px', width: '120px' }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={() => clickUpload()}
              style={{ borderRadius: '6px', width: '160px', marginLeft: '5rem' }}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                <CloudUploadIcon style={{ marginRight: 3 }} />
                Upload Photo
              </h1>
            </Box>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
