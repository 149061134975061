import { useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { Save } from '@material-ui/icons';

const initInput = {
  project_name: null,
  contract_amount: 0,
  project_uuid: null,
  appointmentAt: null,
  open_tenderAt: null,
  close_tenderAt: null,
  mtc_approveAt: null,
  letter_appointmentAt: null,
  kick_off_meetingAt: null,
  eotAt: null,
  cpcAt: null,
  dlpAt: null,
  cmgdAt: null,
};

export default function Hook(props) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(initInput);

  const handleClose = () => {
    setInput(initInput);
    setOpen(false);
  };
  const handleClickOpen = () => setOpen(true);

  const handleChange = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = () => {
    if (!input.contract_amount) return;
    if (!input.project_name) return;
    Api({
      endpoint: endpoints.createProjectMonitor(),
      data: input,
      onSuccess: (response) => {
        toast('success', 'Successfully update Facility');
        props.refreshProjectMonitor();
        setInput(initInput);
        handleClose();
      },
      onFail: () => {
        toast('error', 'Successfully update Facility');
      },
    });
  };

  return {
    input,
    setInput,
    handleSave,
    handleClose,
    handleClickOpen,
    handleChange,
    open,
  };
}
