import React from 'react';
import {
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryGroup,
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryLabel,
} from 'victory';

function getRandomData() {
  let data = [];

  for (let i = 1; i < 32; i++) {
    data.push({
      x: i,
      y: Math.floor(Math.random() * 9 + 1),
    });
  }
  return data;
}

export default function AreaChart(props) {
  return (
    <>
      <VictoryChart
        height={600}
        width={1600}
        domainPadding={10}
        padding={120}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryLegend
          x={40}
          y={20}
          orientation='horizontal'
          gutter={60}
          style={{ labels: { fontFamily: 'Poppins', fontSize: 12 } }}
          data={[
            {
              name: props.pointers[0],
              symbol: { fill: '#008FFB', type: 'square' },
            },
            {
              name: props.pointers[1],
              symbol: { fill: '#FB9C5D', type: 'square' },
            },
            {
              name: props.pointers[2],
              symbol: { fill: '#B85EEE', type: 'square' },
            },
            {
              name: props.pointers[3],
              symbol: { fill: '#2ECCA9', type: 'square' },
            },
          ]}
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          style={{
            tickLabels: { fontFamily: 'Poppins', fontSize: 32, padding: 20, opacity: 0.65 },
            ticks: { size: 0 },
            axis: { stroke: 'transparent' },
            grid: { stroke: 'transparent' },
          }}
          label='Days'
          axisLabelComponent={
            <VictoryLabel
              angle={0}
              verticalAnchor='start'
              dy={60}
              dx={0}
              style={{ fontFamily: 'Poppins', fontSize: 32 }}
            />
          }
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          dependentAxis
          style={{
            tickLabels: { fontFamily: 'Poppins', fontSize: 32, padding: 20, opacity: 0.65 },
            ticks: { size: 0 },
            axis: { stroke: 'transparent' },
            grid: { stroke: 'transparent' },
          }}
        />

        <VictoryGroup
          color='#008FFB'
          labels={({ datum }) => [props.pointers[0], datum.y]}
          labelComponent={
            <VictoryTooltip
              style={{ fontFamily: 'Poppins', fontSize: 24, fill: '#008FFB' }}
              flyoutPadding={20}
              flyoutStyle={{
                fill: '#FFFFFF',
                stroke: 'rgba(0,0,0, 0.1)',
                strokeWidth: 3,
              }}
              pointerOrientation='left'
              centerOffset={{ x: 200, y: 20 }}
            />
          }
          data={getRandomData()}
        >
          <VictoryLine
            interpolation='linear'
            style={{
              data: {
                stroke: '#008FFB',
                strokeWidth: 4,
              },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          />
          <VictoryScatter
            size={({ active }) => (active ? 5 : 0)}
            style={{
              data: {
                stroke: '#008FFB',
                fillOpacity: 0,
                strokeWidth: 3,
              },
            }}
          />
        </VictoryGroup>
        <VictoryGroup
          color='#FB9C5D'
          labels={({ datum }) => [props.pointers[1], datum.y]}
          labelComponent={
            <VictoryTooltip
              style={{ fontFamily: 'Poppins', fontSize: 24, fill: '#FB9C5D' }}
              flyoutPadding={20}
              flyoutStyle={{
                fill: '#FFFFFF',
                stroke: 'rgba(0,0,0, 0.1)',
                strokeWidth: 3,
              }}
              pointerOrientation='left'
              centerOffset={{ x: 200, y: 20 }}
            />
          }
          data={getRandomData()}
        >
          <VictoryLine
            interpolation='linear'
            style={{
              data: {
                stroke: '#FB9C5D',
                strokeWidth: 4,
              },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          />
          <VictoryScatter
            size={({ active }) => (active ? 5 : 0)}
            style={{
              data: {
                stroke: '#FB9C5D',
                fillOpacity: 0,
                strokeWidth: 3,
              },
            }}
          />
        </VictoryGroup>
        <VictoryGroup
          color='#B85EEE'
          labels={({ datum }) => [props.pointers[2], datum.y]}
          labelComponent={
            <VictoryTooltip
              style={{ fontFamily: 'Poppins', fontSize: 24, fill: '#B85EEE' }}
              flyoutPadding={20}
              flyoutStyle={{
                fill: '#FFFFFF',
                stroke: 'rgba(0,0,0, 0.1)',
                strokeWidth: 3,
              }}
              pointerOrientation='left'
              centerOffset={{ x: 200, y: 20 }}
            />
          }
          data={getRandomData()}
        >
          <VictoryLine
            interpolation='linear'
            style={{
              data: {
                stroke: '#B85EEE',
                strokeWidth: 4,
              },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          />
          <VictoryScatter
            size={({ active }) => (active ? 5 : 0)}
            style={{
              data: {
                stroke: '#B85EEE',
                fillOpacity: 0,
                strokeWidth: 3,
              },
            }}
          />
        </VictoryGroup>
        <VictoryGroup
          color='#2ECCA9'
          labels={({ datum }) => [props.pointers[3], datum.y]}
          labelComponent={
            <VictoryTooltip
              style={{ fontFamily: 'Poppins', fontSize: 24, fill: '#2ECCA9' }}
              flyoutPadding={20}
              flyoutStyle={{
                fill: '#FFFFFF',
                stroke: 'rgba(0,0,0, 0.1)',
                strokeWidth: 3,
              }}
              pointerOrientation='left'
              centerOffset={{ x: 200, y: 20 }}
            />
          }
          data={getRandomData()}
        >
          <VictoryLine
            interpolation='linear'
            style={{
              data: {
                stroke: '#2ECCA9',
                strokeWidth: 4,
              },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          />
          <VictoryScatter
            size={({ active }) => (active ? 5 : 0)}
            style={{
              data: {
                stroke: '#2ECCA9',
                fillOpacity: 0,
                strokeWidth: 3,
              },
            }}
          />
        </VictoryGroup>
      </VictoryChart>
    </>
  );
}
