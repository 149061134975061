import React from 'react';
import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel';
import { makeStyles } from '@material-ui/core/styles';
import Card from './Card';

const useStylesCarousel = makeStyles(() => ({
  content: {
    width: '100% !important',
    maxWidth: '1140px!important',
    height: '600px !important',
  },
  footer: {
    visibility: 'hidden',
  },
  slide: {
    backgroundColor: 'white',
  },
}));

const useStylesSlide = makeStyles(() => ({
  root: {
    backgroundColor: 'white !important',
  },
  text: {
    paddingTop: '0 !important',
  },
}));

export default function AutoRotatingCarouselModal({ handleOpen, setHandleOpen, image }) {
  const classesCarousel = useStylesCarousel();
  const classesSlide = useStylesSlide();
  const sortedImage = [...image.slice(handleOpen?.idx), ...image.slice(0, handleOpen?.idx)];

  return (
    !!handleOpen.open && (
      <AutoRotatingCarousel
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        autoplay={false}
        style={{ position: 'absolute' }}
        classes={classesCarousel}
      >
        {sortedImage?.map((item) => (
          <Slide
            mediaBackgroundStyle={{ height: '100%' }}
            classes={classesSlide}
            media={<Card {...item} />}
          />
        ))}
      </AutoRotatingCarousel>
    )
  );
}
