import React, { useEffect, useState } from 'react';
import {
  Grid, Dialog, DialogTitle, DialogContent, DialogActions, OutlinedInput, Box, FormControl, Select, MenuItem, TextField,
} from '@material-ui/core';
import Api, { endpoints } from '@Helpers/api';
import { Add, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import UploadIcon from '@Components/UploadIcon';
import NumberFormat from 'react-number-format';
import moment from 'moment';
// import './tempstyle.css';

const inputTemplate = {
  name: null,
  lot_no: null,
  area: 0,
  rental: 0,
  commenceAt: '',
  expireAt: '',
  TenantBumiStatusId: 1,
  TenantTradeCategoryId: 1,
  TenantTradeCategoryDetailId: '',
  TenantRenewedStatusId: 1,
};

const titles = [
  { label: 'Tenant', col: 1 },
  { label: 'Bumi Status', col: 1 },
  { label: 'Trade Category', col: 1 },
  { label: 'Trade Category Detail', col: 1 },
  { label: 'Lot No.', col: 1 },
  { label: 'Unit', col: 1 },
  { label: 'Area (ft²)', col: 1 },
  { label: 'Current Term', col: 3 },
  { label: 'Rental (RM)', col: 1 },
  { label: 'Renewed Status', col: 1 },
];

const useStyles = makeStyles(() => ({
  smallBtn: { borderRadius: '6px', margin: 0 },
  blueBtn: { borderRadius: '6px', width: '17em', marginRight: '2em' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
}));

export default ({ uuid, setIsSaved = () => null }) => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState([]);
  const [bumiStatus, setBumiStatus] = useState([]);
  const [tradeCategory, setTradeCategory] = useState([]);
  const [tradeCategoryDetails, setTradeCategoryDetails] = useState([]);
  const [renewedStatus, setRenewedStatus] = useState([]);
  const classes = useStyles();

  const handleClose = () => {
    setInput([]);
    setOpen(false);
  };

  const getTenantDetails = () => Api({
    endpoint: endpoints.getTenantDetails(uuid),
    onSuccess: ({ data }) => setInput(data),
  });

  useEffect(() => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setBumiStatus(data.TenantBumiStatus);
        setTradeCategory(data.TenantTradeCategory);
        setTradeCategoryDetails(data.TenantTradeCategoryDetail);
        setRenewedStatus(data.TenantRenewedStatus);
      },
    });
  }, []);

  const handleClickOpen = () => {
    getTenantDetails();
    setOpen(true);
  };

  const handleSave = () => {
    Api({
      endpoint: endpoints.updateTenantDetails(uuid),
      data: { input },
      onSuccess: () => {
        toast('success', 'Successfully update Tenant Details'); // eslint-disable-line no-undef
        handleClose();
        setIsSaved(prev => prev + 1);
      },
      onFail: () => {
        toast('error', 'Unsuccessfully update Tenant Details'); // eslint-disable-line no-undef
      },
    });
  };

  const handleAddInput = (i) => {
    const temp = [...input];
    temp.splice(i, 0, inputTemplate);
    setInput(temp);
  };

  const handleChangeInput = (i, name, value) => {
    const temp = [...input];
    temp.splice(i, 1, { ...temp[i], [name]: value });
    setInput(temp);
  };

  const handleRemoveInput = (index) => setInput(i => i.filter((_, idx) => idx !== index));

  return (
    <>
      <button
        onClick={handleClickOpen}
        className="blue-button flex-standard"
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          margin: 0,
        }}
      >
        <UploadIcon />
        <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>Upload Data</p>
      </button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <DialogTitle>
          <Grid container justify="space-between">
            <h1>Manual Upload Data - Tenant Details</h1>
            <Close className={classes.closeBtn} onClick={handleClose} />
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: 500 }}>
          <table className="custom-form-table" style={{ width: '100%' }}>
            <tr>
              <td>
                <Box
                  component="button"
                  className={`${classes.smallBtn} blue-button`}
                  onClick={() => handleAddInput(0)}
                >
                  <h1 className={`${classes.blueBtnText} flex-standard`}>
                    <Add />
                  </h1>
                </Box>
              </td>
              {[
                { label: 'Tenant', col: 1 },
                { label: 'Bumi Status', col: 1 },
                { label: 'Trade Category', col: 1 },
                { label: 'Trade Category Detail', col: 1 },
                { label: ['7940dd60-7147-11ee-b962-0242ac120002'].includes(uuid)?'Unit':'Lot No.', col: 1 },
                { label: 'Area (ft²)', col: 1 },
                { label: 'Current Term', col: 3 },
                { label: 'Rental (RM)', col: 1 },
                { label: 'Renewed Status', col: 1 },
              ].map(m => <td colSpan={m.col} children={<h4>{m.label}</h4>} />)}
              <td />
            </tr>
            {input.map((ipt, index) => {
              return (
                <tr>
                  <td>
                    <Box
                      component="button"
                      className={`${classes.smallBtn} blue-button`}
                      onClick={() => handleAddInput(index)}
                    >
                      <h1 className={`${classes.blueBtnText} flex-standard`}>
                        <Add />
                      </h1>
                    </Box>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '15em' }}>
                      <OutlinedInput
                        value={ipt.name || ''}
                        placeholder="Tenant Name"
                        onChange={e => handleChangeInput(index, 'name', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                          getContentAnchorEl: null,
                        }}
                        value={ipt.TenantBumiStatusId}
                        onChange={e => handleChangeInput(index, 'TenantBumiStatusId', e.target.value)}
                      >
                        {bumiStatus.map((e) => <MenuItem value={e.id}>{e.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '11em' }}>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                          getContentAnchorEl: null,
                        }}
                        placeholder="Select Category"
                        value={ipt.TenantTradeCategoryId}
                        onChange={e => handleChangeInput(index, 'TenantTradeCategoryId', e.target.value)}
                      >
                        {tradeCategory.map((e) => <MenuItem value={e.id}>{e.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '11em' }}>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                          getContentAnchorEl: null,
                        }}
                        placeholder="Select Category Detail"
                        value={ipt.TenantTradeCategoryDetailId}
                        onChange={e => handleChangeInput(index, 'TenantTradeCategoryDetailId', e.target.value)}
                      >
                        {tradeCategoryDetails.map((e) => <MenuItem value={e.id} dense>{e.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </td>
                  {['7940dd60-7147-11ee-b962-0242ac120002'].includes(uuid)? (
                    <td>
                      <FormControl variant="outlined" size="small" style={{ width: '7em' }}>
                        <OutlinedInput
                          value={ipt.lot_no || ''}
                          placeholder="Unit."
                          onChange={e => handleChangeInput(index, 'lot_no', e.target.value)}
                        />
                      </FormControl>
                    </td>
                  ):(
                    <td>
                      <FormControl variant="outlined" size="small" style={{ width: '7em' }}>
                        <OutlinedInput
                          value={ipt.lot_no || ''}
                          placeholder="Lot No."
                          onChange={e => handleChangeInput(index, 'lot_no', e.target.value)}
                        />
                      </FormControl>
                    </td>
                  )}
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '6em' }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        value={ipt.area}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={e => handleChangeInput(index, 'area', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '11em' }}>
                      <OutlinedInput
                        type="date"
                        value={moment(ipt.commenceAt).format('YYYY-MM-DD')}
                        onChange={e => handleChangeInput(index, 'commenceAt', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td>-</td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '11em' }}>
                      <OutlinedInput
                        type="date"
                        value={moment(ipt.expireAt).format('YYYY-MM-DD')}
                        onChange={e => handleChangeInput(index, 'expireAt', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" style={{ width: '7em' }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        value={ipt.rental}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={e => handleChangeInput(index, 'rental', e.target.value)}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                          getContentAnchorEl: null,
                        }}
                        value={ipt.TenantRenewedStatusId}
                        onChange={e => handleChangeInput(index, 'TenantRenewedStatusId', e.target.value)}
                      >
                        {renewedStatus.map((e) => <MenuItem value={e.id}>{e.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Box
                      component="button"
                      className={`${classes.smallBtn} blue-button`}
                      onClick={() => handleRemoveInput(index)}
                    >
                      <h1 className={`${classes.blueBtnText} flex-standard`}>
                        <Close />
                      </h1>
                    </Box>
                  </td>
                </tr>
              );
            })}
          </table>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: '0 3em', width: '100%', display: 'flex' }}>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={handleSave}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                Save Data
              </h1>
            </Box>
            <Box
              component="button"
              className="cancel-button"
              onClick={handleClose}
              style={{
                borderRadius: '6px',
                width: '120px',
              }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>

          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}
