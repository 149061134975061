import { Grid } from '@material-ui/core';
import OccupancyRateChart from '@Components/Leasing/OccupancyRateChart';
import RenewalOfTenanciesChart from './components/RenewalOfTenanciesChart';
import SalesTurnoverPerMonthChart from '@Components/Leasing/SalesTurnoverPerMonthChart';
import TenancyMixBreakdownChart from '@Components/Leasing/TenancyMixBreakdownChart';
import TenantLotChart from '@Components/Leasing/TenantLotChart';
import TotalSalesTurnOverChart from '@Components/Leasing/TotalSalesTurnoverChart';
import { Tabs } from '@Components/Tabs';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import RentalRateChart from '@Components/Leasing/RentalRateChart';
import DebtorsAgingTable from '@Components/Leasing/DebtorsAgingTable';
import TenantDetailsForm from './components/TenantDetailsForm';
import DebtorsAgingForm from '@Components/Leasing/DebtorsAgingForm';
import ExpireTable from './components/ExpireTable'
import Hook from './hook';
import ROTDIalog from './components/ROTDIalog';
import ExpireTableDialog from './components/ExpireTableDialog';

function Leasing(props) {
  const h = Hook(props);
  return (
    <>
      <MainContentNavbar to="/property/" text="TENANCY" />
      <h3 style={{ marginTop: -10, marginBottom: 10, paddingLeft: 40 }}>Tenancy Analysis</h3>
      <ROTDIalog {...h} />
      <ExpireTableDialog {...h} />
      <MainContentContainer>
        {/* <Grid
          container
          spacing={3}
          style={{ marginBottom: 30, paddingTop: 10 }}
        >
          <Grid item xs={12} md={3} style={{ paddingRight: 30 }}>
            <OccupancyRateChart {...h.OccupancyRate} />
          </Grid>
          {isShowSto && (
            <Grid container item xs={12} md={9} className="paper">
              <Grid item xs={12} md={3}>
                <TotalSalesTurnOverChart {...h} />
              </Grid>
              <Grid item xs={12} md={9}>
                <SalesTurnoverPerMonthChart {...h} />
              </Grid>
            </Grid>
          )}
        </Grid> */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <RenewalOfTenanciesChart {...h} upload={<TenantDetailsForm uuid={props.building.uuid} setIsSaved={h.set_isSaved} />} />
          </Grid>
          {/* <Grid item xs={12} md={8}>
            {Object.keys(h.tla).length !== 0 && (<TenantLotChart {...h} />)}
          </Grid> */}
          <Grid item xs={12} md={4} style={{ paddingRight: 30 }}>
            <OccupancyRateChart {...h.OccupancyRate} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {Object.keys(h.tmb).length !== 0 && (<TenancyMixBreakdownChart {...h} />)}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <ExpireTable {...h} />
          </Grid>
          <Grid item xs={12} md={5}>
            <DebtorsAgingTable
              {...h}
              uuid={props.building.uuid}
              upload={<DebtorsAgingForm uuid={props.building.uuid} refresh={h.getDebtoraging} />}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tabs data={tabData} />
          </Grid>
        </Grid> */}
      </MainContentContainer>
    </>
  );
}

export default Leasing;
