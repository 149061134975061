import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactHlsPlayer from 'react-hls-player';
import { OfflineCloud } from '@Assets/Icons';
import Hook from './hook';

function CCTVWebview() {
  const h = Hook();

  return (
    <>
      {!h.cameras.length ? (
        <div
          style={{
            display: 'flex', justifyContent: 'center', width: '100vw',
          }}
        >Camera Not Found
        </div>
      ) : (
        <Grid container justify="center" alignItems="center" className="cctvTabContent">
          {h.isLoading ? (
            <CircularProgress style={{ position: 'absolute' }} />
          ) : (
            <h3 style={{ position: 'absolute' }}>Error loading live CCTV footage.</h3>
          )}
          <div style={{ position: 'relative', width: '100%' }}>
            <ReactHlsPlayer
              src={h.url}
              autoPlay={false}
              controls
              width="100%"
              height="auto"
              style={{ zIndex: 2 }}
              hlsConfig={{
                manifestLoadingTimeOut: 30000,
                maxLoadingDelay: 30,
                minAutoBitrate: 0,
                lowLatencyMode: true,
              }}
            />
            {(!h.isOnline) && (
              <div style={{
                position: 'absolute',
                backgroundColor: 'rgba(234, 22, 1,0.3)',
                top: 0,
                right: 0,
                padding: '10px',
                zIndex: 4,
                borderRadius: '0 0 0 5px',
              }}
              >
                <OfflineCloud width="40" height="32" />
                <p style={{ color: '#EA1601', fontSize: 18, textWeight: 600 }}>OFFLINE</p>
              </div>
            )}
          </div>
        </Grid>
      )}
    </>
  );
}

export default CCTVWebview;
