import React from 'react'
import { Link, useParams } from 'react-router-dom'
import MainContentContainer from "@Components/MainContentContainer";
import { Grid } from '@material-ui/core';
import StatusCard from '@Components/AdminPanel/StatusCard'
import Hook from './hook'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import moment from 'moment';

export default function CCTVStatus(props) {
    const {building} = useParams()
    const h = Hook({...props, building})
    return(
        <>
          <Grid container justify='space-between' style={{marginBottom:10}}>
            <Grid item xs={6} container alignItems='center'>
              <Link to='/admin-panel'>
            <h2 style={{ fontWeight: 700 }}>
                Admin Panel
            </h2>
            </Link>
            <ChevronRightIcon />
            <h2 style={{fontWeight: 400}} >{building} CCTV</h2>
            </Grid>
            <Grid item xs={6} container alignItems='center' justify='flex-end'>
              <small>DAX 1.0.0</small>
              <small style={{color:'gray', marginLeft:3}}>(last update on {moment(h.cctvData.last_updated).format('DD MMMM YYYY, h:mma')})</small>
            </Grid>
          </Grid>
            <MainContentContainer>
                <Grid container style={{ marginBottom: 30 }}>
                    <h3 style={{ fontWeight: 700, marginBottom: 10 }}>CCTV status</h3>
            <Grid item container xs={12} spacing={2}>
                {Object.keys(h.cctvData).filter(f => typeof(h.cctvData[f]) === 'object').map(m => 
                <Grid item xs={12} md={3} lg={2}>
                <StatusCard
                        name={m}
                        online={h.cctvData[m].online}
                        last_update={h.cctvData[m].last_updated}
                        last_backup={h.cctvData[m].last_backup}
                    />
                    </Grid>
                )}
            </Grid>
                </Grid>
            </MainContentContainer>
        </>
    )
}