import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  // MenuItem,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '150px' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
  fontLabel: { fontSize: '10px' },
  formControl: { paddingTop: 10, paddingBottom: 5 },
  root: { '&$checked': { color: 'rgb(30, 52, 101)' }, transform: 'scale(0.8)' },
}));

export default function CreateInspection({ project, tab, tabslist, callback }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const today = moment(new Date()).format('YYYY-MM-DDThh:mm');
  const [inspectionAt, setInspectionAt] = useState(today);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [pilot_name, setPilot_name] = useState('');
  // const [category, setCategory] = useState('open');

  const handleSubmit = () => {
    const categoryInput = {};
    const { InspectionCategoryId, QualityCategoryId } = tabslist.find(x => x.value === tab).filter;
    if (InspectionCategoryId) categoryInput.category = InspectionCategoryId;
    if (QualityCategoryId) categoryInput.qualityControl = QualityCategoryId;
    callback({
      project_uuid: project.uuid,
      DivisionId: project.DivisionId,
      name,
      inspectionAt,
      description,
      pilot_name,
      ...categoryInput,
    });
    setOpen(false);
    setInspectionAt(moment(new Date()).format('YYYY-MM-DDThh:mm'));
    setName('');
    setDescription('');
    setPilot_name('');
    // setCategory('open');
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Box
          component="button"
          className={`${classes.blueBtn} blue-button`}
          style={{ alignItems: 'flex-end' }}
          onClick={() => setOpen(true)}
        >
          <h1 className={`${classes.blueBtnText} flex-standard`}>
            New Inspection
          </h1>
        </Box>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ style: { borderRadius: 10, maxHeight: '40rem', width: 'auto' } }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Create New Inspection
          <Close className={classes.closeBtn} onClick={() => setOpen(false)} />
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <div className="flex" style={{ justifyContent: 'center' }}>
            <Grid container>
              {[
                {
                  label: 'Date',
                  input: (
                    <TextField
                      type="datetime-local"
                      variant="outlined"
                      size="small"
                      className={classes.formControl}
                      value={inspectionAt}
                      onChange={(e) => setInspectionAt(e.target.value)}
                      inputProps={{ max: today }}
                    />
                  ),
                },
                {
                  label: 'Inspection Name',
                  input: (
                    <TextField
                      placeholder="Enter name of the inspection"
                      variant="outlined"
                      fullWidth
                      required
                      size="small"
                      className={classes.formControl}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  ),
                },
                {
                  label: 'Description',
                  input: (
                    <TextField
                      placeholder="Enter description of the inspection"
                      multiline
                      fullWidth
                      required
                      rows={4}
                      variant="outlined"
                      className={classes.formControl}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  ),
                },
                {
                  label: 'Pilot Name',
                  input: (
                    <TextField
                      placeholder="Enter pilot that fly the drone"
                      variant="outlined"
                      fullWidth
                      required
                      size="small"
                      className={classes.formControl}
                      value={pilot_name}
                      onChange={(e) => setPilot_name(e.target.value)}
                    />
                  ),
                },
                // {
                //   label: 'Category',
                //   input: (
                //     <TextField
                //       select
                //       fullWidth
                //       variant="outlined"
                //       value={category}
                //       onChange={(e) => setCategory(e.target.value)}
                //       size="small"
                //       className={classes.formControl}
                //       required
                //     >
                //       {[
                //         { value: 'osh', children: 'OSH' },
                //         { value: 'env', children: 'ENVIRONMENT' },
                //         { value: 'qc1', children: 'Q. CONTROL 1' },
                //         { value: 'qc2', children: 'Q. CONTROL 2' },
                //         { value: 'qc3', children: 'Q. CONTROL 3' },
                //         { value: 'preq', children: 'PREQLASSIC' },
                //       ].map(e => (<MenuItem {...e} />))}
                //     </TextField>
                //   ),
                // },
              ].map(e => (
                <Grid item container>
                  <Grid item xs={3}>
                    <p style={{ padding: '15px 10px' }}>{e.label}</p>
                  </Grid>
                  <Grid item xs={9}>
                    {e.input}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div
            className="flex space-between"
            style={{ padding: '0 20px', width: '100%' }}
          >
            <Box
              component="button"
              className="blue-button-outlined"
              onClick={() => setOpen(false)}
              style={{ borderRadius: '6px', width: '120px' }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>
            <Box
              component="button"
              className={`${classes.blueBtn} blue-button`}
              onClick={handleSubmit}
              style={{ borderRadius: '6px', width: '180px' }}
            >
              <h1 className={`${classes.blueBtnText} flex-standard`}>
                Create Inspection
              </h1>
            </Box>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
