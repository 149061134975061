import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { ExpandMore } from "@material-ui/icons";

export default function DropdownCategory(h) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectItem = (m) => {
    h.set_selectedCategory(m)
    handleClose();
  }

  return (
    <div>
      <Button
        onClick={handleClick}
        endIcon={<ExpandMore />}
        style={{ borderRadius: 10, backgroundColor: "#1E3465", color: "#ffffff", margin: '0 10px' }}
      >
        {h.selectedCategory ?? 'Category'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          selected={h.selectedCategory === 'All Categories'}
          onClick={() => selectItem('All Categories')}
        >All Categories</MenuItem>
        <MenuItem disabled>
          <em>Categories</em>
        </MenuItem>
        {h.menuItem?.map(m => (
          <MenuItem
            selected={h.selectedCategory === m}
            onClick={() => selectItem(m)}
          >{m}</MenuItem>
        ))}
      </Menu>
    </div>
  );
}
