import React from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
} from 'victory';
import { MoneyFormat, yearComparisons } from '@Helpers';

import data from './SalesTurnoverPerMonthData.json';

function SalesTurnoverPerMonthChart({ SalesTurnOver }) {
  return (
    <>
      <h5 style={{ fontWeight: 400, marginLeft: '15px' }}>Sales Turnover per Month</h5>
      <VictoryChart
        // domainPadding will add space to each side of VictoryBar to
        // prevent it from overlapping the axis
        domainPadding={60}
        width={800}
        theme={VictoryTheme.material}
      >
        <VictoryLegend
          x={620}
          y={0}
          orientation='horizontal'
          gutter={20}
          data={[
            { name: yearComparisons(2)[0], symbol: { fill: '#1F3566', type: 'square' } },
            { name: yearComparisons(2)[1], symbol: { fill: '#008FFB', type: 'square' } },
          ]}
          style={{ labels: { fontFamily: 'Poppins', fontSize: 12 } }}
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          tickFormat={(x) => x}
          style={{
            tickLabels: { fontFamily: 'Poppins', fontSize: 14, padding: 5 },
            ticks: { size: 0 },
            axis: { stroke: 'rgba(30, 52, 101, 0.15)' },
            grid: { stroke: 'transparent' },
          }}
        />
        <VictoryAxis
          dependentAxis
          label='(RM)'
          axisLabelComponent={<VictoryLabel angle={0} verticalAnchor='start' dy={-150} dx={0} />}
          tickFormat={(y) => MoneyFormat(y)}
          style={{
            axis: { stroke: '#FFFFFF' },
            tickLabels: { fontSize: 14, padding: 5 },
            ticks: { stroke: '#FFFFFF', size: 0 },
            grid: { stroke: 'rgba(30, 52, 101, 0.15)', strokeDasharray: false },
          }}
        />
        <VictoryGroup offset={10} colorScale={['#1F3566', '#008FFB']}>
          <VictoryBar
            data={SalesTurnOver ?? data}
            x='x'
            y='y2'
            barWidth={10}
            labels={({ datum }) => `${((datum.y2)/1000000).toFixed(1)}M`}
            labelComponent={
              <VictoryTooltip
              flyoutWidth={60}
              flyoutHeight={40}
              cornerRadius={5}
              pointerLength={10}
              flyoutStyle={{
                stroke: '#1F3566',
                strokeWidth: 1,
                fill: 'rgba(255,255,255,0.9)'
              }}
              style={{
                fontFamily: 'Poppins',
                fill: "#868C97",
                fontSize: 15,
                fontWeight: 500,
                textAnchor: "middle"
              }}
            />}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            cornerRadius={3}
          />
          <VictoryBar
            data={SalesTurnOver ?? data}
            x='x'
            y='y1'
            barWidth={10}
            labels={({ datum }) => `${((datum.y1)/1000000).toFixed(1)}M`}
            labelComponent={
              <VictoryTooltip
              flyoutWidth={60}
              flyoutHeight={40}
              cornerRadius={5}
              pointerLength={10}
              flyoutStyle={{
                stroke: '#1F3566',
                strokeWidth: 1,
                fill: 'rgba(255,255,255,0.9)'
              }}
              style={{
                fontFamily: 'Poppins',
                fill: "#868C97",
                fontSize: 15,
                fontWeight: 500,
                textAnchor: "middle"
              }}
            />}
            animate={{
              duration: 2000,
              onLoad: { duration: 2000 },
            }}
            cornerRadius={3}
          />
        </VictoryGroup>
      </VictoryChart>
    </>
  );
}

export default SalesTurnoverPerMonthChart;
