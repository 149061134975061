import { useDropzone } from 'react-dropzone';

export default function Hook({ setFiles, type = 'image' }) {
  const acceptType = {
    excel: '.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    image: 'image/jpeg, image/png',
    doc: '.pdf, .docx, .doc, .excel, .csv, .xlsx',
    csv: '.csv',
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: acceptType[type],
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => Object.assign(file, {
          preview: type === 'image' ? URL.createObjectURL(file) : `${process.env.REACT_APP_S3}/icons/file.png`,
        })),
      );
    },
  });

  return { getRootProps, getInputProps, open };
}
