import React, { useEffect, useState } from 'react';
import {
  Grid, Dialog, DialogTitle, DialogContent, DialogActions, OutlinedInput, Box, FormControl,
} from '@material-ui/core';
import Api, { endpoints } from '@Helpers/api';
import { Add, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import UploadIcon from '@Components/UploadIcon';

const titles = [
  'NO RESIGN',
  'NO 1ST JAN',
  'NO CURRENT',
];

const useStyles = makeStyles(() => ({
  blueBtn: { borderRadius: '6px', width: '100%' },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  outlinedBtnText: { fontWeight: 600, fontSize: 16 },
  closeBtn: { cursor: 'pointer', float: 'right' },
}));
export default () => {
  const [open, setOpen] = useState(false);
  const [turnovers, set_turnovers] = useState([]);
  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);
  const classes = useStyles();

  useEffect(() => {
    Api({
      endpoint: endpoints.getTurnovers(),
      onSuccess: ({ data }) => { if (data) { set_turnovers(data); } },
      onFail: (err) => console.log(err),
    });
  }, []);

  const handleChangeInput = (index, title, value) => {
    const to = turnovers;
    to[index][title] = parseInt(value);
    set_turnovers([...to]);
  };

  const handleSave = (turnover) => {
    const input = {
      'NO RESIGN': turnover['NO RESIGN'],
      'NO 1ST JAN': turnover['NO 1ST JAN'],
      'NO CURRENT': turnover['NO CURRENT'],
    };
    Api({
      endpoint: endpoints.updateTurnovers(turnover.id),
      data: { input },
      onSuccess: () => { toast('success', 'Successfully update turnovers'); },
      onFail: () => { toast('error', 'Fail update turnovers'); },
    });
  };

  return (
    <>
      <button
        onClick={handleClickOpen}
        className="blue-button flex-standard"
        style={{
          width: 160,
          height: 36,
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: 15,
          margin: 0,
        }}
      >
        <UploadIcon />
        <p style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}>Upload Data</p>
      </button>
      <Dialog
        open={open}
        maxWidth="xl"
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: 10 } }}
      >
        <DialogTitle>
          <Grid container>
            <h1>Manual Upload Data</h1>
            <Close className={classes.closeBtn} onClick={handleClose} style={{ marginLeft: 'auto' }} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '0 20px', height: '300px', color: '#1e3465' }}>
            <Grid container spacing={3}>
              <Grid item xs={4} />
              {titles.map(t => <Grid item xs={2} children={<b>{t}</b>} />)}
            </Grid>
            {turnovers.map((b, idx) => (
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div style={{ fontWeight: 'bold' }}>{b.COMPANY}</div>
                </Grid>
                {titles.map((t) => {
                  return (
                    <Grid item xs={2}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          value={b[t]}
                          onChange={e => handleChangeInput(idx, t, e.target.value)}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  );
                })}
                <Grid container xs={2} alignItems="center">
                  <Box
                    component="button"
                    className={`${classes.blueBtn} blue-button`}
                    onClick={() => handleSave(b)}
                  >
                    <h1 className={`${classes.blueBtnText} flex-standard`}>
                      <Add />
                      {' '}
                      Save
                    </h1>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: '0 3em', width: '100%', display: 'flex' }}>
            <Box
              component="button"
              className="cancel-button"
              onClick={handleClose}
              style={{
                borderRadius: '6px',
                width: '120px',
              }}
            >
              <h1 className={`${classes.outlinedBtnText} flex-standard`}>
                Cancel
              </h1>
            </Box>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
