import React from 'react';
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryLabel,
  VictoryTheme,
} from 'victory';

import data from './LineChart.json';

export default function LineChart({ title }) {
  return (
    <>
      <div style={{
        display: 'flex', flexDirection: 'column', alignItems: 'center',
      }}
      >
        <h3>{title}</h3>
      </div>
      <VictoryChart
        // domainPadding will add space to each side of VictoryBar to
        // prevent it from overlapping the axis
        domainPadding={[30, 60]}
        width={1000}
        padding={{
          top: 30, left: 60, right: 60, bottom: 55,
        }}
        theme={VictoryTheme.material}
        height={400}
      >
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          tickFormat={(x) => x}
          style={{
            tickLabels: {
              fontSize: 20,
              padding: 10,
              fill: '#1F3566',
              fontWeight: 700,
            },
            ticks: { size: 0 },
            axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
            grid: { stroke: 'transparent' },
          }}
        />
        <VictoryAxis
          dependentAxis
          label="Ratio"
          axisLabelComponent={
            <VictoryLabel angle={0} verticalAnchor="middle" dy={-180} dx={10} />
          }
          tickFormat={(y) => `${y.toFixed(1)}`}
          style={{
            axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
            tickLabels: { fontSize: 14, padding: 5 },
            ticks: { stroke: '#FFFFFF', size: 0 },
            grid: { stroke: 'transparent' },
          }}
        />
        <VictoryGroup offset={0} colorScale={['#009AFC']}>
          <VictoryLine
            style={{
              data: { strokeWidth: 5, strokeLinecap: 'round' },
            }}
            data={data}
            x="x"
            y="y4"
            animate={{
              duration: 2000,
              onLoad: { duration: 2000 },
            }}
          />
        </VictoryGroup>
      </VictoryChart>
    </>
  );
}
