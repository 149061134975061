import React from 'react';
import Legend from './Legend';

export default function Title() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
      }}
    >
      <h1 style={{ marginRight: 20, marginBottom: 6 }}>Facilities Management</h1>
      {/* <Legend color="#2ECCA9" title="Good" />
      <Legend color="#FB9C5D" title="Caution" />
      <Legend color="#FF4560" title="Alert" /> */}
    </div>
  );
}
