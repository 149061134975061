import { Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { VictoryPie, VictoryLabel, VictoryLegend } from 'victory';
import _ from 'lodash';

import data from './RenewalOfTenanciesData.json';

const colorSet = [
  '#008FFB',
  '#B85EEE',
  '#FB9C5D',
  '#2ECCA9',
  '#008FFB',
  '#B85EEE',
  '#FB9C5D',
  '#2ECCA9',
  '#008FFB',
  '#B85EEE',
  '#FB9C5D',
  '#2ECCA9',
];
function RenewalOfTenanciesChart({ RenewalOfTenancySummary, RenewalOfTenancyDetail, upload = null }) {
  const non_renewal_area = (RenewalOfTenancySummary['Non Renewal']?.AREA / _.sumBy(Object.values(RenewalOfTenancySummary), (o) => o.AREA))
    * 100;
  const non_renewal_rental = (RenewalOfTenancySummary['Non Renewal']?.RENTAL
      / _.sumBy(Object.values(RenewalOfTenancySummary), (o) => o.RENTAL))
    * 100;
  const labels = [];
  const RotData = [];
  const LegendData = [];
  Object.keys(RenewalOfTenancyDetail).map((eachtype, idx) => {
    const y = RenewalOfTenancyDetail[eachtype].length;
    labels.push(`${eachtype} \n ${y} Tenants`);
    RotData.push({ x: idx + 1, y });
    LegendData.push({
      name: `${eachtype} (${y})`,
      symbol: { fill: colorSet[idx], type: 'square' },
    });
  });
  return (
    <>
      <Grid container justify="space-between">
        <h2 style={{ fontWeight: 600 }}>Renewal of Tenancies</h2>
        {upload}
      </Grid>
      <svg viewBox="0 0 550 340">
        <VictoryLegend
          x={300}
          y={40}
          orientation="horizontal"
          standalone={false}
          itemsPerRow={1}
          gutter={20}
          data={LegendData}
          style={{ labels: { fontFamily: 'Poppins', fontSize: 14 } }}
        />
        <VictoryPie
          colorScale={colorSet}
          standalone={false}
          width={340}
          height={340}
          data={RotData.length ? RotData : data}
          innerRadius={64}
          labels={[]}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontFamily: 'Poppins', fontSize: 30, fontWeight: 600 }}
          x={170}
          y={150}
          text={_.sumBy(RotData, (o) => o.y).toLocaleString()}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontFamily: 'Poppins', fontSize: 16 }}
          x={170}
          y={185}
          text={'Total expiry \n of tenancy'}
        />
      </svg>
      <Grid container>
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <Link to="/property/leasing/non-renewal-tenants">
            <p style={{ fontSize: 12 }}>Area of non renewal</p>
            <p style={{ fontSize: 18 }}>{isNaN(non_renewal_area) ? 0 : non_renewal_area.toFixed(0)}%</p>
            <p style={{ fontSize: 12 }}>{RenewalOfTenancySummary['Non Renewal']?.AREA.toLocaleString() ?? 0} ft²</p>
          </Link>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <Link to="/property/leasing/non-renewal-tenants">
            <p style={{ fontSize: 12 }}>Total rental of non renewal</p>
            <p style={{ fontSize: 18 }}>{isNaN(non_renewal_rental) ? 0 : non_renewal_rental.toFixed(0)}%</p>
            <p style={{ fontSize: 12 }}>
              RM{' '}
              {RenewalOfTenancySummary['Non Renewal']?.RENTAL.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }) ?? 0}
            </p>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default RenewalOfTenanciesChart;
