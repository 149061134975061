export default function Legend(props) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 5px',
        padding: '1px 8px',
        border: '1px solid rgba(30, 52, 101, 0.45)',
        borderRadius: 5,
      }}
    >
      <div
        style={{
          width: 12,
          height: 12,
          borderRadius: 2,
          backgroundColor: props.color,
        }}
      />
      <p style={{ fontSize: 12, marginLeft: 6 }}>{props.title}</p>
    </div>
  );
}
