import React from 'react';
import { VictoryPie, VictoryLabel, VictoryLegend } from 'victory';

function OccupancyRateChart({ NLA = 0, NLA_OC = 0 }) {
  const percentage_oc = (NLA_OC / NLA) * 100;
  const graph_data = [
    {
      x: 1,
      y: parseInt(percentage_oc.toFixed(0)),
    },
    {
      x: 2,
      y: 100 - parseInt(percentage_oc.toFixed(0)),
    },
  ];
  const LegendData = [
    {
      name: 'NLA',
      symbol: { fill: '#82C9FF', type: 'square' },
    },
    {
      name: 'NLA Occupied',
      symbol: { fill: '#008FFB', type: 'square' },
    },
  ];
  return (
    <>
      <h2 style={{ fontWeight: 600 }}>Occupancy Rate</h2>
      <svg viewBox="0 0 450 340">
        <VictoryPie
          colorScale={['#008FFB', '#82C9FF']}
          standalone={false}
          width={340}
          height={340}
          data={graph_data}
          innerRadius={64}
          labelComponent={<VictoryLabel style={{ fontFamily: 'Poppins', fontSize: 20 }} />}
          labels={[]}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 28 }}
          x={170}
          y={170}
          text={`${percentage_oc.toFixed(0)}%`}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontFamily: 'Poppins', fontSize: 20 }}
          x={350}
          y={170}
          text={`NLA\nOccupied\n ${NLA_OC?.toLocaleString()} ft²`}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontFamily: 'Poppins', fontSize: 20 }}
          x={170}
          y={320}
          text={`NLA ${NLA?.toLocaleString()} ft²`}
        />
        <VictoryLegend
          x={270}
          y={30}
          orientation="horizontal"
          standalone={false}
          itemsPerRow={1}
          gutter={20}
          data={LegendData}
          style={{ labels: { fontFamily: 'Poppins', fontSize: 14 } }}
        />
      </svg>
    </>
  );
}

export default OccupancyRateChart;
