/* eslint-disable complexity */
import { Grid } from '@material-ui/core';
import BarChart from '@Components/Management/Finance/BarChart';
import HorizontalBarCharts from '@Components/Management/DasbFinancial/HorizontalBarCharts';
import DebtorsAgingTable from '@Components/Management/DasbFinancial/DebtorsAgingTable';
import moment from 'moment';
import _ from 'lodash';
import { yearComparisons, currentMonth, monthsSequence } from '@Helpers';

const currYear = yearComparisons(2)[1];

export default (h) => {
  return (
    <Grid container spacing={3}>
      <Grid container item xs={8}>
        <Grid container spacing={3}>
          <RevenueOE data={h.financialData} />
          <BillingCollection data={h.financialData} />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <DebtorsAgingTable debtorAging={h.debtorAgingData.map(d => ({
          ...d,
          SecurityDebtorAgingGroupName: h.debtorsAgingGroup?.find(y => y.id === d.SecurityDebtorAgingGroupId)?.name,
        }))}
        />
      </Grid>
    </Grid>
  );
};

const RevenueOE = ({ data }) => {
  const groupData = [
    {
      title: 'Revenue',
      chart: [
        {
          title: `Current Month (${currYear})`,
          data: [
            { x: 'Budget', y: data?.find(d => d.year === currYear && d.month === currentMonth)?.revenue_budget || 0 },
            { x: 'Actual', y: data?.find(d => d.year === currYear && d.month === currentMonth)?.revenue_actual || 0 },
          ],
        },
        {
          title: 'Year to Date',
          data: [
            { x: 'Budget', y: _.sumBy(data?.filter(d => d.year === currYear), 'revenue_budget') || 0 },
            { x: 'Actual', y: _.sumBy(data?.filter(d => d.year === currYear), 'revenue_actual') || 0 },
          ],
        },
      ],
    },
    {
      title: 'Operating Expenses',
      chart: [
        {
          title: `Current Month (${currYear})`,
          data: [
            { x: 'Budget', y: data?.find(d => d.year === currYear && d.month === currentMonth)?.operating_budget || 0 },
            { x: 'Actual', y: data?.find(d => d.year === currYear && d.month === currentMonth)?.operating_actual || 0 },
          ],
        },
        {
          title: 'Year to Date',
          data: [
            { x: 'Budget', y: _.sumBy(data?.filter(d => d.year === currYear), 'operating_budget') || 0 },
            { x: 'Actual', y: _.sumBy(data?.filter(d => d.year === currYear), 'operating_actual') || 0 },
          ],
        },
      ],
    },
  ];
  return groupData.map(d => (
    <Grid container item xs={6}>
      <div className="paper" style={{ padding: 20, width: '100%' }}>
        <h3>{d.title}</h3>
        {d.chart.map(c => (
          <HorizontalBarCharts
            labelPrefix="RM"
            width={800}
            {...c}
          />
        ))}
      </div>
    </Grid>
  ));
};

const BillingCollection = ({ data }) => {
  const billingData = monthsSequence.map(month => ({
    month: moment(`${month}-${currYear}`, 'MM-YYYY').format('MMM YYYY'),
    billing: data.find(d => d.year === currYear && d.month === Number(month))?.billing || 0,
    collection: data.find(d => d.year === currYear && d.month === Number(month))?.collection || 0,
  }));
  return (
    <Grid container item xs={12}>
      <BarChart
        title="Billing &amp; Collection"
        legend={['Billing', 'Collection']}
        data={billingData}
        active="BNC"
        width={1200}
        style={{ width: '100%' }}
      />
    </Grid>
  );
};
