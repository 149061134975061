import Card from '@Components/Maintenance/Card';
import OpenIcon from '@Assets/Icons/OpenIcon.png';
import InProgressIcon from '@Assets/Icons/InProgressIcon.png';
import OnHoldIcon from '@Assets/Icons/OnHoldIcon.png';
import OverdueIcon from '@Assets/Icons/OverdueIcon.png';
import ResolvedIcon from '@Assets/Icons/ResolvedIcon.png';
import CallOffIcon from '@Assets/Icons/CallOffIcon.png';
import { Grid } from '@material-ui/core';

export function IssuesCards(h) {
  const issues = [
    {
      type: 'Open',
      color: '#008FFB',
      src: OpenIcon,
    },
    {
      type: 'In Progress',
      color: '#FB9C5D',
      src: InProgressIcon,
    },
    {
      type: 'On Hold',
      color: '#B85EEE',
      src: OnHoldIcon,
    },
    {
      type: 'Overdue',
      color: '#FF4560',
      src: OverdueIcon,
    },
    {
      type: 'Resolved',
      color: '#2ECCA9',
      src: ResolvedIcon,
    },
  ];

  return (
    <>
      <h3 style={{ fontWeight: 600, marginBottom: 10 }}>Issue</h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          alignItems: 'stretch',
          flexWrap: 'wrap',
        }}
      >
        {issues.map((c) => (
          <Card
            to={`/integrated-facilities/facilities/issue?=${c.type}&building=${h.facilities[h.selectedFacilities]?.institution_id}`}
            title={c.type}
            description={h.issue_summary[c.type].toLocaleString()}
            color={c.color}
            icon={<img src={c.src} height='40px' alt='' />}
          />
        ))}
      </div>
    </>
  );
}

export function PreventivesCards(h) {
  const tasks = [
    {
      type: 'Open',
      color: '#008FFB',
      src: OpenIcon,
    },
    {
      type: 'In Progress',
      color: '#FB9C5D',
      src: InProgressIcon,
    },
    {
      type: 'Pending Review',
      color: '#B85EEE',
      src: OnHoldIcon,
    },
    {
      type: 'Overdue',
      color: '#FF4560',
      src: OverdueIcon,
    },
    {
      type: 'Call Off',
      color: '#FF4560',
      src: CallOffIcon,
    },
    {
      type: 'Completed',
      color: '#2ECCA9',
      src: ResolvedIcon,
    },
  ];

  return (
    <>
      <h3 style={{ fontWeight: 600, marginBottom: 10, marginTop: 30 }}>Planned Preventive Maintenance</h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          alignItems: 'stretch',
          flexWrap: 'wrap',
        }}
      >
        {tasks.map((c) => (
          <Grid item xs={4}>
            <Card
              to={`/integrated-facilities/facilities/preventive?=${c.type}&building=${h.facilities[h.selectedFacilities]?.institution_id}`}
              title={c.type}
              description={h.task_summary[c.type]}
              color={c.color}
              icon={<img src={c.src} height='40px' alt='' />}
            />
          </Grid>
        ))}
      </div>
    </>
  );
}
