import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';

export default function Hook() {
  const [divisions, set_divisions] = useState([]);
  const [selected_division, set_selected_division] = useState(0);
  const [projects, set_projects] = useState([]);
  const [filtered_projects, set_filtered_projects] = useState([]);
  const [selected_project, set_selected_project] = useState(0);
  const [special_project_idx, set_special_project_idx] = useState(0);
  const [justSaved, setjustSaved] = useState(0);

  useEffect(() => {
    Api({
      endpoint: endpoints.getDivisions(),
      onSuccess: ({ data }) => {
        const notSpecial = data.filter(e => !e.is_special);
        const special = data.filter(e => e.is_special);
        set_special_project_idx(notSpecial.length);
        set_divisions([...notSpecial, ...special]);
      },
      onFail: (err) => { toast('error', err); },
    });

    Api({
      endpoint: endpoints.getProjects(),
      onSuccess: ({ data }) => { set_projects(data); },
      onFail: (err) => { toast('error', err); },
    });
  }, [justSaved]);

  useEffect(() => {
    if (!projects.length) return;
    const division_uuid = divisions?.[selected_division]?.uuid;
    const filterProject = projects.filter((p) => p.Division.uuid === division_uuid);
    set_filtered_projects([
      { name: 'View All Projects', lat: divisions?.[selected_division]?.lat, lng: divisions?.[selected_division]?.lng },
      ...filterProject,
    ]);
    set_selected_project(v => v ?? 0);
  }, [projects, divisions, selected_division]);
  return {
    divisions,
    division: divisions[selected_division] || {},
    selected_division,
    set_selected_division,
    projects,
    project: filtered_projects.length ? filtered_projects[selected_project] : projects[selected_project] || {},
    filtered_projects,
    selected_project,
    set_selected_project,
    special_project_idx,
    isSpecialSelected: selected_division >= special_project_idx,
    noSpecial: special_project_idx === divisions.length,
    setjustSaved,
  };
}
