import React from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
} from 'victory';
import { CircularProgress } from '@material-ui/core';
import { t } from '@Helpers';
import data from './BarChartData.json';

function BarChart(props) {
  const title = props.title?.split(' ').map(s => t(s)).join(' ');
  const selectorType = props.title?.split(' ')[0];
  const isYield = ['yield', 'Yield', 'YIELD'].includes(selectorType);
  return (
    <div
      className="paper"
      style={{
        padding: '10px 20px 20px 20px',
        ...props.style,
      }}
    >
      <div style={{ display: 'flex' }}>
        <h2 style={{ fontWeight: 600, marginRight: 20 }}>{t(title)}</h2>
        {props.loading && <CircularProgress disableShrink style={{ width: 35, height: 35 }} />}
      </div>
      <VictoryChart
        // domainPadding will add space to each side of VictoryBar to
        // prevent it from overlapping the axis
        padding={{
          left: 70, right: 10, top: 20, bottom: 30,
        }}
        domainPadding={{ x: [60, 60], y: [0, 20] }}
        width={1400}
        theme={VictoryTheme.material}
      >
        <VictoryLegend
          x={1150}
          y={0}
          orientation="horizontal"
          gutter={20}
          style={{ labels: { fontFamily: 'Poppins', fontSize: 12 } }}
          data={[
            {
              name: props.legend[0],
              symbol: { fill: '#008FFB', type: 'square' },
            },
            {
              name: props.legend[1],
              symbol: { fill: '#1F3566', type: 'square' },
            },
          ]}
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
          tickFormat={(x) => x}
          style={{
            tickLabels: { fontSize: 18, padding: 10 },
            ticks: { size: 0 },
            axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
            grid: { stroke: 'transparent' },
          }}
          offsetY={30}
          orientation="bottom"
        />
        <VictoryAxis
          style={{
            tickLabels: { fontSize: 0 },
            ticks: { size: 0 },
            axis: { stroke: 'rgba(30, 52, 101, 0.15)', strokeWidth: 1 },
            grid: { stroke: 'transparent' },
          }}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(x) => (isYield ? x : `${(x / 1000000).toFixed(1)} M`)}
          label={isYield ? '(%)' : '(RM)'}
          axisLabelComponent={<VictoryLabel angle={0} verticalAnchor="start" dy={-170} dx={-20} />}
          style={{
            axis: { stroke: '#FFFFFF' },
            tickLabels: {
              fontSize: 14,
              padding: 5,
              fill: 'rgba(30, 52, 101, 0.65)',
            },
            ticks: { stroke: '#FFFFFF', size: 0 },
            grid: { stroke: 'rgba(30, 52, 101, 0.15)', strokeDasharray: false },
          }}
          crossAxis={false}
        />
        <VictoryGroup offset={25} colorScale={['#008FFB', '#1F3566']}>
          <VictoryBar
            data={props.data ?? data}
            x="month"
            y="billing"
            barWidth={25}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            cornerRadius={3}
            style={{ labels: { fontSize: 9 } }}
            labels={({ datum }) => (isYield ? (datum._y?.toFixed(1)).toLocaleString() : Math.floor(datum._y)?.toLocaleString())}
          />
          <VictoryBar
            data={props.data ?? data}
            x="month"
            y="collection"
            barWidth={25}
            animate={{
              duration: 2000,
              onLoad: { duration: 2000 },
            }}
            cornerRadius={3}
            style={{ labels: { fontSize: 9 } }}
            labels={({ datum }) => (isYield ? (datum._y?.toFixed(1)).toLocaleString() : Math.floor(datum._y)?.toLocaleString())}
          />
        </VictoryGroup>
      </VictoryChart>
    </div>
  );
}

export default BarChart;
