import { useEffect, useState } from 'react';
import Api from '@Helpers/api';
import { endpoints } from '@Configs/endpoints';
import _ from 'lodash';

const buildings = {
  0: 261, // B5
  1: 157, // GALLERIA
  2: 161, // KOMTAR JBCC
  3: 156, // MENARA KOMTAR
  4: 162, // LARKIN SENTRAL
  5: 159, // MENARA ANSAR
  6: 155, // MENARA JLAND
  7: 163, // @MART
  8: 158, // Tg Leman Beach Resort
  9: 160, // PPTD
};

export default function Hook({
  selectedBuilding, allBuilding, facilities, selectedFacilities,
}) {
  const [issues, set_issues] = useState([]);
  const [is_update, set_is_update] = useState(0);
  const [is_ready, set_is_ready] = useState(0);
  const [is_loading, set_is_loading] = useState(true);
  const [refresh, set_refresh] = useState(0);
  const [categories, set_categories] = useState({});
  const [menuItem, set_menuItem] = useState([]);
  const [countCategory, set_countCategory] = useState([]);
  const [countIssues, set_countIssues] = useState([]);

  useEffect(() => {
    Api({
      endpoint: endpoints.getServedeck(),
      data: { query: '{category{id name}}' },
      onSuccess: ({ data: { category } }) => {
        if (category) {
          set_categories(category.reduce((a, b) => { a[b.id] = b.name; return a; }, {}));
          set_is_ready(is_ready + 1);
        }
      },
    });
  }, []);

  useEffect(() => {
    set_is_loading(true);
    const b = allBuilding ? '' : `(institution_id:${facilities ? facilities[selectedFacilities]?.institution_id : buildings[selectedBuilding]})`;
    Api({
      endpoint: endpoints.getServedeck(),
      data: { query: `{issue${b}{title category_id}}` },
      onSuccess: ({ data: { issue } }) => {
        if (issue) {
          set_issues(issue);
          set_is_update(is_update + 1);
          set_is_loading(false);
        }
      },
    });
  }, [selectedBuilding, selectedFacilities, refresh]);

  useEffect(() => {
    const data = issues.map(iss => ({ title: iss.title, category: categories[iss.category_id] }));

    set_issues(data);

    const byCategory = _.groupBy(data, e => e.category?.toUpperCase());
    const categoryLength = Object.keys(byCategory).map(m => ({ text: m, value: byCategory[m].length }));
    const menu = categoryLength.sort((a, b) => b.value - a.value);

    if (categoryLength.length === 1 && categoryLength[0].text === 'undefined') {
      set_refresh(refresh + 1);
    }

    set_countCategory(categoryLength);
    set_menuItem(menu.map(e => e.text));

    const obj = data
      .map(e => e.title.replace(/\s+/g, ' ').trim().toUpperCase())
      .reduce((a, b) => { (a[b] = a[b] ? a[b] + 1 : 1); return a; }, {});

    const arr = Object.keys(obj)
      .map(o => ({ text: o, value: obj[o] }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 60);

    set_countIssues(arr);
  }, [is_update, is_ready]);

  return {
    issues,
    countCategory,
    countIssues,
    menuItem,
    is_loading,
  };
}
