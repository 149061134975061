import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import _ from 'lodash';

const columns = [
  {
    name: 'Tenant',
    selector: 'TENANT',
  },
  {
    name: 'Amount (RM)',
    selector: 'AMOUNT',
    right: true,
  },
  {
    name: 'Total Day Aging',
    selector: 'TDA',
    right: true,
  },
];

export default function DebtorsAgingTable({ debtorAging, upload = null }) {
  const [debtorAgingSorted, set_debtorAgingSorted] = useState(_.orderBy(debtorAging, ['AMOUNT'], ['desc']));
  const sortTableBy = (selector) => {
    set_debtorAgingSorted(_.orderBy(debtorAging, [selector], ['desc']));
  };

  useEffect(() => {
    if (debtorAging) {
      set_debtorAgingSorted(_.orderBy(debtorAging, ['AMOUNT'], ['desc']));
    }
  }, [debtorAging]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2 style={{ fontWeight: 600 }}>Debtors Aging</h2>
        {upload}
      </div>
      <div className='paper' style={{ padding: '10px 20px', marginTop: 20 }}>
        <TableContainer style={{ maxHeight: 640 }}>
          <Table size='small' style={{ minWidth: 400 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell align={column.right && 'right'} onClick={() => sortTableBy(column.selector)}>
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {debtorAgingSorted?.map((row) => (
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      align={column.right && 'right'}
                      style={{ paddingTop: 5, paddingBottom: 20 }}
                    >
                      {column.selector === 'AMOUNT'
                        ? row[column.selector].toLocaleString(undefined, { minimumFractionDigits: 2 })
                        : row[column.selector]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
