import React, { useState } from 'react';
import {
  Dialog, Button, DialogActions, DialogContent, DialogContentText, IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default ({
  onDelete, user, row,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };

  const access = {
    OSH: 'can_delete_report_osh',
    Environment: 'can_delete_report_environment',
    'Quality Control 1': 'can_delete_report_quality',
    'Quality Control 2': 'can_delete_report_quality',
    'Quality Control 3': 'can_delete_report_quality',
    Preqlassic: 'can_delete_report_quality',
  };

  const canDelete = row.options?.split(', ')
    .map(opt => ((user?.id) ? !!user[access[opt]] : false))
    .reduce((a, b) => a && b, true);

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <DeleteIcon style={{ color: canDelete && 'red' }} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {canDelete
              ? 'Are you sure you want to delete this?'
              : 'You have no access to delete this report.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{canDelete ? 'Cancel' : 'Close'}</Button>
          {canDelete && (
            <Button Button onClick={() => onDelete(row.id)} color="secondary" autoFocus>
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
