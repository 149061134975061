
import { useEffect, useState } from 'react'

export default function Hook({ issues, menuItem, countIssues }) {
  const [countIssuesInSelectedCategory, set_countIssuesInSelectedCategory] = useState([])
  const [selectedCategory, set_selectedCategory] = useState('All Categories')

  useEffect(() => {
    if (selectedCategory === 'All Categories') {
      set_countIssuesInSelectedCategory(countIssues)
    } else {
      let obj = issues
        .filter(e => e.category?.toUpperCase() === selectedCategory)
        .map(e => e.title.replace(/\s+/g, ' ').trim().toUpperCase())
        .reduce((a, b) => { (a[b] ? a[b] += 1 : a[b] = 1); return a }, {})

      let arr = Object.keys(obj)
        .map(o => ({ text: o, value: obj[o] }))
        .sort((a, b) => a.value - b.value)
        .reverse()
        .slice(0, 60)

      set_countIssuesInSelectedCategory(arr)
    }
    // eslint-disable-next-line
  }, [selectedCategory, countIssues])

  useEffect(() => {
    set_selectedCategory('All Categories')
    // eslint-disable-next-line
  }, [menuItem])

  return {
    countIssuesInSelectedCategory,
    selectedCategory,
    set_selectedCategory,
  }
}