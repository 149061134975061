import { useEffect, useState } from 'react';
import Api from '@Helpers/api';
import { formatTime } from '@Helpers';
import { endpoints } from '@Configs/endpoints';
import useUrlQueries from '@Hooks/urlQueries';

const buildings = {
  0: 261, // B5
  1: 157, // GALLERIA
  2: 161, // KOMTAR JBCC
  3: 156, // MENARA KOMTAR
  4: 162, // LARKIN SENTRAL
  5: 159, // MENARA ANSAR
  6: 155, // MENARA JLAND
  7: 163, // @MART
  8: 158, // Tg Leman Beach Resort
  9: 160, // PPTD
};

const arrToObj = (arr) => arr.reduce((a, b) => { a[b.id] = b.name; return a; }, {});

export default function Hook({ selectedBuilding, allBuilding }) {
  const { url_queries } = useUrlQueries();
  const { '': status, building } = url_queries;
  const [issues, set_issues] = useState([]);
  const [is_update, set_is_update] = useState(0);
  const [assignee, set_assignee] = useState({});
  const [institutions, set_institutions] = useState({});
  const [areas, set_areas] = useState({});
  const [categories, set_categories] = useState({});

  // fetch all common data
  useEffect(() => {
    Api({
      endpoint: endpoints.getServedeck(),
      data: { query: '{user_extra{id name}institution{id name}area{id name}category{id name}}' },
      onSuccess: ({
        data: {
          user_extra, institution, area, category,
        },
      }) => {
        if (user_extra) set_assignee(arrToObj(user_extra));
        if (institution) set_institutions(arrToObj(institution));
        if (area) set_areas(arrToObj(area));
        if (category) set_categories(arrToObj(category));
      },
    });
  }, []);

  useEffect(() => {
    const b = allBuilding ? '' : `(institution_id:${building ?? buildings[selectedBuilding]})`;
    Api({
      endpoint: endpoints.getServedeck(),
      data: { query: `{issue${b}{issue_number title description status resolved_date detail_location created_date institution_id assignee_id reporter_id category_id area_id due_date priority_id accepted_by accepted_timestamp}}` },
      onSuccess: ({ data: { issue } }) => {
        if (issue) {
          set_issues(issue);
          set_is_update(is_update + 1);
        }
      },
    });
  }, [selectedBuilding]);

  // change Issue's foreign ID to its value from common data
  useEffect(() => {
    let filteredIssues;

    if (status === undefined) {
      filteredIssues = issues;
    } else if (status === 'Overdue') {
      filteredIssues = issues.filter(d => (['Open', 'In Progress'].includes(d.status) && parseInt(d.due_date) < new Date().getTime()));
    } else {
      filteredIssues = issues.filter(f => f.status === status);
    }

    set_issues(filteredIssues.map(s => ({
      ...s,
      assignee: assignee[s.assignee_id] ?? 'N/A',
      institution: institutions[s.institution_id],
      area: areas[s.area_id],
      category: categories[s.category_id],
      createdDate: (
        <div>
          <p>{formatTime(s.created_date, 'D/MM/YYYY')}</p>
          <p className="text-light">{formatTime(s.created_date, 'h:mm A')}</p>
        </div>
      ),
    })));
  }, [is_update]);

  return {
    issues,
  };
}
