/* eslint-disable max-nested-callbacks */
import { Grid } from '@material-ui/core';
import {
  VictoryAxis, VictoryChart, VictoryLabel, VictoryLegend, VictoryLine,
} from 'victory';
import _ from 'lodash';
import moment from 'moment';
import { yearComparisons, monthsSequence } from '@Helpers';

export default (h) => {
  const data = h.incidenceTypes.map(eachType => ({
    title: `${eachType.incidence_type} by Comparison`,
    lineData: yearComparisons(2).map(eachYear => ({
      name: eachYear,
      data: monthsSequence.map(month => ({
        x: `${eachYear}-${month}`,
        y: h.incidenceData.find(d => d.SecurityIncidenceTypeId === eachType.id
        && d.year === eachYear && d.month === Number(month))?.incidence || 0,
      })),
    })),
  }));

  const MinMaxSet = data.map(d => {
    const eachMinMax = d.lineData.map(eachLine => ({
      max: _.max(eachLine.data.map(({ y }) => y)),
      min: _.min(eachLine.data.map(({ y }) => y)),
    }));
    return {
      max: _.max(eachMinMax.map(({ max }) => max)),
      min: _.min(eachMinMax.map(({ min }) => min)),
    };
  });

  return (
    <Grid container spacing={3} style={{ marginTop: 12 }}>
      {data.map((d, idy) => (
        <Grid item xs={12} md={4}>
          <div className="paper finance-donut" style={{ height: 'auto' }}>
            <h4
              className="flex-standard"
              style={{
                margin: '0 0 10px 0', fontWeight: 700, color: '#1E3465',
              }}
            >
              {d.title}
            </h4>
            <div style={{ position: 'relative' }}>
              <VictoryChart
                height={650}
                width={1050}
                padding={{
                  left: 60, right: 30, top: 100, bottom: 50,
                }}
              >
                <VictoryAxis
                  style={{
                    tickLabels: { fontSize: 32, opacity: 0.65 },
                    ticks: { size: 0 },
                    axis: { stroke: 'transparent' },
                    grid: { stroke: 'transparent' },
                  }}
                  tickValues={d.lineData}
                  tickCount={12}
                  axisLabelComponent={(
                    <VictoryLabel
                      angle={0}
                      verticalAnchor="start"
                      dy={60}
                      dx={0}
                      style={{ fontSize: 32 }}
                    />
                  )}
                />
                <VictoryAxis
                  dependentAxis
                  crossAxis
                  label="Total"
                  axisLabelComponent={(
                    <VictoryLabel
                      angle={0}
                      verticalAnchor="start"
                      y={-3}
                      dx={0}
                      style={[{ fontSize: 36 }]}
                    />
                  )}
                  domain={[MinMaxSet[idy].min, MinMaxSet[idy].max]}
                  style={{
                    tickLabels: { fontSize: 32, opacity: 0.65 },
                    ticks: { size: 0 },
                    axis: { stroke: 'transparent' },
                    grid: { stroke: 'transparent' },
                  }}
                  standalone={false}
                />
                <VictoryLegend
                  x={300}
                  y={-20}
                  orientation="horizontal"
                  gutter={100}
                  style={{ labels: { fontFamily: 'Poppins', fontSize: 40 } }}
                  data={d.lineData?.map((line, idx) => ({
                    name: line.name,
                    symbol: { fill: idx % 2 ? '#008FFB' : '#FB9C5D', type: 'square' },
                  }))}
                />
                {d.lineData && (
                  d.lineData.map((line, idx) => (
                    <VictoryLine
                      interpolation="linear"
                      style={{
                        data: { stroke: idx % 2 ? '#008FFB' : '#FB9C5D' },
                        parent: { border: '1px solid #A93226' },
                      }}
                      data={line.data.map(({ x, y }) => (
                        { y, x: moment(x).format('MMM').toUpperCase() }
                      ))}
                    />
                  ))
                )}
              </VictoryChart>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
