import React, { useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,
  InputAdornment, MenuItem, Select, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { yearComparisons } from '@Helpers';

const useStyles = makeStyles(() => ({
  blueBtn: {
    borderRadius: '6px',
    width: '17em',
    marginRight: '2em',
    position: 'absolute',
    top: '0px',
    right: '50px',
  },
  blueBtnText: { color: '#FFFFFF', fontWeight: 600, fontSize: 16 },
  input: { paddingBottom: 10, paddingTop: 10 },
  closeBtn: {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  backBtn: {
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    left: '7px',
  },
}));

export default ({
  open, setOpen, ContractorId, ...props
}) => {
  const [project, setproject] = useState('');
  const [short_project, setshort_project] = useState('');
  const [GFA, setGFA] = useState(0.00);
  const [no_samples, setno_samples] = useState(0);
  const [cidb_score, setcidb_score] = useState(0.00);
  const [preqlassic_score, setpreqlassic_score] = useState(0.00);
  const [year, setYear] = useState(yearComparisons()[1]);
  const classes = useStyles();

  const update = () => {
    props.onSave({
      project, short_project, ContractorId, GFA, no_samples, year, cidb_score, preqlassic_score,
    });
  };
  return (
    <Dialog
      open={open}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>
        <ArrowBackIosIcon
          className={classes.backBtn}
          onClick={() => setOpen(false)}
        />
        <p style={{ marginLeft: 20, fontSize: 20, fontWeight: 600 }}>Add data</p>
        <Close
          className={classes.closeBtn}
          onClick={() => setOpen(false)}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add Information for the Contractor
        </DialogContentText>
        <Grid container className="flex-standard">
          <Grid xl={2} lg={2} md={2} sm={2} className={classes.input}>
            Project Name <strong>*</strong>
          </Grid>
          <Grid xl={10} lg={10} md={10} sm={10}>
            <TextField
              value={project}
              required
              id="project"
              fullWidth
              autoFocus
              onChange={(e) => setproject(e.target.value)}
            />
          </Grid>
          <Grid xl={2} lg={2} md={2} sm={2} className={classes.input}>
            Short Project Name
          </Grid>
          <Grid xl={10} lg={10} md={10} sm={10}>
            <TextField
              value={short_project}
              required
              id="short_project"
              fullWidth
              autoFocus
              onChange={(e) => setshort_project(e.target.value)}
            />
          </Grid>
          <Grid xl={2} lg={2} md={2} sm={2} className={classes.input}>
            Year <strong>*</strong>
          </Grid>
          <Grid xl={10} lg={10} md={10} sm={10}>
            <Select
              labelId="demo-simple-select-label"
              id="year"
              value={year}
              onChange={(e) => setYear(Number(e.target.value))}
            >
              {[...Array(100)].map((e, i) => (<MenuItem value={i + 1980} children={i + 1980} />))}
            </Select>
          </Grid>
          <Grid xl={2} lg={2} md={2} sm={2} className={classes.input}>
            GFA
          </Grid>
          <Grid xl={10} lg={10} md={10} sm={10}>
            <TextField
              value={GFA}
              required
              id="gfa"
              fullWidth
              autoFocus
              type="number"
              onChange={(e) => setGFA(Number(e.target.value))}
            />
          </Grid>
          <Grid xl={2} lg={2} md={2} sm={2} className={classes.input}>
            No. of samples
          </Grid>
          <Grid xl={10} lg={10} md={10} sm={10}>
            <TextField
              value={no_samples}
              required
              id="no_samples"
              fullWidth
              autoFocus
              type="number"
              onChange={(e) => setno_samples(Number(e.target.value))}
            />
          </Grid>
          <Grid xl={2} lg={2} md={2} sm={2} className={classes.input}>
            PreQlassic Score
          </Grid>
          <Grid xl={10} lg={10} md={10} sm={10}>
            <TextField
              value={preqlassic_score}
              required
              id="preqlassic_score"
              fullWidth
              autoFocus
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={(e) => setpreqlassic_score(Number(e.target.value))}
            />
          </Grid>
          <Grid xl={2} lg={2} md={2} sm={2} className={classes.input}>
            CIDB Qlassic Score
          </Grid>
          <Grid xl={10} lg={10} md={10} sm={10}>
            <TextField
              value={cidb_score}
              required
              id="cidb_score"
              fullWidth
              autoFocus
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={(e) => setcidb_score(Number(e.target.value))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button color="primary" autoFocus onClick={update}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
