import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import _ from 'lodash';

const columns = [
  {
    name: 'Tenant',
    selector: 'TENANT',
  },
  {
    name: 'Amount (RM)',
    selector: 'AMOUNT',
    right: true,
  },
  {
    name: 'Total Day Aging',
    selector: 'TDA',
    right: true,
  },
];

export default function DebtorsAgingTable({ DebtByBuilding }) {
  const [expanded, setExpanded] = useState();
  const [debtorAgingSorted, set_debtorAgingSorted] = useState(_.orderBy(DebtByBuilding, ['AMOUNT'], ['desc']));
  const Building = _.groupBy(DebtByBuilding, 'building');
  const sortTableBy = (selector) => {
    set_debtorAgingSorted(_.orderBy(DebtByBuilding, [selector], ['desc']));
  };

  const handleClick = (idx) => {
    setExpanded(expanded.map((boolean_value, i) => {
      if (idx === i) return !boolean_value;
    }));
  };

  const totalAmount = (e) => {
    const countTotal = (items) => items.reduce((acc, curr) => acc + curr.AMOUNT, 0);
    return countTotal(debtorAgingSorted?.filter(x => x.building === e));
  };

  const totalTDA = (e) => {
    const countTotal = (items) => items.reduce((acc, curr) => acc + curr.TDA, 0);
    return countTotal(debtorAgingSorted?.filter(x => x.building === e));
  };

  useEffect(() => {
    if (DebtByBuilding) {
      set_debtorAgingSorted(_.orderBy(DebtByBuilding, ['AMOUNT'], ['desc']));
      setExpanded([...Array(Object.keys(Building)?.length)].map((val) => false));
    }
  }, [DebtByBuilding]);

  return (
    <div className="paper" style={{ padding: 10, height: '98%' }}>
      <h2 style={{ fontWeight: 600 }}>Debtors Aging</h2>
      <TableContainer style={{ maxHeight: '100vh' }}>
        <Table size="small" style={{ minWidth: 400 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell align={column.right && 'right'} onClick={() => sortTableBy(column.selector)}>
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {Object.keys(Building)?.map((e, idx) => (
            <TableBody>
              <TableRow colSpan={3}>
                {columns.map((col) => (
                  <TableCell
                    align={col.right && 'right'}
                    onClick={() => handleClick(idx)}
                    style={{ paddingTop: 5, paddingBottom: 20, cursor: 'pointer' }}
                  >
                    {col.selector === 'TENANT' && (
                      <>
                        <IconButton size="small" style={{ transform: 'scale(0.65)', marginLeft: -10 }}>
                          {expanded[idx] ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        {e}
                      </>
                    )}
                    {col.selector === 'AMOUNT' && totalAmount(e).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    {col.selector === 'TDA' && totalTDA(e)}
                  </TableCell>
                ))}
              </TableRow>
              {expanded[idx] && (
                <>
                  {debtorAgingSorted?.filter(x => x.building === e).map((row) => (
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          align={column.right && 'right'}
                          style={{ paddingTop: 5, paddingBottom: 20, backgroundColor: 'rgba(184, 206, 255, 0.06)' }}
                        >
                          {column.selector === 'AMOUNT'
                            ? row[column.selector].toLocaleString(undefined, { minimumFractionDigits: 2 })
                            : row[column.selector]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </div>
  );
}
