import React from 'react';
import {
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryGroup,
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryAxis,
} from 'victory';

export default function Chart(props) {
  return (
    <>
      <VictoryLegend
        x={700}
        y={0}
        height={30}
        width={1200}
        orientation='horizontal'
        gutter={20}
        style={{ labels: { fontFamily: 'Poppins', fontSize: 12 } }}
        data={[
          {
            name: props.legends[0],
            symbol: { fill: '#008FFB', type: 'square' },
          },
          {
            name: props.legends[1],
            symbol: { fill: '#1F3566', type: 'square' },
          },
        ]}
      />
      <VictoryChart height={600} width={2200} domainPadding={10} containerComponent={<VictoryVoronoiContainer />}>
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickValues={props.tickValues}
          style={{
            tickLabels: { fontSize: 24, padding: 20, opacity: 0.65 },
            ticks: { size: 0 },
            axis: { stroke: 'transparent' },
            grid: { stroke: 'transparent' },
          }}
        />
        <VictoryGroup
          color='#008FFB'
          labels={({ datum }) => [props.pointers[0], datum.y]}
          labelComponent={
            <VictoryTooltip
              style={{ fontSize: 24, fill: '#008FFB' }}
              flyoutPadding={20}
              flyoutStyle={{
                fill: '#FFFFFF',
                stroke: 'rgba(0,0,0, 0.1)',
                strokeWidth: 3,
              }}
              pointerOrientation='left'
              centerOffset={{ x: 200, y: 20 }}
            />
          }
          data={props.graphOne}
        >
          <VictoryLine
            interpolation='linear'
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            style={{ data: { strokeWidth: 5 } }}
          />
          <VictoryScatter
            size={({ active }) => (active ? 5 : 0)}
            style={{
              data: {
                stroke: '#008FFB',
                fillOpacity: 0,
                strokeWidth: 3,
              },
            }}
          />
        </VictoryGroup>
        <VictoryGroup
          color='#1F3566'
          labels={({ datum }) => [props.pointers[1], datum.y]}
          labelComponent={
            <VictoryTooltip
              style={{ fontSize: 24, fill: '#1F3566' }}
              flyoutPadding={20}
              flyoutStyle={{
                fill: '#FFFFFF',
                stroke: 'rgba(0,0,0, 0.1)',
                strokeWidth: 3,
              }}
              pointerOrientation='left'
              centerOffset={{ x: 200, y: 60 }}
            />
          }
          data={props.graphTwo}
        >
          <VictoryLine
            interpolation='linear'
            animate={{
              duration: 2000,
              onLoad: { duration: 2000 },
            }}
            style={{ data: { strokeWidth: 5 } }}
          />
          <VictoryScatter
            size={({ active }) => (active ? 5 : 0)}
            style={{
              data: {
                stroke: '#1F3566',
                fillOpacity: 0,
                strokeWidth: 3,
              },
            }}
          />
        </VictoryGroup>
      </VictoryChart>
    </>
  );
}
