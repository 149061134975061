import React from 'react';
import MaterialTable from '@Components/MaterialTable';
import useHook from './hook';

const columns = [
  {
    name: 'Occurence',
    selector: 'occurence',
    sortable: true,
    align: 'left',
    minWidth: 60,
  },
  {
    name: 'Issue',
    selector: 'issue',
    sortable: true,
    align: 'right',
    minWidth: 60,
  },
  // {
  //   name: "Issue no.",
  //   selector: "issueNo",
  //   sortable: true,
  //   align: "right",
  //   minWidth: 60,
  // },
];
export default function TopRecurringIssue(props) {
  const h = useHook(props);
  return (
    <>
      <MaterialTable
        tableHead
        columns={columns}
        tableData={h.tableData}
        tableMinWidth={300}
        tableMaxHeight={300}
      />
    </>
  );
}
