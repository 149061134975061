import { Grid } from '@material-ui/core';
import PieChart from '@Components/Finance/PieChart';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import MaterialTable from '@Components/MaterialTable';
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import { monthComparisons, yearComparisons } from '@Helpers';

const latest_month = monthComparisons(2).format('MMMM YYYY');

const columns = [
  {
    name: 'Description', selector: 'description', sortable: true, align: 'left', minWidth: 100,
  },
  {
    name: 'Actual', selector: 'actual', sortable: true, align: 'right', minWidth: 90,
  },
  {
    name: 'Budget', selector: 'budget', sortable: true, align: 'right', minWidth: 90,
  },
];

const columns2 = [
  {
    name: 'Description', selector: 'description', sortable: true, align: 'right', minWidth: 100,
  },
  {
    name: 'Actual', selector: 'actual', sortable: true, align: 'right', minWidth: 90,
  },
  {
    name: 'Budget', selector: 'budget', sortable: true, align: 'right', minWidth: 90,
  },
];
const subColumns = [
  { selector: 'name' },
];

function ServiceCharge({ building: { uuid } }) {
  const [table_data, set_table_data] = useState([]);
  const [table_data2, set_table_data2] = useState([]);
  const [pie_data, set_pie_data] = useState([]);
  const [NLA, set_NLA] = useState(0);
  const localCurrency = (num) => num.toLocaleString('en-MY', { style: 'currency', currency: 'MYR' });
  useEffect(() => {
    Api({
      data: { building_uuid: uuid, YEAR: yearComparisons(2)[1] },
      endpoint: endpoints.getOccupancy(),
      onSuccess: (response) => set_NLA(response.data[0].NLA),
      onFail: set_NLA(0),
    });
  }, [uuid]);
  useEffect(() => {
    Api({
      data: { uuid },
      endpoint: endpoints.getServiceCharge(),
      onSuccess: ({ data }) => {
        if (data?.length > 0) {
          const graphData = data.map(e => ({
            description: e.Service,
            actual: localCurrency(e.ACTUAL),
            budget: localCurrency(e.BUDGET),
          }));
          const pieData = data.map(e => ({
            service: e.Service,
            expenses: e.ACTUAL,
          }));
          const sumActual = _.sumBy(data, o => o.ACTUAL);
          const sumBudget = _.sumBy(data, o => o.BUDGET);
          const analytic = [
            {
              description: 'Total building expenses',
              actual: localCurrency(sumActual),
              budget: localCurrency(sumBudget),
            },
            {
              description: 'Total saving for this month',
              actual: <span style={{ color: 'rgba(46,204,169,1)' }}>{localCurrency(sumBudget - sumActual)}</span>,
            },
            {
              description: 'Total Net Lettable Area (NLA)',
              actual: `${NLA?.toLocaleString()} ft²,`,
            },
            // {
            //   description: 'Service Charge p.s.f',
            //   actual: 'RM 1.20',
            //   budget: 'RM 4.20',
            // },
          ];
          set_table_data([{ name: latest_month, data: graphData }]);
          set_table_data2(analytic);
          set_pie_data(pieData);
        } else {
          set_table_data([]);
          set_table_data2([]);
        }
      },
    });
  }, [uuid, NLA]);

  return (
    <>
      <MainContentNavbar to="/property/finance" text="SERVICE CHARGE" />
      <MainContentContainer>
        <Grid container>
          <Grid item md={12} lg={6}>
            <MaterialTable
              columns={columns}
              subColumnTop
              subColumns={subColumns}
              tableData={table_data}
              tableMinWidth={500}
              tableMaxHeight={700}
            />
            <MaterialTable
              tableHead={false}
              columns={columns2}
              tableData={table_data2}
              tableMinWidth={500}
              tableMaxHeight={700}
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <div
              style={{
                padding: 20,
                marginTop: 40,
              }}
            >
              <PieChart data={pie_data} />
            </div>
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default ServiceCharge;
