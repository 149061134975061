/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
  Grid, Modal, Paper, Tab, Tabs,
} from '@material-ui/core';
import {
  Add, ArrowBack, Close, PlayCircleFilled,
} from '@material-ui/icons';
import Dropdown from '@Components/Inspection/Dropdown';
import Button from '@Components/Inspection/Button';
import PdfIcon from '@Components/Inspection/PdfIcon';
import ProgressButton from '@Components/Inspection/ProgressButton';
import TabPanel from '@Components/Inspection/TabPanel';

import './style.css';

import Filter from '@Components/Inspection/Filter';
import CustomCheckbox from '@Components/Inspection/CustomCheckbox';
import FilterInput from '@Components/Inspection/FilterInput';
import DateDropdown from '@Components/Inspection/DateDropdown';
import Dropzone from '@Components/Inspection/Dropzone';
import AnnotateImage from '@Components/Inspection/AnnotateImage';
import MainContentNavbar from '@Components/MainContentNavbar';
import Hook from './hook';
import { a11yProps } from './helper';

const siteImage = 'https://de4fa44r3eebh.cloudfront.net/uploads/companies/4/assets/10/aerial-inspections/11/inspection-medias/2vM14ay8DF9a8953XhTZ4O31rRTy0esVpSZrZx2K.jpeg';
// const defaultImage = "https://de4fa44r3eebh.cloudfront.net/uploads/companies/4/assets/10/aerial-inspections/11/inspection-medias/2vM14ay8DF9a8953XhTZ4O31rRTy0esVpSZrZx2K.jpeg";

const phases = [
  { name: 'Completed', selector: 'completed' },
  { name: 'Planning', selector: 'planning' },
  { name: 'O & M', selector: 'om' },
  { name: 'Under Construction', selector: 'underConstruction' },
  { name: 'Decomission', selector: 'decomission' },
];

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

const initialAddDataState = { phase: '', date: '', img: '' };

export default function Inspection(props) {
  const {
    images = [],
    videos = [],
    mainImage = {},
    setMainImage = () => null,
    mainVideo = {},
    setMainVideo = () => null,
  } = Hook(props);
  // Progress Button
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const startProgress = async () => {
    setLoading(true);
    for (let i = 1; i <= 100; i++) {
      setProgress(i);
      await sleep(100);
    }
    setLoading(false);
  };

  // Filter Dropdown
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [filterSeverity, setFilterSeverity] = useState([
    { name: 'Minor', checked: false },
    { name: 'Major', checked: false },
    { name: 'Critical', checked: false },
    { name: 'Solved', checked: false },
  ]);
  const [filterTags, setFilterTags] = useState([]);
  const handleFilterTagChange = (event, newValue) => {
    console.log(newValue);
  };

  // Tab Handler
  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  // Modal
  const [modal, setModal] = useState(false);
  const handleModalOpen = () => {
    setModal(true);
  };
  const handleModalClose = () => {
    setModal(false);
  };

  // Modal Video
  const [modalVideo, setModalVideo] = useState(false);
  const handleModalVideoOpen = () => {
    setModalVideo(true);
  };
  const handleModalVideoClose = () => {
    setModalVideo(false);
  };

  // Add Data Form
  const [addData, setAddData] = useState(initialAddDataState);
  const handleAddData = (value, selector) => {
    setAddData({ ...addData, [selector]: value });
  };

  // Project Phase
  const [projectPhase, setProjectPhase] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    const data = [{ src: siteImage, metaData: [] }];
    const vidData = [
      {
        imgSrc: siteImage,
        vidSrc:
          'https://de4fa44r3eebh.cloudfront.net/uploads/companies/4/assets/10/aerial-inspections/11/inspection-medias/DJI_0011.mp4',
      },
    ];
    // const initImages = async () => {
    //   let i = 1;
    //   while (i <= 30) {
    //     data.push({
    //       src: siteImage,
    //       metaData: [],
    //     });
    //     vidData.push({
    //       imgSrc: siteImage,
    //       vidSrc:
    //         "https://de4fa44r3eebh.cloudfront.net/uploads/companies/4/assets/10/aerial-inspections/11/inspection-medias/DJI_0011.mp4",
    //     });
    //     i++;
    //   }
    //   if (data.length > 0) {
    //     setImages([...data]);
    //     setMainImage(data[0]);
    //   }

    //   if (vidData.length > 0) {
    //     setVideos([...vidData]);
    //     setMainVideo(vidData[0]);
    //   }
    // };

    // initImages();
    setFilterTags([
      { id: 1, name: 'Telco Tower' },
      { id: 2, name: 'Disc' },
      { id: 3, name: 'Network' },
    ]);
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <MainContentNavbar to="/property" text={props.building.name} />
      </Grid>
      <Grid item md={12} lg={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              className="sidebar paper"
              style={{ padding: '10px 30px 30px 30px', border: '1px solid #1E3465' }}
            >
              <Grid item xs={12} container justify="space-between" alignItems="center">
                <Grid item>
                  <Tabs value={tab} onChange={handleTabChange} centered>
                    <Tab label="Image" {...a11yProps(0)} />
                    <Tab label="Video" {...a11yProps(1)} />
                  </Tabs>
                </Grid>
                <Grid item>
                  <Filter active={filterDropdown} setActive={setFilterDropdown}>
                    <p>Severity</p>
                    <div>
                      {filterSeverity.length > 0
                        && filterSeverity.map((filter, index) => (
                          <CustomCheckbox
                            key={index}
                            name={filter.name}
                            checked={filter.checked}
                            onChange={() => {
                              const newFilters = filterSeverity;
                              newFilters[index].checked = !filterSeverity[index]
                                .checked;
                              setFilterSeverity([...newFilters]);
                            }}
                          />
                        ))}
                    </div>
                    <p>Asset tag</p>
                    <FilterInput
                      placeholder="Add asset tag"
                      data={filterTags}
                      onChange={handleFilterTagChange}
                    />
                    <div
                      className="flex row left middle"
                      style={{ paddingTop: 15 }}
                    >
                      <Button variant="contained" style={{ marginLeft: 0 }}>
                        Filter
                      </Button>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 14,
                          marginLeft: 6,
                          cursor: 'pointer',
                        }}
                        onClick={() => setFilterDropdown(false)}
                      >
                        Cancel
                      </p>
                    </div>
                  </Filter>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ padding: 1 }}
                    onClick={handleModalOpen}
                  >
                    <Add style={{ fontSize: 32 }} />
                  </Button>
                </Grid>
              </Grid>
              <div className="sidebar-container">
                <TabPanel value={tab} index={0}>
                  <Grid container spacing={1}>
                    {images.length > 0
                      && images.map((image, index) => (
                        <Grid key={index} item xs={4}>
                          <div
                            className="img-container"
                            style={{ backgroundImage: `url(${image.src})` }}
                            onClick={() => setMainImage(image)}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <Grid container spacing={3}>
                    {videos.length > 0
                      && videos.map((video, index) => (
                        <Grid key={index} item xs={12}>
                          <div
                            className="video-container"
                            onClick={() => setMainVideo(video)}
                          >
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              className="flex row center middle video-img"
                              style={{
                                backgroundImage: `url(${video.imgSrc})`,
                                borderTopLeftRadius: 5,
                                borderTopRightRadius: 5,
                              }}
                            >
                              <PlayCircleFilled />
                            </Grid>
                            <div className="flex row middle video-description">
                              <p style={{ color: '#03A398', fontWeight: 600 }}>
                                {video.asset}
                              </p>
                              <p style={{ fontSize: '0.8em', opacity: 0.65 }}>
                                {video.title}
                              </p>
                            </div>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </TabPanel>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} lg={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {tab === 0
              && (images.length > 0 ? (
                <AnnotateImage data={mainImage} setData={setMainImage} />
              ) : (
                <div className="flex column center middle">
                  <img
                    src={props.building.image}
                    width={350}
                    style={{ marginTop: 100 }}
                    alt=""
                  />
                  <h1
                    style={{
                      fontSize: '3em',
                      color: '#e5e5e5',
                      marginBottom: 20,
                    }}
                  >
                    No data have been added
                  </h1>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleModalOpen}
                    style={{ transform: 'scale(1.3)' }}
                  >
                    Add data
                  </Button>
                </div>
              ))}
            {tab === 1
              && (videos.length > 0 ? (
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  className="main-img flex column center middle"
                  style={{ backgroundImage: `url(${mainVideo.imgSrc})` }}
                >
                  <PlayCircleFilled onClick={handleModalVideoOpen} />
                </Grid>
              ) : (
                <div className="flex column center middle">
                  <img
                    src={props.building.image}
                    width={350}
                    style={{ marginTop: 100 }}
                    alt=""
                  />
                  <h1
                    style={{
                      fontSize: '3em',
                      color: '#e5e5e5',
                      marginBottom: 20,
                    }}
                  >
                    No data have been added
                  </h1>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleModalOpen}
                    style={{ transform: 'scale(1.3)' }}
                  >
                    Add data
                  </Button>
                </div>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={modal}
        onClose={handleModalClose}
        className="flex flex-standard row center middle"
      >
        <Paper style={{ padding: '10px 20px', width: 800, outline: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="flex" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h1 style={{ color: '#03A398' }}>Add Data</h1>
                <Close
                  style={{
                    cursor: 'pointer', color: '#6C757D', fontSize: 18, marginRight: '-10px',
                  }}
                  onClick={handleModalClose}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <p style={{ fontWeight: 600, color: '#6C757D', marginBottom: 5 }}>
                Project Phase
              </p>
              <Dropdown
                title="Choose"
                options={phases}
                selected={addData.phase}
                w="100%"
                onChange={(val) => handleAddData(val, 'phase')}
                m={0}
                variant="white"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p style={{ fontWeight: 600, color: '#6C757D', marginBottom: 5 }}>
                Date
              </p>
              <DateDropdown
                selected={addData.date}
                onChange={(val) => handleAddData(val, 'date')}
                m={0}
                variant="white"
              />
            </Grid>
            <Grid item xs={12}>
              <p style={{ fontWeight: 600, color: '#6C757D', marginBottom: 5 }}>
                Upload Image{' '}
                <span
                  style={{
                    fontSize: '0.8em',
                    color: '#ADB5BD',
                    marginLeft: 10,
                    fontWeight: 400,
                  }}
                >
                  File format should be in jpg, png.
                </span>
              </p>
              <Dropzone handleModalClose={handleModalClose} />
            </Grid>
          </Grid>
        </Paper>
      </Modal>
      <Modal
        open={modalVideo}
        onClose={handleModalVideoClose}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <div style={{
          width: '80%', height: '80%', outline: 0, position: 'relative',
        }}
        >
          <Close
            style={{
              color: 'white',
              backgroundColor: 'red',
              cursor: 'pointer',
              position: 'fixed',
              top: 10,
              right: 10,
              zIndex: 2,
            }}
            onClick={handleModalVideoClose}
          />
          <video width="100%" height="100%" autoPlay controls>
            <source src={mainVideo.vidSrc} />
          </video>
        </div>
      </Modal>
    </Grid>
  );
}
