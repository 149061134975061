import React from 'react';
import { Link } from 'react-router-dom';

export default function Card(props) {
  return (
    <Link
      to={props.to}
      className='paper'
      style={{
        display: 'flex',
        flex: 1,
        marginRight: 10,
        marginBottom: 10,
        padding: '10px',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {props.icon}
      </div>
      <div
        style={{
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingLeft: 15,
          // paddingRight: 15,
        }}
      >
        <p className='text-light' style={{ fontSize: 12 }}>
          {props.title}
        </p>
        <p style={{ color: props.color, fontSize: 30, fontWeight: 600, lineHeight: 1 }}>{props.description}</p>
      </div>
    </Link>
  );
}
