import React from 'react';

export default () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'>
    <g id='Icon_feather-upload' data-name='Icon feather-upload' transform='translate(-3.5 -3.5)'>
      <path
        id='Path_1680'
        data-name='Path 1680'
        d='M16.5,22.5v2.667A1.333,1.333,0,0,1,15.167,26.5H5.833A1.333,1.333,0,0,1,4.5,25.167V22.5'
        transform='translate(0 -10)'
        fill='none'
        stroke='#fff'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
      />
      <path
        id='Path_1681'
        data-name='Path 1681'
        d='M17.167,7.833,13.833,4.5,10.5,7.833'
        transform='translate(-3.333)'
        fill='none'
        stroke='#fff'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
      />
      <path
        id='Path_1682'
        data-name='Path 1682'
        d='M18,4.5v8'
        transform='translate(-7.5)'
        fill='none'
        stroke='#fff'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
      />
    </g>
  </svg>
)