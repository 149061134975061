import { Grid } from '@material-ui/core';
import Table from '@Components/Table';
import MainContentContainer from '@Components/MainContentContainer';
import MainContentNavbar from '@Components/MainContentNavbar';
import useHook from './hook';

const columns = [
  {
    name: 'Preventive Maintenance Number',
    selector: 'task_execution_running_number',
    sortable: true,
    width: '80px',
  },
  {
    name: 'Site',
    selector: 'institution',
    sortable: true,
    right: false,
    width: '110px',
    wrap: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    wrap: true,
    width: '300px',
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    right: false,
    // wrap: true,
    width: '200px',
  },
  {
    name: 'Assignee',
    selector: 'assignee',
    sortable: true,
    right: false,
    wrap: true,
    width: '200px',
  },
  {
    name: 'Created Date',
    selector: 'createdDate',
    sortable: true,
    right: false,
    wrap: true,
    width: '130px',
  },
  {
    name: 'Schedule Date',
    selector: 'scheduleDate',
    sortable: true,
    right: false,
    wrap: true,
    width: '130px',
  },
  {
    name: 'Due Date',
    selector: 'dueDate',
    sortable: true,
    right: false,
    wrap: true,
    width: '130px',
  },
  {
    name: 'Overall Progress',
    selector: 'progress',
    sortable: true,
    wrap: true,
    width: '160px',
  },
];

function MaintenanceTable(props) {
  const h = useHook(props);
  return (
    <>
      <MainContentNavbar to={props.prevUrl} text={props.text || 'MAINTENANCE'} />
      <MainContentContainer>
        <Grid container>
          <Grid item xs={12}>
            <h2>TASKS</h2>
            <Table columns={columns} data={h.tasks} />
          </Grid>
        </Grid>
      </MainContentContainer>
    </>
  );
}

export default MaintenanceTable;
