import { db } from "@Configs/firebase";
import { useEffect, useState } from "react";

export default function Hook(props) {
    const [cctvData, setCctvData] = useState({})

    useEffect(() => {
        db.ref(`cctv/${props.building}`).on("value", (snapshot) => setCctvData(snapshot.val()));
        return () => {
            db.ref(`cctv/${props.building}`).off("value")
        };
    }, []);

    return {
        cctvData,
    }
}