import Navbar from '@Components/Management/Navbar';
import FinancialStatement from './components/FinancialStatement';
import useHook from './hook';

export default function MgmtDasbFinancial(props) {
  const h = useHook(props);

  return (
    <>
      <Navbar />
      <h1 style={{ fontWeight: 700, marginBottom: 10 }}>
        DASB Group of Company <span style={{ opacity: 0.35, fontWeight: 600 }}>(as of {h.latest_updated})</span>
      </h1>
      <FinancialStatement {...h} />
    </>
  );
}
