export const DashboardIcon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='#1e3465' width='16' height='16' viewBox='0 0 16 16' {...props}>
      <path d='M6.167,0h-5A1.168,1.168,0,0,0,0,1.167v3A1.168,1.168,0,0,0,1.167,5.333h5A1.168,1.168,0,0,0,7.333,4.167v-3A1.168,1.168,0,0,0,6.167,0Zm0,0' />
      <path
        d='M6.167,213.332h-5A1.168,1.168,0,0,0,0,214.5v7a1.168,1.168,0,0,0,1.167,1.167h5A1.168,1.168,0,0,0,7.333,221.5v-7A1.168,1.168,0,0,0,6.167,213.332Zm0,0'
        transform='translate(0 -206.665)'
      />
      <path
        d='M283.5,341.332h-5a1.168,1.168,0,0,0-1.167,1.167v3a1.168,1.168,0,0,0,1.167,1.167h5a1.168,1.168,0,0,0,1.167-1.167v-3A1.168,1.168,0,0,0,283.5,341.332Zm0,0'
        transform='translate(-268.665 -330.665)'
      />
      <path
        d='M283.5,0h-5a1.168,1.168,0,0,0-1.167,1.167v7A1.168,1.168,0,0,0,278.5,9.334h5a1.168,1.168,0,0,0,1.167-1.167v-7A1.168,1.168,0,0,0,283.5,0Zm0,0'
        transform='translate(-268.665)'
      />
    </svg>
  );
};
export const MapIcon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='#1e3465' width='18' height='16' viewBox='0 0 18 16' {...props}>
      <g transform='translate(-1 -2.5)'>
        <path
          d='M1.5,6.2V19l5.6-3.2L13.5,19l5.6-3.2V3L13.5,6.2,7.1,3Z'
          transform='translate(0 0)'
          stroke='#fff'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1'
        />
        <path
          d='M12,3V15.8'
          transform='translate(-4.901 0)'
          stroke='#fff'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1'
        />
        <path
          d='M24,9V21.8'
          transform='translate(-10.503 -2.801)'
          stroke='#fff'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='1'
        />
      </g>
    </svg>
  );
};
export const BuildingIcon = (props) => {
  return (
    <svg fill='#1e3465' height='16' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g>
        <path d='m204.869 379.739h102.261v132.261h-102.261z' />
        <path d='m165.24 96.309h43.508v43.508h-43.508z' />
        <path d='m303.252 96.309h43.508v43.508h-43.508z' />
        <path d='m165.24 235.954h43.508v43.508h-43.508z' />
        <path d='m303.252 235.954h43.508v43.508h-43.508z' />
        <path d='m482.284 482h-39.573v-467c0-8.284-6.716-15-15-15h-343.423c-8.284 0-15 6.716-15 15v467h-39.572c-8.284 0-15 6.716-15 15s6.716 15 15 15h145.154v-147.261c0-8.284 6.716-15 15-15h132.26c8.284 0 15 6.716 15 15v147.261h145.154c8.284 0 15-6.716 15-15s-6.715-15-15-15zm-105.524-400.691v73.508c0 8.284-6.716 15-15 15h-73.508c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15zm-138.012 213.153c0 8.284-6.716 15-15 15h-73.508c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15zm0-139.645c0 8.284-6.716 15-15 15h-73.508c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15zm49.504 154.645c-8.284 0-15-6.716-15-15v-73.508c0-8.284 6.716-15 15-15h73.508c8.284 0 15 6.716 15 15v73.508c0 8.284-6.716 15-15 15z' />
      </g>
    </svg>
  );
};
export const HRIcon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='#1e3465' width='25' height='16' viewBox='0 0 25 16' {...props}>
      <g id='Icon_ionic-ios-people' data-name='Icon ionic-ios-people' transform='translate(-2.215 -7.748)'>
        <path
          d='M20.944,20.057c-.614-.219-1.617-.23-2.061-.4a2.912,2.912,0,0,1-.954-.444,5.9,5.9,0,0,1-.159-1.563,2.524,2.524,0,0,0,.543-.768,8.53,8.53,0,0,0,.263-1.508s.362.154.5-.57c.121-.625.351-.954.291-1.415s-.318-.351-.318-.351a4.105,4.105,0,0,0,.318-2.051,3.281,3.281,0,0,0-3.542-3.24,3.292,3.292,0,0,0-3.547,3.24,4.166,4.166,0,0,0,.313,2.051s-.258-.11-.318.351.164.79.291,1.415c.143.729.5.57.5.57a8.613,8.613,0,0,0,.263,1.508,2.524,2.524,0,0,0,.543.768,5.9,5.9,0,0,1-.159,1.563,2.8,2.8,0,0,1-.954.439c-.439.17-1.442.192-2.061.411a3.869,3.869,0,0,0-2.511,3.684H23.45A3.862,3.862,0,0,0,20.944,20.057Z'
          transform='translate(-1.316 0)'
        />
        <path
          d='M8.164,18.149a3.722,3.722,0,0,0,1.831-.51c-.85-1.288-.389-2.791-.565-4.194a2.313,2.313,0,0,0-2.56-2.237H6.848a2.32,2.32,0,0,0-2.538,2.237c-.175,1.4.313,3.07-.559,4.194a3.445,3.445,0,0,0,1.837.455h0a2.777,2.777,0,0,1-.055.921,1.384,1.384,0,0,1-.658.3,12.721,12.721,0,0,0-1.42.395,2.308,2.308,0,0,0-1.239,2.04h4.4a3.939,3.939,0,0,1,1.754-1.634,3.682,3.682,0,0,1,1.354-.285s.208-.329-.477-.455A4.674,4.674,0,0,1,8.2,19.01C8.092,18.889,8.164,18.149,8.164,18.149Z'
          transform='translate(0 -0.762)'
        />
        <path
          d='M25.639,18.149a3.722,3.722,0,0,1-1.831-.51c.85-1.288.389-2.791.565-4.194a2.313,2.313,0,0,1,2.56-2.237h.022a2.32,2.32,0,0,1,2.538,2.237c.175,1.4-.313,3.07.559,4.194a3.445,3.445,0,0,1-1.837.455h0a2.777,2.777,0,0,0,.055.921,1.384,1.384,0,0,0,.658.3,12.721,12.721,0,0,1,1.42.395,2.308,2.308,0,0,1,1.239,2.04h-4.4a3.939,3.939,0,0,0-1.754-1.634,3.682,3.682,0,0,0-1.354-.285s-.208-.329.477-.455a4.674,4.674,0,0,0,1.053-.367C25.71,18.889,25.639,18.149,25.639,18.149Z'
          transform='translate(-4.756 -0.762)'
        />
      </g>
    </svg>
  );
};
export const MarketingIcon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16' {...props}>
      <g id='megaphone' transform='translate(-30.136)'>
        <g id='Group_6657' data-name='Group 6657' transform='translate(35.949 0.128)'>
          <g id='Group_6656' data-name='Group 6656'>
            <path
              id='Path_1792'
              data-name='Path 1792'
              d='M224,10.615l-6.24-6.241A.938.938,0,1,0,216.437,5.7l6.241,6.24A.937.937,0,1,0,224,10.615Z'
              transform='translate(-216.163 -4.1)'
              fill='#1e3465'
            />
          </g>
        </g>
        <g id='Group_6659' data-name='Group 6659' transform='translate(33.49 2.591)'>
          <g id='Group_6658' data-name='Group 6658'>
            <path
              id='Path_1793'
              data-name='Path 1793'
              d='M139.763,82.9l-.047.235a10.076,10.076,0,0,1-2.222,4.5l3.395,3.395a10.043,10.043,0,0,1,4.479-2.244l.236-.047Z'
              transform='translate(-137.494 -82.905)'
              fill='#1e3465'
            />
          </g>
        </g>
        <g id='Group_6661' data-name='Group 6661' transform='translate(30.136 8.03)'>
          <g id='Group_6660' data-name='Group 6660' transform='translate(0)'>
            <path
              id='Path_1794'
              data-name='Path 1794'
              d='M32.868,256.989l-2.32,2.32a1.406,1.406,0,0,0,0,1.989l1.326,1.326a1.406,1.406,0,0,0,1.989,0l2.32-2.32Zm.331,3.646a.469.469,0,1,1-.663-.663l.663-.663a.469.469,0,0,1,.663.663Z'
              transform='translate(-30.136 -256.989)'
              fill='#1e3465'
            />
          </g>
        </g>
        <g id='Group_6663' data-name='Group 6663' transform='translate(35.188 10.814)'>
          <g id='Group_6662' data-name='Group 6662'>
            <path
              id='Path_1795'
              data-name='Path 1795'
              d='M194.764,349.175l.638-.638a1.4,1.4,0,0,0,0-1.988l-.474-.474a8.889,8.889,0,0,0-.755.571l.566.566a.468.468,0,0,1,0,.662l-.648.648-.952-.923-1.326,1.326,2.127,2.061a.937.937,0,0,0,1.325-1.327Z'
              transform='translate(-191.813 -346.075)'
              fill='#1e3465'
            />
          </g>
        </g>
        <g id='Group_6665' data-name='Group 6665' transform='translate(39.564)'>
          <g id='Group_6664' data-name='Group 6664' transform='translate(0)'>
            <path
              id='Path_1796'
              data-name='Path 1796'
              d='M332.333,0a.468.468,0,0,0-.469.469v.937a.469.469,0,1,0,.937,0V.469A.468.468,0,0,0,332.333,0Z'
              transform='translate(-331.864)'
              fill='#1e3465'
            />
          </g>
        </g>
        <g id='Group_6667' data-name='Group 6667' transform='translate(42.376 3.75)'>
          <g id='Group_6666' data-name='Group 6666' transform='translate(0)'>
            <path
              id='Path_1797'
              data-name='Path 1797'
              d='M423.27,120h-.937a.469.469,0,0,0,0,.937h.937a.469.469,0,0,0,0-.937Z'
              transform='translate(-421.864 -120)'
              fill='#1e3465'
            />
          </g>
        </g>
        <g id='Group_6669' data-name='Group 6669' transform='translate(41.439 0.937)'>
          <g id='Group_6668' data-name='Group 6668'>
            <path
              id='Path_1798'
              data-name='Path 1798'
              d='M393.6,30.137a.469.469,0,0,0-.663,0l-.937.937a.469.469,0,0,0,.663.663l.937-.937A.469.469,0,0,0,393.6,30.137Z'
              transform='translate(-391.864 -30)'
              fill='#1e3465'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export const BellIcon = (props) => {
  return (
    <svg
      id='Component_28_70'
      data-name='Component 28 – 70'
      xmlns='http://www.w3.org/2000/svg'
      height='20'
      viewBox='0 0 23.179 22.252'
      {...props}
    >
      `{' '}
      <g id='Group_6364' data-name='Group 6364' transform='translate(4683.179 15622)'>
        <path
          id='Icon_material-notifications-none'
          data-name='Icon material-notifications-none'
          d='M14.205,23.751A2.057,2.057,0,0,0,16.257,21.7h-4.1A2.057,2.057,0,0,0,14.205,23.751ZM20.36,17.6V12.468c0-3.149-1.672-5.785-4.616-6.482v-.7a1.539,1.539,0,0,0-3.077,0v.7c-2.933.7-4.616,3.323-4.616,6.482V17.6L6,19.648v1.026H22.411V19.648Zm-2.051,1.026H10.1V12.468c0-2.544,1.549-4.616,4.1-4.616s4.1,2.072,4.1,4.616Z'
          transform='translate(-4689.177 -15623.499)'
          fill='#001833'
        />
        <path
          id='Icon_material-notifications'
          data-name='Icon material-notifications'
          d='M14.207,23.754A2.058,2.058,0,0,0,16.258,21.7h-4.1A2.051,2.051,0,0,0,14.207,23.754ZM20.362,17.6V12.469c0-3.149-1.682-5.786-4.616-6.483v-.7a1.539,1.539,0,1,0-3.077,0v.7c-2.944.7-4.616,3.324-4.616,6.483V17.6L6,19.65v1.026H22.413V19.65Z'
          transform='translate(-4689.179 -15623.502)'
          fill='#001833'
        />
        <g id='Group_6354' data-name='Group 6354' transform='translate(-4873 -16352)'>
          <circle
            id='Ellipse_438'
            data-name='Ellipse 438'
            cx='7'
            cy='7'
            r='7'
            transform='translate(199 730)'
            fill='#f05069'
          />
          <text
            id='_99'
            data-name='99'
            transform='translate(206 740)'
            fill='#fff'
            font-size='8'
            font-family='Poppins-Medium, Poppins'
            font-weight='500'
          >
            <tspan x='-5.08' y='0'>
              99
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};
