import React from "react";
import { Modal } from "@material-ui/core";
import EmployeeDetail from './EmployeeDetail';

export default function EmployeeDetailModal(p) {
  return (
    <Modal
      open={p.open}
      onClose={p.handleClose}
      className="flex-standard"
      style={{ outline: 0 }}
    >
      <EmployeeDetail {...p} modal />
    </Modal>
  );
}