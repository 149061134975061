import React from 'react';
import Navbar from '@Components/Management/Navbar';
import { Grid } from '@material-ui/core';
import CreationPlan from './components/CreationPlan';
import PerformanceReview from './components/PerformanceReview';
import TransformationTracksTable from './components/TransformationTracksTable';
import PerformanceCharts from './components/PerformanceCharts'
import useHook from './hook';

export default function MgmtReid(props) {
  const h = useHook(props);

  return (
    <>
      <Navbar />
      <h1 style={{ fontWeight: 700, marginBottom: 10 }}>
        Real Estate &amp; Infrastructure Division Johor Corporation
      </h1>
      <Grid xs={12} className="main-content" style={{ marginBottom: '2rem' }}>
        <PerformanceReview {...h} />
      </Grid>
      <Grid xs={12} className="main-content" style={{ marginBottom: '2rem' }}>
        <CreationPlan {...h} />
      </Grid>
      <Grid xs={12} className="main-content" style={{ marginBottom: '2rem' }}>
        <TransformationTracksTable {...h}/>
      </Grid>
      <Grid xs={12} className="main-content" style={{ marginBottom: '2rem' }}>
        <PerformanceCharts {...h} />
      </Grid>
    </>
  );
}
