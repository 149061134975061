import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';

export default function Hook({ facilities, selectedFacilities }) {
  const [issues, set_issues] = useState([]);
  const [categories, set_categories] = useState({});
  const [tableData, set_tableData] = useState([]);

  useEffect(() => {
    Api({
      endpoint: endpoints.getServedeck(),
      data: { query: '{category{id name}}' },
      onSuccess: ({ data: { category } }) => {
        if (category) {
          set_categories(category.reduce((a, b) => { a[b.id] = b.name; return a; }, {}));
        }
      },
    });
  }, []);

  useEffect(() => {
    if (!facilities.length) return;
    if (selectedFacilities === undefined) return;
    Api({
      endpoint: endpoints.getServedeck(),
      data: { query: `{issue(institution_id:${facilities[selectedFacilities].institution_id}){title category_id}}` },
      onSuccess: ({ data: { issue } }) => {
        if (issue) {
          set_issues(issue);
        }
      },
    });
  }, [selectedFacilities]);

  useEffect(() => {
    const data = issues.map(d => ({ ...d, category: categories[d.category_id] }));

    const byCategory = _.groupBy(data, e => e.category);
    const categoryLength = Object.keys(byCategory).map(m => ({ issue: m, occurence: byCategory[m].length }));
    const sorted = _.orderBy(categoryLength, ['occurence'], ['desc']);

    set_tableData(sorted);
  }, [issues, categories]);

  return {
    tableData,
  };
}
