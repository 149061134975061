import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

import "./filter.css";

export default function FilterInput({ data, onChange }) {
  return (
    <Autocomplete
      className="filter-input"
      multiple
      options={data}
      getOptionLabel={(option) => option.name}
      filterSelectedOptions
      disableCloseOnSelect
      disablePortal
      debug
      fullWidth
      closeIcon={false}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} placeholder="Add asset tag" />
      )}
    />
  );
}
